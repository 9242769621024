<template>
  <v-card elevation="2" class="pa-1" ref="blocklyCard">
    <div id="blocklyDiv" ref="blocklyDiv" v-bind:style="blocklyStyles" />
  </v-card>
</template>

<script>
// import Interpreter as JSInterpreter from 'js-interpreter.js';
import Interpreter from 'js-interpreter';
import * as Blockly from 'blockly/core';
import 'blockly/blocks';
import 'blockly/blockly';
import 'blockly/browser';
import 'blockly/javascript';
import 'blockly/python';
import 'blockly/csharp';

import 'blockly/blocks_compressed';
import 'blockly/blockly_compressed';
import * as JA from 'blockly/msg/ja';

import './../module/custom_category_es5'
import './../module/toolbox_label_es5'
import { codeLanguage } from './../module/defines';

import * as blk_default from './../blocks/blocks_default'
import * as blk_js_draw from './../blocks/blocks_js_draw'
import * as blk_js_chart from './../blocks/blocks_js_chart'
import * as blk_js_timer from './../blocks/blocks_js_timer'
import * as blk_js_debug from './../blocks/blocks_js_debug'
import * as blk_js_image from './../blocks/blocks_js_image'
// import * as blk_js_input from './../blocks/blocks_js_input'
import * as blk_js_map from './../blocks/blocks_js_map'
import * as blk_js_TakamatsuOpenData from './../blocks/blocks_js_TakamatsuOpenData'
import * as blk_js_three from './../blocks/blocks_js_three'
import * as blk_js_json from './../blocks/blocks_js_json'
// import * as blk_js_camera from './../blocks/blocks_js_camera'
import * as blk_js_camera_mp from './../blocks/blocks_js_camera_mediapipe'
import * as blk_js_ui from './../blocks/blocks_js_ui'
import * as blk_py_numpy from './../blocks/blocks_python_numpy'
import * as blk_py_pandas from './../blocks/blocks_python_pandas'
import * as blk_html from './../blocks/blocks_html'
import * as blk_js_textex from './../blocks/blocks_js_textex'
import * as blk_cs_class from './../blocks/blocks_cs_class'
import * as blk_js_ar from './../blocks/blocks_js_ar'

export default {
    name: 'BlockView',

    // firebaseオブジェクト
    fbo: null,

    props: [
        'updateComponent',
    ],

    watch: {
        updateComponent(newVal) {
            if (newVal) {
                // サイズを更新
                this.blocklyDivW = (this.$refs.blocklyCard.$el.clientWidth);
                this.blocklyDivH = (this.$refs.blocklyCard.$el.clientHeight);
                Blockly.svgResize(this.workspace);
            }
        }
    },

    data() {
        return {
            // Blockly オブジェクト
            workspace: null,

            timeoutID: 100,
            delay: 300,

            blocklyDivW: 100,
            blocklyDivH: 100,

            highlightPause: false,
            latestCode: '',
            // outputArea: '',
            myInterpreter: null,
        };
    },
    created() {
        // firebaseオブジェクト
        this.fbo = this.$store.state.firebaseObject;
    },
    computed: {
        blocklyStyles() {
            return {
                'width': this.blocklyDivW,
                'height': this.blocklyDivH,
            }
        }
    },
    async mounted() {
        // ログイン設定情報を取得
        var accountInf = this.$store.state.accountInf;

        // 日本語化
        Blockly.setLocale(JA);

        // 色
        Blockly.HSV_SATURATION = 0.6;
        Blockly.HSV_VALUE = 0.8;

        // HATデザイン
        Blockly.BlockSvg.START_HAT = true;
        Blockly.BlockSvg.ADD_START_HATS = true;

        // 縁取り線
        Blockly.blockRendering.ConstantProvider.prototype.generateTertiaryColour_ =
            (colour) => Blockly.utils.colour.blend('#000', colour, 1.0) || colour;

        // DIV
        var blocklyDiv = document.getElementById('blocklyDiv');

        var selectionLanguage = 0;
        // 選択言語
        if (this.$store.state.selectionLanguage == codeLanguage.JS){
            selectionLanguage = 0;
        }
        else if (this.$store.state.selectionLanguage == codeLanguage.Python){
            selectionLanguage = 1;
        }
        else if (this.$store.state.selectionLanguage == codeLanguage.CS){
            selectionLanguage = 2;
        }
        else if (this.$store.state.selectionLanguage == codeLanguage.HTML){
            selectionLanguage = 3;
        }

        // console.log('LOGIN_TYPE:' + accountInf.env.LOGIN_TYPE);
        // console.log('EXT_LINK:' + accountInf.env.EXT_LINK);

        // Blockly初期化
        this.workspace = Blockly.inject(blocklyDiv, {
            trashcan: true,
            readOnly: false,
            move: {
                scrollbars: true,
                drag: true,
                wheel: true,
            },
            theme: 'Zelos',
            renderer: 'zelos',

            grid: {
                spacing: 10,
                length: 10,
                colour: '#ddd',
                snap: true
            },
            zoom: {
                controls: true,
                wheel: true,
                startScale: 0.7,
                maxScale: 3,
                minScale: 0.3,
                scaleSpeed: 1.2
            },
            oneBasedIndex: false,
            toolbox:
            (accountInf.env.EXT_LINK == 0/*accountInf.env.LOGIN_TYPE == 999*/) ? 
            (
            `<xml xmlns="https://developers.google.com/blockly/xml" id="toolbox-categories" style="display: none;">`
            + 
            `<toolboxlabel name="` + this.$store.state.selectionLanguage + `" colour="darkslategrey" css-label="customLabel"></toolboxlabel>`
                + (selectionLanguage != 3 ? blk_default.category_Logic : ``)
                + (selectionLanguage != 3 ? blk_default.category_Loop : ``)
                + (selectionLanguage != 3 ? blk_default.category_Math : ``)
                + (selectionLanguage != 3 ? blk_default.category_Text : ``)
                + (selectionLanguage != 3 ? blk_js_textex.category_js_textex : ``)
                + ((selectionLanguage != 2 && selectionLanguage != 3) ? blk_default.category_Variables : ``)
                + (selectionLanguage == 2 && accountInf.blocks.CS_CLASS == true ? blk_default.category_ClassMember : ``)
                + ((selectionLanguage != 2 && selectionLanguage != 3) ? blk_default.category_Lists : ``)
                + (selectionLanguage == 2 ? blk_default.category_TypedLists : ``)
                + ((selectionLanguage != 2 && selectionLanguage != 3) ? blk_default.category_Functions : ``)
                + (selectionLanguage == 2 && accountInf.blocks.CS_CLASS == true ? blk_default.category_ClassMethod : ``)
                + (selectionLanguage == 2 && accountInf.blocks.CS_CLASS == true ? blk_cs_class.category_classDef : ``)
                + (selectionLanguage == 0 && accountInf.blocks.JS_DRAW == true ? blk_js_draw.category_Draw : ``)
                + (selectionLanguage == 0 && accountInf.blocks.JS_CHART == true ? blk_js_chart.category_Chart : ``)
                + (selectionLanguage == 0 && accountInf.blocks.JS_TIMER == true ? blk_js_timer.category_Timer : ``)
                + (selectionLanguage == 0 && accountInf.blocks.JS_IMAGE == true ? blk_js_image.category_Image : ``)
                + (selectionLanguage == 0 && accountInf.blocks.JS_DEBUG == true ? blk_js_debug.category_Debug : ``)
                + (selectionLanguage == 0 && accountInf.blocks.JS_THREE == true ? blk_js_three.category_Three : ``)
                // + blk_js_camera.category_Camera
                + (selectionLanguage == 0 && accountInf.blocks.JS_MEDIAPIPE == true ? blk_js_camera_mp.category_Camera_Mediapipe : ``)
                + (selectionLanguage == 0 && accountInf.blocks.JS_INPUT == true ? blk_js_ui.category_UI : ``)
                + (selectionLanguage == 0 && accountInf.blocks.JS_MAP == true ? blk_js_map.category_googleMap : '')
                + (selectionLanguage == 0 && accountInf.blocks.JS_MAP == true ? blk_js_json.category_json : '')            
                + (selectionLanguage == 0 && accountInf.blocks.JS_AR == true ? blk_js_ar.category_ar : ``)
                // + (accountInf.blocks.JS_INPUT == 1 ? blk_js_input.category_Input : '')
                // // + blk_html.category_Html
                + (selectionLanguage == 1 ? blk_py_numpy.category_python_numpy : ``)
                + (selectionLanguage == 1 ? blk_py_pandas.category_python_pandas : ``)
                + (selectionLanguage == 3 ? blk_html.category_Html : ``)
                + (selectionLanguage == 0 && accountInf.blocks.JS_TAKAMATSU_OPEN_DATA == true ? blk_js_TakamatsuOpenData.category_TakamatsuOpenData : ``)
            + '</xml>'
            ) : ``
            //`
        });
        
        // Load the interpreter now, and upon future changes.
        this.generateCodeAndLoadIntoInterpreter();
        this.workspace.addChangeListener((event) => {
            if (!(event instanceof Blockly.Events.Ui)) {
                // Something changed. Parser needs to be reloaded.
                this.generateCodeAndLoadIntoInterpreter();
            }
        });

        blk_js_three.setUploadedImageData(this.$store.getters.getUploadedImageData);
        blk_js_ar.setUploadedImageData3D(this.$store.getters.getUploadedImageData3D);
    },
    methods: {
        // メインブロックの高さ計算
        calcMainBlockHeight() {
            //return window.innerHeight - 260;
            return window.innerHeight - 180;
        },
        // 新規プロジェクト作成
        mnNewProject(){
            try {
                // 全ブロックを消去
                Blockly.mainWorkspace.clear();

            } catch (e) {
                alert(e);
            }
        },
        // プロジェクトを保存
        async mnDataSave(title, comment){

            // オブジェクトの存在確認
            if (this.fbo == null
                || this.workspace == null
                || String(title).length <= 0){
                //
                console.log('mnDataSave: ' + 'workspace is null');
                //
                return;
            }

            // try {
                // 作成ブロックのコードを生成
                var xml = Blockly.Xml.workspaceToDom(this.workspace);
                var xml_text = Blockly.Xml.domToText(xml);

                // /DATAS/アカウトの登録
                await this.fbo.writeAccountDatas(this.$store.state.loginId)
                // /DATAS/PROJECTNAME/プロジェクト名を生成
                await this.fbo.writeProjectDatas(this.$store.state.loginId, 'projectName', this.$store.state.selectionLanguage);
                // データを保存
                await this.fbo.writeCodeDatas(this.$store.state.loginId, 'projectName', title, comment, xml_text);

            // } catch (e) {
            //     alert(e);
            // }
        },
        // コードのステップ実行
        mnStepExec(){
            this.stepCode();
        },
        // コードの停止
        mnBlockStop() {
            // ステップ実行をリセット
            this.myInterpreter = null;
            this.resetStepUi(true);
        },
        // ブロックを更新
        mnAddImage() {
            console.log('mnAddImage:');

            // オブジェクトの存在確認
            if (this.workspace == null){
                return;
            }
            var blocks = this.workspace.getAllBlocks();

            for (var i = 0; i < blocks.length; i++) {
                var block = blocks[i];
                if (block.type == 'js_three_block_mesh_basic_material') {
                    console.log('block.type:' + block.type);

                    // var datas = [
                    //     ['false', 'false'],
                    //     ['true', 'true'],
                    // ];
                    
                    // block.doValueUpdate_(datas);
                    // block.setFieldValue(datas, 'texture_image');
                    block.updateDropDown();
                }
            }
            
        },
        // ブロックを更新
        mnAddImage3D() {
            console.log('mnAddImage3D:');

            // オブジェクトの存在確認
            if (this.workspace == null){
                return;
            }
            var blocks = this.workspace.getAllBlocks();

            for (var i = 0; i < blocks.length; i++) {
                var block = blocks[i];
                if (block.type == 'js_ar_block_3d_material') {
                    console.log('block.type:' + block.type);

                    // var datas = [
                    //     ['false', 'false'],
                    //     ['true', 'true'],
                    // ];
                    
                    // block.doValueUpdate_(datas);
                    // block.setFieldValue(datas, 'texture_image');
                    block.updateDropDown();
                }
            }
            
        },
        // ブロックコピー
        mnEditCopy() {
            // オブジェクトの存在確認
            if (this.workspace == null){
                return;
            }
            // copy
            try {
                // 選択済みブロックを取得
                var selectedBlock = Blockly.selected;
                if (selectedBlock == null) {
                    return;
                }

                // ブロックをコピー
                Blockly.duplicate(Blockly.selected);

            } catch (e) {
                alert(e);
            }
        },
        // createKeyboardEvent: function (name, key, altKey, ctrlKey, shiftKey, metaKey/*, bubbles*/) {
        //     var e = new Event(name)
        //     e.key = key
        //     e.keyCode = e.key.charCodeAt(0)
        //     e.which = e.keyCode
        //     e.altKey = altKey
        //     e.ctrlKey = ctrlKey
        //     e.shiftKey = shiftKey
        //     e.metaKey =  metaKey
        //     // e.bubbles = bubbles
        //     return e
        // },
        // ブロック貼り付け
        mnEditPaste() {
            // paste
            try {
                // オブジェクトの存在確認
                if (this.workspace == null){
                    return;
                }
                // 貼り付け
                Blockly.clipboard.paste();
            } catch (e) {
                alert(e);
            }
        },
        // もとに戻す
        mnEditUndo() {
            // オブジェクトの存在確認
            if (this.workspace == null){
                return;
            }
            // もどす
            try {
                this.workspace.undo(false);
            } catch (e) {
                alert(e);
            }
        },
        // すすめる
        mnEditRedo() {
            // オブジェクトの存在確認
            if (this.workspace == null){
                return;
            }
            // すすめる
            try {
                this.workspace.undo(true);
            } catch (e) {
                alert(e);
            }
        },
        // 有効・無効
        mnEnabled() {
            // オブジェクトの存在確認
            if (this.workspace == null){
                return;
            }

            try {
                // 選択済みブロックを取得
                var selectedBlock = Blockly.selected;
                if (selectedBlock == null) {
                    return;
                }
                // ブロック無効に
                selectedBlock.setEnabled(!selectedBlock.isEnabled());

            } catch (e) {
                alert(e);
            }
        },
        // 折りたたむ
        mnExpand() {
        },
        // 整理する
        mnAdjustment() {
        },
        // 削除する
        mnDelete() {
            // オブジェクトの存在確認
            if (this.workspace == null){
                return;
            }

            try {
                // 選択済みブロックを取得
                var selectedBlock = Blockly.selected;
                if (selectedBlock == null) {
                    return;
                }
                if (!selectedBlock.isDeletable()) {
                    return;
                }
                // ブロック削除
                selectedBlock.block.disopose(false, true);

            } catch (e) {
                alert(e);
            }
        },
        // コード生成
        mnGenerateCode() {

            // コード生成処理
            // window.LoopTrap = 1000;
            // Blockly.JavaScript.INFINITE_LOOP_TRAP = 'if(--window.LoopTrap == 0) throw "Infinite loop.";\n';
            var code = '';

            if (this.$store.state.selectionLanguage == codeLanguage.JS){
                code = Blockly.JavaScript.workspaceToCode(this.workspace);
            }
            else if (this.$store.state.selectionLanguage == codeLanguage.Python){
                code = Blockly.Python.workspaceToCode(this.workspace);
            }
            else if (this.$store.state.selectionLanguage == codeLanguage.CS){
                code = Blockly.CSharp.workspaceToCode(this.workspace);
            }
            else if (this.$store.state.selectionLanguage == codeLanguage.HTML){
                code = Blockly.JavaScript.workspaceToCode(this.workspace);
            }

            if (code == undefined){
                code = '';
            }
            // Blockly.JavaScript.INFINITE_LOOP_TRAP = null;
            return code;
        },
        // 拡張ブロックを追加
        mnBlockAddExt() {
        },
        // 拡張ブロックを削除
        mnBlockRemoveExt() {
        },
        // ブロックコードの生成
        generateUpdateBlockCode(/*event*/) {
            try {
                var code = '';

                if (this.$store.state.selectionLanguage == codeLanguage.JS){
                    // PREFIXをクリア
                    Blockly.JavaScript.STATEMENT_PREFIX = '';
                    // // コード生成処理
                    // window.LoopTrap = 1000;
                    // Blockly.JavaScript.INFINITE_LOOP_TRAP = 'if(--window.LoopTrap == 0) throw "Infinite loop.";\n';
                    code = Blockly.JavaScript.workspaceToCode(this.workspace);
                    // Blockly.JavaScript.INFINITE_LOOP_TRAP = null;

                    // console.log('generateUpdateBlockCode JS:' + code);

                    // vuex データ更新
                    this.$store.dispatch('commitCode', code);
                    // child -> parent
                    this.$emit('codeGenerateEvent', code);
                }
                else if (this.$store.state.selectionLanguage == codeLanguage.Python){
                    // PREFIXをクリア
                    Blockly.Python.STATEMENT_PREFIX = '';
                    // // コード生成処理
                    // window.LoopTrap = 1000;
                    // Blockly.JavaScript.INFINITE_LOOP_TRAP = 'if(--window.LoopTrap == 0) throw "Infinite loop.";\n';
                    code = Blockly.Python.workspaceToCode(this.workspace);
                    // Blockly.JavaScript.INFINITE_LOOP_TRAP = null;

                    // console.log('generateUpdateBlockCode python:' + code);

                    // vuex データ更新
                    this.$store.dispatch('commitCode', code);
                    // child -> parent
                    this.$emit('codeGenerateEvent', code);
                }
                else if (this.$store.state.selectionLanguage == codeLanguage.CS){
                    // PREFIXをクリア
                    Blockly.CSharp.STATEMENT_PREFIX = '';
                    // // コード生成処理
                    // window.LoopTrap = 1000;
                    // Blockly.JavaScript.INFINITE_LOOP_TRAP = 'if(--window.LoopTrap == 0) throw "Infinite loop.";\n';
                    code = Blockly.CSharp.workspaceToCode(this.workspace);
                    // Blockly.JavaScript.INFINITE_LOOP_TRAP = null;
                    // vuex データ更新
                    this.$store.dispatch('commitCode', code);
                    // child -> parent
                    this.$emit('codeGenerateEvent', code);
                }
                else if (this.$store.state.selectionLanguage == codeLanguage.HTML){
                    // コード生成処理
                    code = Blockly.JavaScript.workspaceToCode(this.workspace);
                    // vuex データ更新
                    this.$store.dispatch('commitCode', code);
                    // child -> parent
                    this.$emit('codeGenerateEvent', code);
                }

            }catch(error){
                console.log(error);
            }
        },
        refresh() {
            window.dispatchEvent(new Event('resize'));
        },
        // setCode
        async setCode(code) {

            // オブジェクトの存在確認
            if (this.workspace == null){
                //
                console.log('setCode: ' + 'workspace is null');
                //
                return;
            }

            try {
                // ワークスペースをクリア
                this.workspace.clear();

                if (code != "") {
                    // 作成ブロックのコードを生成
                    var xml = Blockly.Xml.textToDom(code);
                    Blockly.Xml.domToWorkspace(xml, this.workspace);
                }
                // ブロックコードの生成
                this.generateUpdateBlockCode();

            } catch (e) {
                alert(e);
            }
        },
        // getCode
        async getCode() {

            // オブジェクトの存在確認
            if (this.workspace == null){
                console.log('getCode: ' + 'workspace is null');
                return;
            }

            try {
                // コードデータの取得
                var xml = Blockly.Xml.workspaceToDom(this.workspace);
                var xml_text = Blockly.Xml.domToText(xml);
                // コードを返す
                return xml_text;

            } catch (e) {
                alert(e);
            }

            return null;
        },
        // 
        initApi(interpreter, globalObject) {
            // Add an API function for the alert() block, generated for "text_print" blocks.
            interpreter.setProperty(globalObject, 'alert', interpreter.createNativeFunction((text) => {
                text = arguments.length ? text : '';
                // this.outputArea += ('\n' + text);
                alert(text);
            }));

            // interpreter.setProperty(globalObject, 'createElement', interpreter.createNativeFunction((w, h) => {
            //     var text = w + h;
            //     // this.outputArea += ('\n' + text);
            //     alert(text);
            // }));

            // // Add an API function for the prompt() block.
            // var wrapperText = (text) => {
            //     //return interpreter.createPrimitive(prompt(text));
            //     return prompt(text);
            // };
            // interpreter.setProperty(globalObject, 'prompt', interpreter.createNativeFunction(wrapperText));

            // Add an API function for highlighting blocks.
            var wrapperID = (id) => {
                id = String(id || '');
                // return interpreter.createPrimitive(this.highlightBlock(id));
                return this.highlightBlock(id);
            };
            interpreter.setProperty(globalObject, 'highlightBlock', interpreter.createNativeFunction(wrapperID));
        },
        // 
        highlightBlock(id) {
            this.workspace.highlightBlock(id);
            this.highlightPause = true;
        },
        // 
        resetStepUi(clearOutput) {
            this.workspace.highlightBlock(null);
            this.highlightPause = false;

            if (clearOutput) {
                // this.outputArea = 'Program output:\n=================';
            }
        },
        // 
        generateCodeAndLoadIntoInterpreter() {

            // コード生成
            this.generateUpdateBlockCode();

            // コード生成処理
            window.LoopTrap = 1000;

            if (this.$store.state.selectionLanguage == codeLanguage.JS){
                Blockly.JavaScript.INFINITE_LOOP_TRAP = 'if(--window.LoopTrap == 0) throw "Infinite loop.";\n';

                // Generate JavaScript code and parse it.
                // Blockly.JavaScript.STATEMENT_PREFIX = 'highlightBlock(%1);\n';
                // Blockly.JavaScript.addReservedWords('highlightBlock');
                this.latestCode = Blockly.JavaScript.workspaceToCode(this.workspace);
                Blockly.JavaScript.INFINITE_LOOP_TRAP = null;
            }
            else if (this.$store.state.selectionLanguage == codeLanguage.Python){
                // Blockly.python.INFINITE_LOOP_TRAP = 'if(--window.LoopTrap == 0) throw "Infinite loop.";\n';

                // Generate JavaScript code and parse it.
                // Blockly.JavaScript.STATEMENT_PREFIX = 'highlightBlock(%1);\n';
                // Blockly.JavaScript.addReservedWords('highlightBlock');
                this.latestCode = Blockly.Python.workspaceToCode(this.workspace);
                // Blockly.python.INFINITE_LOOP_TRAP = null;
            }
            else if (this.$store.state.selectionLanguage == codeLanguage.CS){
                // Blockly.CSharp.INFINITE_LOOP_TRAP = 'if(--window.LoopTrap == 0) throw "Infinite loop.";\n';

                // // Generate CSharp code and parse it.
                // Blockly.CSharp.STATEMENT_PREFIX = 'highlightBlock(%1);\n';
                // Blockly.CSharp.addReservedWords('highlightBlock');
                this.latestCode = Blockly.CSharp.workspaceToCode(this.workspace);
                // Blockly.CSharp.INFINITE_LOOP_TRAP = null;

                // this.latestCode = 'using System;\n\n' + this.latestCode;
                // console.log('cs latestCode:' + this.latestCode);
            }
            else if (this.$store.state.selectionLanguage == codeLanguage.HTML){
                this.latestCode = Blockly.JavaScript.workspaceToCode(this.workspace);
            }

            // console.log('runCodeGenerateEvent:' + this.latestCode);

            // child -> parent
            this.$emit('runCodeGenerateEvent', this.latestCode);

            this.resetStepUi(true);
        },
        // 
        stepCode() {

            if (!this.myInterpreter) {
                // First statement of this code.
                // Clear the program output.
                this.resetStepUi(true);
                this.myInterpreter = new Interpreter(this.latestCode, this.initApi);

                // And then show generated code in an alert.
                // In a timeout to allow the outputArea.value to reset first.
                setTimeout(() => {
                    // alert('Ready to execute the following code\n' +
                    //     '===================================\n' + this.latestCode);
                    this.highlightPause = true;
                    this.stepCode();
                }, 1);
                return;
            }
            this.highlightPause = false;

            var hasMoreCode;
            do {
                try {
                    hasMoreCode = this.myInterpreter.step();
                } catch(error) {
                    console.log(error);
                }

                if (!hasMoreCode) {
                    // Program complete, no more code to execute.
                    // this.outputArea += '\n\n<< Program complete >>';

                    this.myInterpreter = null;
                    this.resetStepUi(false);

                    // // Cool down, to discourage accidentally restarting the program.
                    // stepButton.disabled = 'disabled';
                    // setTimeout(function() {
                    //     stepButton.disabled = '';
                    // }, 2000);
                    break;
                }

            // Keep executing until a highlight statement is reached,
            // or the code completes or errors.
            } while (hasMoreCode && !this.highlightPause);
        },
        // WorkspaceToImage
        workspaceToImage() {

            // alert('workspaceToImage');

            var aleph = this.workspace.svgBlockCanvas_.cloneNode(true);
            if (aleph == null) {
                alert('aleph is null');
                return;
            }

            aleph.removeAttribute("width");
            aleph.removeAttribute("height");

            if (aleph.children[0] !== undefined) {

                alert(aleph.children[0]);

                aleph.removeAttribute("transform");
                aleph.children[0].removeAttribute("transform");
                aleph.children[0].children[0].removeAttribute("transform");

                var bbox = document.getElementsByClassName("blocklyBlockCanvas")[0].getBBox();
                if (bbox == null) {
                    alert('bbox is null');
                    return;
                }
                var xml = new XMLSerializer().serializeToString(aleph);
                if (xml == null) {
                    alert('xml is null');
                    return;
                }

                var w = 80;
                var h = 5;

                //xml = '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="'+bbox.width+'" height="'+bbox.height+'" viewBox="0 0 '+bbox.width+' '+bbox.height+'"><rect width="100%" height="100%" fill="white"></rect>'+xml+'</svg>';
                xml = '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="'+ w +'" height="'+ h +'" viewBox="0 0 '+ w +' '+ h +'"><rect width="100%" height="100%" fill="white"></rect>' + xml + '</svg>';

                var data = "data:image/svg+xml;base64," + btoa(unescape(encodeURIComponent(xml)));
                if (data == null) {
                    alert('data is null');
                    return;
                }

                var canvasWorker = document.createElement("canvas");
                canvasWorker.width = bbox.width;
                canvasWorker.height = bbox.height;

                var ctx = canvasWorker.getContext("2d");
                var image = new Image;
                image.onload = function(){
                    ctx.drawImage(image, 0, 0);
                    var a = document.createElement("a");
                    a.href = canvasWorker.toDataURL("image/png");
                    a.setAttribute("download", "image.png");
                    a.dispatchEvent(new MouseEvent("click"));
                }

                image.src = data;

            }else{
                alert('aleph undefined');
            }

            alert('workspaceToImage fin');

        },
        ///////////////////////////////////////////
    },
}
</script>

<style>
.blocklyTreeLabel {
  color: white;
}
.blocklyToolboxContents {
  padding: 8px;
}
.blocklyTreeRow {
  padding: 6px;
  margin-bottom: 6px;
  border-radius: 4px;
}
.blocklyTreeRowContentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.blocklyTreeRow {
  height: initial;
}
#blocklyContainer {
  position: relative;
  width: 100%;
  height: 100%;
}
#blocklyDiv {
  height: 100%;
}
.customLabel {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: normal;
  align-content: center;
  align-items: center;
}
</style>
