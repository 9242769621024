var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"pl-2 pr-1"},[_c('v-card',{staticStyle:{"margin-top":"-50px"},attrs:{"elevation":"0"}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-7",attrs:{"absolute":"","dark":"","fab":"","top":"","left":"","color":"blue darken-1","small":""},on:{"click":function($event){return _vm.homeButtonEvent()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-home")])],1)]}}])},[_c('span',[_vm._v("ホーム画面へ")])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!_vm.enabled)?_c('v-btn',_vm._g(_vm._b({staticClass:"mt-7",staticStyle:{"margin-left":"50px"},attrs:{"absolute":"","dark":"","fab":"","top":"","left":"","color":"purple","small":"","disabled":_vm.enabledSave},on:{"click":function($event){return _vm.saveProjectButtonEvent()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-folder-upload")])],1):_vm._e()]}}])},[_c('span',[_vm._v("プロジェクト保存")])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-7",staticStyle:{"margin-left":"100px"},attrs:{"absolute":"","dark":"","fab":"","top":"","left":"","color":"black","small":""},on:{"click":function($event){return _vm.blockCopyButtonEvent()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}])},[_c('span',[_vm._v("コピー")])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-7",staticStyle:{"margin-left":"150px"},attrs:{"absolute":"","dark":"","fab":"","top":"","left":"","color":"black","small":""},on:{"click":function($event){return _vm.blockUndoButtonEvent()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-undo")])],1)]}}])},[_c('span',[_vm._v("取り消す")])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-7",staticStyle:{"margin-left":"200px"},attrs:{"absolute":"","dark":"","fab":"","top":"","left":"","color":"black","small":""},on:{"click":function($event){return _vm.blockRedoButtonEvent()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-redo")])],1)]}}])},[_c('span',[_vm._v("やり直す")])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-7",staticStyle:{"margin-left":"250px"},attrs:{"absolute":"","dark":"","fab":"","top":"","left":"","color":"green","small":""},on:{"click":function($event){return _vm.blockRunButtonEvent()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-play")])],1)]}}])},[_c('span',[_vm._v("実行")])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-7",staticStyle:{"margin-left":"300px"},attrs:{"absolute":"","dark":"","fab":"","top":"","left":"","color":"red","small":""},on:{"click":function($event){return _vm.blockBreakButtonEvent()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-stop")])],1)]}}])},[_c('span',[_vm._v("停止")])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-7",staticStyle:{"margin-left":"350px"},attrs:{"absolute":"","dark":"","fab":"","top":"","left":"","color":"teal lighten-1","small":""},on:{"click":function($event){return _vm.blockSourceCopyButtonEvent()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-text-box-multiple")])],1)]}}])},[_c('span',[_vm._v("コードをクリップボードにコピー")])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-7",staticStyle:{"margin-left":"400px"},attrs:{"absolute":"","dark":"","fab":"","top":"","left":"","color":_vm.consoleSize.icon_color,"small":""},on:{"click":function($event){return _vm.blockTestEvent()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.consoleSize.icon))])],1)]}}])},[_c('span',[_vm._v("デバッグ出力")])])],1),_c('v-col',[_c('SplitGrid',_vm._b({staticClass:"sb_split-grid",on:{"drag-start":_vm.onDragStart,"drag-end":_vm.onDragEnd}},'SplitGrid',_vm.splitGridOptions,false),[_c('SplitGridArea',{ref:"split_blockly",attrs:{"sizeUnit":"%","sizeValue":34}},[(_vm.resetFlag)?_c('BlockView',{ref:"blockly",style:({height: _vm.height_b}),attrs:{"updateComponent":_vm.updateComponent},on:{"codeGenerateEvent":_vm.saveCode,"runCodeGenerateEvent":_vm.runCode}}):_vm._e()],1),_c('SplitGridGutter',{staticStyle:{"background-color":"transparent"},attrs:{"render":false}}),_c('SplitGridArea',{ref:"split_source",attrs:{"sizeUnit":"%","sizeValue":33}},[_c('SourceView',{ref:"source",style:({height: _vm.height_b})})],1),_c('SplitGridGutter',{staticStyle:{"background-color":"transparent"},attrs:{"render":false}}),_c('SplitGrid',{staticClass:"sb_sub-grid",attrs:{"direction":"row"},on:{"drag-start":_vm.onDragStart,"drag-end":_vm.onDragEnd}},[_c('SplitGridArea',{ref:"split_result",style:({width: _vm.consoleWidth}),attrs:{"size-unit":_vm.resultSize.unit,"size-value":_vm.resultSize.value}},[_c('ResultView',{ref:"result",attrs:{"rawHtml":_vm.rawHtml,"rawScript":_vm.rawScript},on:{"mapProc":_vm.emitMapProc}})],1),_c('SplitGridGutter',{staticStyle:{"background-color":"red"},attrs:{"render":false}}),_c('SplitGridArea',{ref:"split_console",style:({width: _vm.consoleWidth}),attrs:{"size-unit":_vm.consoleSize.unit,"size-value":_vm.consoleSize.value,"show":_vm.consoleSize.show}},[_c('ConsoleView',{ref:"consoleView"})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }