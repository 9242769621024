<template>
    <v-card
        max-width='100%'
        height='100%'
        elevation='2'
        class='ml-1 mr-1 mt-1 pa-1'
        >
    <div
        style='width:100%; height:100%; overflow: scroll;'
        class='pa-1 mr-1'
        >
        <pre v-if="texts != ''" style='text-align:left; font-size: 90%; font-family: Noto Sans JP;' class='pa-0 ma-1'>{{ texts }}</pre>
        <div v-else style='margin-top: 0%; text-align: left; color: lightgray; font-family:Noto Sans JP; font-size: 150%;'>Console</div>

    </div>
    </v-card>
</template>
<script>

export default {
    name: 'ConsoleView',

    data() {
        return {
            texts: '',
        };
    },
    methods: {
        updateDebugString(code) {
            if (code == undefined){
                code = '';
            }
            this.texts = String(code);
        },
    },
}

</script>

