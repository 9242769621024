import * as Blockly from 'blockly/core';

// ブロックの配色
var html_color = 80;

// HTMLブロック定義
const category_Html = `
    <category name="HTML" colour="80">
        <block type="html_output_text">
            <value name="text">
                <block type="text">
                    <field name="TEXT">abc</field>
                </block>
            </value>
        </block>
        <block type="html_html"></block>
        <block type="html_body"></block>
        <block type="html_h">
            <value name="text">
                <block type="text">
                    <field name="TEXT">abc</field>
                </block>
            </value>
        </block>
        <block type="html_p">
            <value name="text">
                <block type="text">
                    <field name="TEXT">abc</field>
                </block>
            </value>
        </block>
        <block type="html_div">
        </block>
        <block type="html_table">
        </block>
        <block type="html_img">
        </block>
        <block type="html_anker">
            <value name="text">
                <block type="text">
                    <field name="TEXT">google</field>
                </block>
            </value>
        </block>
        <block type="html_ol">
        </block>
        <block type="html_li">
            <value name="text">
                <block type="text">
                    <field name="TEXT">abc</field>
                </block>
            </value>
        </block>
        <block type="html_style">
            <value name="width">
                <block type="math_number">
                    <field name="NUM">100</field>
                </block>
            </value>
            <value name="height">
                <block type="math_number">
                    <field name="NUM">20</field>
                </block>
            </value>
            <value name="margin">
                <block type="math_number">
                    <field name="NUM">1</field>
                </block>
            </value>
            <value name="padding">
                <block type="math_number">
                    <field name="NUM">1</field>
                </block>
            </value>
            <value name="border">
                <block type="math_number">
                    <field name="NUM">0</field>
                </block>
            </value>
            <value name="fontsize">
                <block type="math_number">
                    <field name="NUM">12</field>
                </block>
            </value>
        </block>

    </category>`;
    
    // <block type="html_br"></block>

export {category_Html};

///////////////////////////////////////////////////////////////////
// TEXT
Blockly.Blocks['html_output_text'] = {
    init: function() {
        this.appendValueInput("text")
            .appendField("文字列")
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(html_color);
        this.setTooltip("テキスト");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['html_output_text'] = function(block) {

    var text = Blockly.JavaScript.valueToCode(block, 'text', Blockly.JavaScript.ORDER_ATOMIC);

    var code = text.replace(/'/g, '') + '\n';

    return code;
};

///////////////////////////////////////////////////////////////////
// HTML
Blockly.Blocks['html_html'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("HTML")
        this.appendStatementInput("data")
            .setCheck(null);
        this.setPreviousStatement(false, null);
        this.setNextStatement(false, null);
        this.setColour(html_color);
        this.setTooltip("HTML");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['html_html'] = function(block) {

    var statements_data = Blockly.JavaScript.statementToCode(block, 'data');

    var code = '<html>' + '\n' + statements_data + '\n' + '</html>\n';

    return code;
};

///////////////////////////////////////////////////////////////////
// BODY
Blockly.Blocks['html_body'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("BODY")
        this.appendStatementInput("data")
            .setCheck(null);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(html_color);
        this.setTooltip("BODY");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['html_body'] = function(block) {

    var statements_data = Blockly.JavaScript.statementToCode(block, 'data');

    var code = '<body>\n'
                + statements_data + '\n'
                + '</body>';

    return code;
};

///////////////////////////////////////////////////////////////////
// H1～H6
Blockly.Blocks['html_h'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("H")
            .appendField(new Blockly.FieldDropdown([["1","h1"], ["2","h2"], ["3","h3"], ["4","h4"], ["5","h5"], ["6","h6"]]), "html_h");
        this.appendValueInput("text")
            .appendField("文字列")
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(html_color);
        this.setTooltip("STYLE:");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['html_h'] = function(block) {
    
    var html_h = block.getFieldValue('html_h');
    var text = Blockly.JavaScript.valueToCode(block, 'text', Blockly.JavaScript.ORDER_ATOMIC);

    // 'を削除
    text = text.replace(/'/g, '');

    var code = '<' + html_h + '>' + text + '</' + html_h + '>\n';

    return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// P
Blockly.Blocks['html_p'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("P")
        this.appendValueInput("text")
            .appendField("文字列")
        this.appendValueInput("style")
            .appendField("STYLE")
            .setCheck(null)
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(html_color);
        this.setTooltip("P");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['html_p'] = function(block) {
    
    var text = Blockly.JavaScript.valueToCode(block, 'text', Blockly.JavaScript.ORDER_ATOMIC);
    var style = Blockly.JavaScript.valueToCode(block, 'style', Blockly.JavaScript.ORDER_ATOMIC);

    // 'を削除
    text = text.replace(/'/g, '');

    var code = '<p ' + style + '>' + text + '</p>\n';

    return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// DIV
Blockly.Blocks['html_div'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("DIV")
        this.appendValueInput("style")
            .appendField("STYLE")
            .setCheck(null)
        this.appendStatementInput("data")
            .setCheck(null);
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(html_color);
        this.setTooltip("DIV");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['html_div'] = function(block) {
    
    var statements_data = Blockly.JavaScript.statementToCode(block, 'data');
    var style = Blockly.JavaScript.valueToCode(block, 'style', Blockly.JavaScript.ORDER_ATOMIC);

    var code = '<div ' + style + '>\n' + statements_data + '</div>\n';

    return code;
};

///////////////////////////////////////////////////////////////////
// A
Blockly.Blocks['html_anker'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("A")
            .appendField("href:")
            .appendField(new Blockly.FieldTextInput("http://www.google.co.jp"), "href")
        this.appendValueInput("text")
            .appendField("文字列")
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(html_color);
        this.setTooltip("H");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['html_anker'] = function(block) {

    var href = block.getFieldValue('href');
    var text = Blockly.JavaScript.valueToCode(block, 'text', Blockly.JavaScript.ORDER_ATOMIC);

    var code = '<a href=\'' + href + '\' target=\'_blank\'>' + text + '</a>\n';

    return code;
};

// ///////////////////////////////////////////////////////////////////
// // BR
// Blockly.Blocks['html_br'] = {
//     init: function() {
//         this.appendDummyInput()
//             .appendField("LINK BR")
//         this.setPreviousStatement(true, null);
//         this.setNextStatement(true, null);
//         this.setColour(70);
//         this.setTooltip("BR");
//         this.setHelpUrl("");
//     }
// };

// Blockly.JavaScript['html_br'] = function(/*block*/) {
//     var code = '&lt;br&gt;\r';
//     return code;
// };

///////////////////////////////////////////////////////////////////
// IMAGE
Blockly.Blocks['html_img'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("IMG")
        this.appendDummyInput()
            .appendField("SRC:")
            .appendField(new Blockly.FieldDropdown([
                // ["かさ","https://firebasestorage.googleapis.com/v0/b/broccoly-97269.appspot.com/o/image%2Fbeach-umbrella.png?alt=media&token=0acdeaf1-e267-4959-8015-51af6a96bbf0"],
                // ["くらげ","https://firebasestorage.googleapis.com/v0/b/broccoly-97269.appspot.com/o/image%2Fjellyfish.png?alt=media&token=06fb9ac5-eae9-4b06-8664-8d5979439aa7"],
                // ["たこ","https://firebasestorage.googleapis.com/v0/b/broccoly-97269.appspot.com/o/image%2Foctopus.png?alt=media&token=cdc1b963-8c14-4c22-bd20-6a8808a1a1b1"],
                // ["みかん","https://firebasestorage.googleapis.com/v0/b/broccoly-97269.appspot.com/o/image%2Forange.png?alt=media&token=f24ce313-936c-46e9-bf4f-47c3d63464df"],
                // ["パイン","https://firebasestorage.googleapis.com/v0/b/broccoly-97269.appspot.com/o/image%2Fpalm-tree.png?alt=media&token=f83da49c-cd47-44f4-8ae5-b6eea5374378"],
                // ["ペリカン","https://firebasestorage.googleapis.com/v0/b/broccoly-97269.appspot.com/o/image%2Fpelican.png?alt=media&token=b08869fd-8749-4d1e-b0c1-3c3e547bb2f5"],
                // ["植物","https://firebasestorage.googleapis.com/v0/b/broccoly-97269.appspot.com/o/image%2Fplant.png?alt=media&token=7b669ad2-6b89-45ba-8136-0d191529f93a"],
                // ["かもめ","https://firebasestorage.googleapis.com/v0/b/broccoly-97269.appspot.com/o/image%2Fseagull.png?alt=media&token=59b5b566-0b65-470f-918d-dc1f3af00937"],
                // ["ひとで","https://firebasestorage.googleapis.com/v0/b/broccoly-97269.appspot.com/o/image%2Fstarfish.png?alt=media&token=37bcb6ea-503e-44c5-a1a1-5b5550340857"],
                // ["温度計","https://firebasestorage.googleapis.com/v0/b/broccoly-97269.appspot.com/o/image%2Fthermometer.png?alt=media&token=6057ca82-c731-41da-b4c3-894af3c13dbe"],
                ["かさ","かさ"],
                ["くらげ","くらげ"],
                ["たこ","たこ"],
                ["みかん","みかん"],
                ["パイン","パイン"],
                ["ペリカン","ペリカン"],
                ["植物","植物"],
                ["かもめ","かもめ"],
                ["ひとで","ひとで"],
                ["温度計","温度計"],
            ]), "html_img");
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(html_color);
        this.setTooltip("IMG");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['html_img'] = function(block) {

    var src = block.getFieldValue('html_img');
    // console.log(src);

    var code = '<img' + ' src=\'' + src + '\'>\n'
    //var code = '<img' + ' onload=\'getSrc();console.log("456");\'>\n'

    return code;
};

///////////////////////////////////////////////////////////////////
// TABLE, TR, TD
Blockly.Blocks['html_table'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("TABLE")
            .appendField(new Blockly.FieldDropdown([["table","table"], ["tr","tr"], ["td","td"]]), "html_table");
        this.appendValueInput("style")
            .appendField("STYLE")
            .setCheck(null)
        this.appendStatementInput("data")
            .setCheck(null);
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(html_color);
        this.setTooltip("TABLE");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['html_table'] = function(block) {

    var tag = block.getFieldValue('html_table');
    var statements_data = Blockly.JavaScript.statementToCode(block, 'data');
    var style = Blockly.JavaScript.valueToCode(block, 'style', Blockly.JavaScript.ORDER_ATOMIC);

    var code = '<' + tag + ' ' + style + '>\n' + statements_data + '</' + tag + '>\n';

    return code;
};

///////////////////////////////////////////////////////////////////
// style
Blockly.Blocks['html_style'] = {
    init: function() {
        this.appendDummyInput()
            .appendField('STYLE')
        this.appendValueInput("width")
            .appendField("width:")
            .setCheck("Number")
        this.appendValueInput("height")
            .appendField("height:")
            .setCheck("Number")
        this.appendValueInput("margin")
            .appendField("margin:")
            .setCheck("Number")
        this.appendValueInput("padding")
            .appendField("padding:")
            .setCheck("Number")
        this.appendValueInput("border")
            .appendField("border:")
            .setCheck("Number")
        this.appendValueInput("fontsize")
            .appendField("font size:")
            .setCheck("Number")
        this.appendDummyInput()
            .appendField("align:")
            .appendField(new Blockly.FieldDropdown([["左","left"], ["中央","center"], ["右","right"],]), "html_style_align");
        this.appendDummyInput()
            .appendField("color:")
            .appendField(new Blockly.FieldDropdown([["黒","black"], ["白","white"], ["赤","red"], ["緑","h3"], ["青","blue"], ["黃","yellow"], ["紫","purple"]]), "html_color");
        this.appendDummyInput()
            .appendField("bgcolor:")
            .appendField(new Blockly.FieldDropdown([["黒","black"], ["白","white"], ["赤","red"], ["緑","h3"], ["青","blue"], ["黃","yellow"], ["紫","purple"]]), "html_bgcolor");
        this.setInputsInline(false);
        this.setOutput(true, null);
        this.setColour(html_color);
        this.setTooltip("STYLE");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['html_style'] = function(block) {

    var width = Blockly.JavaScript.valueToCode(block, 'width', Blockly.JavaScript.ORDER_ATOMIC);
    var height = Blockly.JavaScript.valueToCode(block, 'height', Blockly.JavaScript.ORDER_ATOMIC);
    var margin = Blockly.JavaScript.valueToCode(block, 'margin', Blockly.JavaScript.ORDER_ATOMIC);
    var padding = Blockly.JavaScript.valueToCode(block, 'padding', Blockly.JavaScript.ORDER_ATOMIC);
    var border = Blockly.JavaScript.valueToCode(block, 'border', Blockly.JavaScript.ORDER_ATOMIC);
    var fontsize = Blockly.JavaScript.valueToCode(block, 'fontsize', Blockly.JavaScript.ORDER_ATOMIC);
    var align = block.getFieldValue('html_style_align');
    var color = block.getFieldValue('html_color');
    var bgcolor = block.getFieldValue('html_bgcolor');

    var code = 'STYLE=\'width:' + width + 'px; ';
    code += 'height:' + height + 'px; ';
    code += 'margin:' + margin + 'px; ';
    code += 'padding:' + padding + 'px; ';
    code += 'border:' + border + 'px #000000 solid;'
    code += 'font-size:' + fontsize + 'px; '
    code += 'text-align:' + align + ';'
    code += 'color:' + color + ';'
    code += 'background-color:' + bgcolor + ';'
    code += '\''
    ;

    return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// OL
Blockly.Blocks['html_ol'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("OL")
            .appendField(new Blockly.FieldDropdown([["1","1"], ["a","a"], ["A","A"], ["i","i"], ["I","I"],]), "html_ol");
        this.appendStatementInput("data")
            .setCheck(null);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(html_color);
        this.setTooltip("OL");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['html_ol'] = function(block) {

    var html_ol = block.getFieldValue('html_ol');
    var statements_data = Blockly.JavaScript.statementToCode(block, 'data');

    var code = '<ol type=\'' + html_ol + '\'>' + '\n' + statements_data + '</ol>\n';

    return code;
};

///////////////////////////////////////////////////////////////////
// LI
Blockly.Blocks['html_li'] = {
    init: function() {
        this.appendValueInput("text")
            .appendField("LI")
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(html_color);
        this.setTooltip("LI");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['html_li'] = function(block) {

    var text = Blockly.JavaScript.valueToCode(block, 'text', Blockly.JavaScript.ORDER_ATOMIC);

    var code = '<li>' + text.replace(/'/g, '') + '</li>\n';

    return code;
};
