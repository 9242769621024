import * as Blockly from 'blockly/core';

// ブロックの配色
var js_ar_block_color = 340;

var UploadedImageData3D = undefined;

// Timerブロック定義
const category_ar = `
    <category name="AR" colour="340">
        <block type="js_ar_block_ar_thread"></block>
        <block type="js_ar_block_ar_start_thread"></block>
        <block type="js_ar_block_ar_ARThreeOnLoad"></block>
        <block type="js_ar_block_ar_exec_ARRenderTick"></block>
        <block type="js_ar_block_ar_ARRenderTick"></block>
        <block type="js_ar_block_ar_load_marker">
            <value name="markerid">
                <block type="math_number">
                    <field name="NUM">1</field>
                </block>
            </value>
        </block>

        <block type="js_ar_block_ar_controller_property"></block>

        <block type="js_ar_block_ar_arScene"></block>
        <block type="js_ar_block_ar_arScene_scene"></block>

        <block type="js_ar_block_ar_gltf">
            <value name="modelSize">
                <block type="math_number">
                    <field name="NUM">10</field>
                </block>
            </value>
            <value name="posX">
                <block type="math_number">
                    <field name="NUM">1</field>
                </block>
            </value>
            <value name="posY">
                <block type="math_number">
                    <field name="NUM">1</field>
                </block>
            </value>
            <value name="posZ">
                <block type="math_number">
                    <field name="NUM">1</field>
                </block>
            </value>
        </block>

        <block type="js_ar_block_append_render"></block>

    </category>`;

export {category_ar};

export function setUploadedImageData3D(uploadedImageData3D) {
    UploadedImageData3D = uploadedImageData3D;
}

///////////////////////////////////////////////////////////////////
// js_ar_block_ar_start_thread
Blockly.Blocks['js_ar_block_ar_start_thread'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("start AR")
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(js_ar_block_color);
        this.setTooltip("start AR");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_ar_block_ar_start_thread'] = function(/*block*/) {

    var code = `
    if (window.ARController && ARController.getUserMediaThreeScene) {
        ARThreeOnLoad();
    }
    `;

    return code;
};

///////////////////////////////////////////////////////////////////
// js_ar_block_ar_thread
Blockly.Blocks['js_ar_block_ar_thread'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("AR Thread")
        this.appendStatementInput("code")
            .setCheck(null);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(js_ar_block_color);
        this.setTooltip("start AR");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_ar_block_ar_thread'] = function(block) {

    var statements_data = Blockly.JavaScript.statementToCode(block, 'code');

    var code = `
    const resultDiv = document.getElementById('ExecutionPanel');

    if (resultDiv == null){
        console.log('null check');
    }
    window.ARThreeOnLoad = function() {
        ARController.getUserMediaThreeScene({
            maxARVideoSize: 320,
            cameraParam: './lib/ARCameraConfig/camera_para-iPhone 5 rear 640x480 1.0m.dat',
            onSuccess: function(arScene, arController, arCamera) {
                document.body.className = arController.orientation;
                arController.setPatternDetectionMode(artoolkit.AR_TEMPLATE_MATCHING_COLOR);
    
                var renderer = new THREE.WebGLRenderer({antialias: true});
                renderer.setSize(arController.videoWidth, arController.videoHeight);
    
                renderer.domElement.style.transform = 'scaleX(-1)';
                resultDiv.appendChild(renderer.domElement);
    
                const light = new THREE.DirectionalLight(0xFFFFFF);
                light.intensity = 2;
                light.position.set(1, 1, 1);
                arScene.scene.add(light);
                `
                + statements_data
                + `
                var tick = function() {
                    arScene.process();
                    arScene.renderOn(renderer);
                    requestAnimationFrame(tick);
                };
    
                tick();
            }
        });
    
        delete window.ARThreeOnLoad;    
    };
    `;

    return code;
};

///////////////////////////////////////////////////////////////////
// js_ar_block_ar_gltf
Blockly.Blocks['js_ar_block_ar_gltf'] = {
    init: function() {
        this.appendDummyInput('diTextureImage')
            .appendField("GLTF Data")
            .appendField(new Blockly.FieldDropdown(this.generateOptions), 'texture_image')
            .appendField(')', 'kakko_end');
        this.appendValueInput("modelSize")
            .appendField("model size")
            .setCheck("Number")
        this.appendValueInput("posX")
            .appendField("X")
            .setCheck("Number")
        this.appendValueInput("posY")
            .appendField("Y")
            .setCheck("Number")
        this.appendValueInput("posZ")
            .appendField("Z")
            .setCheck("Number")
        this.appendStatementInput("code")
            .setCheck(null);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setInputsInline(true);
        this.setColour(js_ar_block_color);
        this.setTooltip("start AR");
        this.setHelpUrl("");
    },
    generateOptions: function() {
        var options = [];
        // 初期値
        options.push(
            ['なし', ''],
        );
        // // 保存済みデータ
        // if (UploadedImageData3D != undefined){
        //     var worker = UploadedImageData3D;
        //     // console.log('store 3d data count:' + worker.length);

        //     // 保存済みデータの展開
        //     for (var i = 0; i < worker.length; i++) {
        //       options.push([worker[i].filename, worker[i].url]);
        //     //   console.log('store 3d data:' + worker[i].filename);
        //     }
        // }else{
        //     console.log('store 3d is undefined');
        // }

        console.log(UploadedImageData3D);

        options.push(['虹', './image/sample.glb']);
        options.push(['ペンギン', './image/penguin.glb']);
        options.push(['しまうま', './image/zebra.glb']);

        return options;
    },
};

Blockly.JavaScript['js_ar_block_ar_gltf'] = function(block) {

    var textureImage = block.getFieldValue('texture_image');
    var modelSize = Blockly.JavaScript.valueToCode(block, 'modelSize', Blockly.JavaScript.ORDER_ATOMIC);
    var posX = Blockly.JavaScript.valueToCode(block, 'posX', Blockly.JavaScript.ORDER_ATOMIC);
    var posY = Blockly.JavaScript.valueToCode(block, 'posY', Blockly.JavaScript.ORDER_ATOMIC);
    var posZ = Blockly.JavaScript.valueToCode(block, 'posZ', Blockly.JavaScript.ORDER_ATOMIC);
    var statements_data = Blockly.JavaScript.statementToCode(block, 'code');


    if (textureImage == ''){
        // console.log('data is none.');
        textureImage = './image/sample.glb';
    }

    var code = `
                {
                    var gtfLoader = new THREE.GLTFLoader();
                    gtfLoader.crossOrigin = 'anonymous';
                    gtfLoader.crossOrigin = 'use-credentials';

                    gtfLoader.load('` + textureImage + `', function(gltf) {
                            
                        if (gltf == null){
                            console.log('gltf is null');
                        }
                        var model = gltf.scene;
                        model.scale.x = ` + modelSize + `;
                        model.scale.y = ` + modelSize + `;
                        model.scale.z = ` + modelSize + `;

                        model.position.x = ` + posX + `;
                        model.position.y = ` + posY + `;
                        model.position.z = ` + posZ + `;

                        model.rotation.x = 0;
                        model.rotation.y = 0;
                        model.rotation.z = 0;
                        `
                        + statements_data
                        + `
                    });
                }
    `;

    return code;
};

///////////////////////////////////////////////////////////////////
// js_ar_block_ar_load_marker
Blockly.Blocks['js_ar_block_ar_load_marker'] = {
    init: function() {
        this.appendDummyInput('LoadMarker')
            .appendField("Marker")
            .appendField(new Blockly.FieldDropdown(this.generateOptions), 'marker')
            .appendField(')', 'kakko_end');
        this.appendValueInput('markerid')
            .appendField('Marker ID')
            .setCheck('Number')
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setInputsInline(true);
        this.setColour(js_ar_block_color);
        this.setTooltip("Load Marker");
        this.setHelpUrl("");
    },
    generateOptions: function() {
        var options = [];
        // 初期値
        options.push(
            ['HIRO', './lib/ARMarker/patt.hiro'],
            ['星と月', './lib/ARMarker/pattern-moon_and_star.patt'],
            ['オオカミ', './lib/ARMarker/pattern-wolf.patt'],
            ['うさぎ', './lib/ARMarker/pattern-rabbit.patt'],
            ['くるま', './lib/ARMarker/pattern-car.patt'],
        );
        return options;
    },
    updateDropDown: function() {
        // 選択済みデータを保存
        const selectedVal = this.getFieldValue('marker');

        // ブロックの参照を取得
        var _option = new Blockly.FieldDropdown(this.generateOptions);

        // 選択肢の再設定
        const _input = this.getInput('LoadMarker');
        _input.removeField('marker');
        _input.removeField('kakko_end');
        _input.appendField(_option, 'marker');
        _input.appendField(')', 'kakko_end');

        // 再選択
        for (var cnt=0; cnt<_option.length; cnt++) {
            if (selectedVal == _option[cnt][0]) {
                this.setFieldValue(_option[cnt][0], 'marker');
                break;
            }
        }
    }

};

Blockly.JavaScript['js_ar_block_ar_load_marker'] = function(block) {

    var marker = block.getFieldValue('marker');
    var markerid = Blockly.JavaScript.valueToCode(block, 'markerid', Blockly.JavaScript.ORDER_ATOMIC);

    var code = `
    arController.loadMarker('` + marker + `', function(markerId) {
        var markerRoot = arController.createThreeMarker(markerId, ` + markerid + `);
        markerRoot.add(model);
        arScene.scene.add(markerRoot);
    });
    `;

    return code;
};

///////////////////////////////////////////////////////////////////
// js_ar_block_ar_ARThreeOnLoad
Blockly.Blocks['js_ar_block_ar_ARThreeOnLoad'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("ARThreeOnLoad");
        this.appendStatementInput("code")
            .setCheck(null);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(js_ar_block_color);
        this.setTooltip("start AR");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_ar_block_ar_ARThreeOnLoad'] = function(block) {

    var statements_data = Blockly.JavaScript.statementToCode(block, 'code');

    var code = `
    window.ARThreeOnLoad = function() {
        ARController.getUserMediaThreeScene({
            maxARVideoSize: 640,
            cameraParam: './lib/ARCameraConfig/camera_para-iPhone 5 rear 640x480 1.0m.dat',
            onSuccess: function(arScene, arController, arCamera) {
                document.body.className = arController.orientation;
                arController.setPatternDetectionMode(artoolkit.AR_TEMPLATE_MATCHING_COLOR);
                `
                + statements_data
                + `
            }
        });
    
        delete window.ARThreeOnLoad;    
    };
    `;

    return code;
};

///////////////////////////////////////////////////////////////////
// js_ar_block_ar_ARRenderTick
Blockly.Blocks['js_ar_block_ar_ARRenderTick'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("function RenderTick(")
        this.appendValueInput("rendererVar")
            .setCheck(null)
        this.appendDummyInput()
            .appendField(")")
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(js_ar_block_color);
        this.setTooltip("function tick");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_ar_block_ar_ARRenderTick'] = function(block) {

    var rendererVar = Blockly.JavaScript.valueToCode(block, 'rendererVar', Blockly.JavaScript.ORDER_ATOMIC);

    var code = `
        var renderTick = function() {
            arScene.process();
            arScene.renderOn(` + rendererVar + `);
            requestAnimationFrame(renderTick);
        };
    `;

    return code;
};

///////////////////////////////////////////////////////////////////
// js_ar_block_ar_exec_ARRenderTick
Blockly.Blocks['js_ar_block_ar_exec_ARRenderTick'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("renderTick()")
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(js_ar_block_color);
        this.setTooltip("exec renderTick");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_ar_block_ar_exec_ARRenderTick'] = function(/*block*/) {

    var code = 'renderTick();\n';

    return code;
};

///////////////////////////////////////////////////////////////////
// js_ar_block_ar_controller_property
Blockly.Blocks['js_ar_block_ar_controller_property'] = {
    init: function() {
        this.appendDummyInput('PropertyValue')
            .appendField("arController Property:")
            .appendField(new Blockly.FieldDropdown(this.generateOptions), 'property')
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(js_ar_block_color);
        this.setTooltip("exec property");
        this.setHelpUrl("");

    },
    generateOptions: function() {
        var options = [];
        // 初期値
        options.push(
            ['幅', 'videoWidth'],
            ['高さ', 'videoHeight'],
        );
        return options;
    },
    updateDropDown: function() {
        // 選択済みデータを保存
        const selectedVal = this.getFieldValue('property');

        // ブロックの参照を取得
        var _option = new Blockly.FieldDropdown(this.generateOptions);

        // 選択肢の再設定
        const _input = this.getInput('PropertyValue');
        _input.removeField('property');
        _input.appendField(_option, 'property');

        // 再選択
        for (var cnt=0; cnt<_option.length; cnt++) {
            if (selectedVal == _option[cnt][0]) {
                this.setFieldValue(_option[cnt][0], 'marker');
                break;
            }
        }
    }
};

Blockly.JavaScript['js_ar_block_ar_controller_property'] = function(block) {

    var property = block.getFieldValue('property');

    var code = 'arController.' + property;

    return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// js_ar_block_ar_arScene
Blockly.Blocks['js_ar_block_ar_arScene'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("arScene");
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(js_ar_block_color);
        this.setTooltip("arScene");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_ar_block_ar_arScene'] = function(/*block*/) {
    
    var code = 'arScene';

    return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// js_ar_block_ar_arScene_scene
Blockly.Blocks['js_ar_block_ar_arScene_scene'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("arController.scene");
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(js_ar_block_color);
        this.setTooltip("arScene.scene");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_ar_block_ar_arScene_scene'] = function(/*block*/) {
    
    var code = 'arScene.scene';

    return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// js_ar_block_append_render
Blockly.Blocks['js_ar_block_append_render'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("append")
        this.appendValueInput("rendererVar")
            .setCheck(null)
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(js_ar_block_color);
        this.setTooltip("Settings");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_ar_block_append_render'] = function(block) {

    var rendererVar = Blockly.JavaScript.valueToCode(block, 'rendererVar', Blockly.JavaScript.ORDER_ATOMIC);
    var code = 'document.getElementById(\'ExecutionPanel\').appendChild(' + rendererVar + '.domElement);\n';

    return code;
};
