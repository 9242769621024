import * as Blockly from 'blockly/core';
// import scTakObject from './../module/smart_city_takamatsu';

// ブロックの配色
var js_sctak_block_color = 80;

// Timerブロック定義
const category_TakamatsuOpenData = `
    <category name="スマートシティ高松" colour="80">
        <block type="js_TakamatsuOpenData_block_getTypes">
            <value name="offset">
                <block type="math_number">
                    <field name="NUM">1</field>
                </block>
            </value>
            <value name="limit">
                <block type="math_number">
                    <field name="NUM">1</field>
                </block>
            </value>
        </block>
        <block type="js_TakamatsuOpenData_block_getEntities">
            <value name="dataType">
                <block type="text">
                    <field name="TEXT"></field>
                </block>
            </value>
            <value name="offset">
                <block type="math_number">
                    <field name="NUM">1</field>
                </block>
            </value>
            <value name="limit">
                <block type="math_number">
                    <field name="NUM">1</field>
                </block>
            </value>
        </block>
    </category>`;

// <block type="js_TakamatsuOpenData_block_getTypesSelector"></block>
// <block type="js_sctak_block_BicycleRentalPort"></block>

export {category_TakamatsuOpenData};

///////////////////////////////////////////////////////////////////
// get Types
Blockly.Blocks['js_TakamatsuOpenData_block_getTypes'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("データの種類リスト")
        this.appendValueInput("offset")
            .appendField("データ開始位置")
            .setCheck("Number")
        this.appendValueInput("limit")
            .appendField("データ数")
            .setCheck("Number")
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(js_sctak_block_color);
        this.setTooltip("log");
        this.setHelpUrl("");

        // var mode = this.getFieldValue('iii');
        // console.log(mode);
    },
};

Blockly.JavaScript['js_TakamatsuOpenData_block_getTypes'] = function(block) {
    var offset = Blockly.JavaScript.valueToCode(block, 'offset', Blockly.JavaScript.ORDER_ATOMIC);
    var limit = Blockly.JavaScript.valueToCode(block, 'limit', Blockly.JavaScript.ORDER_ATOMIC);

    if (offset == '' || offset == undefined) {
        offset = 0;
    }
    if (limit == '' || limit == undefined) {
        limit = 10;
    }

    // var code = '(async ()=> {return this.getTakamatsuOpenData(\'https://pf.smartcity-takamatsu.jp/orion/v2.0/types?offset=' + offset + '\')})().then((value)=>{return(value)})';
    var code = 'this.getTakamatsuOpenData(\'https://pf.smartcity-takamatsu.jp/orion/v2.0/types?offset=' + offset + '&limit=' + limit + '\')';

    return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// get Types
Blockly.Blocks['js_TakamatsuOpenData_block_getEntities'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("エンティティ")
        this.appendValueInput("dataType")
            .appendField("文字列")
        this.appendValueInput("offset")
            .appendField("データ開始位置")
            .setCheck("Number")
        this.appendValueInput("limit")
            .appendField("データ数")
            .setCheck("Number")
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(js_sctak_block_color);
        this.setTooltip("log");
        this.setHelpUrl("");

        // var mode = this.getFieldValue('iii');
        // console.log(mode);
    },
};

Blockly.JavaScript['js_TakamatsuOpenData_block_getEntities'] = function(block) {
    var dataType = Blockly.JavaScript.valueToCode(block, 'dataType', Blockly.JavaScript.ORDER_ATOMIC);
    var offset = Blockly.JavaScript.valueToCode(block, 'offset', Blockly.JavaScript.ORDER_ATOMIC);
    var limit = Blockly.JavaScript.valueToCode(block, 'limit', Blockly.JavaScript.ORDER_ATOMIC);

    // 'を削除
    dataType = dataType.replace(/'/g, '');

    if (offset == '' || offset == undefined) {
        offset = 0;
    }
    if (limit == '' || limit == undefined) {
        limit = 10;
    }

    // var code = '(async ()=> {return this.getTakamatsuOpenData(\'https://pf.smartcity-takamatsu.jp/orion/v2.0/types?offset=' + offset + '\')})().then((value)=>{return(value)})';
    var code = 'this.getTakamatsuOpenData(\'https://pf.smartcity-takamatsu.jp/orion/v2.0/entities?type=' + dataType + '&offset=' + offset + '&limit=' + limit + '\')';

    return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

// ///////////////////////////////////////////////////////////////////
// // get Types combo
// Blockly.Blocks['js_TakamatsuOpenData_block_getTypesSelector'] = {
//     init: function() {
//         this.appendDummyInput()
//             .appendField("データの種類")
//             .appendField(new Blockly.FieldDropdown(this.generateOptions), 'dataKind')
//         this.setInputsInline(true);
//         this.setOutput(true, null);
//         this.setColour(js_sctak_block_color);
//         this.setTooltip("log");
//         this.setHelpUrl("");

//         // var mode = this.getFieldValue('iii');
//         // console.log(mode);
//     },
//     generateOptions: function() {
//         var options = [];
//         // 初期値
//         options.push(['なし', '']);
//         // // 保存済みデータ
//         // if (UploadedImageData != undefined){
//         //     var worker = UploadedImageData;
//         //     console.log('store data count:' + worker.length);

//         //     // 保存済みデータの展開
//         //     for (var i = 0; i < worker.length; i++) {
//         //       options.push([worker[i].filename, worker[i].url]);
//         //       console.log('store data:' + worker[i].filename);
//         //     }
//         // }else{
//         //     console.log('store is undefined');
//         // }

//         return options;
//     },

// };

// Blockly.JavaScript['js_TakamatsuOpenData_block_getTypesSelector'] = function(block) {
//     var dataKind = Blockly.JavaScript.valueToCode(block, 'dataKind', Blockly.JavaScript.ORDER_ATOMIC);

//     var code = dataKind;

//     return code;
// };

// ///////////////////////////////////////////////////////////////////
// // BicycleRentalPort()
// Blockly.Blocks['js_sctak_block_BicycleRentalPort'] = {
//     init: function() {
//         this.appendDummyInput()
//             .appendField("レンタル自転車");
//         this.setInputsInline(true);
//         this.setOutput(true, null);
//         this.setColour(js_sctak_block_color);
//         this.setTooltip("BicycleRentalPort");
//         this.setHelpUrl("");
//     }
// };

// Blockly.JavaScript['js_sctak_block_BicycleRentalPort'] = function(/*block*/) {

//     var code = Blockly.JavaScript.provideFunction_('_getBicycleRentalPort',
//         [ 'function ' + Blockly.JavaScript.FUNCTION_NAME_PLACEHOLDER_ + '(sctk) {',
//         '  return sctk.getBicycleRentalPort();',
//         '}']);

//     return [code + "(this.sctk)", Blockly.JavaScript.ORDER_FUNCTION_CALL];
// };


