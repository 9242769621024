import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        // 作成コード
        code: '',
        // ログインID
        loginId: '',
        // ログインUUID
        loginUUID: '',
        // Firebaseオブジェクト
        firebaseObject: null,
        // SimpleTextDialogリソース
        simpleTextDialogResource: null,
        // プロジェクト情報
        projectInf: {
            projectName: '',
            projectKey: '',
        },
        // アプリケーション設定
        appConfig: null,
        // ログイン情報
        accountInf: {
            blocks: {
                HTML: false,
                JS_CHART: false,
                JS_DEBUG: false,
                JS_DRAW: false,
                JS_IMAGE: false,
                JS_TIMER: false,
                JS_TAKAMATSU_OPEN_DATA: false,
                JS_INPUT: false,
                JS_MAP: false,
                JS_THREE: false,
                JS_MEDIAPIPE: false,
                JS_AR: false,
                PY_NUMPY: false,
                PY_PANDAS: false,
                CS_CLASS: false,
            },
            env: {
                MAX_SAVE_COUNTS: 5,
                LOGIN_TYPE: 0,
                EXT_LINK: 0,
            }
        },
        // 実行結果出力
        resultText: '',
        // コンソール出力
        consoleText: '',
        // 選択言語
        selectionLanguage: 'JavaScript',
        // アップロード済み画像データ
        uploadedImageData: [],
        // アップロード済み画像データ
        uploadedImageData3D: [],
    },
    getters: {
        getCode(state) {
            return state.code;
        },
        getLoginId(state) {
            return state.loginId;
        },
        getLoginUUID(state) {
            return state.loginUUID;
        },
        getFirebaseObject(state) {
            return state.firebaseObject;
        },
        getSimpleTextDialogResource(state) {
            return state.simpleTextDialogResource;
        },
        getSimpleListDialogResource(state) {
            return state.simpleListDialogResource;
        },
        getProjectInf(state) {
            return state.projectInf;
        },
        getAppConfig(state) {
            return state.appConfig;
        },
        getAccountInf(state) {
            return state.accountInf;
        },
        getResultText(state) {
            return state.resultText;
        },
        getConsoleText(state) {
            return state.consoleText;
        },
        getSelectionLanguage(state) {
            return state.selectionLanguage;
        },
        getUploadedImageData(state) {
            return state.uploadedImageData;
        },
        getUploadedImageData3D(state) {
            return state.uploadedImageData3D;
        },
    },
    mutations: {
        setCode(state, code) {
            state.code = code;
        },
        setLoginId(state, loginid) {
            state.loginId = loginid;
        },
        setLoginUUID(state, loginUUID) {
            state.loginUUID = loginUUID;
        },
        setFirebaseObject(state, firebaseObject) {
            return state.firebaseObject = firebaseObject;
        },
        setSimpleTextDialogResource(state, simpleTextDialogResource) {
            return state.simpleTextDialogResource = simpleTextDialogResource;
        },
        setSimpleListDialogResource(state, simpleListDialogResource) {
            return state.simpleListDialogResource = simpleListDialogResource;
        },
        setProjectInf(state, projectInf) {
            return state.projectInf = projectInf;
        },
        setAppConfig(state, appConfig) {
            return state.appConfig = appConfig;
        },
        setAccountInf(state, accountInf) {
            return state.accountInf = accountInf;
        },
        setResultText(state, resultText) {
            return state.resultText = resultText;
        },
        setConsoleText(state, consoleText) {
            return state.consoleText = consoleText;
        },
        setSelectionLanguage(state, selectionLanguage) {
            return state.selectionLanguage = selectionLanguage;
        },
        setUploadedImageData(state, uploadedImageData) {
            return state.uploadedImageData = uploadedImageData;
        },
        setUploadedImageData3D(state, uploadedImageData3D) {
            return state.uploadedImageData3D = uploadedImageData3D;
        },
    },
    actions: {
        commitCode({ commit }, code) {
            return commit('setCode', { code });
        },
        commitLoginId({ commit }, loginid) {
            return commit('setLoginId', loginid);
        },
        commitLoginUUID({ commit }, loginUUID) {
            return commit('setLoginUUID', loginUUID);
        },
        commitFirebaseObject({ commit }, firebaseObject) {
            return commit('setFirebaseObject', firebaseObject);
        },
        commitSimpleTextDialogResource({ commit }, simpleTextDialogResource) {
            return commit('setSimpleTextDialogResource', simpleTextDialogResource);
        },
        commitSimpleListDialogResource({ commit }, simpleListDialogResource) {
            return commit('setSimpleListDialogResource', simpleListDialogResource);
        },
        commitProjectInf({ commit }, projectInf) {
            return commit('setProjectInf', projectInf);
        },
        commitAppConfig({ commit }, appConfig) {
            return commit('setAppConfig', appConfig);
        },
        commitAccountInf({ commit }, accountInf) {
            return commit('setAccountInf', accountInf);
        },
        commitResultText({ commit }, resultText) {
            return commit('setResultText', resultText);
        },
        commitConsoleText({ commit }, consoleText) {
            return commit('setConsoleText', consoleText);
        },
        commitSelectionLanguage({ commit }, selectionLanguage) {
            return commit('setSelectionLanguage', selectionLanguage);
        },
        commitUploadedImageData({ commit }, uploadedImageData) {
            return commit('setUploadedImageData', uploadedImageData);
        },
        commitUploadedImageData3D({ commit }, uploadedImageData3D) {
            return commit('setUploadedImageData3D', uploadedImageData3D);
        },
    },
});
