import * as Blockly from 'blockly/core';

// ブロックの配色
var js_ui_block_color = 310;

// UIブロック定義
const category_UI = `
    <category name="UI" colour="310">
        <block type="js_ui_button">
            <value name="button_name">
                <block type="text">
                    <field name="TEXT">button</field>
                </block>
            </value>
        </block>
    </category>`;

export {category_UI};

///////////////////////////////////////////////////////////////////
// ready
Blockly.Blocks['js_ui_button'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("ボタン")
        this.appendValueInput("button_name")
            .appendField("名前")
        this.appendStatementInput("code")
            .setCheck(null);
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(js_ui_block_color);
        this.setTooltip("button");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_ui_button'] = function(block) {

    var statements_data = Blockly.JavaScript.statementToCode(block, 'code');
    var button_name = Blockly.JavaScript.valueToCode(block, 'button_name', Blockly.JavaScript.ORDER_ATOMIC);
    
    var code = 'var elm=document.createElement(\'button\');\n';
    // code += 'elm.id=\'button\';\n';
    code += 'elm.type=\'button\';\n';
    // code += 'elm.width=\'300px\';\n';
    // code += 'elm.height=\'50px\';\n';
    // code += 'elm.style.position=\'absolute\';\n';
    // code += 'elm.style.left=\'100px\';\n';
    // code += 'elm.style.top=\'50px\';\n';
    code += 'elm.innerText=' + button_name + ';\n';
    code += 'elm.style=\'background:#cccccc;padding:8px;margin:4px;\';\n';
    // code += 'elm.addEventListener(\'onclick\',function(){alert(123);});\n';
    code += 'elm.onclick=function(){\n';
    code += statements_data;
    code += '};\n';
    code += 'document.getElementById(\'ExecutionPanel\').appendChild(elm);\n';

    return code;
};

