<template>
    <v-card
        max-width='100%'
        height='100%'
        elevation='2'
        class='ml-1 mr-1 mb-1 pa-1'
        >
    <div id='ExecutionPanel'
            style='width:100%; height:100%; margin: 0px; padding: 5px; overflow: auto; background-color:white;'
            ref='ResultDiv'
            >

            <!-- <div id="map"
                    style='width:100%; height:300px; margin: 0px; padding: 5px;'
            ></div> -->

            <pre style="margin-top: 0%; text-align: left; color: lightgray; font-family:Noto Sans JP; font-size: 150%;">Result</pre>
    </div>

    <!-- <modal name="mapView" :draggable="false" :resizable="false" @opened="beforeOpen" :width="840" :height="640"> -->
    <modal name="mapView" :draggable="false" :resizable="false"  @opened="beforeOpen" :width="840" :height="640">
        <div class="modal-header">
        <div id="map" style="width:800px; height:600px; margin-left: 20px; margin-top: 20px;"></div>
        </div>  
    </modal>

    </v-card>
</template>
<script>

// import { Loader } from "@googlemaps/js-api-loader"
import L from "leaflet"
import 'leaflet/dist/leaflet.css'
import httpRequests from './../module/httpRequests'

export default {
    name: 'ResultView',

    data() {
        return {
            //
            // loader: null,
            map: null,
            mapdiv: null,
            map_position: {
                latitude: 35.658,
                longtitude: 139.745,
                comment: '',
            },
            // marker: {
            //     latitude: 35.658,
            //     longtitude: 139.745,
            // }
            map_marker: [],
            map_circle: [],
            map_rectangle: [],

        };
    },

    created() {
    },

    mounted() {
        // this.$modal.show('mapView');

        // this.loader = new Loader({
        //     apiKey: process.env.VUE_APP_GM_KEY,
        //     version: "weekly",
        //     libraries: ["places"],
        // });

        // this.mapdiv = document.getElementById("map");
        // this.map = L.map('map').setView([34.292, 134.124], 13);
        // L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        //     attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        // }).addTo(this.map);

        // 初期化
        this.map_marker = [];
        this.map_circle = [];
        this.map_rectangle = [];
    },

    methods: {
        // JS実行   
        setScriptCode(code) {
            // 初期化
            this.map_marker = [];
            this.map_circle = [];
            this.map_rectangle = [];
            // カメラ停止
            this.stopCamera();

            /////////////////////////////////////////////////////
            // console.log hook
            var consoleExt = {};
            consoleExt.originalConsoleLog = console.log;
            consoleExt.result = '===== ' + this.formatDate(new Date(), 'yyyy/M/d H:m:s') + ' =====\r\n';
            consoleExt.logOutput = true;

            consoleExt.log = function(message) {
            //    if (consoleExt.logOutput)
                {
                    // オリジナル処理を実行
                    consoleExt.originalConsoleLog(message);
                    // alert(message);
                }
                // 出力内容を保存
                consoleExt.result += (message + '\r\n');
            };
            consoleExt.hook = function() {
                if (consoleExt.originalConsoleLog === console.log) {
                    console.log = consoleExt.log;
                }
            };
            consoleExt.unhook = function() {
                if (consoleExt.originalConsoleLog !== console.log) {
                    console.log = consoleExt.originalConsoleLog;
                }
            };
            consoleExt.hook();
            /////////////////////////////////////////////////////

            // MAPオブジェクト用
            // eslint-disable-line no-unused-vars
            // const obj = this;
            // console.log(obj);
            
            // 子エレメントを削除
            this.$refs.ResultDiv.innerHTML = '';

            // console.log(code);
            
            // scriptを実行
            try {
                if (code != ''){
                    eval(code);
                }
            } catch(e) {
                console.log(e.message);
                consoleExt.result += (e.message + '\r\n');
                // consoleExt.result += ('check\r\n');
            }            

            // 実行結果を保存
            this.$store.dispatch('commitConsoleText', consoleExt.result);

            // alert(this.$store.getters.getConsoleText);
            
            // 実行結果をコンソールへ出力
            // this.$emit('codeGenerateEvent', message);

            /////////////////////////////////////////////////////
            // console.log unhook
            consoleExt.unhook();
            /////////////////////////////////////////////////////

            if (this.$refs.ResultDiv.innerHTML == ''){
                // HTML初期化
                this.$refs.ResultDiv.innerHTML = '<pre style="margin-top: 0%; text-align: left; color: lightgray; font-family:Noto Sans JP; font-size: 150%;">Result</pre>';
            }

        },
        // 実行結果
        setResult(code) {

            // かさ
            var umbllera = '<img src="https://firebasestorage.googleapis.com/v0/b/broccoly-97269.appspot.com/o/image%2Fbeach-umbrella.png?alt=media&token=0acdeaf1-e267-4959-8015-51af6a96bbf0">';
            // くらげ
            var jellyfish = '<img src="https://firebasestorage.googleapis.com/v0/b/broccoly-97269.appspot.com/o/image%2Fjellyfish.png?alt=media&token=06fb9ac5-eae9-4b06-8664-8d5979439aa7">';
            // たこ
            var octopus = '<img src="https://firebasestorage.googleapis.com/v0/b/broccoly-97269.appspot.com/o/image%2Foctopus.png?alt=media&token=cdc1b963-8c14-4c22-bd20-6a8808a1a1b1">';
            // みかん
            var orange = '<img src="https://firebasestorage.googleapis.com/v0/b/broccoly-97269.appspot.com/o/image%2Forange.png?alt=media&token=f24ce313-936c-46e9-bf4f-47c3d63464df">';
            // パイン
            var pine = '<img src="https://firebasestorage.googleapis.com/v0/b/broccoly-97269.appspot.com/o/image%2Fpalm-tree.png?alt=media&token=f83da49c-cd47-44f4-8ae5-b6eea5374378">';
            // ペリカン
            var pelican = '<img src="https://firebasestorage.googleapis.com/v0/b/broccoly-97269.appspot.com/o/image%2Fpelican.png?alt=media&token=b08869fd-8749-4d1e-b0c1-3c3e547bb2f5">';
            // 植物
            var plants = '<img src="https://firebasestorage.googleapis.com/v0/b/broccoly-97269.appspot.com/o/image%2Fplant.png?alt=media&token=7b669ad2-6b89-45ba-8136-0d191529f93a">';
            // かもめ
            var seagull = '<img src="https://firebasestorage.googleapis.com/v0/b/broccoly-97269.appspot.com/o/image%2Fseagull.png?alt=media&token=59b5b566-0b65-470f-918d-dc1f3af00937">';
            // ひとで
            var starfish = '<img src="https://firebasestorage.googleapis.com/v0/b/broccoly-97269.appspot.com/o/image%2Fstarfish.png?alt=media&token=37bcb6ea-503e-44c5-a1a1-5b5550340857">';
            // 温度計
            var thermometer = '<img src="https://firebasestorage.googleapis.com/v0/b/broccoly-97269.appspot.com/o/image%2Fthermometer.png?alt=media&token=6057ca82-c731-41da-b4c3-894af3c13dbe">';

            // 置換
            code = code.replace(/\s*<img src='かさ'>/g, umbllera);
            code = code.replace(/\s*<img src='くらげ'>/g, jellyfish);
            code = code.replace(/\s*<img src='たこ'>/g, octopus);
            code = code.replace(/\s*<img src='みかん'>/g, orange);
            code = code.replace(/\s*<img src='パイン'>/g, pine);
            code = code.replace(/\s*<img src='ペリカン'>/g, pelican);
            code = code.replace(/\s*<img src='植物'>/g, plants);
            code = code.replace(/\s*<img src='かもめ'>/g, seagull);
            code = code.replace(/\s*<img src='ひとで'>/g, starfish);
            code = code.replace(/\s*<img src='温度計'>/g, thermometer);

            // 子エレメントを削除
            this.$refs.ResultDiv.innerHTML = code;

        },
        // 日付フォーマット
        formatDate(date, format) {
            format = format.replace(/yyyy/g, date.getFullYear());
            format = format.replace(/M/g, ('00' + (date.getMonth() + 1)).slice(-2));
            format = format.replace(/d/g, ('00' + (date.getDate())).slice(-2));
            format = format.replace(/H/g, ('00' + (date.getHours())).slice(-2));
            format = format.replace(/m/g, ('00' + (date.getMinutes())).slice(-2));
            format = format.replace(/s/g, ('00' + (date.getSeconds())).slice(-2));
            format = format.replace(/S/g, ('000' + (date.getMilliseconds())).slice(-3));
            return format;
        },
        // カメラ停止
        stopCamera() {
            var _stream = null;
            var _elm = document.getElementById('my-video');
            if (_elm != null){
                _stream = _elm.srcObject;
            }

            if (_stream != null) {
                _stream.getVideoTracks()[0].stop();
                _stream.removeTrack(_stream.getVideoTracks()[0]);
                _stream.getTracks().forEach(track => { track.stop(); });
                _stream = null;
            }
        },
        //
        async showMap() {
            await this.$modal.show('mapView');
        },
        // MAP表示イベント
        beforeOpen() {

            // console.log("beforeOpen()");

            const mapdiv = document.getElementById("map");

            if (mapdiv != null)
            {
                const obj = this;

                // console.log("beforeOpen() 01");

                // this.loader.load().then(() => {
                //     obj.map = new window.google.maps.Map(mapdiv, {
                //         center: { lat: 34.350547, lng: 134.0474826 },
                //         zoom: 8,
                //     });

                //     console.log("beforeOpen() 02");

                //     // var service = new window.google.maps.places.PlacesService(obj.map);
        
                //     // service.findPlaceFromQuery({
                //     //     query: '高松駅',
                //     //     fields: ['name', 'geometry'],
                //     // }, function(results, status) {
                //     //     if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                //     //         console.log(results[0].geometry.location.lat());
                //     //         console.log(results[0].geometry.location.lng());
                //     //     }
                //     // });

                // });

                // console.log('obj.map_position.latitude:' + obj.map_position.latitude);
                // console.log('obj.map_position.longtitude:' + obj.map_position.longtitude);

                obj.mapdiv = L.map('map').setView([obj.map_position.latitude, obj.map_position.longtitude], 13);
                L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                }).addTo(obj.mapdiv);

                L.Icon.Default.imagePath = 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/';

                var cnt=0;

                // marker
                if (obj.map_marker != null){
                    for (cnt=0; cnt<obj.map_marker.length; cnt++){
                        var m = L.marker([obj.map_marker[cnt].latitude, obj.map_marker[cnt].longtitude]).
                        addTo(obj.mapdiv);

                        if (obj.map_marker[cnt].comment != ''){
                            m.bindPopup(obj.map_marker[cnt].comment)
                            .openPopup();
                        }
                    }
                }
                // circle
                if (obj.map_circle != null){
                    for (cnt=0; cnt<obj.map_circle.length; cnt++){
                        L.circle([obj.map_circle[cnt].latitude, obj.map_circle[cnt].longtitude], {
                            color: 'red',
                            fillColor: '#f03',
                            fillOpacity: 0.5,
                            radius: obj.map_circle[cnt].radius
                        }).addTo(obj.mapdiv);
                    }
                }
                // rectangle
                if (obj.map_rectangle != null){
                    for (cnt=0; cnt<obj.map_rectangle.length; cnt++){
                        L.polygon([
                            [obj.map_rectangle[cnt].north, obj.map_rectangle[cnt].west],
                            [obj.map_rectangle[cnt].north, obj.map_rectangle[cnt].east],
                            [obj.map_rectangle[cnt].south, obj.map_rectangle[cnt].east],
                            [obj.map_rectangle[cnt].south, obj.map_rectangle[cnt].west],
                        ]).addTo(obj.mapdiv);
                    }
                }
            }
        },
        //        
        setCenterForMap(map, latitude, longtitude)
        {
            // console.log("setCenterForMap() 01");

            // var latlng = new window.google.maps.LatLng(latitude, longtitude);
            // this.map.setCenter(latlng);

            // map = L.map('map').setView([latitude, longtitude], 13);
            // L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            //     attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            // }).addTo(map);

            // const mapdiv = document.getElementById("map");

            // if (mapdiv != null)
            // {
            //     // const obj = this;
            //     mapdiv.setView([latitude, longtitude], 6);
            //     L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            //         attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            //     }).addTo(mapdiv);
            // }
            this.map_position.latitude = latitude;
            this.map_position.longtitude = longtitude;

        },
        //
        setMarkerForMap(map, latitude, longtitude, comment)
        {
            // var latlng = new window.google.maps.LatLng(latitude, longtitude);
            // var marker = new window.google.maps.Marker({
            //     position: latlng,
            //     title: ""
            // });
            // marker.setMap(map);

            // this.marker.latitude = latitude;
            // this.marker.longtitude = longtitude;

            this.map_marker.push({
                latitude: latitude,
                longtitude: longtitude,
                comment: comment,
            });
        },
        //
        setRectangleForMap(map, north, south, east, west)
        {
            // new window.google.maps.Rectangle({
            //     strokeColor: "#FF0000",
            //     strokeOpacity: 0.8,
            //     strokeWeight: 2,
            //     fillColor: "#FF0000",
            //     fillOpacity: 0.35,
            //     map: map,
            //     bounds: {
            //         north: north,
            //         south: south,
            //         east: east,
            //         west: west,
            //     },
            // });

            this.map_rectangle.push({
                north: north,
                south: south,
                east: east,
                west: west,
            });
        },
        
        setCircleForMap(map, latitude, longtitude, radius)
        {
            // new window.google.maps.Circle({
            //     strokeColor: "#FF0000",
            //     strokeOpacity: 0.8,
            //     strokeWeight: 2,
            //     fillColor: "#FF0000",
            //     fillOpacity: 0.35,
            //     map: map,
            //     center: {
            //         lat: latitude,
            //         lng: longtitude,
            //     },
            //     radius: radius,
            // });

            // console.log('latitude:' + latitude);
            // console.log('longtitude:' + longtitude);
            // console.log('radius:' + radius);

            this.map_circle.push({
                latitude: latitude,
                longtitude: longtitude,
                radius: radius,
            });
        },
        // //
        // getLocationFromKeyword(map, keyword)
        // {
        //     console.log('getLocationFromKeyword 00');

        //     console.log('getLocationFromKeyword 01:' + keyword);

        //     var location;

        //     // new window.google.maps.places.PlacesService(map).findPlaceFromQuery({
        //     //     query: '高松駅',
        //     //     fields: ['name', 'geometry'],
        //     //     }, function(results, status) {
        //     //         if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        //     //             location = 
        //     //             {
        //     //                 lat: results[0].geometry.location.lat(),
        //     //                 lng: results[0].geometry.location.lng(),
        //     //             }
        //     //             console.log('getLocationFromKeyword 02:' + location);
        //     //         }else{
        //     //             console.log('getLocationFromKeyword 02:PlacesServiceStatus.NG');
        //     //         }
        //     //     });

        //     // console.log('getLocationFromKeyword 03:' + location);

        //     new Promise((resolve) => {
        //         new window.google.maps.places.PlacesService(map).findPlaceFromQuery({
        //             query: '高松駅',
        //             fields: ['name', 'geometry'],
        //             }, function(results, status) {
        //                 if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        //                     console.log('getLocationFromKeyword 02:PlacesServiceStatus.OK');
        //                     resolve(results[0]);
        //                 }else{
        //                     console.log('getLocationFromKeyword 02:PlacesServiceStatus.NG');
        //                 }
        //             });
        //     }).then((val) => {
        //         console.log('getLocationFromKeyword 02:then');
        //         this.location = 
        //         {
        //             lat: val.geometry.location.lat(),
        //             lng: val.geometry.location.lng(),
        //         }

        //         console.log('getLocationFromKeyword fin:' + this.location.lat);
        //         return location;
        //     });

        //     console.log('getLocationFromKeyword check:');

        // },
        
        getTakamatsuOpenData(url){

            var req = new httpRequests();
            var text = req.getTakamatsuOpenData(url);

            return text;
        }
    }
}

</script>

