// http requests
class httpRequests {
        
    // コンストラクタ
    constructor() {
    }

    // GET 同期
    // responseType（'' / text / json / document / blob / arraybuffer）
    async getSync(url) {

        var text = '';

        // オブジェクトを作成
        var xhr = new XMLHttpRequest();
        // 送信準備
        // xhr.open(method, URL, [async, user, password])
        xhr.open('GET', url, false);
        // CORS対応
        xhr.withCredentials = true;
        
        try {
            // 送信
            xhr.send();
            // 実行結果ステータス
            if (xhr.status != 200) {
                // NG
                alert(`Error ${xhr.status}: ${xhr.statusText}`);
            } else {
                // OK
                text = xhr.response;
            }
        } catch(err) { // onerror の代わり
            alert("getSync:Request failed");
        }

        return text;
    }

    // GET 非同期
    // responseType（'' / text / json / document / blob / arraybuffer）
    async getASync(obj, url, code) {

        var text = '';

        // オブジェクトを作成
        var xhr = new XMLHttpRequest();
        // 送信準備
        xhr.open('GET', url + '?paramCode=' + code);
        // CORS対応
        xhr.withCredentials = true;
        // Content-Type
        xhr.responseType = 'text';
        // // parameter
        // var param = 'paramCode=' + code;

        try {
            // 送信
            xhr.send();
            // エラー
            xhr.onerror = (() => {
                console.log('onerror:getASync:error');
            });
            // 受信状態
            // xhr.onreadystatechange = function() {
            xhr.onreadystatechange = (() => {
                switch (xhr.readyState) {
                    case 3:
                    // loading
                        {
                            console.log('http requests loading.');
                        }
                        break;
                    case 4:
                    // request finished
                        {
                            // vuex データ更新
                            obj.$emit("responseBuffer", xhr.responseText);
                        }
                        break;
                    default:
                        break;
                }
            });
  
        } catch(err) { // onerror の代わり
            alert("Request failed");
        }

        return text;
    }

    // POST 非同期
    // responseType（'' / text / json / document / blob / arraybuffer）
    async postASync(url, code) {

        var text = '';

        // オブジェクトを作成
        var xhr = new XMLHttpRequest();
        // 送信準備
        xhr.open('POST', url);
        // CORS対応
        xhr.withCredentials = true;
        // Content-Type
        xhr.responseType = 'text';
        // parameter
        var param = 'paramCode=' + code;

        try {
            // 送信
            xhr.send(param);
            // エラー
            xhr.onerror = (() => {
                console.log('onerror:postASync:error');
            });
            // 受信状態
            // xhr.onreadystatechange = function() {
            xhr.onreadystatechange = (() => {
                switch (xhr.readyState) {
                    case 3:
                    // loading
                        {
                            console.log('loading:' + xhr.responseText);
                        }
                        break;
                    case 4:
                    // request finished
                        {
                            console.log('request finished:' + xhr.responseText);
                        }
                        break;
                    default:
                        break;
                }
            });
  
        } catch(err) { // onerror の代わり
            alert("Request failed");
        }

        return text;
    }

    // GET 同期
    // responseType（'' / text / json / document / blob / arraybuffer）
    getTakamatsuOpenData(url) {

        var text = '';

        // console.log('getTakamatsuOpenData: 00');

        // オブジェクトを作成
        var xhr = new XMLHttpRequest();
        // 送信準備
        xhr.open('GET', url, false);
        // CORS対応
        xhr.withCredentials = false;
        // 追加ヘッダー
        // xhr.setRequestHeader("Accept", "application/json");
        // xhr.setRequestHeader("Content-Type", "application/json");
        xhr.setRequestHeader("Authorization", "Bearer efc02456-d053-3494-af29-882909a50695");        

        // console.log('getTakamatsuOpenData: 01');

        try {
            // 送信
            xhr.send();

            // console.log('getTakamatsuOpenData: 02');

            // console.log('getTakamatsuOpenData ret:' + xhr.status);

            // 実行結果ステータス
            if (xhr.status != 200) {
                // NG
                // alert(`Error ${xhr.status}: ${xhr.statusText}`);
                // console.log('getTakamatsuOpenData:' + text);
            } else {
                // OK
                // JSON形式のまま返す
                text = xhr.response;

                // console.log('getTakamatsuOpenData:' + text);
            }
        } catch(err) { // onerror の代わり
            alert("getSync:Request failed");
        }

        // console.log('getTakamatsuOpenData:99');

        return text;
    }

}

export default httpRequests;


