// Chartブロック定義
// Logic
const category_Logic = `
    <category name="条件" colour="%{BKY_LOGIC_HUE}">
        <block type="controls_if"></block>
        <block type="controls_if">
            <mutation else="1"></mutation>
        </block>
        <block type="controls_if">
            <mutation elseif="1" else="1" />
        </block>
        <block type="logic_compare"></block>
        <block type="logic_operation"></block>
        <block type="logic_negate"></block>
        <block type="logic_boolean"></block>
        <block type="logic_null"></block>
        <block type="logic_ternary"></block>
    </category>`;
// Loops
// BKY_LOOPS_HUE
const category_Loop = `
    <category name="繰り返し" colour="160">
        <block type="controls_repeat_ext">
            <value name="TIMES">
                <block type="math_number">
                    <field name="NUM">10</field>
                </block>
            </value>
        </block>
        <block type="controls_whileUntil"></block>
        <block type="controls_for">
            <field name="VAR">i</field>
            <value name="FROM">
                <block type="math_number">
                    <field name="NUM">1</field>
                </block>
            </value>
            <value name="TO">
            <block type="math_number">
                <field name="NUM">10</field>
            </block>
            </value>
            <value name="BY">
            <block type="math_number">
                <field name="NUM">1</field>
            </block>
            </value>
        </block>
        <block type="controls_forEach"></block>
        <block type="controls_flow_statements"></block>
    </category>`;
// Math
// BKY_MATH_HUE
const category_Math = `
    <category name="計算" colour="100">
        <block type="math_number">
            <field name="NUM">123</field>
        </block>
        <block type="math_arithmetic"></block>
        <block type="math_single"></block>
        <block type="math_trig"></block>
        <block type="math_constant"></block>
        <block type="math_number_property"></block>
        <block type="math_round"></block>
        <block type="math_on_list"></block>
        <block type="math_modulo"></block>
        <block type="math_constrain">
            <value name="LOW">
            <block type="math_number">
                <field name="NUM">1</field>
            </block>
            </value>
            <value name="HIGH">
            <block type="math_number">
                <field name="NUM">100</field>
            </block>
            </value>
        </block>
        <block type="math_random_int">
            <value name="FROM">
            <block type="math_number">
            <field name="NUM">1</field>
            </block>
            </value>
            <value name="TO">
            <block type="math_number">
            <field name="NUM">100</field>
            </block>
            </value>
        </block>
        <block type="math_random_float"></block>
        <block type="math_atan2"></block>
    </category>`;
// Text
// BKY_TEXTS_HUE
const category_Text = `
    <category name="文字" colour="46">
        <block type="text"></block>
        <block type="text_multiline"></block>
        <block type="text_join"></block>
        <block type="text_create_join_container"></block>
        <block type="text_create_join_item"></block>
        <block type="text_append"></block>
        <block type="text_isEmpty"></block>
        <block type="text_indexOf"></block>
        <block type="text_charAt"></block>
        <block type="text_length"></block>
        <block type="text_print"></block>
    </category>`;
// Colour
// BKY_COLOUR_HUE
const category_Colour = `
    <category name="色" colour="300">
        <block type="colour_rgb"></block>
        <block type="colour_random"></block>
    </category>`;
// Variables
// BKY_VARIABLES_HUE
const category_Variables = `
    <category name="変数" custom="VARIABLE" colour="20"></category>`;
// Lists
const category_Lists = `
    <category name="リスト" colour="%{BKY_LISTS_HUE}">
        <block type="lists_create_empty"></block>
        <block type="lists_create_with"></block>
        <block type="lists_repeat">
            <value name="NUM">
            <block type="math_number">
                <field name="NUM">5</field>
            </block>
            </value>
        </block>
        <block type="lists_length"></block>
        <block type="lists_isEmpty"></block>
        <block type="lists_indexOf"></block>
        <block type="lists_getIndex"></block>
        <block type="lists_setIndex"></block>
    </category>`;
// Functions
// BKY_PROCEDURES_HUE
const category_Functions = `
    <category name="関数" custom="PROCEDURE" colour="333"></category>`;

// const category_ClassDef = `
//     <category name="クラス" custom="CLASS_DEF" colour="333">
//     <block type="class_member_class_def"></block>
//     </category>`;

const category_ClassMember = `
    <category name="変数" custom="CLASS_MEMBER" colour="20">
    <block type="class_member_class_def"></block>
    </category>`;
const category_ClassMethod = `
    <category name="関数" custom="CLASS_METHOD" colour="333">
    </category>`;
const category_TypedLists = `
    <category name="リスト" colour="%{BKY_LISTS_HUE}">
    <block type="typed_lists_create_empty"></block>
    <block type="typed_lists_length"></block>
    <block type="typed_lists_isEmpty"></block>
    <block type="typed_lists_getIndex"></block>
    <block type="typed_lists_setIndex"></block>
    </category>`;

    // <block type="typed_lists_indexOf"></block>
    // <block type="typed_lists_create_with"></block>
    // <block type="typed_lists_repeat">
    //     <value name="NUM">
    //     <block type="math_number">
    //         <field name="NUM">5</field>
    //     </block>
    //     </value>
    // </block>

export {
    category_Logic
    , category_Loop
    , category_Math
    , category_Text
    , category_Colour
    , category_Variables
    , category_Lists
    , category_Functions
    // , category_ClassDef
    , category_ClassMember
    , category_ClassMethod
    , category_TypedLists
};
