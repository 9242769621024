<template>
    <v-row
        class='pl-2 pr-1'
        >

    <v-card
        elevation='0'
        style='margin-top:-50px;'
        >

        <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
            <v-btn
                absolute
                dark
                fab
                top
                left
                color='blue darken-1'
                small
                class='mt-7'
                v-bind="attrs"
                v-on="on"
                @click="homeButtonEvent()"
                >
                <v-icon>mdi-home</v-icon>
            </v-btn>
            </template>
        <span>ホーム画面へ</span>
        </v-tooltip>

        <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
            <v-btn
                absolute
                dark
                fab
                top
                left
                color='purple'
                small
                style='margin-left:50px;'
                class='mt-7'
                v-bind="attrs"
                v-on="on"
                @click="saveProjectButtonEvent()"
                v-bind:disabled="enabledSave"
                v-if="!enabled"
                >
            <v-icon>mdi-folder-upload</v-icon>
            </v-btn>
        </template>
        <span>プロジェクト保存</span>
        </v-tooltip>

        <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
            <v-btn
                absolute
                dark
                fab
                top
                left
                color='black'
                small
                style='margin-left:100px;'
                class='mt-7'
                v-bind="attrs"
                v-on="on"
                @click="blockCopyButtonEvent()"
                >
                <v-icon>mdi-content-copy</v-icon>
            </v-btn>
            </template>
        <span>コピー</span>
        </v-tooltip>

        <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
            <v-btn
                absolute
                dark
                fab
                top
                left
                color='black'
                small
                style='margin-left:150px;'
                class='mt-7'
                v-bind="attrs"
                v-on="on"
                @click="blockUndoButtonEvent()"
                >
                <v-icon>mdi-undo</v-icon>
            </v-btn>
            </template>
        <span>取り消す</span>
        </v-tooltip>

        <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
            <v-btn
                absolute
                dark
                fab
                top
                left
                color='black'
                small
                style='margin-left:200px;'
                class='mt-7'
                v-bind="attrs"
                v-on="on"
                @click="blockRedoButtonEvent()"
                >
                <v-icon>mdi-redo</v-icon>
            </v-btn>
            </template>
        <span>やり直す</span>
        </v-tooltip>

        <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
            <v-btn
                absolute
                dark
                fab
                top
                left
                color='green'
                small
                style='margin-left:250px;'
                class="mt-7"
                v-bind="attrs"
                v-on="on"
                @click="blockRunButtonEvent()"
                >
                <v-icon>mdi-play</v-icon>
            </v-btn>
            </template>
        <span>実行</span>
        </v-tooltip>

        <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
            <v-btn
                absolute
                dark
                fab
                top
                left
                color='red'
                small
                style='margin-left:300px;'
                class='mt-7'
                v-bind="attrs"
                v-on="on"
                @click="blockBreakButtonEvent()"
                >
                <v-icon>mdi-stop</v-icon>
            </v-btn>
            </template>
        <span>停止</span>
        </v-tooltip>

        <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
            <v-btn
                absolute
                dark
                fab
                top
                left
                color='teal lighten-1'
                small
                style='margin-left:350px;'
                class='mt-7'
                v-bind="attrs"
                v-on="on"
                @click="blockSourceCopyButtonEvent()"
                >
                <v-icon>mdi-text-box-multiple</v-icon>
            </v-btn>
            </template>
        <span>コードをクリップボードにコピー</span>
        </v-tooltip>

        <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
            <v-btn
                absolute
                dark
                fab
                top
                left
                :color='consoleSize.icon_color'
                small
                style='margin-left:400px;'
                class='mt-7'
                v-bind="attrs"
                v-on="on"
                @click="blockTestEvent()"
                >
                <v-icon>{{consoleSize.icon}}</v-icon>
            </v-btn>
            </template>
        <span>デバッグ出力</span>
        </v-tooltip>

        <!-- <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
            <v-btn
                absolute
                dark
                fab
                top
                left
                color='red lighten-1'
                small
                style='margin-left:450px;'
                class='mt-7'
                v-bind="attrs"
                v-on="on"
                @click="blockSpaceToImageButtonEvent()"
                >
                <v-icon>mdi-text-box-multiple</v-icon>
            </v-btn>
            </template>
        <span>TEST</span>
        </v-tooltip> -->

        <!-- <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
            <v-btn
                absolute
                dark
                fab
                top
                left
                color='green'
                small
                style='margin-left:350px;'
                class='mt-7'
                v-bind="attrs"
                v-on="on"
                @click="blockStepButtonEvent()"
                >
                <v-icon>mdi-step-forward</v-icon>
            </v-btn>
            </template>
        <span>ステップ</span>
        </v-tooltip> -->
    </v-card>
    <v-col>
        <SplitGrid
            class='sb_split-grid'
            v-bind="splitGridOptions"
            v-on:drag-start="onDragStart"
            v-on:drag-end="onDragEnd"           
            >
        <SplitGridArea
            ref='split_blockly'
            sizeUnit='%'
            :sizeValue='34'>
            <BlockView
                ref='blockly'
                v-if="resetFlag"
                v-bind:style="{height: height_b}"
                @codeGenerateEvent="saveCode"
                @runCodeGenerateEvent="runCode"
                :updateComponent=updateComponent
                />
        </SplitGridArea>
        <SplitGridGutter style='background-color: transparent;' :render="false"/>
        <SplitGridArea ref='split_source' sizeUnit='%' :sizeValue='33'>
            <SourceView
                ref='source'
                v-bind:style="{height: height_b}"
                />
        </SplitGridArea>
        <SplitGridGutter style='background-color: transparent;' :render="false"/>
            <SplitGrid
                class='sb_sub-grid'
                v-on:drag-start="onDragStart"
                v-on:drag-end="onDragEnd"
                direction="row"
                >
            <SplitGridArea
                ref='split_result'
                :size-unit='resultSize.unit'
                :size-value='resultSize.value'
                v-bind:style="{width: consoleWidth}"
                >
                <ResultView
                    ref='result'
                    :rawHtml=rawHtml
                    :rawScript=rawScript
                    @mapProc="emitMapProc"
                    />
            </SplitGridArea>
            <SplitGridGutter style='background-color: red;' :render="false"/>
            <SplitGridArea
                ref='split_console'
                :size-unit='consoleSize.unit'
                :size-value='consoleSize.value'
                :show='consoleSize.show'
                v-bind:style="{width: consoleWidth}"
                >
                <ConsoleView
                    ref='consoleView'
                    />
            </SplitGridArea>
            </SplitGrid>
        </SplitGrid>
    </v-col>
    </v-row>
</template>

<style>
.sb_split-grid {
    height: 100%;
}
.sb_sub-grid {
    height: 100%;
}
</style>

<script>
import { SplitGrid, SplitGridArea, SplitGridGutter } from 'vue-split-grid';
import swal from 'sweetalert2';
import { swalIcon, swalTitle, codeLanguage } from './../module/defines';
import httpRequests from './../module/httpRequests'
import BlockView from './BlockView';
import SourceView from './SourceView';
import ResultView from './ResultView';
import ConsoleView from './ConsoleView';

export default {

    name: 'MainView',
    // firebaseオブジェクト
    fbo : null,

    components: {
        SplitGrid,
        SplitGridArea,
        SplitGridGutter,        
        BlockView,
        SourceView,
        ResultView,
        ConsoleView,
    },

    computed: {
        consoleSize() {
            return this.consoleToggleSize
            ? { show: true, value: this.height_b4, unit: 'px', icon: 'mdi-chevron-down-box', icon_color: 'teal darken-4', }
            : { show: false, value: 0, unit: 'px', icon: 'mdi-chevron-up-box', icon_color: 'teal lighten-1', };
        },
        resultSize() {
            return this.consoleToggleSize
            ? { show: true, value: this.height_b3, unit: 'px' }
            : { show: true, value: this.height_b3 + this.height_b4 + 4, unit: 'px' };
        }
    },

    data() {
        return {
            // ブロック高さ
            height_b:1000,
            height_b2:500,
            height_b3:500,
            height_b4:200,
            consoleWidth:'600px',

            //
            enabled: false,
            enabledSave: false,
            // html source
            rawScript: '',
            rawHtml: '',
            consoleText: '',
            // BlockView更新
            updateComponent: false,
            // ウインドウリサイズ時のBlockView更新
            timeoutID: 100,
            timeoutID_: 101,
            delay: 50,
            // 起動時のBlockView更新
            resetFlag: false,
            // Splitオプション
            splitGridOptions: {
                columnMinSizes: { 0: 0 },
                snapOffset: 0,
                dragInterval: 0,
                gutterSize: 2,
            },
            // トグルサイズ
            consoleToggleSize: true,
        };
    },

    created() {
        // 各パネルの初期高さ設定
        this.height_b = this.calcMainBlockHeight();
        this.height_b2 = this.calcMainBlockHeight2();
        this.height_b3 = this.calcMainBlockHeight3();
        this.height_b4 = this.calcMainBlockHeight4();
        this.consoleWidth = this.calcConsoleBlockWidth();

        // firebaseオブジェクト
        this.fbo = this.$store.state.firebaseObject;
        // Analytics開始
        this.fbo.startAnalytics_main();

    },

    mounted() {
        // トライアルログイン
        this.enabledSave = (this.$store.state.loginId == process.env.VUE_APP_CONF_GUEST_ID);

        // 起動時画面更新
        clearTimeout(this.timeoutID);
        this.timeoutID = setTimeout(()=>{
            this.resetFlag = true;
        }, this.delay);

        // ウインドウサイズ変更時イベントを設定
        window.addEventListener('resize', () => {
            clearTimeout(this.timeoutID);
            this.timeoutID = setTimeout(()=>{

                // 高さを更新
                this.height_b = this.calcMainBlockHeight();
                this.height_b2 = this.calcMainBlockHeight2();
                this.height_b3 = this.calcMainBlockHeight3();
                this.height_b4 = this.calcMainBlockHeight4();
                this.consoleWidth = this.calcConsoleBlockWidth();
                // 更新
                this.updateComponent = true;
                // 時間をおいてフラグをリセット
                clearTimeout(this.timeoutID_);
                this.timeoutID_ = setTimeout(()=>{
                    this.updateComponent = false;
                }, this.delay);

            }, this.delay);
        }, false);
    },

    methods: {
        // メニューファンクション
        async functionMethod(data) {

            if (this.$refs.blockly == null) {
                console.log('blockly is null');
                return;
            }
            switch (data.functionNo){
            // File メニューアイテム
                case 100:   // 新規作成
                    // Blocklyの初期化
                    this.$refs.blockly.mnNewProject();
                    break;
                case 101:   // 保存
                    this.$refs.blockly.mnDataSave(data.fileName, data.savefileComment);
                    break;
                case 102:   // 読み込み
                    // this.$refs.blockly.mnDataLoad(data.fileName);
                    break;
            // Edit メニューアイテム
                case 200:   // コピー
                    this.$refs.blockly.mnEditCopy();
                    break;
                case 201:   // すすめる
                    this.$refs.blockly.mnEditRedo();
                    break;
                case 202:   // 戻す
                    this.$refs.blockly.mnEditUndo();
                    break;
                case 205:   // 有効・無効
                    this.$refs.blockly.mnEnabled();
                    break;
                case 206:   // 折りたたむ
                    this.$refs.blockly.mnExpand();
                    break;
                case 207:   // 整理する
                    this.$refs.blockly.mnAdjustment();
                    break;
                case 208:   // 削除する
                    this.$refs.blockly.mnDelete();
                    break;
                case 209:   // 貼り付け
                    this.$refs.blockly.mnPaste();
                    break;
            // Run メニューアイテム
                case 300:   // 実行
                    // 停止
                    this.$refs.result.setScriptCode('');
                    this.$refs.blockly.mnBlockStop();

                    var req = new httpRequests();

                    // 出力クリア
                    this.$refs.result.setResult("");
                    this.$refs.consoleView.updateDebugString("実行中");

                    // 選択言語
                    if (this.$store.state.selectionLanguage == codeLanguage.JS){
                        // scriptを実行
                        this.$refs.result.setScriptCode(this.generatedRunCode);
                        // コンソール出力
                        this.$refs.consoleView.updateDebugString(this.$store.getters.getConsoleText);
                    }
                    else if (this.$store.state.selectionLanguage == codeLanguage.Python){
                        // python Serverへコードを送信
                        await req.getASync(this, 'https://pythonbroccoly.pythonanywhere.com/broccoycoder_python', encodeURIComponent(this.generatedRunCode));

                        // this.generatedRunCode += 'print("standard error", file=redirected_output)';
                        // await req.getASync(this, 'https://pythonbroccoly.pythonanywhere.com/broccoycoder_python_tester', encodeURIComponent(this.generatedRunCode));

                    }
                    else if (this.$store.state.selectionLanguage == codeLanguage.CS){
                        // csharp Serverへコードを送信
                        // await req.getASync(this, 'http://localhost:5051/api/ApiTest/', encodeURIComponent(this.generatedRunCode));
                        await req.getASync(this, 'https://csbtest.somee.com/api/ApiTest/', encodeURIComponent(this.generatedRunCode));
                    }
                    else if (this.$store.state.selectionLanguage == codeLanguage.HTML){

                        // HTMLへコードを送信
                        this.$refs.result.setResult(this.generatedRunCode);
                    }
                    break;
                case 301:   // 停止
                    this.$refs.result.setScriptCode('');
                    this.$refs.blockly.mnBlockStop();
                    break;
                case 302:   // コード生成
                    // // this.$refs.blockly.mnGenerateCode();
                    // this.downloadCode();
                    break;
                case 303:   // ステップ実行
                    this.$refs.blockly.mnStepExec();
                    break;
                case 304:   // 画像アップロード
                    console.log('mnImageUpload: ' + 'upload image');
                    {
                        const _fbo = this.fbo;

                        // storageのダウンロードリンク件数を取得
                        var imgCount = await _fbo.getAllStorageImageCount(this.$store.getters.getLoginUUID);

                        console.log('imgCount:' + imgCount);

                        // 保存数オーバー
                        if (imgCount > 10) {
                            await swal.fire({
                                title: swalTitle.INFO,
                                text: '保存できる画像数が上限に達しています。',
                                icon: swalIcon.INFO,
                            });

                            return;
                        }

                        const { value: file } = await swal.fire({
                            icon: swalIcon.INFO,
                            title: 'アップロードする画像を選択してください。',
                            input: 'file',
                            showCancelButton: true,
                            inputAttributes: {
                                'accept': 'image/*',
                            }
                        })

                        if (file) {
                            // file information
                            // console.log(file.name);
                            // console.log(file.size);

                            const reader = new FileReader()
                            var worker = this.$store.getters.getUploadedImageData;
                            // var worker = [];

                            if (worker == undefined){
                                // console.log('worker is undefind:');
                                worker = [];
                            }
                            // console.log('worker count:' + worker);
                            // console.log('worker count:' + worker.length);

                            reader.onload = async (/*e*/) => {
                                // 画像データ書き込み
                                var url = await _fbo.writeStorageImage(this.$store.getters.getLoginUUID, file.name, file);
                                // 配列にデータを保存
                                worker.push({
                                    filename: file.name,
                                    url: url,
                                });

                                console.log('url:' + url);
                                // console.log('url count:' + worker.length);

                                // データを保存
                                this.$store.dispatch('commitUploadedImageData', worker);
                                // ブロックを更新
                                this.$refs.blockly.mnAddImage();
                            }
                            reader.readAsDataURL(file)
                        }
                    }
                    break;
                case 305:   // 画像アップロード3D
                    console.log('mnImageUpload3D: ' + 'upload image');
                    {
                        const _fbo = this.fbo;

                        // storageのダウンロードリンク件数を取得
                        var imgCount3D = await _fbo.getAllStorageImageCount3D(this.$store.getters.getLoginUUID);

                        console.log('imgCount3D:' + imgCount3D);

                        // 保存数オーバー
                        if (imgCount3D > 10) {
                            await swal.fire({
                                title: swalTitle.INFO,
                                text: '保存できる画像数が上限に達しています。',
                                icon: swalIcon.INFO,
                            });

                            return;
                        }

                        const { value: file } = await swal.fire({
                            icon: swalIcon.INFO,
                            title: 'アップロードする3D画像を選択してください。',
                            input: 'file',
                            showCancelButton: true,
                            inputAttributes: {
                                'accept': 'image/glb',
                            }
                        })

                        if (file) {
                            // file information
                            // console.log(file.name);
                            // console.log(file.size);

                            const reader = new FileReader()
                            var worker3D = this.$store.getters.getUploadedImageData3D;
                            // var worker = [];

                            if (worker3D == undefined){
                                // console.log('worker is undefind:');
                                worker3D = [];
                            }
                            // console.log('worker count:' + worker);
                            // console.log('worker count:' + worker.length);

                            reader.onload = async (/*e*/) => {
                                // 画像データ書き込み
                                var url = await _fbo.writeStorageImage3D(this.$store.getters.getLoginUUID, file.name, file);
                                // 配列にデータを保存
                                worker3D.push({
                                    filename: file.name,
                                    url: url,
                                });

                                console.log('url:' + url);
                                // console.log('url count:' + worker.length);

                                // データを保存
                                this.$store.dispatch('commitUploadedImageData3D', worker3D);
                                // ブロックを更新
                                this.$refs.blockly.mnAddImage3D();
                            }
                            reader.readAsDataURL(file)
                        }
                    }
                    break;
            // Help メニューアイテム
                case 400:   // ヘルプ
                    break;
                case 401:   // バージョン
                    break;
            // その他処理
                case 900:   // コード保存
                    this.$refs.blockly.setCode(data.code);
                    break;
                case 901:   // コード取得
                    return this.$refs.blockly.getCode();
                case 902:   // コード取得
                    return this.generatedCode;
            }
        },
        // 生成コードの保存
        saveCode(code){

            // console.log('saveCode:' + code);

            // 編集コードを保存
            this.generatedCode = code;
            // 更新
            this.selectedCode();
        },
        // 実行コード
        runCode(code){
            // 編集コードを保存
            this.generatedRunCode = code;
        },
        // CODEタブクリック時イベント
        selectedCode() {
            if (this.$refs.source == undefined){
                return;
            }

            // console.log('selectedCode:call mnGenerateCode');
            
            var code = this.$refs.blockly.mnGenerateCode();

            // console.log('selectedCode:' + code);

            this.$refs.source.updateCodeList(code);

        },
        // ウインドウリサイズイベント
        resizeWindowEvent() {
            // 各パネルの初期高さ設定
            this.height_b = this.calcMainBlockHeight();
            this.height_b2 = this.calcMainBlockHeight2();
            this.height_b3 = this.calcMainBlockHeight3();
            this.height_b4 = this.calcMainBlockHeight4();
            this.consoleWidth = this.calcConsoleBlockWidth();
        },
        // メインブロックの高さ計算
        calcMainBlockHeight() {
            return (window.innerHeight - 170) + 'px';
        },
        calcMainBlockHeight2() {
            return (window.innerHeight - 274) + 'px';
        },
        calcMainBlockHeight3() {
            // return (window.innerHeight - 170 - this.height_b4) + 'px';
            return (window.innerHeight - 174 - this.height_b4);
        },
        calcMainBlockHeight4() {
            // return (200) + 'px';
            return (200);
        },
        calcConsoleBlockWidth() {
            return (((window.innerWidth / 3) - 20)) + 'px';
        },
        // ホーム画面へ遷移
        homeButtonEvent() {
            // イベントパラメーター生成
            var event = {
                id: 'mnHome',
            };
            // ファイル作成イベント
            this.$emit('mainProc', event);
        },
        // プロジェクト保存
        saveProjectButtonEvent() {
            // イベントパラメーター生成
            var event = {
                id: 'mnSaveProject',
            };
            // ファイル作成イベント
            this.$emit('mainProc', event);
        },
        // コピー操作
        blockCopyButtonEvent() {
            this.$refs.blockly.mnEditCopy();
        },
        // すすめる操作
        blockRedoButtonEvent() {
            this.$refs.blockly.mnEditRedo();
        },
        // 戻す操作
        blockUndoButtonEvent() {
            this.$refs.blockly.mnEditUndo();
        },
        // 実行操作
        blockRunButtonEvent() {
            this.$emit('mainProc', null);
        },
        // ステップ実行
        blockStepButtonEvent() {
            this.$emit('stepProc', null);
        },
        // プログラム停止
        blockBreakButtonEvent() {
            this.$emit('stopProc', null);
        },
        // ペースト操作
        blockPasteButtonEvent() {
            this.$refs.blockly.mnEditPaste();
        },
        // ソースコピー
        blockSourceCopyButtonEvent() {
            // コードを取得
            var code = this.$refs.blockly.mnGenerateCode();
            // クリップボードにコピー
            navigator.clipboard.writeText(code)
            .then(() => {
                // 確認メッセージ
                swal.fire({
                    title: swalTitle.INFO,
                    text: 'クリップボードにコードをコピーしました。',
                    icon: swalIcon.INFO,
                });
            })
            .catch(e => {
                console.log(e);
                // 確認メッセージ
                swal.fire({
                    title: swalTitle.INFO,
                    text: 'コードのコピーに失敗しました。',
                    icon: swalIcon.INFO,
                });
            })
        },
        // 
        blockSpaceToImageButtonEvent() {
            
            alert('blockSpaceToImageButtonEvent');

            this.$refs.blockly.workspaceToImage();

            // var svg = document.getElementsByClassName("blocklyBlockCanvas")[0].getBBox();
            // if (svg == null || svg == undefined) {
            //     alert('svg is null');
            //     return;
            // }
            // var svgData = new XMLSerializer().serializeToString(svg);
            // if (svgData == null || svgData == undefined) {
            //     alert('svgData is null');
            //     return;
            // }
        },
        // テストメソッド
        blockTestEvent() {
            this.consoleToggleSize = !this.consoleToggleSize;
        },
        // Splitterドラッグスタート
        onDragStart() {
        },
        // Splitterドラッグエンド
        onDragEnd() {
        },
        //
        updateResultString() {
            // 実行結果出力
            this.$refs.result.setResult(this.$store.getters.getResultText);
        },
        //
        updateDebugString(){
            // 実行時間を記録
            var text = '===== ' + this.formatDate(new Date(), 'yyyy/M/d H:m:s') + ' =====\r\n'
                        + this.$store.getters.getConsoleText;

            // コンソール出力
            this.$refs.consoleView.updateDebugString(text);
        },
        // 日付フォーマット
        formatDate(date, format) {
            format = format.replace(/yyyy/g, date.getFullYear());
            format = format.replace(/M/g, ('00' + (date.getMonth() + 1)).slice(-2));
            format = format.replace(/d/g, ('00' + (date.getDate())).slice(-2));
            format = format.replace(/H/g, ('00' + (date.getHours())).slice(-2));
            format = format.replace(/m/g, ('00' + (date.getMinutes())).slice(-2));
            format = format.replace(/s/g, ('00' + (date.getSeconds())).slice(-2));
            format = format.replace(/S/g, ('000' + (date.getMilliseconds())).slice(-3));
            return format;
        },
        //
        emitMapProc(event) {
            console.log('MainView::mapProc:' + event);
            this.$emit('mapProc', event);
        }
    },
}
</script>
