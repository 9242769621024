import * as Blockly from 'blockly/core';

// ブロックの配色
var js_three_block_color = 270;

var UploadedImageData = undefined;

// Timerブロック定義
const category_Three = `
    <category name="Three.js" colour="270">
        <block type="js_three_block_camera">
            <value name="angle">
                <block type="math_number">
                    <field name="NUM">75</field>
                </block>
            </value>
            <value name="aspect">
                <block type="math_number">
                    <field name="NUM">1.0</field>
                </block>
            </value>
            <value name="near">
                <block type="math_number">
                    <field name="NUM">0.1</field>
                </block>
            </value>
            <value name="far">
                <block type="math_number">
                    <field name="NUM">1000</field>
                </block>
            </value>
        </block>

        <block type="js_three_block_render">
        </block>

        <block type="js_three_block_light">
        </block>

        <block type="js_three_block_ambient_light">
            <value name="color">
            </value>
        </block>

        <block type="js_three_block_scene">
        </block>

        <block type="js_three_block_texture">
        </block>

        <block type="js_three_block_boxgeometry">
            <value name="wSeg">
                <block type="math_number">
                    <field name="NUM">1</field>
                </block>
            </value>
            <value name="hSeg">
                <block type="math_number">
                    <field name="NUM">1</field>
                </block>
            </value>
            <value name="dSeg">
                <block type="math_number">
                    <field name="NUM">1</field>
                </block>
            </value>
        </block>

        <block type="js_three_block_circlegeometry">
            <value name="radius">
                <block type="math_number">
                    <field name="NUM">1</field>
                </block>
            </value>
            <value name="segments">
                <block type="math_number">
                    <field name="NUM">1</field>
                </block>
            </value>
        </block>

        <block type="js_three_block_conegeometry">
            <value name="radius">
                <block type="math_number">
                    <field name="NUM">1</field>
                </block>
            </value>
            <value name="height">
                <block type="math_number">
                    <field name="NUM">1</field>
                </block>
            </value>
            <value name="radialSegments">
                <block type="math_number">
                    <field name="NUM">8</field>
                </block>
            </value>
        </block>

        <block type="js_three_block_cylindergeometry">
            <value name="radiusTop">
                <block type="math_number">
                    <field name="NUM">1</field>
                </block>
            </value>
            <value name="radiusBottom">
                <block type="math_number">
                    <field name="NUM">1</field>
                </block>
            </value>
            <value name="height">
                <block type="math_number">
                    <field name="NUM">1</field>
                </block>
            </value>
            <value name="radialSegments">
                <block type="math_number">
                    <field name="NUM">8</field>
                </block>
            </value>
        </block>

        <block type="js_three_block_octahedrongeometry">
            <value name="radius">
                <block type="math_number">
                    <field name="NUM">1</field>
                </block>
            </value>
            <value name="detail">
                <block type="math_number">
                    <field name="NUM">1</field>
                </block>
            </value>
        </block>

        <block type="js_three_block_spheregeometry">
            <value name="radius">
                <block type="math_number">
                    <field name="NUM">1</field>
                </block>
            </value>
            <value name="widthSegments">
                <block type="math_number">
                    <field name="NUM">1</field>
                </block>
            </value>
            <value name="heightSegments">
                <block type="math_number">
                    <field name="NUM">1</field>
                </block>
            </value>
        </block>

        <block type="js_three_block_tetrahedrongeometry">
            <value name="radius">
                <block type="math_number">
                    <field name="NUM">1</field>
                </block>
            </value>
            <value name="detail">
                <block type="math_number">
                    <field name="NUM">1</field>
                </block>
            </value>
        </block>

        <block type="js_three_block_torusgeometry">
            <value name="radius">
                <block type="math_number">
                    <field name="NUM">1</field>
                </block>
            </value>
            <value name="tube">
                <block type="math_number">
                    <field name="NUM">0.4</field>
                </block>
            </value>
            <value name="radialSegments">
                <block type="math_number">
                    <field name="NUM">8</field>
                </block>
            </value>
            <value name="tubularSegments">
                <block type="math_number">
                    <field name="NUM">6</field>
                </block>
            </value>
        </block>

        <block type="js_three_block_buffergeometry">
        </block>

        <block type="js_three_block_text_geometry">
            <value name="text">
                <block type="text">
                    <field name="TEXT">abc</field>
                </block>
            </value>
        </block>

        <block type="js_three_block_mesh_lambert_material">
        </block>

        <block type="js_three_block_mesh_basic_material">
        </block>

        <block type="js_three_block_mesh_standard_material">
        </block>

        <block type="js_three_block_line_basic_material">
        </block>

        <block type="js_three_block_mesh">
        </block>

        <block type="js_three_block_line">
        </block>

        <block type="js_three_block_axeshelper">
            <value name="size">
                <block type="math_number">
                    <field name="NUM">5</field>
                </block>
            </value>
        </block>

        <block type="js_three_block_add">
            <value name="objVar">
            </value>
        </block>

        <block type="js_three_block_push">
            <value name="objVar">
            </value>
        </block>

        <block type="js_three_block_map">
            <value name="objVar">
            </value>
        </block>

        <block type="js_three_block_background">
        </block>

        <block type="js_three_block_mesh_position_set">
            <value name="posX">
                <block type="math_number">
                    <field name="NUM">0</field>
                </block>
            </value>
            <value name="posY">
                <block type="math_number">
                    <field name="NUM">0</field>
                </block>
            </value>
            <value name="posZ">
                <block type="math_number">
                    <field name="NUM">0</field>
                </block>
            </value>
        </block>

        <block type="js_three_block_new_color">
            <value name="R">
                <block type="math_number">
                    <field name="NUM">255</field>
                </block>
            </value>
            <value name="G">
                <block type="math_number">
                    <field name="NUM">255</field>
                </block>
            </value>
            <value name="B">
                <block type="math_number">
                    <field name="NUM">255</field>
                </block>
            </value>
        </block>

        <block type="js_three_block_new_vector">
            <value name="X">
                <block type="math_number">
                    <field name="NUM">0</field>
                </block>
            </value>
            <value name="Y">
                <block type="math_number">
                    <field name="NUM">0</field>
                </block>
            </value>
            <value name="Z">
                <block type="math_number">
                    <field name="NUM">0</field>
                </block>
            </value>
        </block>

        <block type="js_three_block_set_color">
            <value name="color">
            </value>
        </block>

        <block type="js_three_block_set_size">
            <value name="width">
                <block type="math_number">
                    <field name="NUM">100</field>
                </block>
            </value>
            <value name="height">
                <block type="math_number">
                    <field name="NUM">100</field>
                </block>
            </value>
        </block>

        <block type="js_three_block_set_wireframe">
        </block>

        <block type="js_three_block_controller">
        </block>

        <block type="js_three_block_controller_settings">
        </block>

        <block type="js_three_block_canvas">
        </block>

        <block type="js_three_block_append_render">
        </block>

        <block type="js_three_block_exec_animate">
        </block>

        <block type="js_three_block_define_animate">
        </block>

        <block type="js_three_block_text_property">
            <value name="font_size">
                <block type="math_number">
                    <field name="NUM">1</field>
                </block>
            </value>
            <value name="height">
                <block type="math_number">
                    <field name="NUM">1</field>
                </block>
            </value>
            <value name="curveSegments">
                <block type="math_number">
                    <field name="NUM">1</field>
                </block>
            </value>
            <value name="bevelThickness">
                <block type="math_number">
                    <field name="NUM">1</field>
                </block>
            </value>
            <value name="bevelSize">
                <block type="math_number">
                    <field name="NUM">1</field>
                </block>
            </value>
            <value name="bevelOffset">
                <block type="math_number">
                    <field name="NUM">0</field>
                </block>
            </value>
            <value name="bevelSegments">
                <block type="math_number">
                    <field name="NUM">1</field>
                </block>
            </value>
        </block>
        
        <block type="js_three_block_fontloader">
        </block>
        <block type="js_three_block_textureloader">
        </block>

    </category>`;

    // <block type="js_three_block_capsulegeometry">
    //     <value name="radius">
    //         <block type="math_number">
    //             <field name="NUM">1</field>
    //         </block>
    //     </value>
    //     <value name="length">
    //         <block type="math_number">
    //             <field name="NUM">1</field>
    //         </block>
    //     </value>
    // </block>

    // <block type="js_three_block_object3d">
    // </block>

export {category_Three};

export function setUploadedImageData(uploadedImageData) {
    UploadedImageData = uploadedImageData;
}

///////////////////////////////////////////////////////////////////
// camera
Blockly.Blocks['js_three_block_camera'] = {
    init: function() {
        this.appendValueInput("angle")
            .appendField("new Camera(")
            .appendField("視野角")
            .setCheck("Number")
        this.appendValueInput("aspect")
            .appendField("アスペクト比")
            .setCheck("Number")
        this.appendValueInput("near")
            .appendField("near")
            .setCheck("Number")
        this.appendValueInput("far")
            .appendField("far")
            .setCheck("Number")
        this.appendDummyInput()
            .appendField(")")
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(js_three_block_color);
        this.setTooltip("camera");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_three_block_camera'] = function(block) {

    var angle = Blockly.JavaScript.valueToCode(block, 'angle', Blockly.JavaScript.ORDER_ATOMIC);
    var aspect = Blockly.JavaScript.valueToCode(block, 'aspect', Blockly.JavaScript.ORDER_ATOMIC);
    var near = Blockly.JavaScript.valueToCode(block, 'near', Blockly.JavaScript.ORDER_ATOMIC);
    var far = Blockly.JavaScript.valueToCode(block, 'far', Blockly.JavaScript.ORDER_ATOMIC);

    var code = 'new THREE.PerspectiveCamera(';
    code += angle + ',';
    code += aspect + ',';
    code += near + ',';
    code += far + '';
    code += ')';

    return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// render
Blockly.Blocks['js_three_block_render'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("new Render()");
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(js_three_block_color);
        this.setTooltip("Render");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_three_block_render'] = function(/*block*/) {
    
    var code = 'new THREE.WebGLRenderer({';
    code += 'antialias: true';
    code += '})';

    return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// light
Blockly.Blocks['js_three_block_light'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("new Light()");
        this.appendValueInput("color")
            .setCheck(null)
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(js_three_block_color);
        this.setTooltip("Light");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_three_block_light'] = function(block) {

    var color = Blockly.JavaScript.valueToCode(block, 'color', Blockly.JavaScript.ORDER_ATOMIC);
    
    var code = 'new THREE.DirectionalLight(' + color + ', 1)';

    return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// ambient_light
Blockly.Blocks['js_three_block_ambient_light'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("new AmbientLight()")
        this.appendValueInput("color")
            .setCheck(null)
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(js_three_block_color);
        this.setTooltip("AmbientLight");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_three_block_ambient_light'] = function(block) {
    
    var color = Blockly.JavaScript.valueToCode(block, 'color', Blockly.JavaScript.ORDER_ATOMIC);

    var code = 'new THREE.AmbientLight(' + color + ', 1)';

    return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// scene
Blockly.Blocks['js_three_block_scene'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("new Scene()");
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(js_three_block_color);
        this.setTooltip("Scene");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_three_block_scene'] = function(/*block*/) {
    
    var code = 'new THREE.Scene()';

    return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// texture
Blockly.Blocks['js_three_block_texture'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("new Texture()");
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(js_three_block_color);
        this.setTooltip("Scene");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_three_block_texture'] = function(/*block*/) {
    
    // var code = '(new THREE.TextureLoader()).load(\'https://firebasestorage.googleapis.com/v0/b/broccoly-97269.appspot.com/o/image%2Fbeach-umbrella.png?alt=media&token=0d80eac6-e82d-4441-9f7b-5a81657b0b4d\', function (font) {\n';
    // var code = '(new THREE.TextureLoader()).load(\'./assets/broccoly_logo.png\')';
    // var code = '(new THREE.TextureLoader()).load(\'./../assets/pattern.png\')';
    var code = '(new THREE.TextureLoader()).load(\'https://firebasestorage.googleapis.com/v0/b/broccoly-97269.appspot.com/o/image%2Fbeach-umbrella.png?alt=media&token=0d80eac6-e82d-4441-9f7b-5a81657b0b4d\')';

    return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// BoxGeometry
Blockly.Blocks['js_three_block_boxgeometry'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("new BoxGeometry(")
        this.appendValueInput("wSeg")
            .appendField("Width Segment")
            .setCheck("Number")
        this.appendValueInput("hSeg")
            .appendField("Height Segment")
            .setCheck("Number")
        this.appendValueInput("dSeg")
            .appendField("Depth Segment")
            .setCheck("Number")
        this.appendDummyInput()
            .appendField(")")
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(js_three_block_color);
        this.setTooltip("BoxGeometry");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_three_block_boxgeometry'] = function(block) {

    var wSeg = Blockly.JavaScript.valueToCode(block, 'wSeg', Blockly.JavaScript.ORDER_ATOMIC);
    var hSeg = Blockly.JavaScript.valueToCode(block, 'hSeg', Blockly.JavaScript.ORDER_ATOMIC);
    var dSeg = Blockly.JavaScript.valueToCode(block, 'dSeg', Blockly.JavaScript.ORDER_ATOMIC);

    var code = 'new THREE.BoxGeometry(' + String(wSeg) + ',' + String(hSeg) + ',' + String(dSeg) + ')';

    return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

// ///////////////////////////////////////////////////////////////////
// // CapsuleGeometry
// Blockly.Blocks['js_three_block_capsulegeometry'] = {
//     init: function() {
//         this.appendDummyInput()
//             .appendField("new CapsuleGeometry(")
//         this.appendValueInput("radius")
//             .appendField("radius")
//             .setCheck("Number")
//         this.appendValueInput("length")
//             .appendField("length")
//             .setCheck("Number")
//         this.appendDummyInput()
//             .appendField(")")
//         this.setInputsInline(true);
//         this.setOutput(true, null);
//         this.setColour(js_three_block_color);
//         this.setTooltip("CapsuleGeometry");
//         this.setHelpUrl("");
//     }
// };

// Blockly.JavaScript['js_three_block_capsulegeometry'] = function(block) {

//     var radius = Blockly.JavaScript.valueToCode(block, 'radius', Blockly.JavaScript.ORDER_ATOMIC);
//     var length = Blockly.JavaScript.valueToCode(block, 'length', Blockly.JavaScript.ORDER_ATOMIC);

//     var code = 'new THREE.CapsuleGeometry(' + String(radius) + ',' + String(length) + ',4,8)';

//     return [code, Blockly.JavaScript.ORDER_ATOMIC];
// };

///////////////////////////////////////////////////////////////////
// CircleGeometry
Blockly.Blocks['js_three_block_circlegeometry'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("new CircleGeometry(")
        this.appendValueInput("radius")
            .appendField("radius")
            .setCheck("Number")
        this.appendValueInput("segments")
            .appendField("segments")
            .setCheck("Number")
        this.appendDummyInput()
            .appendField(")")
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(js_three_block_color);
        this.setTooltip("CubeGeometry");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_three_block_circlegeometry'] = function(block) {

    var radius = Blockly.JavaScript.valueToCode(block, 'radius', Blockly.JavaScript.ORDER_ATOMIC);
    var segments = Blockly.JavaScript.valueToCode(block, 'segments', Blockly.JavaScript.ORDER_ATOMIC);

    var code = 'new THREE.CircleGeometry(' + String(radius) + ',' + String(segments) + ')';

    return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// ConeGeometry
Blockly.Blocks['js_three_block_conegeometry'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("new ConeGeometry(")
        this.appendValueInput("radius")
            .appendField("radius")
            .setCheck("Number")
        this.appendValueInput("height")
            .appendField("height")
            .setCheck("Number")
        this.appendValueInput("radialSegments")
            .appendField("radialSegments")
            .setCheck("Number")
        this.appendDummyInput()
            .appendField(")")
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(js_three_block_color);
        this.setTooltip("CubeGeometry");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_three_block_conegeometry'] = function(block) {

    var radius = Blockly.JavaScript.valueToCode(block, 'radius', Blockly.JavaScript.ORDER_ATOMIC);
    var height = Blockly.JavaScript.valueToCode(block, 'height', Blockly.JavaScript.ORDER_ATOMIC);
    var radialSegments = Blockly.JavaScript.valueToCode(block, 'radialSegments', Blockly.JavaScript.ORDER_ATOMIC);

    var code = 'new THREE.ConeGeometry(' + String(radius) + ',' + String(height) + ',' + String(radialSegments) + ')';

    return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// CylinderGeometry
Blockly.Blocks['js_three_block_cylindergeometry'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("new CylinderGeometry(")
        this.appendValueInput("radiusTop")
            .appendField("radiusTop")
            .setCheck("Number")
        this.appendValueInput("radiusBottom")
            .appendField("radiusBottom")
            .setCheck("Number")
        this.appendValueInput("height")
            .appendField("height")
            .setCheck("Number")
        this.appendValueInput("radialSegments")
            .appendField("radialSegments")
            .setCheck("Number")
        this.appendDummyInput()
            .appendField(")")
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(js_three_block_color);
        this.setTooltip("CubeGeometry");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_three_block_cylindergeometry'] = function(block) {

    var radiusTop = Blockly.JavaScript.valueToCode(block, 'radiusTop', Blockly.JavaScript.ORDER_ATOMIC);
    var radiusBottom = Blockly.JavaScript.valueToCode(block, 'radiusBottom', Blockly.JavaScript.ORDER_ATOMIC);
    var height = Blockly.JavaScript.valueToCode(block, 'height', Blockly.JavaScript.ORDER_ATOMIC);
    var radialSegments = Blockly.JavaScript.valueToCode(block, 'radialSegments', Blockly.JavaScript.ORDER_ATOMIC);

    var code = 'new THREE.CylinderGeometry(' + String(radiusTop) + ',' + String(radiusBottom) + ',' + String(height) + ',' + String(radialSegments) + ')';

    return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// OctahedronGeometry
Blockly.Blocks['js_three_block_octahedrongeometry'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("new OctahedronGeometry(")
        this.appendValueInput("radius")
            .appendField("radius")
            .setCheck("Number")
        this.appendValueInput("detail")
            .appendField("detail")
            .setCheck("Number")
        this.appendDummyInput()
            .appendField(")")
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(js_three_block_color);
        this.setTooltip("CubeGeometry");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_three_block_octahedrongeometry'] = function(block) {

    var radius = Blockly.JavaScript.valueToCode(block, 'radius', Blockly.JavaScript.ORDER_ATOMIC);
    var detail = Blockly.JavaScript.valueToCode(block, 'detail', Blockly.JavaScript.ORDER_ATOMIC);

    var code = 'new THREE.OctahedronGeometry(' + String(radius) + ',' + String(detail) + ')';

    return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// SphereGeometry
Blockly.Blocks['js_three_block_spheregeometry'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("new SphereGeometry(")
        this.appendValueInput("radius")
            .appendField("radius")
            .setCheck("Number")
        this.appendValueInput("widthSegments")
            .appendField("widthSegments")
            .setCheck("Number")
        this.appendValueInput("heightSegments")
            .appendField("heightSegments")
            .setCheck("Number")
        this.appendDummyInput()
            .appendField(")")
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(js_three_block_color);
        this.setTooltip("CubeGeometry");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_three_block_spheregeometry'] = function(block) {

    var radius = Blockly.JavaScript.valueToCode(block, 'radius', Blockly.JavaScript.ORDER_ATOMIC);
    var widthSegments = Blockly.JavaScript.valueToCode(block, 'widthSegments', Blockly.JavaScript.ORDER_ATOMIC);
    var heightSegments = Blockly.JavaScript.valueToCode(block, 'heightSegments', Blockly.JavaScript.ORDER_ATOMIC);

    var code = 'new THREE.SphereGeometry(' + String(radius) + ',' + String(widthSegments) + ',' + String(heightSegments) + ')';

    return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// TetrahedronGeometry
Blockly.Blocks['js_three_block_tetrahedrongeometry'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("new TetrahedronGeometry(")
        this.appendValueInput("radius")
            .appendField("radius")
            .setCheck("Number")
        this.appendValueInput("detail")
            .appendField("detail")
            .setCheck("Number")
        this.appendDummyInput()
            .appendField(")")
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(js_three_block_color);
        this.setTooltip("CubeGeometry");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_three_block_tetrahedrongeometry'] = function(block) {

    var radius = Blockly.JavaScript.valueToCode(block, 'radius', Blockly.JavaScript.ORDER_ATOMIC);
    var detail = Blockly.JavaScript.valueToCode(block, 'detail', Blockly.JavaScript.ORDER_ATOMIC);

    var code = 'new THREE.TetrahedronGeometry(' + String(radius) + ',' + String(detail) + ')';

    return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// TorusGeometry
Blockly.Blocks['js_three_block_torusgeometry'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("new TorusGeometry(")
        this.appendValueInput("radius")
            .appendField("radius")
            .setCheck("Number")
        this.appendValueInput("tube")
            .appendField("tube")
            .setCheck("Number")
        this.appendValueInput("radialSegments")
            .appendField("radialSegments")
            .setCheck("Number")
        this.appendValueInput("tubularSegments")
            .appendField("tubularSegments")
            .setCheck("Number")
        this.appendDummyInput()
            .appendField(")")
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(js_three_block_color);
        this.setTooltip("CubeGeometry");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_three_block_torusgeometry'] = function(block) {

    var radius = Blockly.JavaScript.valueToCode(block, 'radius', Blockly.JavaScript.ORDER_ATOMIC);
    var tube = Blockly.JavaScript.valueToCode(block, 'tube', Blockly.JavaScript.ORDER_ATOMIC);
    var radialSegments = Blockly.JavaScript.valueToCode(block, 'radialSegments', Blockly.JavaScript.ORDER_ATOMIC);
    var tubularSegments = Blockly.JavaScript.valueToCode(block, 'tubularSegments', Blockly.JavaScript.ORDER_ATOMIC);

    var code = 'new THREE.TorusGeometry(' + String(radius) + ',' + String(tube) + ',' + String(radialSegments) + ',' + String(tubularSegments) + ')';

    return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// BufferGeometry
Blockly.Blocks['js_three_block_buffergeometry'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("new BufferGeometry(")
            // .appendField("geometry")
            // .appendField(new Blockly.FieldVariable("points"), "pointsVar")
        this.appendValueInput("pointsVar")
            .setCheck(null)
        this.appendDummyInput()
            .appendField(")")
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(js_three_block_color);
        this.setTooltip("BoxGeometry");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_three_block_buffergeometry'] = function(block) {

    // var pointsVar = Blockly.JavaScript.nameDB_.getName(block.getFieldValue('pointsVar'), 'VARIABLE');
    var pointsVar = Blockly.JavaScript.valueToCode(block, 'pointsVar', Blockly.JavaScript.ORDER_ATOMIC);

    var code = 'new THREE.BufferGeometry().setFromPoints(' + pointsVar + ')';

    return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// TextGeometry
Blockly.Blocks['js_three_block_text_geometry'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("new TextGeometry(")
        this.appendValueInput("text")
            .appendField("文字列")
        this.appendDummyInput()
            .appendField("geometry")
            // .appendField(new Blockly.FieldVariable("property"), "propertyVar")
        this.appendValueInput("propertyVar")
            .setCheck(null)
        this.appendDummyInput()
            .appendField(")")
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(js_three_block_color);
        this.setTooltip("BoxGeometry");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_three_block_text_geometry'] = function(block) {

    var text = Blockly.JavaScript.valueToCode(block, 'text', Blockly.JavaScript.ORDER_ATOMIC);
    // var propertyVar = Blockly.JavaScript.nameDB_.getName(block.getFieldValue('propertyVar'), 'VARIABLE');
    var propertyVar = Blockly.JavaScript.valueToCode(block, 'propertyVar', Blockly.JavaScript.ORDER_ATOMIC);

    var code = 'new THREE.TextGeometry(' + text + ',' + propertyVar + ')';

    return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// MeshLambertMaterial
Blockly.Blocks['js_three_block_mesh_lambert_material'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("new MeshLambertMaterial(")
        this.appendDummyInput()
            .appendField(")")
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(js_three_block_color);
        this.setTooltip("Material");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_three_block_mesh_lambert_material'] = function(/*block*/) {

    var code = 'new THREE.MeshLambertMaterial()';

    return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// MeshBasicMaterial
Blockly.Blocks['js_three_block_mesh_basic_material'] = {
    init: function() {
        this.appendDummyInput('diTextureImage')
            .appendField('new MeshBasicMaterial(')
            .appendField(new Blockly.FieldDropdown(this.generateOptions), 'texture_image')
            .appendField(')', 'kakko_end');

        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(js_three_block_color);
        this.setTooltip("Material");
        this.setHelpUrl("");
    },
    generateOptions: function() {
        var options = [];
        // 初期値
        options.push(['なし', '']);
        // 保存済みデータ
        if (UploadedImageData != undefined){
            var worker = UploadedImageData;
            // console.log('store data count:' + worker.length);

            // 保存済みデータの展開
            for (var i = 0; i < worker.length; i++) {
              options.push([worker[i].filename, worker[i].url]);
            //   console.log('store data:' + worker[i].filename);
            }
        }else{
            console.log('store is undefined');
        }

        return options;
    },
    // onchange: function(ev) {
    //     console.log(ev);
    // },
    // validate: function(newValue) {
    //     this.getSourceBlock().updateConnections(newValue);
    //     return newValue;
    // },    
    updateDropDown: function() {
        // console.log('updateDropDown:00');

        // 選択済みデータを保存
        const selectedVal = this.getFieldValue('texture_image');
        // console.log('updateDropDown:' + selectedVal);

        // ブロックの参照を取得
        var _option = new Blockly.FieldDropdown(this.generateOptions);

        // 選択肢の再設定
        const _input = this.getInput('diTextureImage');
        _input.removeField('texture_image');
        _input.removeField('kakko_end');
        _input.appendField(_option, 'texture_image');
        _input.appendField(')', 'kakko_end');

        // 再選択
        for (var cnt=0; cnt<_option.length; cnt++) {

            // console.log('再選択:' + _option[cnt][0]);

            if (selectedVal == _option[cnt][0]) {

                // console.log('再選択:' + 'OK');

                // _cmb.setValue(_option[cnt][0]);
                this.setFieldValue(_option[cnt][0], 'texture_image');
                break;
            }
        }

        // console.log('updateDropDown:02');
    }
};

Blockly.JavaScript['js_three_block_mesh_basic_material'] = function(block) {

    // console.log(block);
    // var code = 'new THREE.MeshBasicMaterial()';

    var textureImage = block.getFieldValue('texture_image');

    // console.log('js_three_block_mesh_basic_material:' + textureImage);

    // var code = 'new THREE.MeshBasicMaterial({map: (new THREE.TextureLoader()).load(\'./texture/blue_pattern.png\')})';

    var code = 'new THREE.MeshBasicMaterial({map: (new THREE.TextureLoader()).load(\'./texture/blue_pattern.png\')})';
    if (textureImage != ''){
        code = 'new THREE.MeshBasicMaterial({map: (new THREE.TextureLoader()).load(\'' + textureImage + '\')})';
    }
    
    // var code = 'new THREE.MeshBasicMaterial({map: (new THREE.TextureLoader()).load(\'./texture/moon.jpg\')})';
    // var code = 'new THREE.MeshBasicMaterial({map: (new THREE.TextureLoader()).load(\'https://firebasestorage.googleapis.com/v0/b/broccoly-97269.appspot.com/o/image%2Fbeach-umbrella.png?alt=media&token=0d80eac6-e82d-4441-9f7b-5a81657b0b4d\')})';
    
    // var code = 'new THREE.MeshBasicMaterial({map: (new THREE.TextureLoader()).load(\'https://r105.threejsfundamentals.org/threejs/resources/images/wall.jpg\')})';

    // var code = 'new THREE.MeshBasicMaterial()';

    return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// MeshLStandardMaterial
Blockly.Blocks['js_three_block_mesh_standard_material'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("new MeshStandardMaterial(")
        this.appendDummyInput()
            .appendField(")")
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(js_three_block_color);
        this.setTooltip("Material");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_three_block_mesh_standard_material'] = function(/*block*/) {

    var code = 'new THREE.MeshStandardMaterial()';

    return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// MeshBasicMaterial
Blockly.Blocks['js_three_block_line_basic_material'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("new LineBasicMaterial(")
        this.appendDummyInput()
            .appendField(")")
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(js_three_block_color);
        this.setTooltip("Material");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_three_block_line_basic_material'] = function(/*block*/) {

    var code = 'new THREE.LineBasicMaterial()';

    return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// mesh
Blockly.Blocks['js_three_block_mesh'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("new Mesh(")
            .appendField("geometry")
            // .appendField(new Blockly.FieldVariable("geometry"), "geometryVar")
        this.appendValueInput("geometryVar")
            .setCheck(null)
        this.appendDummyInput()
            .appendField("material")
            // .appendField(new Blockly.FieldVariable("material"), "materialVar")
        this.appendValueInput("materialVar")
            .setCheck(null)
        this.appendDummyInput()
            .appendField(")")
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(js_three_block_color);
        this.setTooltip("Material");
        this.setHelpUrl("");
    }

};

Blockly.JavaScript['js_three_block_mesh'] = function(block) {

    // var geometryVar = Blockly.JavaScript.nameDB_.getName(block.getFieldValue('geometryVar'), 'VARIABLE');
    // var materialVar = Blockly.JavaScript.nameDB_.getName(block.getFieldValue('materialVar'), 'VARIABLE');
    var geometryVar = Blockly.JavaScript.valueToCode(block, 'geometryVar', Blockly.JavaScript.ORDER_ATOMIC);
    var materialVar = Blockly.JavaScript.valueToCode(block, 'materialVar', Blockly.JavaScript.ORDER_ATOMIC);

    var code = 'new THREE.Mesh(' + geometryVar + ',' + materialVar + ')';

    return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// mesh
Blockly.Blocks['js_three_block_line'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("new Line(")
            .appendField("geometry")
            // .appendField(new Blockly.FieldVariable("geometry"), "geometryVar")
        this.appendValueInput("geometryVar")
            .setCheck(null)
        this.appendDummyInput()
            .appendField("material")
            // .appendField(new Blockly.FieldVariable("material"), "materialVar")
        this.appendValueInput("materialVar")
            .setCheck(null)
        this.appendDummyInput()
            .appendField(")")
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(js_three_block_color);
        this.setTooltip("Line");
        this.setHelpUrl("");
    }

};

Blockly.JavaScript['js_three_block_line'] = function(block) {

    // var geometryVar = Blockly.JavaScript.nameDB_.getName(block.getFieldValue('geometryVar'), 'VARIABLE');
    // var materialVar = Blockly.JavaScript.nameDB_.getName(block.getFieldValue('materialVar'), 'VARIABLE');
    var geometryVar = Blockly.JavaScript.valueToCode(block, 'geometryVar', Blockly.JavaScript.ORDER_ATOMIC);
    var materialVar = Blockly.JavaScript.valueToCode(block, 'materialVar', Blockly.JavaScript.ORDER_ATOMIC);

    var code = 'new THREE.Line(' + geometryVar + ',' + materialVar + ')';

    return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// mesh.position.set
Blockly.Blocks['js_three_block_mesh_position_set'] = {
    init: function() {
        // this.appendDummyInput()
        //     .appendField(new Blockly.FieldVariable("object"), "objVar")
        this.appendValueInput("objVar")
            .setCheck(null)
        this.appendDummyInput()
            .appendField(".position(")
        this.appendValueInput("posX")
            .appendField("x")
            .setCheck("Number")
        this.appendValueInput("posY")
            .appendField("y")
            .setCheck("Number")
        this.appendValueInput("posZ")
            .appendField("z")
            .setCheck("Number")
        this.appendDummyInput()
            .appendField(")")
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(js_three_block_color);
        this.setTooltip("Mesh.position");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_three_block_mesh_position_set'] = function(block) {

    // var objVar = Blockly.JavaScript.nameDB_.getName(block.getFieldValue('objVar'), 'VARIABLE');
    var objVar = Blockly.JavaScript.valueToCode(block, 'objVar', Blockly.JavaScript.ORDER_ATOMIC);
    var posX = Blockly.JavaScript.valueToCode(block, 'posX', Blockly.JavaScript.ORDER_ATOMIC);
    var posY = Blockly.JavaScript.valueToCode(block, 'posY', Blockly.JavaScript.ORDER_ATOMIC);
    var posZ = Blockly.JavaScript.valueToCode(block, 'posZ', Blockly.JavaScript.ORDER_ATOMIC);

    var code = objVar + '.position.set(' + posX + ',' + posY + ',' + posZ + ');\n';

    return code;
};

///////////////////////////////////////////////////////////////////
// AxesHelper
Blockly.Blocks['js_three_block_axeshelper'] = {
    init: function() {
        this.appendValueInput("size")
            .appendField("new AxesHelper(")
            .appendField("size")
            .setCheck("Number")
        this.appendDummyInput()
            .appendField(")")
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(js_three_block_color);
        this.setTooltip("Material");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_three_block_axeshelper'] = function(block) {

    var size = Blockly.JavaScript.valueToCode(block, 'size', Blockly.JavaScript.ORDER_ATOMIC);

    var code = 'new THREE.AxesHelper(' + size + ')';

    return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// scene.add
Blockly.Blocks['js_three_block_add'] = {
    init: function() {
        // this.appendDummyInput()
        //     .appendField(new Blockly.FieldVariable("object"), "sourceVar")
        this.appendValueInput("sourceVar")
            .setCheck(null)
        this.appendDummyInput()
            .appendField(".add(")
        this.appendValueInput("objVar")
            .setCheck(null)
        this.appendDummyInput()
            .appendField(")")
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(js_three_block_color);
        this.setTooltip("add");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_three_block_add'] = function(block) {

    // var sourceVar = Blockly.JavaScript.nameDB_.getName(block.getFieldValue('sourceVar'), 'VARIABLE');
    var sourceVar = Blockly.JavaScript.valueToCode(block, 'sourceVar', Blockly.JavaScript.ORDER_ATOMIC);

    var objVar = Blockly.JavaScript.valueToCode(block, 'objVar', Blockly.JavaScript.ORDER_ATOMIC);

    var code = sourceVar + '.add(' + objVar + ');\n';

    return code;
};

///////////////////////////////////////////////////////////////////
// push
Blockly.Blocks['js_three_block_push'] = {
    init: function() {
        // this.appendDummyInput()
        //     .appendField(new Blockly.FieldVariable("object"), "sourceVar")
        this.appendValueInput("sourceVar")
            .setCheck(null)
        this.appendDummyInput()
            .appendField(".push(")
        this.appendValueInput("objVar")
            .setCheck(null)
        this.appendDummyInput()
            .appendField(")")
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(js_three_block_color);
        this.setTooltip("push");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_three_block_push'] = function(block) {

    // var sourceVar = Blockly.JavaScript.nameDB_.getName(block.getFieldValue('sourceVar'), 'VARIABLE');
    var sourceVar = Blockly.JavaScript.valueToCode(block, 'sourceVar', Blockly.JavaScript.ORDER_ATOMIC);
    var objVar = Blockly.JavaScript.valueToCode(block, 'objVar', Blockly.JavaScript.ORDER_ATOMIC);

    var code = sourceVar + '.push(' + objVar + ');\n';

    return code;
};

///////////////////////////////////////////////////////////////////
// map
Blockly.Blocks['js_three_block_map'] = {
    init: function() {
        // this.appendDummyInput()
        //     .appendField(new Blockly.FieldVariable("object"), "sourceVar")
        this.appendValueInput("sourceVar")
            .setCheck(null)
        this.appendDummyInput()
            .appendField(".map(")
        this.appendValueInput("objVar")
            .setCheck(null)
        this.appendDummyInput()
            .appendField(")")
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(js_three_block_color);
        this.setTooltip("push");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_three_block_map'] = function(block) {

    // var sourceVar = Blockly.JavaScript.nameDB_.getName(block.getFieldValue('sourceVar'), 'VARIABLE');
    var sourceVar = Blockly.JavaScript.valueToCode(block, 'sourceVar', Blockly.JavaScript.ORDER_ATOMIC);
    var objVar = Blockly.JavaScript.valueToCode(block, 'objVar', Blockly.JavaScript.ORDER_ATOMIC);

    //var code = sourceVar + '.map(' + objVar + ');\n';
    var code = sourceVar + '.map = ' + objVar + ';\n';

    return code;
};

///////////////////////////////////////////////////////////////////
// scene.background
Blockly.Blocks['js_three_block_background'] = {
    init: function() {
        this.appendValueInput("objVar")
            .setCheck(null)
        this.appendDummyInput()
            // .appendField(new Blockly.FieldVariable("object"), "objVar")
            .appendField(".backgorund(")
        this.appendValueInput("color")
            .setCheck(null)
        this.appendDummyInput()
            .appendField(")")
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(js_three_block_color);
        this.setTooltip("backgorund");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_three_block_background'] = function(block) {

    // var objVar = Blockly.JavaScript.nameDB_.getName(block.getFieldValue('objVar'), 'VARIABLE');
    var objVar = Blockly.JavaScript.valueToCode(block, 'objVar', Blockly.JavaScript.ORDER_ATOMIC);
    var color = Blockly.JavaScript.valueToCode(block, 'color', Blockly.JavaScript.ORDER_ATOMIC);

    var code = objVar + '.background = ' + String(color) + ';\n';

    return code;
};

///////////////////////////////////////////////////////////////////
// Color
Blockly.Blocks['js_three_block_new_color'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("new Color(")
        this.appendValueInput("R")
            .appendField("R")
            .setCheck("Number")
        this.appendValueInput("G")
            .appendField("G")
            .setCheck("Number")
        this.appendValueInput("B")
            .appendField("B")
            .setCheck("Number")
        this.appendDummyInput()
            .appendField(")")
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(js_three_block_color);
        this.setTooltip("color");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_three_block_new_color'] = function(block) {

    var r = Blockly.JavaScript.valueToCode(block, 'R', Blockly.JavaScript.ORDER_ATOMIC);
    var g = Blockly.JavaScript.valueToCode(block, 'G', Blockly.JavaScript.ORDER_ATOMIC);
    var b = Blockly.JavaScript.valueToCode(block, 'B', Blockly.JavaScript.ORDER_ATOMIC);

    var code = 'new THREE.Color("rgb(' + String(r) + ',' + String(g) + ',' + String(b) + ')")';

    return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// Color
Blockly.Blocks['js_three_block_new_vector'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("new vector(")
        this.appendValueInput("X")
            .appendField("X")
            .setCheck("Number")
        this.appendValueInput("Y")
            .appendField("Y")
            .setCheck("Number")
        this.appendValueInput("Z")
            .appendField("Z")
            .setCheck("Number")
        this.appendDummyInput()
            .appendField(")")
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(js_three_block_color);
        this.setTooltip("vector");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_three_block_new_vector'] = function(block) {

    var x = Blockly.JavaScript.valueToCode(block, 'X', Blockly.JavaScript.ORDER_ATOMIC);
    var y = Blockly.JavaScript.valueToCode(block, 'Y', Blockly.JavaScript.ORDER_ATOMIC);
    var z = Blockly.JavaScript.valueToCode(block, 'Z', Blockly.JavaScript.ORDER_ATOMIC);

    var code = 'new THREE.Vector3(' + String(x) + ',' + String(y) + ',' + String(z) + ')';

    return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// set Color
Blockly.Blocks['js_three_block_set_color'] = {
    init: function() {
        this.appendValueInput("objVar")
            .setCheck(null)
        this.appendDummyInput()
            // .appendField(new Blockly.FieldVariable("object"), "objVar")
            .appendField(".color(")
        this.appendValueInput("color")
            .setCheck(null)
        this.appendDummyInput()
            .appendField(")")
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(js_three_block_color);
        this.setTooltip("color");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_three_block_set_color'] = function(block) {

    // var objVar = Blockly.JavaScript.nameDB_.getName(block.getFieldValue('objVar'), 'VARIABLE');
    var objVar = Blockly.JavaScript.valueToCode(block, 'objVar', Blockly.JavaScript.ORDER_ATOMIC);

    var color = Blockly.JavaScript.valueToCode(block, 'color', Blockly.JavaScript.ORDER_ATOMIC);

    var code = objVar + '.color.set(' + String(color) + ');\n';

    return code;
};

///////////////////////////////////////////////////////////////////
// set Size
Blockly.Blocks['js_three_block_set_size'] = {
    init: function() {
        this.appendValueInput("objVar")
            .setCheck(null)
        this.appendDummyInput()
            // .appendField(new Blockly.FieldVariable("object"), "objVar")
            .appendField(".setSize(")
        this.appendValueInput("width")
            .appendField("width")
            .setCheck("Number")
        this.appendValueInput("height")
            .appendField("height")
            .setCheck("Number")
        this.appendDummyInput()
            .appendField(")")
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(js_three_block_color);
        this.setTooltip("size");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_three_block_set_size'] = function(block) {

    // var objVar = Blockly.JavaScript.nameDB_.getName(block.getFieldValue('objVar'), 'VARIABLE');
    var objVar = Blockly.JavaScript.valueToCode(block, 'objVar', Blockly.JavaScript.ORDER_ATOMIC);

    var width = Blockly.JavaScript.valueToCode(block, 'width', Blockly.JavaScript.ORDER_ATOMIC);
    var height = Blockly.JavaScript.valueToCode(block, 'height', Blockly.JavaScript.ORDER_ATOMIC);

    var code = objVar + '.setSize(' + String(width) + ',' + String(height) + ');\n';

    return code;
};

///////////////////////////////////////////////////////////////////
// set Wireframe
Blockly.Blocks['js_three_block_set_wireframe'] = {
    init: function() {
        this.appendValueInput("objVar")
            .setCheck(null)
        this.appendDummyInput()
            .appendField(".wireframe(")
            .appendField(new Blockly.FieldDropdown([
                ['false', 'false'],
                ['true', 'true'],
            ]), 'wireframeEnabled');
        this.appendDummyInput()
            .appendField(")")
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(js_three_block_color);
        this.setTooltip("wireframe");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_three_block_set_wireframe'] = function(block) {

    var objVar = Blockly.JavaScript.valueToCode(block, 'objVar', Blockly.JavaScript.ORDER_ATOMIC);
    var wireframeEnabled = block.getFieldValue('wireframeEnabled');

    var code = objVar + '.wireframe = ' + wireframeEnabled + ';\n';

    return code;
};

///////////////////////////////////////////////////////////////////
// controller
Blockly.Blocks['js_three_block_controller'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("new OrbitControls(")
        // this.appendDummyInput()
        //     .appendField(new Blockly.FieldVariable("camera"), "cameraVar")
        this.appendValueInput("cameraVar")
            .setCheck(null)
        // this.appendDummyInput()
        //     .appendField(new Blockly.FieldVariable("renderer"), "rendererVar")            
        this.appendValueInput("rendererVar")
            .setCheck(null)
        this.appendDummyInput()
            .appendField(")")
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(js_three_block_color);
        this.setTooltip("controller");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_three_block_controller'] = function(block) {

    // var cameraVar = Blockly.JavaScript.nameDB_.getName(block.getFieldValue('cameraVar'), 'VARIABLE');
    // var rendererVar = Blockly.JavaScript.nameDB_.getName(block.getFieldValue('rendererVar'), 'VARIABLE');
    var cameraVar = Blockly.JavaScript.valueToCode(block, 'cameraVar', Blockly.JavaScript.ORDER_ATOMIC);
    var rendererVar = Blockly.JavaScript.valueToCode(block, 'rendererVar', Blockly.JavaScript.ORDER_ATOMIC);

    var code = 'new THREE.OrbitControls(' + cameraVar + ',' + rendererVar + '.domElement)';

    return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// controller setting
Blockly.Blocks['js_three_block_controller_settings'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("controls Settings")
        // this.appendDummyInput()
        //     .appendField(new Blockly.FieldVariable("controller"), "controllerVar")
        this.appendValueInput("controllerVar")
            .setCheck(null)
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(js_three_block_color);
        this.setTooltip("Settings");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_three_block_controller_settings'] = function(block) {

    // var controllerVar = Blockly.JavaScript.nameDB_.getName(block.getFieldValue('controllerVar'), 'VARIABLE');
    var controllerVar = Blockly.JavaScript.valueToCode(block, 'controllerVar', Blockly.JavaScript.ORDER_ATOMIC);

    var code = controllerVar + '.enableDamping = true;\n';
    code = code + controllerVar + '.dampingFactor = 0.2;\n';
    code = code + controllerVar + '.target.set(0, 0, 0);\n';
    code = code + controllerVar + '.update();\n';

    return code;
};

///////////////////////////////////////////////////////////////////
// Canvas
Blockly.Blocks['js_three_block_canvas'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("キャンバス 幅")
            .appendField(new Blockly.FieldNumber(500, 100, 500), "width")
            .appendField("高さ")
            .appendField(new Blockly.FieldNumber(500, 100, 500), "height")
        this.appendStatementInput("code")
            .setCheck(null);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(js_three_block_color);
        this.setTooltip("CANVAS");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_three_block_canvas'] = function(block) {
    var statements_data = Blockly.JavaScript.statementToCode(block, 'code');
    var width = block.getFieldValue('width');
    var height = block.getFieldValue('height');

    var code = 'var elm=document.createElement(\'div\');\n';
    code += 'elm.width=\'' + width + '\';\n';
    code += 'elm.height=\'' + height + '\';\n';
    code += statements_data + '\n';

    return code;
};

///////////////////////////////////////////////////////////////////
// append
Blockly.Blocks['js_three_block_append_render'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("append")
        // this.appendDummyInput()
        //     .appendField(new Blockly.FieldVariable("renderer"), "rendererVar")
        this.appendValueInput("rendererVar")
            .setCheck(null)
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(js_three_block_color);
        this.setTooltip("Settings");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_three_block_append_render'] = function(block) {

    // var rendererVar = Blockly.JavaScript.nameDB_.getName(block.getFieldValue('rendererVar'), 'VARIABLE');
    var rendererVar = Blockly.JavaScript.valueToCode(block, 'rendererVar', Blockly.JavaScript.ORDER_ATOMIC);

    var code = 'elm.appendChild(' + rendererVar + '.domElement);\n';
    code = code + 'document.getElementById(\'ExecutionPanel\').appendChild(elm);\n';

    return code;
};

///////////////////////////////////////////////////////////////////
// exec animate
Blockly.Blocks['js_three_block_exec_animate'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("animate()")
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(js_three_block_color);
        this.setTooltip("exec animate");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_three_block_exec_animate'] = function(/*block*/) {

    var code = 'animate();\n';

    return code;
};

///////////////////////////////////////////////////////////////////
// exec animate
Blockly.Blocks['js_three_block_define_animate'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("function animate(")
        // this.appendDummyInput()
        //     .appendField(new Blockly.FieldVariable("renderer"), "rendererVar")
        this.appendValueInput("rendererVar")
            .setCheck(null)
        // this.appendDummyInput()
        //     .appendField(new Blockly.FieldVariable("camera"), "cameraVar")
        this.appendValueInput("cameraVar")
            .setCheck(null)
        // this.appendDummyInput()
        //     .appendField(new Blockly.FieldVariable("scene"), "sceneVar")
        this.appendValueInput("sceneVar")
            .setCheck(null)
        // this.appendDummyInput()
        //     .appendField(new Blockly.FieldVariable("control"), "controlVar")
        this.appendValueInput("controlVar")
            .setCheck(null)
        this.appendDummyInput()
            .appendField(")")
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(js_three_block_color);
        this.setTooltip("function animate");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_three_block_define_animate'] = function(block) {

    // var rendererVar = Blockly.JavaScript.nameDB_.getName(block.getFieldValue('rendererVar'), 'VARIABLE');
    // var cameraVar = Blockly.JavaScript.nameDB_.getName(block.getFieldValue('cameraVar'), 'VARIABLE');
    // var sceneVar = Blockly.JavaScript.nameDB_.getName(block.getFieldValue('sceneVar'), 'VARIABLE');
    // var controlVar = Blockly.JavaScript.nameDB_.getName(block.getFieldValue('controlVar'), 'VARIABLE');
    var rendererVar = Blockly.JavaScript.valueToCode(block, 'rendererVar', Blockly.JavaScript.ORDER_ATOMIC);
    var cameraVar = Blockly.JavaScript.valueToCode(block, 'cameraVar', Blockly.JavaScript.ORDER_ATOMIC);
    var sceneVar = Blockly.JavaScript.valueToCode(block, 'sceneVar', Blockly.JavaScript.ORDER_ATOMIC);
    var controlVar = Blockly.JavaScript.valueToCode(block, 'controlVar', Blockly.JavaScript.ORDER_ATOMIC);

    var code = 'function animate() {\n';
    code = code + '    ' + rendererVar + '.render(' + sceneVar + ',' + cameraVar + ');\n';
    code = code + '    ' + controlVar + '.update();\n';
    code = code + '    ' + 'requestAnimationFrame(animate);\n';
    code = code + '}\n';

    return code;
};

// ///////////////////////////////////////////////////////////////////
// // font
// Blockly.Blocks['js_three_block_font'] = {
//     init: function() {
//       this.appendDummyInput()
//           .appendField('drop down:')
//           .appendField(new Blockly.FieldDropdown([
//               ['helvetiker', 'helvetiker'],
//               ['optimer', 'optimer'],
//               ['gentilis', 'gentilis'],
//               ['droid sans', 'droid sans'],
//               ['droid serif', 'droid serif'],
//           ]), 'font');
//         this.setInputsInline(true);
//         this.setOutput(true, null);
//         this.setColour(js_three_block_color);
//         this.setTooltip("controller");
//         this.setHelpUrl("");
//     }
// };

// Blockly.JavaScript['js_three_block_font'] = function(block) {

//     var font = block.getFieldValue('font');

//     var code = font;

//     return [code, Blockly.JavaScript.ORDER_ATOMIC];
// };

///////////////////////////////////////////////////////////////////
// text property
Blockly.Blocks['js_three_block_text_property'] = {
    init: function() {
        this.appendDummyInput()
            .appendField('text property')
        this.appendDummyInput()
            .appendField('font:')
            // .appendField(new Blockly.FieldVariable("font"), "fontVar")
        this.appendValueInput("fontVar")
            .setCheck(null)
        this.appendValueInput("font_size")
            .appendField("size:")
            .setCheck("Number")
        this.appendValueInput("height")
            .appendField("height:")
            .setCheck("Number")
        this.appendValueInput("curveSegments")
            .appendField("curveSegments:")
            .setCheck("Number")
        this.appendDummyInput()
            .appendField('bevelEnabled:')
            .appendField(new Blockly.FieldDropdown([
                ['false', 'false'],
                ['true', 'true'],
            ]), 'bevelEnabled');
        this.appendValueInput("bevelThickness")
            .appendField("bevelThickness:")
            .setCheck("Number")
        this.appendValueInput("bevelSize")
            .appendField("bevelSize:")
            .setCheck("Number")
        this.appendValueInput("bevelOffset")
            .appendField("bevelOffset:")
            .setCheck("Number")
        this.appendValueInput("bevelSegments")
            .appendField("bevelSegments:")
            .setCheck("Number")
        this.setInputsInline(false);
        this.setOutput(true, null);
        this.setColour(js_three_block_color);
        this.setTooltip("controller");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_three_block_text_property'] = function(block) {

    // var fontVar = Blockly.JavaScript.nameDB_.getName(block.getFieldValue('fontVar'), 'VARIABLE');
    var fontVar = Blockly.JavaScript.valueToCode(block, 'fontVar', Blockly.JavaScript.ORDER_ATOMIC);

    var font_size = Blockly.JavaScript.valueToCode(block, 'font_size', Blockly.JavaScript.ORDER_ATOMIC);
    var height = Blockly.JavaScript.valueToCode(block, 'height', Blockly.JavaScript.ORDER_ATOMIC);
    var curveSegments = Blockly.JavaScript.valueToCode(block, 'curveSegments', Blockly.JavaScript.ORDER_ATOMIC);
    var bevelEnabled = block.getFieldValue('bevelEnabled');
    var bevelThickness = Blockly.JavaScript.valueToCode(block, 'bevelThickness', Blockly.JavaScript.ORDER_ATOMIC);
    var bevelSize = Blockly.JavaScript.valueToCode(block, 'bevelSize', Blockly.JavaScript.ORDER_ATOMIC);
    var bevelOffset = Blockly.JavaScript.valueToCode(block, 'bevelOffset', Blockly.JavaScript.ORDER_ATOMIC);
    var bevelSegments = Blockly.JavaScript.valueToCode(block, 'bevelSegments', Blockly.JavaScript.ORDER_ATOMIC);

    var code = `{
        font:` + fontVar + `,
        size:` + String(font_size) + `,
        height:` + String(height) + `,
        curveSegments:` + String(curveSegments) + `,
        bevelEnabled:` + bevelEnabled + `,
        bevelThickness:` + String(bevelThickness) + `,
        bevelSize:` + String(bevelSize) + `,
        bevelOffset:` + String(bevelOffset) + `,
        bevelSegments:` + String(bevelSegments) + `,
    }`;

    return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// FontLoader
Blockly.Blocks['js_three_block_fontloader'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("フォント ロード")
        this.appendDummyInput()
            .appendField('font:')
            .appendField(new Blockly.FieldDropdown([
                ['Regular', './fonts/Murecho_Thin_Regular.json'],
                ['Bold', './fonts/Murecho_Bold.json'],
            ]), 'font');
        this.appendStatementInput("code")
            .setCheck(null);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(js_three_block_color);
        this.setTooltip("CANVAS");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_three_block_fontloader'] = function(block) {
    var statements_data = Blockly.JavaScript.statementToCode(block, 'code');
    var font = block.getFieldValue('font');

    var code = '(new THREE.FontLoader()).load(\'' + font + '\', function (font) {\n';
    // var code = 'var _ft = new THREE.FontLoader();\n';
    // code += '_ft.load(\'' + font + '\', function (font) {\n';
    code += statements_data + '\n';
    code += '});\n';

    return code;
};

///////////////////////////////////////////////////////////////////
// Texture Loader
Blockly.Blocks['js_three_block_textureloader'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("画像 ロード")
        this.appendStatementInput("code")
            .setCheck(null);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(js_three_block_color);
        this.setTooltip("CANVAS");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_three_block_textureloader'] = function(block) {
    var statements_data = Blockly.JavaScript.statementToCode(block, 'code');

    // var code = '(new THREE.TextureLoader()).load(\'https://firebasestorage.googleapis.com/v0/b/broccoly-97269.appspot.com/o/image%2Fbeach-umbrella.png?alt=media&token=0d80eac6-e82d-4441-9f7b-5a81657b0b4d\', function (font) {\n';
    var code = '(new THREE.TextureLoader()).load(\'./../assets/broccoly_logo.png\', function (texture) {\n';

    code += 'texture.wrapS = texture.wrapT = THREE.RepeatWrapping;\n';
    code += 'texture.anisotropy = renderer.capabilities.getMaxAnisotropy();\n';

    code += statements_data + '\n';
    code += '});\n';

    return code;
};

// ///////////////////////////////////////////////////////////////////
// // object3d
// Blockly.Blocks['js_three_block_object3d'] = {
//     init: function() {
//         this.appendDummyInput()
//             .appendField("Object3D()")
//         this.setInputsInline(true);
//         this.setOutput(true, null);
//         this.setColour(js_three_block_color);
//         this.setTooltip("controller");
//         this.setHelpUrl("");
//     }
// };

// Blockly.JavaScript['js_three_block_object3d'] = function(/*block*/) {

//     var code = 'new THREE.Group()';

//     return [code, Blockly.JavaScript.ORDER_ATOMIC];
// };

