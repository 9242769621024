import * as Blockly from 'blockly/core';

// ブロックの配色
var js_camera_block_color = 290;

// Cameraブロック定義
const category_Camera_Mediapipe = `
    <category name="カメラ検出" colour="290">
        
        <block type="js_camera_block_mp_start_camera"></block>
        <block type="js_camera_block_mp_stop_camera"></block>

        <block type="js_camera_block_mp_face_mesh_elm"></block>
        <block type="js_camera_block_mp_face_result"></block>
        <block type="js_camera_block_mp_face_mesh"></block>

        <block type="js_camera_block_mp_hands_elm"></block>
        <block type="js_camera_block_mp_hands_result"></block>
        <block type="js_camera_block_mp_hands"></block>

    </category>`;

    // <block type="js_camera_block_mp_holistic_elm"></block>
    // <block type="js_camera_block_mp_holistic_result"></block>
    // <block type="js_camera_block_mp_holistic"></block>

export {category_Camera_Mediapipe};

///////////////////////////////////////////////////////////////////
// start camera
Blockly.Blocks['js_camera_block_mp_start_camera'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("カメラ開始")
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(js_camera_block_color);
        this.setTooltip("disabled");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_camera_block_mp_start_camera'] = function(/*block*/) {

    var code = `camera.start();\n`;
    
    return code;
};

///////////////////////////////////////////////////////////////////
// stop camera
Blockly.Blocks['js_camera_block_mp_stop_camera'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("カメラ停止")
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(js_camera_block_color);
        this.setTooltip("disabled");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_camera_block_mp_stop_camera'] = function(/*block*/) {

    var code = `camera.stop();\n`;
    
    return code;
};

///////////////////////////////////////////////////////////////////
// face mesh element
Blockly.Blocks['js_camera_block_mp_face_mesh_elm'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("顔検出 カメラ表示")
        this.appendDummyInput()
            .appendField("幅:")
            .appendField(new Blockly.FieldNumber(500, 100, 500), "width")
            .appendField("高さ:")
            .appendField(new Blockly.FieldNumber(300, 100, 300), "height")
        this.appendDummyInput()
            .appendField('左右反転:')
            .appendField(new Blockly.FieldDropdown([
                ['しない', 'false'],
                ['する', 'true'],
            ]), 'fliphorizontal');
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(js_camera_block_color);
        this.setTooltip("ready");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_camera_block_mp_face_mesh_elm'] = function(block) {

    var width = block.getFieldValue('width');
    var height = block.getFieldValue('height');
    var fliphorizontal = block.getFieldValue('fliphorizontal');

    var code = 'var elm_v=document.createElement(\'video\');\n';
    code += 'elm_v.id="input_video";\n';
    code += 'elm_v.autoplay=true;\n';
    code += 'elm_v.playsinline=true;\n';
    code += 'elm_v.width=\'0\';\n';
    code += 'elm_v.height=\'0\';\n';
    code += 'elm_v.style.visiblity=\'hidden\';\n';
    
    // if (fliphorizontal == 'true'){
    //     code += 'elm_v.style.transform="scaleX(-1)";\n';
    // }
    
    // code += 'elm_v.style.border="1px black solid";\n';
    code += 'document.getElementById(\'ExecutionPanel\').appendChild(elm_v);\n';

    code += 'var elm_c=document.createElement(\'canvas\');\n';
    code += 'elm_c.id="output_canvas";\n';
    code += 'elm_c.width=\'' + width + '\';\n';
    code += 'elm_c.height=\'' + height + '\';\n';
    
    if (fliphorizontal == 'true'){
        code += 'elm_c.style.transform="scaleX(-1)";\n';
    }
    code += 'elm_c.style.border="1px black solid";\n';
    
    code += 'document.getElementById(\'ExecutionPanel\').appendChild(elm_c);\n';

    code += `const videoElement = document.getElementById('input_video');
    const camera = new Camera(videoElement, {
        onFrame: async () => {
            await faceMesh.send({image: videoElement});
        },
        width: ` + width + `,
        height: ` + height + `,
    });\n`;

    return code;
};

///////////////////////////////////////////////////////////////////
// face mesh
Blockly.Blocks['js_camera_block_mp_face_mesh'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("顔検出 準備")
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(js_camera_block_color);
        this.setTooltip("ready");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_camera_block_mp_face_mesh'] = function(/*block*/) {

    var code = `const faceMesh = new FaceMesh({locateFile: (file) => {
        return \`https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/\${file}\`;
      }});
      faceMesh.setOptions({
        maxNumFaces: 1,
        refineLandmarks: true,
        minDetectionConfidence: 0.5,
        minTrackingConfidence: 0.5
      });
      faceMesh.onResults(onResults);
      \n`;

    return code;
};

///////////////////////////////////////////////////////////////////
// face result
Blockly.Blocks['js_camera_block_mp_face_result'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("顔検出 結果処理")
        this.appendDummyInput()
            .appendField('映像:')
            .appendField(new Blockly.FieldDropdown([
                ['あり', 'true'],
                ['なし', 'false'],
            ]), 'showImage');
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(js_camera_block_color);
        this.setTooltip("enabled");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_camera_block_mp_face_result'] = function(block) {

    var showImage = block.getFieldValue('showImage');

    var code = `const canvasElement = document.getElementById('output_canvas');
    const canvasCtx = canvasElement.getContext('2d');
    
    function onResults(results) {
        canvasCtx.save();
        canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
        canvasCtx.fillStyle = 'black';
        canvasCtx.fillRect(0, 0, canvasElement.width, canvasElement.height);`

    if (showImage == 'true'){
        code += `canvasCtx.drawImage(
            results.image, 0, 0, canvasElement.width, canvasElement.height);`
    }
        
    code += `if (results.multiFaceLandmarks) {
          for (const landmarks of results.multiFaceLandmarks) {
            drawConnectors(canvasCtx, landmarks, FACEMESH_TESSELATION,
                           {color: '#C0C0C070', lineWidth: 1});
            drawConnectors(canvasCtx, landmarks, FACEMESH_RIGHT_EYE, {color: '#FF3030'});
            drawConnectors(canvasCtx, landmarks, FACEMESH_RIGHT_EYEBROW, {color: '#FF3030'});
            drawConnectors(canvasCtx, landmarks, FACEMESH_RIGHT_IRIS, {color: '#FF3030'});
            drawConnectors(canvasCtx, landmarks, FACEMESH_LEFT_EYE, {color: '#30FF30'});
            drawConnectors(canvasCtx, landmarks, FACEMESH_LEFT_EYEBROW, {color: '#30FF30'});
            drawConnectors(canvasCtx, landmarks, FACEMESH_LEFT_IRIS, {color: '#30FF30'});
            drawConnectors(canvasCtx, landmarks, FACEMESH_FACE_OVAL, {color: '#E0E0E0'});
            drawConnectors(canvasCtx, landmarks, FACEMESH_LIPS, {color: '#E0E0E0'});
          }
        }
        canvasCtx.restore();
      }
      \n`;

    return code;
};

///////////////////////////////////////////////////////////////////
// hands element
Blockly.Blocks['js_camera_block_mp_hands_elm'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("手検出 カメラ表示")
        this.appendDummyInput()
            .appendField("幅:")
            .appendField(new Blockly.FieldNumber(500, 100, 500), "width")
            .appendField("高さ:")
            .appendField(new Blockly.FieldNumber(300, 100, 300), "height")
        this.appendDummyInput()
            .appendField('左右反転:')
            .appendField(new Blockly.FieldDropdown([
                ['しない', 'false'],
                ['する', 'true'],
            ]), 'fliphorizontal');
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(js_camera_block_color);
        this.setTooltip("ready");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_camera_block_mp_hands_elm'] = function(block) {

    var width = block.getFieldValue('width');
    var height = block.getFieldValue('height');
    var fliphorizontal = block.getFieldValue('fliphorizontal');

    var code = 'var elm_v=document.createElement(\'video\');\n';
    code += 'elm_v.id="input_video";\n';
    code += 'elm_v.autoplay=true;\n';
    code += 'elm_v.playsinline=true;\n';
    code += 'elm_v.width=\'0\';\n';
    code += 'elm_v.height=\'0\';\n';
    code += 'elm_v.style.visiblity=\'hidden\';\n';
    
    if (fliphorizontal == 'true'){
        code += 'elm_v.style.transform="scaleX(-1)";\n';
    }
    
    code += 'elm_v.style.border="1px black solid";\n';
    code += 'document.getElementById(\'ExecutionPanel\').appendChild(elm_v);\n';

    code += 'var elm_c=document.createElement(\'canvas\');\n';
    code += 'elm_c.id="output_canvas";\n';
    code += 'elm_c.width=\'' + width + '\';\n';
    code += 'elm_c.height=\'' + height + '\';\n';
    
    if (fliphorizontal == 'true'){
        code += 'elm_c.style.transform="scaleX(-1)";\n';
    }
    code += 'elm_c.style.border="1px black solid";\n';
    
    code += 'document.getElementById(\'ExecutionPanel\').appendChild(elm_c);\n';

    code += `const videoElement = document.getElementById('input_video');
    const camera = new Camera(videoElement, {
        onFrame: async () => {
            await hands.send({image: videoElement});
        },
        width: ` + width + `,
        height: ` + height + `,
    });\n`;

    return code;
};

///////////////////////////////////////////////////////////////////
// hands
Blockly.Blocks['js_camera_block_mp_hands'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("手検出 準備")
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(js_camera_block_color);
        this.setTooltip("ready");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_camera_block_mp_hands'] = function(/*block*/) {

    var code = `const hands = new Hands({locateFile: (file) => {
        return \`https://cdn.jsdelivr.net/npm/@mediapipe/hands/\${file}\`;
      }});
      hands.setOptions({
        maxNumHands: 2,
        modelComplexity: 1,
        minDetectionConfidence: 0.5,
        minTrackingConfidence: 0.5
      });
      hands.onResults(onResults);      
      \n`;

    return code;
};

///////////////////////////////////////////////////////////////////
// hands result
Blockly.Blocks['js_camera_block_mp_hands_result'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("手検出 結果処理")
        this.appendDummyInput()
            .appendField('映像:')
            .appendField(new Blockly.FieldDropdown([
                ['あり', 'true'],
                ['なし', 'false'],
            ]), 'showImage');
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(js_camera_block_color);
        this.setTooltip("enabled");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_camera_block_mp_hands_result'] = function(block) {

    var showImage = block.getFieldValue('showImage');

    var code = `const canvasElement = document.getElementById('output_canvas');
    const canvasCtx = canvasElement.getContext('2d');

    function onResults(results) {
      canvasCtx.save();
      canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
      canvasCtx.fillRect(0, 0, canvasElement.width, canvasElement.height);`;

      if (showImage == 'true'){
          code += `canvasCtx.drawImage(
              results.image, 0, 0, canvasElement.width, canvasElement.height);`
      }
  
      code += `if (results.multiHandLandmarks) {
        for (const landmarks of results.multiHandLandmarks) {
          drawConnectors(canvasCtx, landmarks, HAND_CONNECTIONS,
                         {color: '#00FF00', lineWidth: 5});
          drawLandmarks(canvasCtx, landmarks, {color: '#FF0000', lineWidth: 2});
        }
      }
      canvasCtx.restore();
    }
    \n`;

    return code;
};

///////////////////////////////////////////////////////////////////
// holistic element
Blockly.Blocks['js_camera_block_mp_holistic_elm'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("骨格検出 カメラ表示")
        this.appendDummyInput()
            .appendField("幅:")
            .appendField(new Blockly.FieldNumber(500, 100, 500), "width")
            .appendField("高さ:")
            .appendField(new Blockly.FieldNumber(300, 100, 300), "height")
        this.appendDummyInput()
            .appendField('左右反転:')
            .appendField(new Blockly.FieldDropdown([
                ['しない', 'false'],
                ['する', 'true'],
            ]), 'fliphorizontal');
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(js_camera_block_color);
        this.setTooltip("ready");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_camera_block_mp_holistic_elm'] = function(block) {

    var width = block.getFieldValue('width');
    var height = block.getFieldValue('height');
    var fliphorizontal = block.getFieldValue('fliphorizontal');

    var code = 'var elm_v=document.createElement(\'video\');\n';
    code += 'elm_v.id="input_video";\n';
    code += 'elm_v.autoplay=true;\n';
    code += 'elm_v.playsinline=true;\n';
    code += 'elm_v.width=\'0\';\n';
    code += 'elm_v.height=\'0\';\n';
    code += 'elm_v.style.visiblity=\'hidden\';\n';
    
    if (fliphorizontal == 'true'){
        code += 'elm_v.style.transform="scaleX(-1)";\n';
    }
    
    code += 'elm_v.style.border="1px black solid";\n';
    code += 'document.getElementById(\'ExecutionPanel\').appendChild(elm_v);\n';

    code += 'var elm_c=document.createElement(\'canvas\');\n';
    code += 'elm_c.id="output_canvas";\n';
    code += 'elm_c.width=\'' + width + '\';\n';
    code += 'elm_c.height=\'' + height + '\';\n';
    
    if (fliphorizontal == 'true'){
        code += 'elm_c.style.transform="scaleX(-1)";\n';
    }
    code += 'elm_c.style.border="1px black solid";\n';
    
    code += 'document.getElementById(\'ExecutionPanel\').appendChild(elm_c);\n';

    code += `const videoElement = document.getElementById('input_video');
    const camera = new Camera(videoElement, {
        onFrame: async () => {
            await holistic.send({image: videoElement});
        },
        width: ` + width + `,
        height: ` + height + `,
    });\n`;

    return code;
};

///////////////////////////////////////////////////////////////////
// holistic
Blockly.Blocks['js_camera_block_mp_holistic'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("骨格検出 準備")
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(js_camera_block_color);
        this.setTooltip("ready");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_camera_block_mp_holistic'] = function(/*block*/) {

    var code = `const holistic = new Holistic({locateFile: (file) => {
        return \`https://cdn.jsdelivr.net/npm/@mediapipe/holistic/\${file}\`;
      }});
      holistic.setOptions({
        modelComplexity: 1,
        smoothLandmarks: true,
        enableSegmentation: true,
        smoothSegmentation: true,
        refineFaceLandmarks: true,
        minDetectionConfidence: 0.5,
        minTrackingConfidence: 0.5
      });
      holistic.onResults(onResults);      
      \n`;

    return code;
};

///////////////////////////////////////////////////////////////////
// holistic result
Blockly.Blocks['js_camera_block_mp_holistic_result'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("骨格検出 結果処理")
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(js_camera_block_color);
        this.setTooltip("enabled");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_camera_block_mp_holistic_result'] = function(/*block*/) {

    var code = `const canvasElement = document.getElementById('output_canvas');
    const canvasCtx = canvasElement.getContext('2d');

    function onResults(results) {
        canvasCtx.save();
        canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
        canvasCtx.drawImage(results.segmentationMask, 0, 0,
                            canvasElement.width, canvasElement.height);
      
        // Only overwrite existing pixels.
        canvasCtx.globalCompositeOperation = 'source-in';
        canvasCtx.fillStyle = '#FF00FF00';
        canvasCtx.fillRect(0, 0, canvasElement.width, canvasElement.height);
      
        // Only overwrite missing pixels.
        canvasCtx.globalCompositeOperation = 'destination-atop';
        canvasCtx.drawImage(
            results.image, 0, 0, canvasElement.width, canvasElement.height);
      
        canvasCtx.globalCompositeOperation = 'source-over';
        drawConnectors(canvasCtx, results.poseLandmarks, POSE_CONNECTIONS,
                       {color: '#00FF00', lineWidth: 4});
        drawLandmarks(canvasCtx, results.poseLandmarks,
                      {color: '#FF0000', lineWidth: 2});
        drawConnectors(canvasCtx, results.faceLandmarks, FACEMESH_TESSELATION,
                       {color: '#C0C0C070', lineWidth: 1});
        drawConnectors(canvasCtx, results.leftHandLandmarks, HAND_CONNECTIONS,
                       {color: '#CC0000', lineWidth: 5});
        drawLandmarks(canvasCtx, results.leftHandLandmarks,
                      {color: '#00FF00', lineWidth: 2});
        drawConnectors(canvasCtx, results.rightHandLandmarks, HAND_CONNECTIONS,
                       {color: '#00CC00', lineWidth: 5});
        drawLandmarks(canvasCtx, results.rightHandLandmarks,
                      {color: '#FF0000', lineWidth: 2});
        canvasCtx.restore();
    }
    \n`;

    return code;
};

