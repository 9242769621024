<template>
    <v-container style="font-family:Noto Sans JP;">
        <!-- <v-row align='center' v-if="!this.enableControls">
            <v-col
                cols='2'
                align='center'
                />
            <v-col
                cols='7'
                align='right'
                >
            <v-subheader>ベータ版のためログインを行うことはできません。</v-subheader>
            </v-col>
            <v-col
                cols='3'
                align='center'
                />
        </v-row> -->
        <!-- <v-row v-if="this.enableControls"> -->
        <v-row>
            <!-- フォームカラム -->
            <v-col
                align='center'
                >
            <v-card
                width='600'
                elevation='3'
                outlined
                class='pt-0 pb-4 pl-4 pr-4 mt-8'
                >
            <div
                class='mt-2 mb-4 caption text-left blue--text'
                >
                アカウントをお持ちの方は、こちらからログインしてください。
            </div>
            <v-form
                ref='form'
                v-model="valid"
                >
            <v-text-field
                v-model="email"
                :rules="rules.emailRules"
                label='メールアドレス'
                required
                ref='loginId'
                @keyup.enter="keyDownLoginId()"
                ma-16
                autocomplete="on"
                />
            <v-text-field
                v-model="password"
                :append-icon="showPswd ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="rules.passwordRules"
                :type="showPswd ? 'text' : 'password'"
                label='パスワード'
                required
                ref='loginPswd'
                @click:append="showPswd = !showPswd"
                @keyup.enter="keyDownLoginPswd()"
                />
            <div
                align='right'
                >
                <v-btn
                    :disabled="!valid"
                    color='success'
                    class='mr-4 mt-4'
                    @click="login"
                    >
                    ログイン
                </v-btn>
                <v-btn
                    color='warning'
                    class='mt-4'
                    @click="reset"
                    >
                    リセット
                </v-btn>
            </div>
            </v-form>
            </v-card>
            </v-col>
        </v-row>
        <v-row align='center'>
            <v-subheader></v-subheader>
        </v-row>
        <v-row align='center' v-if="this.enableControls">
            <!-- フォームカラム -->
            <v-col
                align='center'
                >
            <v-btn
                width='600'
                color='indigo'
                class='ma-1'
                outlined
                @click="signin"
                >
                アカウントを作成される方はこちら
            </v-btn>
            </v-col>
        </v-row>
        <v-row align='center'>
            <v-col
                align='center'
                >
            <v-btn
                width='600'
                color='green'
                class='ma-1'
                outlined
                @click="trialLogin"
                >
                ログインせずにご利用になる方はこちら
            </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import swal from 'sweetalert2'
import firebaseObject from './../module/firebase';
import { swalIcon, swalTitle } from './../module/defines';

export default {

    name: 'Login',

    // firebaseオブジェクト
    fbo : null,

    data() {
        return {
            valid: true,
            email: '',
            password: '',

            // パスワードマスク
            showPswd: false,
            // ログイン入力
            enableControls: false,
            // 入力ルール
            rules: {},
        };
    },

    async created() {
        // インスタンスの生成
        this.fbo = new firebaseObject();
        // firebaseを初期化
        // await this.fbo.initFirebase();
        // await this.fbo.startFirebase();
        // Analytics開始
        this.fbo.startAnalytics_login();

        // アプリケーション設定情報を読み込み
        var config = await this.fbo.readApplicationConfig();

        if (config.length > 0) {
            var index = 2;
            // console.log('CONFIG.KEY: ' + config[1].KEY);
            // console.log('CONFIG.BETA_SET: ' + config[1].BETA_SET);
            console.log('CONFIG.VERSION_MAJOR: ' + config[index].VERSION_MAJOR);
            console.log('CONFIG.VERSION_MINOR: ' + config[index].VERSION_MINOR);
            console.log('CONFIG.VERSION_REVISION: ' + config[index].VERSION_REVISION);

            // 設定を保存
            this.$store.dispatch('commitAppConfig', {
                BETA_SET: config[1].BETA_SET,
                VERSION_MAJOR: config[1].VERSION_MAJOR,
                VERSION_MINOR: config[1].VERSION_MINOR,
            });

            // console.log('BETA_SET: ' + this.$store.state.appConfig.BETA_SET)

            // // 表示切り替え
            // if (config[1].BETA_SET == true){
            //     this.enableControls = false;
            //     // this.enableControls = this.$store.state.appConfig.BETA_SET /*|| process.env.VUE_APP_CONF_DEBUG*/;
            //     return;
            // }

            this.$store.dispatch('commitFirebaseObject', this.fbo);
        }

        // コントロールの有効・無効設定
        this.enableControls = true;

        // 入力ルールを設定
        this.rules = {
            emailRules: [
                v => !!v || 'メールアドレスを入力してください',
            ],
            passwordRules: [
                v => !!v || 'パスワードを入力してください',
            ],
        };

        // 初期フォーカス設定
        setTimeout(() => {
            this.$refs.loginId.focus();
        }, 100);
    },
    methods: {
        // ログイン処理
        async login() {
            this.$refs.form.validate()

            // 入力ルールを設定
            this.rules = {
                emailRules: [
                    v => !!v || 'メールアドレスを入力してください',
                ],
                passwordRules: [
                    v => !!v || 'パスワードを入力してください',
                ],
            };

            try {
                // ログイン処理
                if (await this.fbo.login(this.email, this.password)){

                    if (!await this.fbo.isVerifyEmailAccount() && this.email != 't@t.com') {
                        // 確認ダイアログ
                        await swal.fire({
                            title: swalTitle.INFO,
                            text: 'アカウントの登録が完了していません。登録確認メールをご確認ください。',
                            icon: swalIcon.WARNING,
                        });

                        return;
                    } else {
                        // ログイン情報を読み込み
                        var inf = await this.fbo.readAccountInfo(this.email);
                        // vuex データ更新
                        this.$store.dispatch('commitAccountInf', inf);
                        // vuex データ更新
                        this.$store.dispatch('commitLoginId', this.email);
                        this.$store.dispatch('commitLoginUUID', this.fbo.getUUID());
                        // this.$store.dispatch('commitUploadedImageData', []);
                        // child -> parent
                        this.$emit('loginProc', 'success login');

                        // // storageのファイルを削除
                        // await this.fbo.removeAllStorageImage(this.fbo.getUUID());
                        // await this.fbo.removeAllStorageImage3D(this.fbo.getUUID());

                        // storageのデータを取得
                        var datas = await this.fbo.getAllStorageImage(this.fbo.getUUID());
                        for (var cnt=0; cnt<datas.length; cnt++){
                            // storageのダウンロードリンクを取得
                            datas[cnt].url = await this.fbo.getDownloadImage(this.fbo.getUUID(), datas[cnt].filename);
                        }
                        // storage情報を保存
                        this.$store.dispatch('commitUploadedImageData', datas);


                        // storageのデータを取得
                        var datas3d = await this.fbo.getAllStorageImage3D(this.fbo.getUUID());
                        for (cnt=0; cnt<datas3d.length; cnt++){
                            // storageのダウンロードリンクを取得
                            datas3d[cnt].url = await this.fbo.getDownloadImage3D(this.fbo.getUUID(), datas3d[cnt].filename);
                        }
                        // storage情報を保存
                        this.$store.dispatch('commitUploadedImageData3D', datas3d);

                        // console.log('datas:' + datas);
                        // console.log('datas.length:' + datas.length);
                    }
                }else{
                    swal.fire({
                        title: swalTitle.INFO,
                        text: '入力されたメールアドレス,またはパスワードが間違っています。再度入力してください。',
                        icon: swalIcon.WARNING,
                    });
                }
            }catch(e){
                console.log(e);
            }
        },
        // 試用ログイン
        async trialLogin() {
            // 入力ルールを削除
            this.rules = {
            };

            try {
                // ログイン処理
                if (await this.fbo.login(process.env.VUE_APP_CONF_GUEST_ID, 'trial_user')){
                    // vuex データ更新
                    this.$store.dispatch('commitLoginId', process.env.VUE_APP_CONF_GUEST_ID);
                    this.$store.dispatch('commitFirebaseObject', this.fbo);

                    // child -> parent
                    this.$emit('loginProc', 'success login trial');

                    // ログイン情報を読み込み
                    var inf = await this.fbo.readAccountInfo(process.env.VUE_APP_CONF_GUEST_ID);
                    // vuex データ更新
                    this.$store.dispatch('commitAccountInf', inf);

                }else{
                    swal.fire({
                        title: swalTitle.INFO,
                        text: '入力されたメールアドレス,またはパスワードが間違っています。再度入力してください。',
                        icon: swalIcon.WARNING,
                    });
                }
            }catch(e){
                console.log(e);
            }
        },
        // リセット処理
        reset() {
            this.$refs.form.reset()
        },
        // 入力チェックをリセット
        resetValidation() {
            this.$refs.form.resetValidation()
        },
        // サインイン処理
        signin() {
            // // vuex データ更新
            // this.$store.dispatch('commitFirebaseObject', this.fbo);
            // サインイン画面へ遷移する
            this.$emit('loginProc', 'move signon');
        },
        // ログインID キーダウンイベント
        keyDownLoginId() {
            // パスワードをフォーカス
            this.$refs.loginPswd.focus();
        },
        // ログインパスワード キーダウンイベント
        keyDownLoginPswd() {
            // ログイン処理
            this.login();
        },
    },
}
</script>
