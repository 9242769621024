<template>
    <v-card
        elevation='12'
        class='ml-1 mr-1'
        style='background-color:#CCCCCC;'>
        <!-- style='background-color:#C5CAE9;'> -->

        <!-- ファイルタブ -->
        <v-tabs
            background-color='white'
            color='black accent-4'
            show-arrows
            prev-icon='mdi-arrow-left-bold-box-outline'
            next-icon='mdi-arrow-right-bold-box-outline'
            left
            slider-size='2'
            style='margin-left: 300px; margin-bottom: 0px; width: 60%; height: 0px;'
            v-model="tabs"
            disabled
            v-if="false"
            >
            <v-tabs-slider color='green lighten-0'></v-tabs-slider>
            <v-tab
                v-for="(item) in tabsArray"
                :key="item.key"
                @click="selectedTabByKey(item.key)"
                disabled
                >
                <v-icon left :color="item.icon_color">
                    {{ item.icon }}
                </v-icon>
                <!-- タイトル -->
                {{ item.fileName + '.' + item.fileExtension }}
                <!-- 閉じるボタン -->
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        right
                        @click="removeTabEvent(item.key)"
                        v-bind="attrs"
                        v-on="on"
                        >
                        mdi-close-thick
                    </v-icon>
                    </template>
                <span>閉じる</span>
                </v-tooltip>
            </v-tab>
        </v-tabs>
        <v-main style='height: 100%;'>
            <!-- 分割線 -->
            <!-- <v-divider></v-divider> -->
            <!-- メインブロック -->
            <MainView
                ref='mainView'
                @mainProc="emitMainViewEvent"
                @stepProc="emitStepEvent"
                @stopProc="emitStopEvent"
                @responseBuffer="responseBufferEvent"
                />
                <!-- @mapProc="emitMapProc" -->

            <!-- <modal name="hello-world" :draggable="false" :resizable="true" @opened="beforeOpen" :width="840" :height="620">
                <div class="modal-header">
                <div id="map" style="width:800px; height:600px;"></div>
                </div>  
            </modal> -->
                            
        </v-main>
    </v-card>
</template>

<script>
import MainView from './MainView'
import { codeLanguage } from './../module/defines';
// import { Loader } from "@googlemaps/js-api-loader"

export default {

    name: 'TabView',

    components: {
        MainView,
    },

    data() {
        return {
            tabs: 0,
            // タブ情報
            tabsArray: [
                {
                    id: 0,
                    key: '',
                    fileName: '',
                    fileExtension: 'html',
                    icon: 0,
                    icon_color: 'black',
                    code: '',
                    generateCode: '',
                    createdDate: '',
                }
            ],
            // 最終タブインデックス
            lastTabIndex: 0,
            // 選択中タブインデックス
            selectedTabId: '',
            // コントロールの有効化
            enabled: false,
            iconColor: '',
            //
            loader: null,
            map: null,
        };
    },

    created() {
        // 全タブ削除
        this.removeAllTab();
        // 初期タブ追加
        // this.addTab('untitled', 'js'/*this.fileExtension*/, '');
        this.addTab('untitled', this.$store.state.selectionLanguage, '');

        // 試用ログインの場合は別設定
        if (this.$store.state.loginId == process.env.VUE_APP_CONF_GUEST_ID) {
            this.enabled = true;
            this.iconColor = 'deep-gray lighten-2';
        }else{
            this.enabled = false;
            this.iconColor = 'deep-purple lighten-2';
        }
    },

    async mounted() {
        // ウインドウサイズ変更時イベントを設定
        window.addEventListener('resize', this.resizeWindowEvent);

        // this.$modal.show('hello-world');

        // {
        //     this.loader = new Loader({
        //         apiKey: process.env.VUE_APP_GM_KEY,
        //     });

        //     const mapdiv = document.getElementById("map");

        //     console.log(mapdiv);

        //     if (mapdiv != null){
        //         this.loader.load().then(() => {
        //             new window.google.maps.Map(mapdiv, {
        //                 center: { lat: -34.397, lng: 150.644 },
        //                 zoom: 8,
        //             });
        //         });
        //     }
        // }

    },

    methods: {
        // ウインドウリサイズイベント
        resizeWindowEvent() {
            // 各パネルの初期高さ設定
            this.height_b = window.innerHeight - 170;
        },
        // タブ追加
        async addTab(fileName, fileExtension, code, key = '', created = '', updated = '') {

            // console.log('addTab:' + fileName);
            // console.log('addTab:' + fileExtension);

            // インデックスを調整
            this.lastTabIndex++;

            var icon = '';
            var color = '';

            // アイコン設定
            if (fileExtension == 'html') {
                icon = 'mdi-language-html5';
                color = 'black';
            }
            else if (fileExtension == codeLanguage.JS) {
                icon = 'mdi-language-javascript';
                color = 'blue';
            }
            else if (fileExtension == codeLanguage.Python) {
                icon = 'mdi-language-python';
                color = 'green';
            }
            else if (fileExtension == codeLanguage.CS) {
                icon = 'mdi-language-csharp';
                color = 'purple';
            }
            else if (fileExtension == codeLanguage.HTML) {
                icon = 'mdi-language-html5';
                color = 'red';
            }

            // タブを新規追加
            this.tabsArray.push({
                id: this.lastTabIndex,
                key: key,
                fileName: fileName,
                fileExtension: fileExtension,
                icon: icon,
                icon_color: color,
                code: code,
                generateCode: '',
                createdDate: created,
                updatedDate: updated,
            });

            // 選択タブに設定
            // 選択タブのインデックスを更新
            this.selectedTabId = this.lastTabIndex;
            // 選択タブを更新
            this.tabs = this.lastTabIndex - 1;

            var item;
            if (code == null || code == '') {
                // 復元コードがない場合ブロックをリセット
                item = {
                    id: '',
                    pageNo: 2,
                    pageShow: false,
                    directJump: true,
                    savefileComment: '',
                    fileName: '',
                    functionNo: 100,
                    code: '',
                };
            } else {
                // コードを復元
                item = {
                    id: '',
                    pageNo: 2,
                    pageShow: false,
                    directJump: true,
                    savefileComment: '',
                    fileName: '',
                    functionNo: 900,
                    code: code,
                };
            }
            // blocklyに指示
            if (this.$refs.mainView != null) {
                await this.$refs.mainView.functionMethod(item);
            }
        },
        // タブ削除
        removeTabEvent(key) {

            // 残りタブページが１の場合は削除させない
            if (this.tabsArray.length <= 1) {
                // 確認メッセージ
                alert('これ以上タブを削除できません。');
                // 処理を中断
                return;
            }

            // 削除対象を検索
            var index = this.findTabIndexByKey(key);

            // イベントパラメーター生成
            var event = {
                id: 'mnRemoveFile',
                index: index,
            };
            // ファイル作成イベント
            this.$emit('tabProc', event);
        },
        // タブ削除
        removeTabByKey(key) {

            // 残りタブページが１の場合は削除させない
            if (this.tabsArray.length <= 1) {
                // 確認メッセージ
                alert('これ以上タブを削除できません。');
                // 処理を中断
                return;
            }

            // 削除対象を検索
            var index = this.findTabIndexByKey(key);

            // 対象が見つかった場合は削除
            if (index >= 0) {
                this.tabsArray.splice(index, 1);
            }
        },
        // 全タブ削除
        removeAllTab() {
            // 削除
            this.tabsArray.splice(0);
            // タブインデックスをクリア
            this.lastTabIndex = 0;
        },
        // タブ選択
        async selectedTabByIndex(idx) {

            var item = null;
            var index = -1;

            // 更新元対象を検索
            index = this.tabs;

            // 対象が見つかった場合はコードを更新
            if (index >= 0) {
                // 読み込みデータ情報を作成
                item = {
                    id: '',
                    pageNo: 2,
                    pageShow: false,
                    directJump: true,
                    savefileComment: '',
                    fileName: '',
                    functionNo: 901,
                };

                // データ読み込みを実行
                this.tabsArray[index].code = await this.$refs.mainView.functionMethod(item);
            }

            index = this.findTabIndexByKey(this.tabsArray[idx]);
            // 読み込みデータ情報を作成
            if (index >= 0) {
                // 選択タブを更新
                this.tabs = index;

                item = {
                    id: '',
                    pageNo: 2,
                    pageShow: false,
                    directJump: true,
                    savefileComment: '',
                    fileName: '',
                    functionNo: 900,
                    code: this.tabsArray[index].code,
                };

                // コードを展開
                await this.$refs.mainView.functionMethod(item);

                // 読み込みデータ情報を作成
                item = {
                    id: '',
                    pageNo: 2,
                    pageShow: false,
                    directJump: true,
                    savefileComment: '',
                    fileName: '',
                    functionNo: 902,
                };

                // データ読み込みを実行
                this.tabsArray[index].generateCode = await this.$refs.mainView.functionMethod(item);
                // 選択タブIDを保存
                this.selectedTabId = this.tabsArray[index].id;
            }
        },
        // タブ選択
        async selectedTabByKey(key) {

            if (this.tabsArray.length <= 0){
                return;
            }

            var item = null;
            var index = -1;

            // 更新元対象を検索
            index = this.tabs;

            // 対象が見つかった場合はコードを更新
            if (index >= 0) {
                // 読み込みデータ情報を作成
                item = {
                    id: '',
                    pageNo: 2,
                    pageShow: false,
                    directJump: true,
                    savefileComment: '',
                    fileName: '',
                    functionNo: 901,
                };

                // データ読み込みを実行
                this.tabsArray[index].code = await this.$refs.mainView.functionMethod(item);
            }

            // 更新元対象を検索
            index = this.findTabIndexByKey(key);

            // 読み込みデータ情報を作成
            if (index >= 0) {
                // 選択中タブキーを保存
                this.selectedTabId = this.tabsArray[index].id;

                item = {
                    id: '',
                    pageNo: 2,
                    pageShow: false,
                    directJump: true,
                    savefileComment: '',
                    fileName: '',
                    functionNo: 900,
                    code: this.tabsArray[index].code,
                };

                // コードを展開
                await this.$refs.mainView.functionMethod(item);

                // 読み込みデータ情報を作成
                item = {
                    id: '',
                    pageNo: 2,
                    pageShow: false,
                    directJump: true,
                    savefileComment: '',
                    fileName: '',
                    functionNo: 902,
                };

                // データ読み込みを実行
                this.tabsArray[index].generateCode = await this.$refs.mainView.functionMethod(item);
                // 選択タブIDを保存
                this.selectedTabId = index;
                // 選択タブを更新
                this.tabs = index;
            }
        },
        // タブインデックスを検索
        findTabIndexByKey(key) {

            // 更新先対象を検索
            for (let cnt=0; cnt<this.tabsArray.length; cnt++) {
                // 対象が見つかった場合はコードを更新
                if (this.tabsArray[cnt].key == key) {
                    return cnt;
                }
            }

            return -1;
        },
        // メニューファンクション
        functionMethod(item) {

            if (item.id == 'mnNewProject'){
                // タブ情報をクリア
                this.tabsArray.splice(0);
                // 最終タブインデックス
                this.lastTabIndex = 0;
            }

            // メインブロックにメニュー操作を通知
            this.$refs.mainView.functionMethod(item);
        },
        // タブ情報を検索
        async findTabInformationByName(fileName, fileExtension) {

            for (let cnt=0; cnt<this.tabsArray.length; cnt++) {
                // 対象が見つかった場合はコードを更新
                if (this.tabsArray[cnt].fileName == fileName
                    && this.tabsArray[cnt].fileExtension == fileExtension) {
                    // 読み込みデータ情報を作成
                    var item = {
                        id: '',
                        pageNo: 2,
                        pageShow: false,
                        directJump: true,
                        savefileComment: '',
                        fileName: '',
                        functionNo: 901,
                    };
                    // 最新コードを取得
                    this.tabsArray[cnt].code = await this.$refs.mainView.functionMethod(item);

                    item = {
                        id: '',
                        pageNo: 2,
                        pageShow: false,
                        directJump: true,
                        savefileComment: '',
                        fileName: '',
                        functionNo: 902,
                    };
                    // 最新コードを取得
                    this.tabsArray[cnt].generateCode = await this.$refs.mainView.functionMethod(item);

                    // 選択タブのオブジェクトを返却
                    return this.tabsArray[cnt];
                }
            }

            return null;
        },
        // タブ情報を検索
        findTabInformationByIndex(index) {
            // 入力チェックのインデックスを確認
            if (index < 0 || this.tabsArray.length <= index) {
                console.log('findTabInformationByIndex: null');
                return null;
            }

            // 選択タブのオブジェクトを返却
            return this.tabsArray[index];
        },
        // タブ情報を検索
        async getCurrentTabInformation() {

            var index = this.tabs;

            // 読み込みデータ情報を作成
            var item = {
                id: '',
                pageNo: 2,
                pageShow: false,
                directJump: true,
                savefileComment: '',
                fileName: '',
                functionNo: 901,
            };
            // 最新コードを取得
            this.tabsArray[index].code = await this.$refs.mainView.functionMethod(item);

            // 読み込みデータ情報を作成
            item = {
                id: '',
                pageNo: 2,
                pageShow: false,
                directJump: true,
                savefileComment: '',
                fileName: '',
                functionNo: 902,
            };
            // 最新コードを取得
            this.tabsArray[index].generateCode = await this.$refs.mainView.functionMethod(item);

            // 選択タブのオブジェクトを返却
            return this.tabsArray[index];
        },
        // 選択中タブのコードを更新
        async updateCurrentTabCode() {

            var index = this.tabs;

            // 読み込みデータ情報を作成
            var item = {
                id: '',
                pageNo: 2,
                pageShow: false,
                directJump: true,
                savefileComment: '',
                fileName: '',
                functionNo: 901,
            };
            // 最新コードを取得
            this.tabsArray[index].code = await this.$refs.mainView.functionMethod(item);

            // 読み込みデータ情報を作成
            item = {
                id: '',
                pageNo: 2,
                pageShow: false,
                directJump: true,
                savefileComment: '',
                fileName: '',
                functionNo: 902,
            };
            // 最新コードを取得
            this.tabsArray[index].generateCode = await this.$refs.mainView.functionMethod(item);

        },
        // ファイル名を設定
        setCurrentTabFileName(fileName) {

            var index = -1;

            // 更新元対象を検索
            // index = this.findTabIndexById(this.selectedTabId);
            index = this.tabs;

            // 対象が見つかった場合はファイル名を更新
            if (index >= 0) {
                this.tabsArray[index].fileName = fileName;
            }
        },
        // タブ情報のキーを設定
        async setTabKey(index, key) {
            // 入力チェックのインデックスを確認
            if (index < 0 || this.tabsArray.length <= index) {
                return;
            }

            // 最新のコード情報をで更新する
            this.tabsArray[index].key = key;
        },
        // 現在のタブ数を取得
        getTabCount() {

            if (this.tabsArray == null) {
                return 0;
            }

            return this.tabsArray.length;
        },
        // ホーム画面へ遷移
        homeButtonEvent() {

            // イベントパラメーター生成
            var event = {
                id: 'mnHome',
            };
            // ファイル作成イベント
            this.$emit('tabProc', event);
        },
        // 新規ファイル作成
        plusButtonEvent() {

            // イベントパラメーター生成
            var event = {
                id: 'mnNewFile',
            };
            // ファイル作成イベント
            this.$emit('tabProc', event);
        },
        // プロジェクト保存
        saveProjectButtonEvent() {

            // イベントパラメーター生成
            var event = {
                id: 'mnSaveProject',
            };
            // ファイル作成イベント
            this.$emit('tabProc', event);
        },
        // MainViewイベント
        emitMainViewEvent(event) {

            // イベントパラメーター生成
            if (event == null){
                event = {
                    id: 'mnRun',
                };
            }
            // ファイル作成イベント
            this.$emit('tabProc', event);
        },
        // ステップ実行イベント
        emitStepEvent() {

            // イベントパラメーター生成
            var event = {
                id: 'mnStep',
            };
            // ファイル作成イベント
            this.$emit('tabProc', event);
        },
        // 停止イベント
        emitStopEvent() {

            // イベントパラメーター生成
            var event = {
                id: 'mnStop',
            };
            // ファイル作成イベント
            this.$emit('tabProc', event);
        },
        // ファイル名変更
        fileRenameButtonEvent() {

            // イベントパラメーター生成
            var event = {
                id: 'mnSaveFile',
            };
            // ファイル作成イベント
            this.$emit('tabProc', event);
        },
        // プロジェクトエクスポート
        projectExportButtonEvent() {

            // イベントパラメーター生成
            var event = {
                id: 'mnExportProject',
            };
            // ファイル作成イベント
            this.$emit('tabProc', event);
        },
        // python実行結果を展開
        async responseBufferEvent(data) {
            var resultText = '';
            var consoleText = '';

            // console.log('responseBufferEvent' + data);

            const _json = JSON.parse(data)
            const keyList = Object.keys(_json)

            // // 
            // if (this.$store.state.selectionLanguage == codeLanguage.CS){
            //     data = decodeURI(data);
            // }

            // console.log("json:" + data);

            // 実行結果内容を分類
            for (let k in keyList){

                // console.log('key:' + k);
                // console.log('key:' + keyList[k]);

                if (keyList[k] == 'redirected_output_buffer'){
                    resultText = _json[keyList[k]] + '\n';
                }else{
                    consoleText += `${keyList[k]} = ${_json[keyList[k]]} ` + '\n';
                }
                // console.log(`key=${keyList[k]} | value=${_json[keyList[k]]} `)
            }

            // 改行コードを置換
            resultText = resultText.replace(/\n/g, '<br />');
            // console.log(resultText);
            // console.log(consoleText);

            // 実行結果を保存
            this.$store.dispatch('commitResultText', resultText);
            // 実行結果を出力
            if (this.$refs.mainView != null) {
                this.$refs.mainView.updateResultString();
            }
            
            // コンソール実行結果を保存
            this.$store.dispatch('commitConsoleText', consoleText);
            // コンソール出力
            if (this.$refs.mainView != null) {
                await this.$refs.mainView.updateDebugString();
            }
        },
        // // MAP操作
        // emitMapProc (event) {

        //     // if (event.id == 'showMap'){
        //     //     this.$modal.show('hello-world');                
        //     // }
        // },
        // // MAP表示イベント
        // async beforeOpen() {

        //     console.log('beforeOpen');

        //     this.loader = new Loader({
        //         apiKey: process.env.VUE_APP_GM_KEY,
        //     });

        //     const mapdiv = document.getElementById("map");

        //     console.log('mapdiv:' + mapdiv);

        //     if (mapdiv != null)
        //     {
        //         const obj = this;

        //         await this.loader.load().then(() => {
        //             obj.map = new window.google.maps.Map(mapdiv, {
        //                 center: { lat: -34.397, lng: 150.644 },
        //                 zoom: 8,
        //             });
        //         });

        //         // var latlng = new window.google.maps.LatLng(35.5, 139.5);
        //         // // obj.map.setCenter({lat:' + latitude + ', lng:' + longtitude + '});
        //         // this.map.setCenter(latlng);

        //     }
        // },
    },
}
</script>

<style scoped>
.modal-header, .modal-body {
  padding: 5px 25px;
}
.modal-header {
  border-bottom: 1px solid #ddd;
}
</style>
