<template>
    <v-container style="font-family:Noto Sans JP;">
    <!-- eslint-disable max-len -->
    <v-app-bar
        app
        color='indigo'
        dark
        >

    <v-app-bar-nav-icon />

    <div class='d-flex align-center' style='margin-right:20px'>
        <v-img
            alt='Vuetify Logo'
            class='shrink mr-2'
            contain
            src='./../assets/broccoly.png'
            transition='scale-transition'
            width='40'
            />

        <div class='headline mb-0 ml-2' style='white-space: nowrap;'>
            Broccoly Coder
        </div>
    </div>

    <!-- File -->
    <v-menu
        :close-on-click="closeOnClick"
        :close-on-content-click="closeOnContentClick"
        :offset-x="offsetX"
        :offset-y="offsetY"
        open-on-hover
        >
    <v-list>
    <template v-for="(item) in items">
        <v-divider
            v-if="item.div==true"
            :key="item.id"
            />
        <v-list-item
            v-else
            :key="item.id"
            @click="menuClickEvent(item)"
            >
        <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
    </template>
    </v-list>
    </v-menu>

    <!-- File -->
    <v-menu
        :close-on-click="closeOnClick"
        :close-on-content-click="closeOnContentClick"
        :offset-x="offsetX"
        :offset-y="offsetY"
        open-on-hover
        >
    <template v-slot:activator="{ on, attrs }">
        <v-btn
            text outlined color='normal'
            v-bind="attrs"
            v-on="on"
            slot='activator'
            style='margin-left: 8px;'
            v-bind:disabled="enabledFile"
            >
        ファイル
        </v-btn>
    </template>
    <v-list>
    <template v-for="(item) in mnFileItems">
        <v-divider
            v-if="item.div==true"
            :key="item.id"
            />
        <v-list-item
            v-else
            :key="item.id"
            @click="menuClickEvent(item)"
            >
        <v-icon style='margin-right:10px;'>{{ item.icon }}</v-icon>
        <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
    </template>
    </v-list>
    </v-menu>

    <!-- Edit -->
    <v-menu
        :close-on-click="closeOnClick"
        :close-on-content-click="closeOnContentClick"
        :offset-x="offsetX"
        :offset-y="offsetY"
        open-on-hover
    >
    <template v-slot:activator="{ on, attrs }">
        <v-btn
            text outlined color='normal'
            v-bind="attrs"
            v-on="on"
            slot='activator'
            style='margin-left: 8px;'
            v-bind:disabled="enabled"
        >
        編集
        </v-btn>
    </template>

    <v-list>
    <template v-for="(item) in mnEditItems">
        <v-divider
            v-if="item.div==true"
            :key="item.id"
        ></v-divider>
        <v-list-item
            v-else
            :key="item.id"
            @click="menuClickEvent(item)"
        >
        <v-icon style='margin-right:10px;'>{{ item.icon }}</v-icon>
        <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
    </template>
    </v-list>
    </v-menu>

    <!-- Run -->
    <v-menu
        :close-on-click="closeOnClick"
        :close-on-content-click="closeOnContentClick"
        :offset-x="offsetX"
        :offset-y="offsetY"
        open-on-hover
    >
    <template v-slot:activator="{ on, attrs }">
        <v-btn
            text outlined color='normal'
            v-bind="attrs"
            v-on="on"
            slot='activator'
            style='margin-left: 8px;'
            v-bind:disabled="enabled"
        >
        実行
    </v-btn>

    </template>

    <v-list>
    <template v-for="(item) in mnRunItems">
        <v-divider
            v-if="item.div==true"
            :key="item.id"
        ></v-divider>

        <v-list-item
            v-else
            :key="item.id"
            @click="menuClickEvent(item)"
        >
        <v-icon style='margin-right:10px;'>{{ item.icon }}</v-icon>
        <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
    </template>
    </v-list>
    </v-menu>

    <!-- Help -->
    <v-menu
        :close-on-click="closeOnClick"
        :close-on-content-click="closeOnContentClick"
        :offset-x="offsetX"
        :offset-y="offsetY"
        open-on-hover
    >
    <template v-slot:activator="{ on, attrs }">
        <v-btn
            text outlined color='normal'
            v-bind="attrs"
            v-on="on"
            slot='activator'
            style='margin-left: 8px;'
            v-bind:disabled="EnabledHelp"
        >
        ヘルプ
        </v-btn>
    </template>
    <v-list>
    <template v-for="(item) in mnHelpItems">
        <v-divider
            v-if="item.div==true"
            :key="item.id"
        ></v-divider>
        <v-list-item
            v-else
            :key="item.id"
            @click="menuClickEvent(item)"
        >
        <v-icon style='margin-right:10px;'>{{ item.icon }}</v-icon>
        <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
    </template>
    </v-list>
    </v-menu>

    <!-- Status -->
    <v-text-field
        placeholder='プロジェクト名'
        style='margin-left: 100px; width: 200px; height: 30px;'
        readonly
        v-model="projectName"
    >
    </v-text-field>

    <!-- 右詰め -->
    <v-spacer></v-spacer>
    <v-spacer></v-spacer>
    <v-spacer></v-spacer>

    <!-- ログイン画像 -->
    <!-- <v-avatar
        color="white"
        size="36"
        style="margin-right: 4px; margin-top:4px;"
        v-show="enabled == false"
        >
        <v-icon large color="blue darken-2" small>mdi-message-text</v-icon>
    </v-avatar> -->

    <v-subheader>{{ userName }}</v-subheader>

    <!-- ログイン -->
    <v-menu
        :close-on-click="closeOnClick"
        :close-on-content-click="closeOnContentClick"
        :offset-x="offsetX"
        :offset-y="offsetY"
        open-on-hover
    >
    <template v-slot:activator="{ on, attrs }">
        <v-btn
            text outlined color='normal'
            v-bind="attrs"
            v-on="on"
            slot='activator'
            style='margin-left: 8px; text-transform: none; font-size: 12px;'
            v-bind:disabled="enabledLoginId"
        >
            {{ accountOperationTitle }}
        </v-btn>
    </template>
    <v-list>
    <template v-for="(item) in mnAccountItems">
        <v-divider
            v-if="item.div==true"
            :key="item.id"
        ></v-divider>
        <v-list-item
            v-else
            :key="item.id"
            @click="menuClickEvent(item)"
        >
        <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
    </template>
    </v-list>

    </v-menu>

    </v-app-bar>
    </v-container>

</template>

<script>
export default {
    name: 'MenuBarView',

    props: [
        // メニューの有効・無効
        'menuEnabled',
        // ログインメニューの操作設定
        'menuLoginEnabled',
        // ファイルメニューの有効・無効
        'menuFileEnabled',
        // メニューの有効・無効
        'menuHelpEnabled',
    ],

    data() {
        return {
            // メニュー設定
            closeOnClick: true,
            closeOnContentClick: true,
            offsetX: false,
            offsetY: true,

            // ログインユーザー名
            userName: '',

            // プロジェクト名
            projectName: '',

            // メニューの操作設定
            enabled: true,
            enabledLoginId: true,
            enabledFile: true,
            EnabledHelp: false,

            // アカウント操作ボタン名
            accountOperationTitle: 'ログインしてください',

            // メニューアイテム
            items: [
                { id: 'login', title: 'Login', div: false }, 
                { id: 'vuetify', title: 'Vuetify', div: false },
                { id: 'sp01', title: '', div: true },
                { id: 'blockly', title: 'Blockly', div: false },
                { id: 'sp02', title: '', div: true },
                { id: 'dialog', title: 'Dialog', div: false },
                { id: 'sp03', title: '', div: true },
                { id: 'tabs', title: 'Tabs', div: false },
            ],

            // File メニューアイテム
            mnFileItems: [
                { id: 'mnHome', title: 'ホーム画面へ', div: false, icon: 'mdi-home' },
                { id: 'sp03', title: '', div: true },
                // { id: 'mnNewProject', title: '新規プロジェクト作成', div: false }, 
                { id: 'mnSaveProject', title: 'プロジェクト保存', div: false, icon: 'mdi-folder-upload' },
                // { id: 'mnLoadProject', title: 'プロジェクト読込み', div: false },
                // { id: 'sp01', title: '', div: true },
                // { id: 'mnNewFile', title: '新規ファイル作成', div: false, icon: 'mdi-file-plus' },
                // { id: 'mnSaveFile', title: 'ファイル名を設定', div: false, icon: 'mdi-tag' },
                // { id: 'mnLoadFile', title: 'ファイル読込み', div: false },
                // { id: 'mnFileExport', title: 'ファイルエクスポート', div: false },
                // { id: 'sp02', title: '', div: true },
                // { id: 'mnExportProject', title: 'プロジェクトダウンロード', div: false, icon: 'mdi-download-box' },
                // { id: 'mnExportFile', title: 'ファイルダウンロード', div: false },
                // { id: 'sp03', title: '', div: true },
                // { id: 'mnPreference', title: '設定', div: false },
            ],
            // Edit メニューアイテム
            mnEditItems: [
                { id: 'mnCopy', title: 'コピー', div: false, icon: 'mdi-content-copy' }, 
                // { id: 'mnPaste', title: '貼り付け', div: false }, 
                // { id: 'mnCut', title: '切り取り', div: false }, 
                { id: 'mnUndo', title: '取り消す', div: false, icon: 'mdi-undo' },
                { id: 'mnRedo', title: 'やり直す', div: false, icon: 'mdi-redo' },
                { id: 'sp01', title: '', div: true },
                { id: 'mnEnabled', title: '有効・無効', div: false, icon: 'mdi-sync-off' }, 
                // { id: 'mnExpand', title: '折りたたむ' }, 
                // { id: 'mnAdjustment', title: '整理する' }, 
                // { id: 'mnDelete', title: '削除する' }, 
            ],
            // Run メニューアイテム
            mnRunItems: [
                { id: 'mnRun', title: '実行', div: false, icon: 'mdi-play-circle' }, 
                { id: 'sp01', title: '', div: true },
                { id: 'mnUploadImage', title: '画像アップロード2D', div: false, icon: 'mdi-file-image-plus' }, 
                { id: 'mnUploadImage3D', title: '画像アップロード3D', div: false, icon: 'mdi-file-image-plus' }, 
                // { id: 'mnStop', title: '停止', div: false },
                // { id: 'sp01', title: '', div: true },
                // { id: 'mnGenerate', title: 'コード生成', div: false },
                // { id: 'mnOutputHtml', title: 'HTMLテンプレート', div: false, icon: 'mdi-collage' },
            ],
            // Help メニューアイテム
            mnHelpItems: [
                // { id: 'mnHelp', title: 'ヘルプ', div: false, icon: 'mdi-help-circle' },
                { id: 'mnVersion', title: 'バージョン', div: false, icon: 'mdi-information' }, 
            ],
            // Login メニューアイテム
            mnAccountItems: [
                // { id: 'mnLogin', title: 'ログイン' },
                { id: 'mnLogout', title: 'ログアウト' }, 
                { id: 'sp01', title: '', div: true },
                { id: 'mnPswdReset', title: 'パスワードリセット' }, 
                { id: 'mnWithdrawal', title: '退会' }, 
            ],

            // ページインデックス
            pageIndex: [
                // メニューアイテム
                { id: 'login', pageNo: 0, functionNo:0, pageShow: true, title: 'Login' }, 
                { id: 'vuetify', pageNo:1, functionNo:0, pageShow: true, title: 'Vuetify' },
                { id: 'blockly', pageNo: 2, functionNo:0, pageShow: true, title: 'Blockly' },
                { id: 'dialog', pageNo: 0, functionNo:0, pageShow: true, title: 'Dialog' },
                { id: 'tabs', pageNo: 3, functionNo:0, pageShow: true, title: 'Tabs' },

                // File メニューアイテム
                // { id: 'mnNewProject', pageNo: 2, functionNo:100, pageShow: false, title: 'プロジェクト作成' }, 
                { id: 'mnSaveProject', pageNo: 2, functionNo:102, pageShow: false, title: 'プロジェクト保存' },
                // { id: 'mnLoadProject', pageNo: 2, functionNo:101, pageShow: false, title: 'プロジェクト読込み' },
                // { id: 'mnNewFile', pageNo: 2, functionNo:103, pageShow: false, title: 'ファイル作成' },
                // { id: 'mnLoadFile', pageNo: 2, functionNo:104, pageShow: false, title: 'ファイル読込み' },
                // { id: 'mnSaveFile', pageNo: 2, functionNo:105, pageShow: false, title: 'ファイル名を設定' },
                { id: 'mnExport', pageNo: 2, functionNo:106, pageShow: false, title: 'プロジェクトエクスポート' },
                // { id: 'mnImport', pageNo: 2, functionNo:107, pageShow: false, title: 'プロジェクトインポート' },
                // { id: 'mnFileExport', pageNo: 2, functionNo:108, pageShow: false, title: 'ファイルエクスポート' },
                // { id: 'mnFileImport', pageNo: 2, functionNo:109, pageShow: false, title: 'ファイルトインポート' },

                // Edit メニューアイテム
                { id: 'mnCopy', pageNo: 2, functionNo:200, pageShow: false, title: 'コピー' }, 
                { id: 'mnRedo', pageNo: 2, functionNo:201, pageShow: false, title: 'やり直す' },
                { id: 'mnUndo', pageNo: 2, functionNo:202, pageShow: false, title: '取り消す' },
                // { id: 'mnPaste', pageNo: 2, functionNo:203, pageShow: false, title: '貼り付け' }, 
                // { id: 'mnCut', pageNo: 2, functionNo:204, pageShow: false, title: '切り取り' }, 
                { id: 'mnEnabled', pageNo: 2, functionNo:205, pageShow: false, title: '有効・無効' }, 
                // { id: 'mnExpand', pageNo: 2, functionNo:206, pageShow: false, title: '折りたたむ' }, 
                // { id: 'mnAdjustment', pageNo: 2, functionNo:207, pageShow: false, title: '整理する' }, 
                // { id: 'mnDelete', pageNo: 2, functionNo:208, pageShow: false, title: '削除する' }, 
                // Run メニューアイテム
                { id: 'mnRun', pageNo: 2, functionNo:300, pageShow: false, title: '実行' }, 
                { id: 'mnUploadImage', pageNo: 2, functionNo:304, pageShow: false, title: '画像アップロード2D' }, 
                { id: 'mnUploadImage3D', pageNo: 2, functionNo:305, pageShow: false, title: '画像アップロード3D' }, 
                // { id: 'mnStop', pageNo: 2, functionNo:301, pageShow: false, title: '停止' },
                // { id: 'mnGenerate', pageNo: 2, functionNo:302, pageShow: false, title: 'コード生成' },
                // Help メニューアイテム
                // { id: 'mnHelp', pageNo: 2, functionNo:400, pageShow: false, title: 'ヘルプ' },
                { id: 'mnVersion', pageNo: 2, functionNo:401, pageShow: false, title: 'バージョン' }, 
                { id: 'mnLogout', pageNo: 2, functionNo:402, pageShow: false, title: 'ログアウト' },
                { id: 'mnPswdReset', pageNo: 2, functionNo:403, pageShow: false, title: 'パスワードリセット' }, 
                { id: 'mnWithdrawal', pageNo: 2, functionNo:404, pageShow: false, title: '退会' }, 

            ],
        }
    },
    watch: {
        menuEnabled(newVal/*, oldVal*/){
            // 試用ログインの場合は別設定
            if (this.$store.state.loginId == process.env.VUE_APP_CONF_GUEST_ID) {
                this.enabled = true;
                this.enabledLoginId = true;
                this.enabledFile = true;
                this.EnabledHelp = false;
            }else{
                this.enabled = newVal;
                this.enabledLoginId = newVal;
                this.enabledFile = newVal;
                this.EnabledHelp = false;
            }
        },
        menuLoginEnabled(newVal/*, oldVal*/){
            this.enabledLoginId = newVal;
        },
        menuFileEnabled(newVal/*, oldVal*/){
            this.enabledFile = newVal;
        },
    },
    methods: {
        // メニュークリックイベント
        async menuClickEvent(event){

            console.log(event.id);

            // 新規プロジェクト
            if (event.id == '') {
                this.newProject();
            }

            // テスト
            else if (event.id == 'dialog'){
                // console.log('SimpleListDialog_show is true');
                this.SimpleListDialogArgs.show = true;
                return;
            }

            // 新規ダイアログ
            // 保存ダイアログ
            // 読込みダイアログ
            // 作成コードの実行
            else if (event.id == 'mnHome'
                    || event.id == 'mnNewProject'
                    || event.id == 'mnSaveProject'
                    || event.id == 'mnLoadProject'
                    // || event.id == 'mnNewFile'
                    // || event.id == 'mnSaveFile'
                    // || event.id == 'mnLoadFile'
                    || event.id == 'mnLogout'
                    || event.id == 'mnPswdReset'
                    || event.id == 'mnWithdrawal'
                    || event.id == 'mnRun'
                    || event.id == 'mnUploadImage'
                    || event.id == 'mnExportProject'
                    || event.id == 'mnHelp'
                    || event.id == 'mnVersion'
                    ){

                // 親コンポーネントへ通知
                this.$emit('menuDialogProc', event);
                // 処理を中断
                return;
            }

            // ページ設定
            this.getIndex(event);
        },
        // ページ遷移処理
        getIndex(event) {
            var key = event.id;

            console.log(key);

            for (let cnt=0; cnt<this.pageIndex.length; cnt++) {

                // 参照の取得
                var item = this.pageIndex[cnt];
                // ページ表示 キー判定
                if (item.id == key && item.pageShow == true){
                    // // 該当ページを設定
                    // this.page = item.pageNo;
                    return;
                }

                // 機能実行 キー判定
                if (item.id == key
//                    && (item.pageNo == this.page || event.directJump == true)
                    && item.pageShow == false){

                    // 付加情報を保存
                    item.fileName = event.fileName;
                    item.savefileComment = event.fileComment == 'undefined' ? '' : event.fileComment;

                    // 
                    console.log('getIndex.fileName:' + item.fileName);
                    console.log('getIndex.functionNo:' + item.functionNo);

                    // 親コンポーネントへ通知
                    this.$emit('menuProc', item);

                    return item;
                }
            }
        },
        // ページ遷移処理
        findMenuObject(eventid) {
            for (let cnt=0; cnt<this.pageIndex.length; cnt++) {

                // 参照の取得
                var item = this.pageIndex[cnt];

                // 機能実行 キー判定
                if (item.id == eventid){
                    // 
                    console.log('findMenuObject.fileName:' + item.fileName);
                    console.log('findMenuObject.functionNo:' + item.functionNo);

                    return item;
                }
            }

            return null;
        },
        setAccountName(loginName) {
            // 未設定の場合はログアウト扱い
            if (loginName == "") {
                this.accountOperationTitle = 'ログインしてください';
                return;
            }

            // ログイン名を設定する
            this.accountOperationTitle = loginName;
        }
    },
};
</script>
