import * as Blockly from 'blockly/core';

// ブロックの配色
var js_google_textex_block_color = '#FFBF00';

// Jsonブロック定義
const category_js_textex = `
    <category name="文字列操作拡張" colour="40">
        <block type="js_textex_block_plus"></block>
        <block type="js_textex_block_join"></block>
        <block type="js_textex_block_slice">
            <value name="pos_1">
                <block type="math_number">
                    <field name="NUM">0</field>
                </block>
            </value>
        </block>
        <block type="js_textex_block_substring">
            <value name="pos_1">
                <block type="math_number">
                    <field name="NUM">0</field>
                </block>
            </value>
            <value name="pos_2">
                <block type="math_number">
                    <field name="NUM">1</field>
                </block>
            </value>
        </block>
        <block type="js_textex_block_replace">
            <value name="pos_1">
                <block type="text">
                    <field name="TEXT"></field>
                </block>
            </value>
            <value name="pos_2">
                <block type="text">
                    <field name="TEXT"></field>
                </block>
            </value>
        </block>
        <block type="js_textex_block_split">
            <value name="pos_1">
                <block type="text">
                    <field name="TEXT"></field>
                </block>
            </value>
        </block>
        <block type="js_textex_block_trim"></block>
        <block type="js_textex_block_touppercase"></block>
        <block type="js_textex_block_tolowercase"></block>
        <block type="js_textex_block_number"></block>
        <block type="js_textex_block_string"></block>
    </category>`;
    
export {category_js_textex};

///////////////////////////////////////////////////////////////////
// plus
Blockly.Blocks['js_textex_block_plus'] = {
    init: function() {
        this.appendValueInput("strA")
            .setCheck(null)
        this.appendDummyInput()
            .appendField("+");
        this.appendValueInput("strB")
            .setCheck(null)
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(js_google_textex_block_color);
        this.setTooltip("marge");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_textex_block_plus'] = function(block) {

    var strA = Blockly.JavaScript.valueToCode(block, 'strA', Blockly.JavaScript.ORDER_ATOMIC);
    var strB = Blockly.JavaScript.valueToCode(block, 'strB', Blockly.JavaScript.ORDER_ATOMIC);
    
    var code = strA + '+' + strB;

    return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// join
Blockly.Blocks['js_textex_block_join'] = {
    init: function() {
        this.appendValueInput("src")
            .setCheck(null)
        this.appendDummyInput()
            .appendField(".join(");
        this.appendValueInput("data")
            .setCheck(null)
        this.appendDummyInput()
            .appendField(")")
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(js_google_textex_block_color);
        this.setTooltip("join");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_textex_block_join'] = function(block) {

    var src = Blockly.JavaScript.valueToCode(block, 'src', Blockly.JavaScript.ORDER_ATOMIC);
    var data = Blockly.JavaScript.valueToCode(block, 'data', Blockly.JavaScript.ORDER_ATOMIC);

    var code = src + '.join(' + data + ')';

    return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// slice
Blockly.Blocks['js_textex_block_slice'] = {
    init: function() {
        this.appendValueInput("src")
            .setCheck(null)
        this.appendDummyInput()
            .appendField(".slice(");
        this.appendValueInput("pos_1")
            .setCheck("Number")
        this.appendDummyInput()
            .appendField(")")
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(js_google_textex_block_color);
        this.setTooltip("slice");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_textex_block_slice'] = function(block) {

    var src = Blockly.JavaScript.valueToCode(block, 'src', Blockly.JavaScript.ORDER_ATOMIC);
    var pos1 = Blockly.JavaScript.valueToCode(block, 'pos_1', Blockly.JavaScript.ORDER_ATOMIC);
    
    var code = src + '.slice(' + pos1 + ')' ;

    return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// substring
Blockly.Blocks['js_textex_block_substring'] = {
    init: function() {
        this.appendValueInput("src")
            .setCheck(null)
        this.appendDummyInput()
            .appendField(".substring(");
        this.appendValueInput("pos_1")
            .setCheck("Number")
        this.appendDummyInput()
            .appendField(",")
        this.appendValueInput("pos_2")
            .setCheck("Number")
        this.appendDummyInput()
            .appendField(")")
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(js_google_textex_block_color);
        this.setTooltip("substring");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_textex_block_substring'] = function(block) {

    var src = Blockly.JavaScript.valueToCode(block, 'src', Blockly.JavaScript.ORDER_ATOMIC);
    var pos1 = Blockly.JavaScript.valueToCode(block, 'pos_1', Blockly.JavaScript.ORDER_ATOMIC);
    var pos2 = Blockly.JavaScript.valueToCode(block, 'pos_2', Blockly.JavaScript.ORDER_ATOMIC);
    
    var code = src + '.substring(' + pos1 + ',' + pos2 + ')' ;

    return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// replace
Blockly.Blocks['js_textex_block_replace'] = {
    init: function() {
        this.appendValueInput("src")
            .setCheck(null)
        this.appendDummyInput()
            .appendField(".replace(");
        this.appendValueInput("pos_1")
            .setCheck("String")
        this.appendDummyInput()
            .appendField(",")
        this.appendValueInput("pos_2")
            .setCheck("String")
        this.appendDummyInput()
            .appendField(")")
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(js_google_textex_block_color);
        this.setTooltip("replace");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_textex_block_replace'] = function(block) {

    var src = Blockly.JavaScript.valueToCode(block, 'src', Blockly.JavaScript.ORDER_ATOMIC);
    var pos1 = Blockly.JavaScript.valueToCode(block, 'pos_1', Blockly.JavaScript.ORDER_ATOMIC);
    var pos2 = Blockly.JavaScript.valueToCode(block, 'pos_2', Blockly.JavaScript.ORDER_ATOMIC);
    
    var code = src + '.replace(' + pos1 + ',' + pos2 + ')' ;

    return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// split
Blockly.Blocks['js_textex_block_split'] = {
    init: function() {
        this.appendValueInput("src")
            .setCheck(null)
        this.appendDummyInput()
            .appendField(".split(");
        this.appendValueInput("pos_1")
            .setCheck("String")
        this.appendDummyInput()
            .appendField(")")
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(js_google_textex_block_color);
        this.setTooltip("split");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_textex_block_split'] = function(block) {

    var src = Blockly.JavaScript.valueToCode(block, 'src', Blockly.JavaScript.ORDER_ATOMIC);
    var pos1 = Blockly.JavaScript.valueToCode(block, 'pos_1', Blockly.JavaScript.ORDER_ATOMIC);
    
    var code = src + '.split(' + pos1 + ')' ;

    return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// trim
Blockly.Blocks['js_textex_block_trim'] = {
    init: function() {
        this.appendValueInput("src")
            .setCheck(null)
        this.appendDummyInput()
            .appendField(".trim()");
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(js_google_textex_block_color);
        this.setTooltip("trim");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_textex_block_trim'] = function(block) {

    var src = Blockly.JavaScript.valueToCode(block, 'src', Blockly.JavaScript.ORDER_ATOMIC);
    
    var code = src + '.trim()' ;

    return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// toUpperCase
Blockly.Blocks['js_textex_block_touppercase'] = {
    init: function() {
        this.appendValueInput("src")
            .setCheck(null)
        this.appendDummyInput()
            .appendField(".toUpperCase()");
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(js_google_textex_block_color);
        this.setTooltip("toUpperCase");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_textex_block_touppercase'] = function(block) {

    var src = Blockly.JavaScript.valueToCode(block, 'src', Blockly.JavaScript.ORDER_ATOMIC);
    
    var code = src + '.toUpperCase()' ;

    return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// toLowerCase
Blockly.Blocks['js_textex_block_tolowercase'] = {
    init: function() {
        this.appendValueInput("src")
            .setCheck(null)
        this.appendDummyInput()
            .appendField(".toLowerCase()");
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(js_google_textex_block_color);
        this.setTooltip("toLowerCase");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_textex_block_tolowercase'] = function(block) {

    var src = Blockly.JavaScript.valueToCode(block, 'src', Blockly.JavaScript.ORDER_ATOMIC);
    
    var code = src + '.toLowerCase()' ;

    return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// Number
Blockly.Blocks['js_textex_block_number'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("Number(");
        this.appendValueInput("src")
            .setCheck(null)
        this.appendDummyInput()
            .appendField(")");
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(js_google_textex_block_color);
        this.setTooltip("Nubmer");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_textex_block_number'] = function(block) {

    var src = Blockly.JavaScript.valueToCode(block, 'src', Blockly.JavaScript.ORDER_ATOMIC);
    
    var code = 'Number(' + src + ')' ;

    return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// String
Blockly.Blocks['js_textex_block_string'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("String(");
        this.appendValueInput("src")
            .setCheck(null)
        this.appendDummyInput()
            .appendField(")");
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(js_google_textex_block_color);
        this.setTooltip("String");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_textex_block_string'] = function(block) {

    var src = Blockly.JavaScript.valueToCode(block, 'src', Blockly.JavaScript.ORDER_ATOMIC);
    
    var code = 'String(' + src + ')' ;

    return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

