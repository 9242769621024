import * as Blockly from 'blockly/core';

// ブロックの配色
var python_numpy_block_color = 200;

// Cameraブロック定義
const category_python_numpy = `
    <category name="numpy" colour="200">
        <block type="python_numpy_block_array_11">
            <value name="row0col0">
                <block type="math_number">
                    <field name="NUM">0</field>
                </block>
            </value>
        </block>
        <block type="python_numpy_block_array_12">
            <value name="row0col0">
                <block type="math_number">
                    <field name="NUM">0</field>
                </block>
            </value>
            <value name="row0col1">
                <block type="math_number">
                    <field name="NUM">0</field>
                </block>
            </value>
        </block>
        <block type="python_numpy_block_array_21">
            <value name="row0col0">
                <block type="math_number">
                    <field name="NUM">0</field>
                </block>
            </value>
            <value name="row1col0">
                <block type="math_number">
                    <field name="NUM">0</field>
                </block>
            </value>
        </block>
        <block type="python_numpy_block_array_22">
            <value name="row0col0">
                <block type="math_number">
                    <field name="NUM">0</field>
                </block>
            </value>
            <value name="row0col1">
                <block type="math_number">
                    <field name="NUM">0</field>
                </block>
            </value>
            <value name="row1col0">
                <block type="math_number">
                    <field name="NUM">0</field>
                </block>
            </value>
            <value name="row1col1">
                <block type="math_number">
                    <field name="NUM">0</field>
                </block>
            </value>
        </block>

        <block type="python_numpy_block_shape">
        </block>
        <block type="python_numpy_block_ndim">
        </block>

        <block type="python_numpy_block_zeros">
            <value name="row">
                <block type="math_number">
                    <field name="NUM">1</field>
                </block>
            </value>
            <value name="col">
                <block type="math_number">
                    <field name="NUM">1</field>
                </block>
            </value>
        </block>
        <block type="python_numpy_block_ones">
            <value name="row">
                <block type="math_number">
                    <field name="NUM">1</field>
                </block>
            </value>
            <value name="col">
                <block type="math_number">
                    <field name="NUM">1</field>
                </block>
            </value>
        </block>
        <block type="python_numpy_block_full">
            <value name="row">
                <block type="math_number">
                    <field name="NUM">1</field>
                </block>
            </value>
            <value name="col">
                <block type="math_number">
                    <field name="NUM">1</field>
                </block>
            </value>
            <value name="val">
                <block type="math_number">
                    <field name="NUM">1</field>
                </block>
            </value>
        </block>
        <block type="python_numpy_block_eye">
            <value name="size">
                <block type="math_number">
                    <field name="NUM">2</field>
                </block>
            </value>
        </block>

        <block type="python_numpy_block_random">
            <value name="row">
                <block type="math_number">
                    <field name="NUM">2</field>
                </block>
            </value>
            <value name="col">
                <block type="math_number">
                    <field name="NUM">2</field>
                </block>
            </value>
        </block>
        <block type="python_numpy_block_arange">
            <value name="first">
                <block type="math_number">
                    <field name="NUM">0</field>
                </block>
            </value>
            <value name="last">
                <block type="math_number">
                    <field name="NUM">10</field>
                </block>
            </value>
            <value name="step">
                <block type="math_number">
                    <field name="NUM">1</field>
                </block>
            </value>
        </block>
        <block type="python_numpy_block_get1">
            <value name="row">
                <block type="math_number">
                    <field name="NUM">0</field>
                </block>
            </value>
            <value name="col">
                <block type="math_number">
                    <field name="NUM">0</field>
                </block>
            </value>
        </block>
        <block type="python_numpy_block_get_any">
            <value name="rowS">
                <block type="math_number">
                    <field name="NUM">0</field>
                </block>
            </value>
            <value name="rowE">
                <block type="math_number">
                    <field name="NUM">0</field>
                </block>
            </value>
            <value name="colS">
                <block type="math_number">
                    <field name="NUM">0</field>
                </block>
            </value>
            <value name="colE">
                <block type="math_number">
                    <field name="NUM">0</field>
                </block>
            </value>
        </block>
        <block type="python_numpy_block_set1">
            <value name="row">
                <block type="math_number">
                    <field name="NUM">0</field>
                </block>
            </value>
            <value name="col">
                <block type="math_number">
                    <field name="NUM">0</field>
                </block>
            </value>
            <value name="val">
                <block type="math_number">
                    <field name="NUM">0</field>
                </block>
            </value>
        </block>
        <block type="python_numpy_block_set_any">
            <value name="rowS">
                <block type="math_number">
                    <field name="NUM">0</field>
                </block>
            </value>
            <value name="rowE">
                <block type="math_number">
                    <field name="NUM">0</field>
                </block>
            </value>
            <value name="colS">
                <block type="math_number">
                    <field name="NUM">0</field>
                </block>
            </value>
            <value name="colE">
                <block type="math_number">
                    <field name="NUM">0</field>
                </block>
            </value>
            <value name="val">
                <block type="math_number">
                    <field name="NUM">0</field>
                </block>
            </value>
        </block>

        <block type="python_numpy_block_calc">
            <value name="objVar">
            </value>
        </block>

    </category>`;

export {category_python_numpy};

// ///////////////////////////////////////////////////////////////////
// // array
// Blockly.Blocks['python_numpy_block_array'] = {
//     init: function() {
//         this.appendValueInput("values")
//             .appendField("配列")
//             .setCheck(null)
//         this.setInputsInline(true);
//         this.setOutput(true, null);
//         this.setColour(python_numpy_block_color);
//         this.setTooltip("CubeGeometry");
//         this.setHelpUrl("");
//     }
// };

// Blockly.Python['python_numpy_block_array'] = function(block) {

//     var values = Blockly.Python.valueToCode(block, 'values', Blockly.Python.ORDER_ATOMIC);

//     Blockly.Python.definitions_['import_numpy'] = 'import numpy as np';

//     var code = 'np.array(' + values + ')';

//     return [code, Blockly.Python.ORDER_ATOMIC];
// };

///////////////////////////////////////////////////////////////////
// array
Blockly.Blocks['python_numpy_block_array_11'] = {
    init: function() {
        this.appendValueInput("row0col0")
            .appendField("Numpy配列")
            .appendField("行0列0:")
            .setCheck("Number")
        this.appendDummyInput()
            .appendField('型:')
            .appendField(new Blockly.FieldDropdown([
                ['int32', 'int32'],
                ['float64', 'float64'],
            ]), 'dtype');
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(python_numpy_block_color);
        this.setTooltip("array");
        this.setHelpUrl("");
    }
};

Blockly.Python['python_numpy_block_array_11'] = function(block) {

    Blockly.Python.definitions_['import_numpy'] = 'import numpy as np';

    var row0col0 = Blockly.Python.valueToCode(block, 'row0col0', Blockly.Python.ORDER_ATOMIC);
    var dtype = block.getFieldValue('dtype');

    var code = 'np.array([' + row0col0 + '],dtype=np.' + dtype + ')';

    return [code, Blockly.Python.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// array
Blockly.Blocks['python_numpy_block_array_12'] = {
    init: function() {
        this.appendValueInput("row0col0")
            .appendField("Numpy配列")
            .appendField("行0列0:")
            .setCheck("Number")
        this.appendValueInput("row0col1")
            .appendField("行0列1:")
            .setCheck("Number")
        this.appendDummyInput()
            .appendField('型:')
            .appendField(new Blockly.FieldDropdown([
                ['int32', 'int32'],
                ['float64', 'float64'],
            ]), 'dtype');
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(python_numpy_block_color);
        this.setTooltip("array");
        this.setHelpUrl("");
    }
};

Blockly.Python['python_numpy_block_array_12'] = function(block) {

    Blockly.Python.definitions_['import_numpy'] = 'import numpy as np';

    var row0col0 = Blockly.Python.valueToCode(block, 'row0col0', Blockly.Python.ORDER_ATOMIC);
    var row0col1 = Blockly.Python.valueToCode(block, 'row0col1', Blockly.Python.ORDER_ATOMIC);
    var dtype = block.getFieldValue('dtype');

    var code = 'np.array([' + row0col0 + ',' + row0col1 + '],dtype=np.' + dtype + ')';

    return [code, Blockly.Python.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// array
Blockly.Blocks['python_numpy_block_array_21'] = {
    init: function() {
        this.appendValueInput("row0col0")
            .appendField("Numpy配列")
            .appendField("行0列0:")
            .setCheck("Number")
        this.appendValueInput("row1col0")
            .appendField("行1列0:")
            .setCheck("Number")
        this.appendDummyInput()
            .appendField('型:')
            .appendField(new Blockly.FieldDropdown([
                ['int32', 'int32'],
                ['float64', 'float64'],
            ]), 'dtype');
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(python_numpy_block_color);
        this.setTooltip("array");
        this.setHelpUrl("");
    }
};

Blockly.Python['python_numpy_block_array_21'] = function(block) {

    Blockly.Python.definitions_['import_numpy'] = 'import numpy as np';

    var row0col0 = Blockly.Python.valueToCode(block, 'row0col0', Blockly.Python.ORDER_ATOMIC);
    var row1col0 = Blockly.Python.valueToCode(block, 'row1col0', Blockly.Python.ORDER_ATOMIC);
    var dtype = block.getFieldValue('dtype');

    var code = 'np.array([[' + row0col0 + '],[' + row1col0 + ']],dtype=np.' + dtype + ')';

    return [code, Blockly.Python.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// array
Blockly.Blocks['python_numpy_block_array_22'] = {
    init: function() {
        this.appendValueInput("row0col0")
            .appendField("Numpy配列")
            .appendField("行0列0:")
            .setCheck("Number")
        this.appendValueInput("row0col1")
            .appendField("行0列1:")
            .setCheck("Number")
        this.appendValueInput("row1col0")
            .appendField("行1列0:")
            .setCheck("Number")
        this.appendValueInput("row1col1")
            .appendField("行1列1:")
            .setCheck("Number")
        this.appendDummyInput()
            .appendField('型:')
            .appendField(new Blockly.FieldDropdown([
                ['int32', 'int32'],
                ['float64', 'float64'],
            ]), 'dtype');
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(python_numpy_block_color);
        this.setTooltip("array");
        this.setHelpUrl("");
    }
};

Blockly.Python['python_numpy_block_array_22'] = function(block) {

    Blockly.Python.definitions_['import_numpy'] = 'import numpy as np';

    var row0col0 = Blockly.Python.valueToCode(block, 'row0col0', Blockly.Python.ORDER_ATOMIC);
    var row0col1 = Blockly.Python.valueToCode(block, 'row0col1', Blockly.Python.ORDER_ATOMIC);
    var row1col0 = Blockly.Python.valueToCode(block, 'row1col0', Blockly.Python.ORDER_ATOMIC);
    var row1col1 = Blockly.Python.valueToCode(block, 'row1col1', Blockly.Python.ORDER_ATOMIC);
    var dtype = block.getFieldValue('dtype');

    var code = 'np.array([[' + row0col0 + ',' + row0col1 + '],[' + row1col0 + ',' + row1col1 + ']],dtype=np.' + dtype + ')';

    return [code, Blockly.Python.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// shape
Blockly.Blocks['python_numpy_block_shape'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("shpae")
            .appendField(new Blockly.FieldVariable("object"), "sourceVar")
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(python_numpy_block_color);
        this.setTooltip("shape");
        this.setHelpUrl("");
    }
};

Blockly.Python['python_numpy_block_shape'] = function(block) {

    Blockly.Python.definitions_['import_numpy'] = 'import numpy as np';

    var sourceVar = Blockly.Python.nameDB_.getName(block.getFieldValue('sourceVar'), 'VARIABLE');

    var code = sourceVar + '.shape';

    return [code, Blockly.Python.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// ndim
Blockly.Blocks['python_numpy_block_ndim'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("ndim")
            .appendField(new Blockly.FieldVariable("object"), "sourceVar")
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(python_numpy_block_color);
        this.setTooltip("ndim");
        this.setHelpUrl("");
    }
};

Blockly.Python['python_numpy_block_ndim'] = function(block) {

    Blockly.Python.definitions_['import_numpy'] = 'import numpy as np';

    var sourceVar = Blockly.Python.nameDB_.getName(block.getFieldValue('sourceVar'), 'VARIABLE');

    var code = sourceVar + '.ndim';

    return [code, Blockly.Python.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// zeros
Blockly.Blocks['python_numpy_block_zeros'] = {
    init: function() {
        this.appendValueInput("row")
            .appendField("zeros(")
            .appendField("行")
            .setCheck("Number")
        this.appendValueInput("col")
            .appendField("列")
            .setCheck("Number")
        this.appendDummyInput()
            .appendField(")")
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(python_numpy_block_color);
        this.setTooltip("zeros");
        this.setHelpUrl("");
    }
};

Blockly.Python['python_numpy_block_zeros'] = function(block) {

    Blockly.Python.definitions_['import_numpy'] = 'import numpy as np';

    var row = Blockly.Python.valueToCode(block, 'row', Blockly.Python.ORDER_ATOMIC);
    var col = Blockly.Python.valueToCode(block, 'col', Blockly.Python.ORDER_ATOMIC);

    var code = 'np.zeros((' + row + ',' + col + '))';

    return [code, Blockly.Python.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// ones
Blockly.Blocks['python_numpy_block_ones'] = {
    init: function() {
        this.appendValueInput("row")
            .appendField("ones(")
            .appendField("行")
            .setCheck("Number")
        this.appendValueInput("col")
            .appendField("列")
            .setCheck("Number")
        this.appendDummyInput()
            .appendField(")")
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(python_numpy_block_color);
        this.setTooltip("ones");
        this.setHelpUrl("");
    }
};

Blockly.Python['python_numpy_block_ones'] = function(block) {

    Blockly.Python.definitions_['import_numpy'] = 'import numpy as np';

    var row = Blockly.Python.valueToCode(block, 'row', Blockly.Python.ORDER_ATOMIC);
    var col = Blockly.Python.valueToCode(block, 'col', Blockly.Python.ORDER_ATOMIC);

    var code = 'np.ones((' + row + ',' + col + '))';

    return [code, Blockly.Python.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// full
Blockly.Blocks['python_numpy_block_full'] = {
    init: function() {
        this.appendValueInput("row")
            .appendField("full(")
            .appendField("行")
            .setCheck("Number")
        this.appendValueInput("col")
            .appendField("列")
            .setCheck("Number")
        this.appendValueInput("val")
            .appendField("値")
            .setCheck("Number")
        this.appendDummyInput()
            .appendField(")")
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(python_numpy_block_color);
        this.setTooltip("full");
        this.setHelpUrl("");
    }
};

Blockly.Python['python_numpy_block_full'] = function(block) {

    Blockly.Python.definitions_['import_numpy'] = 'import numpy as np';

    var row = Blockly.Python.valueToCode(block, 'row', Blockly.Python.ORDER_ATOMIC);
    var col = Blockly.Python.valueToCode(block, 'col', Blockly.Python.ORDER_ATOMIC);
    var val = Blockly.Python.valueToCode(block, 'val', Blockly.Python.ORDER_ATOMIC);

    var code = 'np.full((' + row + ',' + col + '),' + val + ')';

    return [code, Blockly.Python.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// eye
Blockly.Blocks['python_numpy_block_eye'] = {
    init: function() {
        this.appendValueInput("size")
            .appendField("eye(")
            .appendField("サイズ")
            .setCheck("Number")
        this.appendDummyInput()
            .appendField(")")
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(python_numpy_block_color);
        this.setTooltip("eye");
        this.setHelpUrl("");
    }
};

Blockly.Python['python_numpy_block_eye'] = function(block) {

    Blockly.Python.definitions_['import_numpy'] = 'import numpy as np';

    var size = Blockly.Python.valueToCode(block, 'size', Blockly.Python.ORDER_ATOMIC);

    var code = 'np.eye(' + size + ')';

    return [code, Blockly.Python.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// random
Blockly.Blocks['python_numpy_block_random'] = {
    init: function() {
        this.appendValueInput("row")
            .appendField("random(")
            .appendField("行")
            .setCheck("Number")
        this.appendValueInput("col")
            .appendField("列")
            .setCheck("Number")
        this.appendDummyInput()
            .appendField(")")
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(python_numpy_block_color);
        this.setTooltip("random");
        this.setHelpUrl("");
    }
};

Blockly.Python['python_numpy_block_random'] = function(block) {

    Blockly.Python.definitions_['import_numpy'] = 'import numpy as np';

    var row = Blockly.Python.valueToCode(block, 'row', Blockly.Python.ORDER_ATOMIC);
    var col = Blockly.Python.valueToCode(block, 'col', Blockly.Python.ORDER_ATOMIC);

    var code = 'np.random.random((' + row + ',' + col + '))';

    return [code, Blockly.Python.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// arange
Blockly.Blocks['python_numpy_block_arange'] = {
    init: function() {
        this.appendValueInput("first")
            .appendField("arange(")
            .appendField("開始")
            .setCheck("Number")
        this.appendValueInput("last")
            .appendField("終了")
            .setCheck("Number")
        this.appendValueInput("step")
            .appendField("増分")
            .setCheck("Number")
        this.appendDummyInput()
            .appendField(")")
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(python_numpy_block_color);
        this.setTooltip("arange");
        this.setHelpUrl("");
    }
};

Blockly.Python['python_numpy_block_arange'] = function(block) {

    Blockly.Python.definitions_['import_numpy'] = 'import numpy as np';

    var first = Blockly.Python.valueToCode(block, 'first', Blockly.Python.ORDER_ATOMIC);
    var last = Blockly.Python.valueToCode(block, 'last', Blockly.Python.ORDER_ATOMIC);
    var step = Blockly.Python.valueToCode(block, 'step', Blockly.Python.ORDER_ATOMIC);

    var code = 'np.arange(' + first + ',' + last + ',' + step + ')';

    return [code, Blockly.Python.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// get
Blockly.Blocks['python_numpy_block_get1'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("get")
            .appendField(new Blockly.FieldVariable("object"), "sourceVar")
        this.appendValueInput("row")
            .appendField("[行")
            .setCheck("Number")
        this.appendValueInput("col")
            .appendField("列")
            .setCheck("Number")
        this.appendDummyInput()
            .appendField("]")
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(python_numpy_block_color);
        this.setTooltip("get");
        this.setHelpUrl("");
    }
};

Blockly.Python['python_numpy_block_get1'] = function(block) {

    Blockly.Python.definitions_['import_numpy'] = 'import numpy as np';

    var sourceVar = Blockly.Python.nameDB_.getName(block.getFieldValue('sourceVar'), 'VARIABLE');
    var row = Blockly.Python.valueToCode(block, 'row', Blockly.Python.ORDER_ATOMIC);
    var col = Blockly.Python.valueToCode(block, 'col', Blockly.Python.ORDER_ATOMIC);

    var code = sourceVar + '[' + row + ',' + col + ']';

    return [code, Blockly.Python.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// get
Blockly.Blocks['python_numpy_block_get_any'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("get")
            .appendField(new Blockly.FieldVariable("object"), "sourceVar")
        this.appendValueInput("rowS")
            .appendField("[開始行")
            .setCheck("Number")
        this.appendValueInput("rowE")
            .appendField("[終了行")
            .setCheck("Number")
        this.appendValueInput("colS")
            .appendField("開始列")
            .setCheck("Number")
        this.appendValueInput("colE")
            .appendField("終了列")
            .setCheck("Number")
        this.appendDummyInput()
            .appendField("]")
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(python_numpy_block_color);
        this.setTooltip("get");
        this.setHelpUrl("");
    }
};

Blockly.Python['python_numpy_block_get_any'] = function(block) {

    Blockly.Python.definitions_['import_numpy'] = 'import numpy as np';

    var sourceVar = Blockly.Python.nameDB_.getName(block.getFieldValue('sourceVar'), 'VARIABLE');
    var rowS = Blockly.Python.valueToCode(block, 'rowS', Blockly.Python.ORDER_ATOMIC);
    var rowE = Blockly.Python.valueToCode(block, 'rowE', Blockly.Python.ORDER_ATOMIC);
    var colS = Blockly.Python.valueToCode(block, 'colS', Blockly.Python.ORDER_ATOMIC);
    var colE = Blockly.Python.valueToCode(block, 'colE', Blockly.Python.ORDER_ATOMIC);

    var code = sourceVar + '[' + rowS + ':' + rowE + ',' + colS + ':' + colE + ']';

    return [code, Blockly.Python.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// set
Blockly.Blocks['python_numpy_block_set1'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("set")
            .appendField(new Blockly.FieldVariable("object"), "sourceVar")
        this.appendValueInput("row")
            .appendField("[行")
            .setCheck("Number")
        this.appendValueInput("col")
            .appendField("列")
            .setCheck("Number")
        this.appendDummyInput()
            .appendField("]=")
        this.appendValueInput("val")
            .appendField("値")
            .setCheck("Number")
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(python_numpy_block_color);
        this.setTooltip("set");
        this.setHelpUrl("");
    }
};

Blockly.Python['python_numpy_block_set1'] = function(block) {

    Blockly.Python.definitions_['import_numpy'] = 'import numpy as np';

    var sourceVar = Blockly.Python.nameDB_.getName(block.getFieldValue('sourceVar'), 'VARIABLE');
    var row = Blockly.Python.valueToCode(block, 'row', Blockly.Python.ORDER_ATOMIC);
    var col = Blockly.Python.valueToCode(block, 'col', Blockly.Python.ORDER_ATOMIC);
    var val = Blockly.Python.valueToCode(block, 'val', Blockly.Python.ORDER_ATOMIC);

    var code = sourceVar + '[' + row + ',' + col + ']=' + val + '\n';

    return code;
};

///////////////////////////////////////////////////////////////////
// set
Blockly.Blocks['python_numpy_block_set_any'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("set")
            .appendField(new Blockly.FieldVariable("object"), "sourceVar")
        this.appendValueInput("rowS")
            .appendField("[開始行")
            .setCheck("Number")
        this.appendValueInput("rowE")
            .appendField("[終了行")
            .setCheck("Number")
        this.appendValueInput("colS")
            .appendField("開始列")
            .setCheck("Number")
        this.appendValueInput("colE")
            .appendField("終了列")
            .setCheck("Number")
        this.appendDummyInput()
            .appendField("]=")
        this.appendValueInput("val")
            .appendField("値")
            .setCheck("Number")
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(python_numpy_block_color);
        this.setTooltip("set");
        this.setHelpUrl("");
    }
};

Blockly.Python['python_numpy_block_set_any'] = function(block) {

    Blockly.Python.definitions_['import_numpy'] = 'import numpy as np';

    var sourceVar = Blockly.Python.nameDB_.getName(block.getFieldValue('sourceVar'), 'VARIABLE');
    var rowS = Blockly.Python.valueToCode(block, 'rowS', Blockly.Python.ORDER_ATOMIC);
    var rowE = Blockly.Python.valueToCode(block, 'rowE', Blockly.Python.ORDER_ATOMIC);
    var colS = Blockly.Python.valueToCode(block, 'colS', Blockly.Python.ORDER_ATOMIC);
    var colE = Blockly.Python.valueToCode(block, 'colE', Blockly.Python.ORDER_ATOMIC);
    var val = Blockly.Python.valueToCode(block, 'val', Blockly.Python.ORDER_ATOMIC);

    var code = sourceVar + '[' + rowS + ':' + rowE + ',' + colS + ':' + colE + ']=' + val + '\n';

    return code;
};


///////////////////////////////////////////////////////////////////
// calc
Blockly.Blocks['python_numpy_block_calc'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("計算:")
        this.appendValueInput("objVar")
            .setCheck(null)
        this.appendDummyInput()
            .appendField('方法:')
            .appendField(new Blockly.FieldDropdown([
                ['sqrt', 'sqrt'],
                ['log', 'log'],
                ['log2', 'log2'],
                ['log10', 'log10'],
            ]), 'calc');
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(python_numpy_block_color);
        this.setTooltip("calc");
        this.setHelpUrl("");
    }
};

Blockly.Python['python_numpy_block_calc'] = function(block) {

    Blockly.Python.definitions_['import_numpy'] = 'import numpy as np';

    var objVar = Blockly.Python.valueToCode(block, 'objVar', Blockly.Python.ORDER_ATOMIC);
    var calc = block.getFieldValue('calc');

    var code = '';

    if (calc == 'sqrt'){
        code = 'np.sqrt(' + objVar + ')'; 
    }else if (calc == 'log'){
        code = 'np.log(' + objVar + ')'; 
    }else if (calc == 'log2'){
        code = 'np.log2(' + objVar + ')'; 
    }else if (calc == 'log10'){
        code = 'np.log10(' + objVar + ')'; 
    }

    return [code, Blockly.Python.ORDER_ATOMIC];
};

