import * as Blockly from 'blockly/core';

// ブロックの配色
var js_chart_block_color = 210;

// Chartブロック定義
const category_Chart = `
    <category name="グラフ" colour="210">
        <block type="js_chart_chart"></block>
        <block type="js_chart_data"></block>
        <block type="js_chart_rgb"></block>
    </category>`;

export {category_Chart};

///////////////////////////////////////////////////////////////////
// chart()
Blockly.Blocks['js_chart_chart'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("グラフ")
            .appendField(new Blockly.FieldDropdown([["折れ線グラフ","line"], ["棒グラフ","bar"], ["円グラフ","pie"], ["レーダーチャート","radar"], ["散布図","scatter"], ]), "graphType");
        this.appendValueInput("labels")
            .setCheck("String")
            .appendField("見出し（リスト）");
        this.appendStatementInput("data")
            .setCheck(null);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(js_chart_block_color);
        this.setTooltip("CHART DATA");
        this.setHelpUrl("");
        
    }
};

Blockly.JavaScript['js_chart_chart'] = function(block) {

    var graphType = block.getFieldValue('graphType');
    var statements_data = Blockly.JavaScript.statementToCode(block, 'data');
    var labels = Blockly.JavaScript.valueToCode(block, 'labels', Blockly.JavaScript.ORDER_ATOMIC);

    // NULLチェック
    if (statements_data == null) {
        return '';
    }
    // NULLチェック
    if (labels == null) {
        return '';
    }

    var code = 'new Chart(context, {\n'
    code += 'type: \'' + graphType + '\',\n';
    code += 'data: {\n';
    code += 'labels:' + labels + ',\n';
    code += 'datasets: [\n';
    code += statements_data;
    code += ']},'
    code += 'options: {\n';
    code += '   responsive: true,\n';
    code += '   title: {\n';
    code += '       display: true,\n';
    code += '       text: \'グラフの見出し\',\n';
    code += '   },\n';
    code += '},\n';
    code += '});\n';

    return code;
};

///////////////////////////////////////////////////////////////////
// filltext()
Blockly.Blocks['js_chart_data'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("データセット");
        this.appendValueInput("labelName")
            .appendField("凡例名")
            .setCheck("String")
        this.appendValueInput("datas")
            .setCheck("Array")
            .appendField("データ（リスト）");
        this.appendValueInput("borderColor")
            .appendField('線色');
        this.appendValueInput("backgroundColor")
            .appendField('背景色');
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(js_chart_block_color);
        this.setTooltip("CHART DATA");
        this.setHelpUrl("");
        
    }
};

Blockly.JavaScript['js_chart_data'] = function(block) {

    var labelName = Blockly.JavaScript.valueToCode(block, 'labelName', Blockly.JavaScript.ORDER_ATOMIC);
    var datas = Blockly.JavaScript.valueToCode(block, 'datas', Blockly.JavaScript.ORDER_ATOMIC);
    var borderColor = Blockly.JavaScript.statementToCode(block, 'borderColor');
    var backgroundColor = Blockly.JavaScript.statementToCode(block, 'backgroundColor');

    // NULLチェック
    if (datas == null) {
        return '';
    }
    // NULLを置換
    if (borderColor == null || borderColor == '') {
        borderColor = 'rgb(0, 0, 0)';
    }
    if (backgroundColor == null || backgroundColor == '') {
        backgroundColor = 'rgb(0, 0, 0)';
    }

    var code = '{';
    code += 'label:' + labelName + ',\n';
    code += 'borderColor:\'' + borderColor + '\',\n';
    code += 'backgroundColor:\'' + backgroundColor + '\',\n';
    code += 'pointStyle: \'circle\',\n';
    code += 'pointRadius: 8,\n';
    code += 'pointHoverRadius: 15,\n';
    code += 'data:' + datas + '\n';
    code += '},\n';

    return code;
};

///////////////////////////////////////////////////////////////////
// js_chart_color
Blockly.Blocks['js_chart_rgb'] = {
    init: function() {
        // this.appendDummyInput()
        //     .appendField("赤")
        //     .appendField(new Blockly.FieldNumber(0, 0, 255), "r");
        // this.appendDummyInput()
        //     .appendField("緑")
        //     .appendField(new Blockly.FieldNumber(0, 0, 255), "g");
        // this.appendDummyInput()
        //     .appendField("青")
        //     .appendField(new Blockly.FieldNumber(0, 0, 255), "b");
        this.appendDummyInput()
            .appendField("グラフの色")
            .appendField(new Blockly.FieldColour("#ff0000"), "Color")
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(js_chart_block_color);
        this.setTooltip("COLOR");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_chart_rgb'] = function(block) {
    
    // var r = block.getFieldValue('r');
    // var g = block.getFieldValue('g');
    // var b = block.getFieldValue('b');

    // var code = 'rgb(' + r + ',' + g + ',' + b + ')';

    var colour_color = block.getFieldValue('Color');
    var code = '' + colour_color + '';

    return code;
};

