import * as Blockly from 'blockly/core';

// ブロックの配色
var js_timer_block_color = 230;

// Timerブロック定義
const category_Timer = `
    <category name="タイマー" colour="230">
        <block type="js_timer_block_setInterval"></block>
        <block type="js_timer_block_clearInterval"></block>
    </category>`;

    // <block type="js_timer_block_setTimer"></block>

export {category_Timer};

///////////////////////////////////////////////////////////////////
// setInterval()
Blockly.Blocks['js_timer_block_setInterval'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("タイマー開始")
            .appendField(new Blockly.FieldNumber(1, 1, 60), "second")
            .appendField("秒ごと")
        this.appendStatementInput("data")
            .setCheck(null);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(js_timer_block_color);
        this.setTooltip("setInterval");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_timer_block_setInterval'] = function(block) {
    var statements_data = Blockly.JavaScript.statementToCode(block, 'data');
    var second = block.getFieldValue('second');

    // 秒に変換
    second = second * 1000;

    var code = 'var timer=setInterval(function(){\n';
    code += statements_data + '\n';
    code += '},' + second + ');\n';

    return code;
};

///////////////////////////////////////////////////////////////////
// clearInterval()
Blockly.Blocks['js_timer_block_clearInterval'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("タイマー停止")
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(js_timer_block_color);
        this.setTooltip("clearInterval");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_timer_block_clearInterval'] = function(/*block*/) {
    
    var code = 'clearInterval(timer);\n';

    return code;
};

// ///////////////////////////////////////////////////////////////////
// // setTimer()
// Blockly.Blocks['js_timer_block_setTimer'] = {
//     init: function() {
//         this.appendDummyInput()
//             .appendField("待ち時間")
//             .appendField(new Blockly.FieldNumber(1, 1, 60), "second")
//             .appendField("秒")
//         this.setPreviousStatement(true, null);
//         this.setNextStatement(true, null);
//         this.setColour(js_timer_block_color);
//         this.setTooltip("setTimer");
//         this.setHelpUrl("");
//     }
// };

// Blockly.JavaScript['js_timer_block_setTimer'] = function(block) {
//     var second = block.getFieldValue('second');

//     // 秒に変換
//     second = second * 1000;

//     var code = 'sleep(' +  second + ');\n';

//     return code;
// };

