import * as Blockly from 'blockly/core';

// ブロックの配色
var cs_class_block_color = "#8066FF";

// Timerブロック定義
const category_classDef = `
    <category name="クラス" colour="#8066FF">
    <block type="cs_class_def"></block>
    <block type="cs_class_new_instance"></block>
    <block type="cs_class_dot_value"></block>
    <block type="cs_class_dot_method"></block>
    <block type="cs_class_dot_method_no_ret"></block>
    <block type="cs_class_this_dot_value"></block>
    <block type="cs_class_this_dot_method"></block>
    <block type="cs_class_this_dot_method_no_ret"></block>
    </category>`;

export {category_classDef};

///////////////////////////////////////////////////////////////////
// class()
Blockly.Blocks['cs_class_def'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("public class")
            .appendField(new Blockly.FieldTextInput("class_name"), "class_name")
        this.appendStatementInput("code")
            .setCheck(null);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(cs_class_block_color);
        this.setTooltip("class");
        this.setHelpUrl("");
    }
};

//////////////////////////////////////////////////////////////////////////
// class()
Blockly.CSharp['cs_class_def'] = function(block) {
    Blockly.CSharp.definitions_['import_system'] = 'using System;';

  var statements_data = Blockly.CSharp.statementToCode(block, 'code');
  var class_name = block.getFieldValue('class_name');

  var code = 'public class ' + class_name + ' {\n';
  code += statements_data + '\n';
  code += '};\n\n';

  return code;

};

///////////////////////////////////////////////////////////////////
// new instance
Blockly.Blocks['cs_class_new_instance'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("new")
            .appendField(new Blockly.FieldTextInput("class_name"), "class_name");
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(cs_class_block_color);
        this.setTooltip("new_instance");
        this.setHelpUrl("");
    }
};

//////////////////////////////////////////////////////////////////////////
// new instance
Blockly.CSharp['cs_class_new_instance'] = function(block) {
    Blockly.CSharp.definitions_['import_system'] = 'using System;';

    var class_name = block.getFieldValue('class_name');
    var code = 'new ' + class_name + '()';

    return [code, Blockly.CSharp.ORDER_ATOMIC];

};

///////////////////////////////////////////////////////////////////
// dot
Blockly.Blocks['cs_class_dot_value'] = {
    init: function() {
        this.appendValueInput("classObj")
            .setCheck(null)
        this.appendDummyInput()
            .appendField(".")
        this.appendValueInput("member")
            .setCheck(null)
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(cs_class_block_color);
        this.setTooltip("new_instance");
        this.setHelpUrl("");
    }
};

//////////////////////////////////////////////////////////////////////////
// dot
Blockly.CSharp['cs_class_dot_value'] = function(block) {
    Blockly.CSharp.definitions_['import_system'] = 'using System;';

    var classObj = Blockly.CSharp.valueToCode(block, 'classObj', Blockly.CSharp.ORDER_ATOMIC);
    var member = Blockly.CSharp.valueToCode(block, 'member', Blockly.CSharp.ORDER_ATOMIC);

    // console.log('cs_class_dot_method:' + member);

    // 'を削除
    classObj = classObj.replace(/\(/g, '').replace(/\)/g, '');
    // member = member.replace(/\(/g, '').replace(/\)/g, '');
    member = member.substring(1);
    member = member.substring(0, member.length-1);

    var code = classObj + '.' + member;

    return [code, Blockly.CSharp.ORDER_ATOMIC];

};

///////////////////////////////////////////////////////////////////
// dot
Blockly.Blocks['cs_class_dot_method'] = {
    init: function() {
        this.appendValueInput("classObj")
            .setCheck(null)
        this.appendDummyInput()
            .appendField(".")
        this.appendValueInput("member")
            .setCheck(null)
        this.appendDummyInput()
            .appendField("=")
        this.appendValueInput("value")
            .setCheck(null)
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setInputsInline(true);
        this.setColour(cs_class_block_color);
        this.setTooltip("new_instance");
        this.setHelpUrl("");
    }
};

//////////////////////////////////////////////////////////////////////////
// dot
Blockly.CSharp['cs_class_dot_method'] = function(block) {
    Blockly.CSharp.definitions_['import_system'] = 'using System;';

    var classObj = Blockly.CSharp.valueToCode(block, 'classObj', Blockly.CSharp.ORDER_ATOMIC);
    var member = Blockly.CSharp.valueToCode(block, 'member', Blockly.CSharp.ORDER_ATOMIC);
    var value = Blockly.CSharp.valueToCode(block, 'value', Blockly.CSharp.ORDER_ATOMIC);

    // 'を削除
    classObj = classObj.replace(/\(/g, '').replace(/\)/g, '');
    // member = member.replace(/\(/g, '').replace(/\)/g, '');
    member = member.substring(1);
    member = member.substring(0, member.length-1);

    var code = classObj + '.' + member + '=' + value + ';\n';

    return code;

};

///////////////////////////////////////////////////////////////////
// dot
Blockly.Blocks['cs_class_dot_method_no_ret'] = {
    init: function() {
        this.appendValueInput("classObj")
            .setCheck(null)
        this.appendDummyInput()
            .appendField(".")
        this.appendValueInput("member")
            .setCheck(null)
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setInputsInline(true);
        this.setColour(cs_class_block_color);
        this.setTooltip("new_instance");
        this.setHelpUrl("");
    }
};

//////////////////////////////////////////////////////////////////////////
// dot
Blockly.CSharp['cs_class_dot_method_no_ret'] = function(block) {
    Blockly.CSharp.definitions_['import_system'] = 'using System;';

    var classObj = Blockly.CSharp.valueToCode(block, 'classObj', Blockly.CSharp.ORDER_ATOMIC);
    var member = Blockly.CSharp.valueToCode(block, 'member', Blockly.CSharp.ORDER_ATOMIC);

    // 'を削除
    classObj = classObj.replace(/\(/g, '').replace(/\)/g, '');
    // member = member.replace(/\(/g, '').replace(/\)/g, '');
    member = member.substring(1);
    member = member.substring(0, member.length-1);

    var code = classObj + '.' + member + ';\n';

    return code;

};

///////////////////////////////////////////////////////////////////
// this dot
Blockly.Blocks['cs_class_this_dot_value'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("this.")
        this.appendValueInput("member")
            .setCheck(null)
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(cs_class_block_color);
        this.setTooltip("new_instance");
        this.setHelpUrl("");
    }
};

//////////////////////////////////////////////////////////////////////////
// this dot
Blockly.CSharp['cs_class_this_dot_value'] = function(block) {
    Blockly.CSharp.definitions_['import_system'] = 'using System;';

    var member = Blockly.CSharp.valueToCode(block, 'member', Blockly.CSharp.ORDER_ATOMIC);

    // console.log('cs_class_this_dot_method:' + member);

    // 'を削除
    // member = member.replace(/\(/g, '').replace(/\)/g, '');
    member = member.substring(1);
    member = member.substring(0, member.length-1);

    var code = 'this.' + member;

    return [code, Blockly.CSharp.ORDER_ATOMIC];

};

///////////////////////////////////////////////////////////////////
// this dot
Blockly.Blocks['cs_class_this_dot_method'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("this.")
        this.appendValueInput("member")
            .setCheck(null)
        this.appendDummyInput()
            .appendField("=")
        this.appendValueInput("value")
            .setCheck(null)
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setInputsInline(true);
        this.setColour(cs_class_block_color);
        this.setTooltip("new_instance");
        this.setHelpUrl("");
    }
};

//////////////////////////////////////////////////////////////////////////
// this dot
Blockly.CSharp['cs_class_this_dot_method'] = function(block) {
    Blockly.CSharp.definitions_['import_system'] = 'using System;';

    var member = Blockly.CSharp.valueToCode(block, 'member', Blockly.CSharp.ORDER_ATOMIC);
    var value = Blockly.CSharp.valueToCode(block, 'value', Blockly.CSharp.ORDER_ATOMIC);

    // 'を削除
    // member = member.replace(/\(/g, '').replace(/\)/g, '');
    member = member.substring(1);
    member = member.substring(0, member.length-1);

    var code = 'this.' + member + '=' + value + ';\n';

    return code;
};

///////////////////////////////////////////////////////////////////
// this dot
Blockly.Blocks['cs_class_this_dot_method_no_ret'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("this.")
        this.appendValueInput("member")
            .setCheck(null)
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setInputsInline(true);
        this.setColour(cs_class_block_color);
        this.setTooltip("new_instance");
        this.setHelpUrl("");
    }
};

//////////////////////////////////////////////////////////////////////////
// this dot
Blockly.CSharp['cs_class_this_dot_method_no_ret'] = function(block) {
    Blockly.CSharp.definitions_['import_system'] = 'using System;';

    var member = Blockly.CSharp.valueToCode(block, 'member', Blockly.CSharp.ORDER_ATOMIC);

    // 'を削除
    // member = member.replace(/\(/g, '').replace(/\)/g, '');
    member = member.substring(1);
    member = member.substring(0, member.length-1);

    var code = 'this.' + member + ';\n';

    return code;

};
