import * as Blockly from 'blockly/core';

// ブロックの配色
var js_debug_block_color = 250;

// Timerブロック定義
const category_Debug = `
    <category name="デバッグ" colour="250">
        <block type="js_debug_block_log"></block>
    </category>`;

export {category_Debug};

///////////////////////////////////////////////////////////////////
// log()
Blockly.Blocks['js_debug_block_log'] = {
    init: function() {
        this.appendValueInput("text")
            .appendField("コンソール出力")
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(js_debug_block_color);
        this.setTooltip("log");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_debug_block_log'] = function(block) {
    var text = Blockly.JavaScript.valueToCode(block, 'text', Blockly.JavaScript.ORDER_ATOMIC);

    var code = 'console.log(' + text + ');\n';
    return code;
};

