import * as Blockly from 'blockly/core';

// ブロックの配色
var js_google_json_block_color = 330;

// Jsonブロック定義
const category_json = `
    <category name="JSON" colour="330">
        <block type="js_json_block_parse"></block>
        <block type="js_json_block_key"></block>
        <block type="js_json_block_dot"></block>
        <block type="js_json_block_stringify"></block>
    </category>`;

    // <block type="js_json_block_httprequest"></block>
    
export {category_json};

///////////////////////////////////////////////////////////////////
// parse
Blockly.Blocks['js_json_block_parse'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("Parse(");
        this.appendValueInput("data")
            .setCheck(null)
        this.appendDummyInput()
            .appendField(")")
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(js_google_json_block_color);
        this.setTooltip("Light");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_json_block_parse'] = function(block) {

    var data = Blockly.JavaScript.valueToCode(block, 'data', Blockly.JavaScript.ORDER_ATOMIC);
    
    var code = 'JSON.parse(' + data + ')';

    return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// key
Blockly.Blocks['js_json_block_key'] = {
    init: function() {
        this.appendValueInput("src")
            .setCheck(null)
        this.appendDummyInput()
            .appendField("[");
        this.appendValueInput("data")
            .setCheck(null)
        this.appendDummyInput()
            .appendField("]")
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(js_google_json_block_color);
        this.setTooltip("Light");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_json_block_key'] = function(block) {

    var src = Blockly.JavaScript.valueToCode(block, 'src', Blockly.JavaScript.ORDER_ATOMIC);
    var data = Blockly.JavaScript.valueToCode(block, 'data', Blockly.JavaScript.ORDER_ATOMIC);

    // 'を削除
    data = data.replace(/'/g, '');
    
    var code = src + '[' + data + ']';

    return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// dot
Blockly.Blocks['js_json_block_dot'] = {
    init: function() {
        this.appendValueInput("src")
            .setCheck(null)
        this.appendDummyInput()
            .appendField(".");
        this.appendValueInput("data")
            .setCheck(null)
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(js_google_json_block_color);
        this.setTooltip("Light");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_json_block_dot'] = function(block) {

    var src = Blockly.JavaScript.valueToCode(block, 'src', Blockly.JavaScript.ORDER_ATOMIC);
    var data = Blockly.JavaScript.valueToCode(block, 'data', Blockly.JavaScript.ORDER_ATOMIC);
    
    // 'を削除
    data = data.replace(/'/g, '');

    var code = src + '.' + data ;

    return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// stringify
Blockly.Blocks['js_json_block_stringify'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("stringify(");
        this.appendValueInput("data")
            .setCheck(null)
        this.appendDummyInput()
            .appendField(")")
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(js_google_json_block_color);
        this.setTooltip("Light");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_json_block_stringify'] = function(block) {

    var data = Blockly.JavaScript.valueToCode(block, 'data', Blockly.JavaScript.ORDER_ATOMIC);
    
    var code = 'JSON.stringify(' + data + ')';

    return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

// ///////////////////////////////////////////////////////////////////
// // http request
// Blockly.Blocks['js_json_block_httprequest'] = {
//     init: function() {
//         this.appendDummyInput()
//             .appendField("request(");
//         this.appendValueInput("data")
//             .setCheck(null)
//         this.appendDummyInput()
//             .appendField('Credentials')
//             .appendField(new Blockly.FieldDropdown([
//                 ['OFF', 'OFF'],
//                 ['ON', 'ON'],
//             ]), 'Credentials');
//         this.appendDummyInput()
//             .appendField(")")
//         this.setInputsInline(true);
//         this.setOutput(true, null);
//         this.setColour(js_google_json_block_color);
//         this.setTooltip("Light");
//         this.setHelpUrl("");
//     }
// };

// Blockly.JavaScript['js_json_block_httprequest'] = function(block) {

//     var data = Blockly.JavaScript.valueToCode(block, 'data', Blockly.JavaScript.ORDER_ATOMIC);
//     var Credentials = block.getFieldValue('Credentials');

//     // var code = `this.requests('https://yesno.wtf/api/')`;
//     var code = `this.requests('${data}',${Credentials == 'ON'})`;
    
//     return [code, Blockly.JavaScript.ORDER_ATOMIC];
// };
