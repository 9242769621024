import { initializeApp } from 'firebase/app';
import { getFirestore,
    collection,
    getDocs,
    getDoc,
    deleteDoc,
    doc,
    addDoc,
    query,
    setDoc,
    orderBy
} from 'firebase/firestore';
import { getAuth,
    signInWithEmailAndPassword,
    onAuthStateChanged,
    fetchSignInMethodsForEmail,
    EmailAuthProvider,
    sendPasswordResetEmail,
    createUserWithEmailAndPassword,
    sendEmailVerification,
    signOut,
    deleteUser } from 'firebase/auth';
import 'firebase/firestore';
import { getStorage, ref, uploadBytes, listAll, deleteObject, getDownloadURL } from 'firebase/storage';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { createHash } from 'crypto';

import 'date-utils';

// firebase アクセス情報
const firebaseConfig = {
    apiKey: process.env.VUE_APP_FB_APIKEY,
    authDomain: process.env.VUE_APP_FB_AUTHDOMAIN,
    databaseURL: process.env.VUE_APP_FB_DATABASEURL,
    projectId: process.env.VUE_APP_FB_PROJECTID,
    storageBucket: process.env.VUE_APP_FB_STORAGEBUCKET,
    messagingSenderId: process.env.VUE_APP_FB_MESSAGINGSENDERID,
    appId: process.env.VUE_APP_FB_APPID,
    // measurementId: process.env.VUE_APP_FB_MEASUREMENTID,
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const analytics = getAnalytics();
const auth = getAuth();

// firebase object
class firebaseObject {
        
    // セーブデータ用キーワード
    FIRE_STORE_KEYWORD = {
        DATAS: process.env.VUE_APP_FB_KW_DATAS,             // ルートキー
        PROJECTS: process.env.VUE_APP_FB_KW_PROJECTS,       // プロジェクトキー
        TITLE: process.env.VUE_APP_FB_KW_TITLE,             // 保存タイトル
        EXTENSION: process.env.VUE_APP_FB_KW_EXTENSION,     // 拡張子
        CODES: process.env.VUE_APP_FB_KW_CODES,             // 保存コード
        CODE: process.env.VUE_APP_FB_KW_CODE,               // 保存コード
        LOGINID: process.env.VUE_APP_FB_KW_LOGINID,         // ログインID
        COMMENT: process.env.VUE_APP_FB_KW_COMMENT,         // 保存コメント
        CREATED: process.env.VUE_APP_FB_KW_CREATED,         // 作成日時
        UPDATED: process.env.VUE_APP_FB_KW_UPDATED,         // 更新日時
        ACCOUNT: process.env.VUE_APP_FB_KW_ACCOUNT,         // アカウント情報
        CONFIG: process.env.VUE_APP_FB_KW_CONFIG,           // システム情報
        DELETE: process.env.VUE_APP_FB_KW_DELETE,           // 削除区分
    }

    // コンストラクタ
    constructor() {
    }
    // 初期化
    async initFirebase() {
    }
    async startFirebase() {
    }
    // Analytics開始
    startAnalytics_login() {
        // analytisc
        logEvent(analytics, 'notification_received_login');
    }
    // Analytics開始
    startAnalytics_fileview() {
        // analytisc
        logEvent(analytics, 'notification_received_fileview');
    }
    // Analytics開始
    startAnalytics_main() {
        // analytisc
        logEvent(analytics, 'notification_received_main');
    }
    // Analytics開始
    startAnalytics_link() {
        // analytisc
        logEvent(analytics, 'notification_received_link');
    }
    // ログイン
    async login(email, password) {

        var ret = false;

        // emailでサインイン
        await signInWithEmailAndPassword(auth, email, password)
        .then((/*result*/) => {
            ret = true;
        })
        .catch((error) => {
            // Handle Errors here.
            console.log('signInWithEmailAndPassword errorCode:' + error.code);
            console.log('signInWithEmailAndPassword errorMessage:' + error.message);

            ret = false;
        });

        return ret;
    }
    // ログオフ
    logoff() {
        signOut(auth).then(() => {
            // Sign-out successful.
        }).catch((error) => {
            // An error happened.
            console.log(error);
        });        
    }
    // ユーザー認証確認
    async isVerifyEmailAccount () {

        var ret = false;

        await onAuthStateChanged(auth, (user) => {
            if (user) {
                ret = user.emailVerified;
            } else {
                // ユーザー未登録
            }
        })

        return ret;
    }
    // サインオン
    async signon(email, password, messageCode) {
        var ret = false;
        var user = null;

        // emailでサインイン
        await createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            user = userCredential.user;
            ret = true;
            // アカウントレコードを作成
            this.writeAccountInfo(email);
            // /DATAS/アカウトの登録
            this.writeAccountDatas(email);
        })
        .catch((error) => {
            // Handle Errors here.
            console.log('createUserWithEmailAndPassword errorCode:' + error.code);
            console.log('createUserWithEmailAndPassword errorMessage:' + error.message);
            messageCode.code = error.code;
            return false;
        });

        // 確認メールを送信
        if (ret) {
            await sendEmailVerification(user)
            .then(() => {
                console.log('sendEmailVerification success:');
                ret = true;
            })
            .catch((error) => {
                console.log('sendEmailVerification errorCode:' + error.code);
                console.log('sendEmailVerification errorMessage:' + error.message);
                messageCode.code = error.code;
                return false;
            });
        }
        return ret;
    }
    // サインオン確認
    async isSignOn(email) {
        // 既にアカウントが登録されているかを調べる
        const providers = await fetchSignInMethodsForEmail(auth, email);
        if (providers.includes(EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD)) {
            return true;
        }

        return false;
    }
    // リセットパスワード
    async resetPassword(email) {
        await sendPasswordResetEmail(auth, email)
        .then(() => {
            // Email sent.
            return true;
        }).catch((error) => {
            // An error happened.
            console.log(error);
            return false;
        });
    }
    // アカウント削除
    async deleteAccount() {
        const user = auth.currentUser;
        deleteUser(user).then(() => {
            // User deleted.
        }).catch((error) => {
          // An error ocurred
            console.log(error);
        });        
    }
    // UUID
    getUUID() {
        return auth.currentUser.uid;
    }
    // /DATAS/アカウトの登録
    async writeAccountDatas(email) {
        await setDoc(doc(db, this.FIRE_STORE_KEYWORD.DATAS, email), {
        }).then(() => {
        }).catch((/*error*/) => {
        });
    }
    // /ACCOUNT/loginId/の削除
    async deleteAccountDatas(loginId) {
        return await deleteDoc(doc(db, this.FIRE_STORE_KEYWORD.ACCOUNT, loginId));
    }
    // /DATAS/PROJECTの登録
    async writeProjectDatas(loginId, projectName, codeLanguage) {

        // 更新日付
        var date = new Date().toFormat('YYYYMMDDHH24MISS');

        const docRef = await addDoc(collection(db, this.FIRE_STORE_KEYWORD.DATAS, loginId, this.FIRE_STORE_KEYWORD.PROJECTS), {
            PROJECTNAME: projectName,
            CREATED: date,
            UPDATED: date,
            DELETE: false,
            CODELANGUAGE: codeLanguage,
        });

        return docRef.id;
    }
    // /DATAS/PROJECTの削除
    async removeProjectDatas(loginId, projectName) {
        var key = '';

        key += this.FIRE_STORE_KEYWORD.DATAS;
        key += ('/' + loginId);
        key += ('/' + this.FIRE_STORE_KEYWORD.PROJECTS);
        key += ('/' + projectName);
        key += ('/' + this.FIRE_STORE_KEYWORD.CODES);

        // 配下データを削除
        const querySnapShot = await getDocs(query(collection(db, key)));
        await querySnapShot.forEach(row => {
            deleteDoc(doc(db, key + '/' + row.id));
        });
        // プロジェクトデータを削除
        return await deleteDoc(doc(db, this.FIRE_STORE_KEYWORD.DATAS, loginId, this.FIRE_STORE_KEYWORD.PROJECTS, projectName));
    }
    // /DATAS/の削除
    async removeDatas(loginId) {
        await deleteDoc(doc(db, this.FIRE_STORE_KEYWORD.DATAS, loginId));
    }
    // /DATAS/PROJECT/CODES/ITEMの登録
    async writeCodeDatas(loginId, projectName, title, extension, comment, code) {

        // 更新日付
        var date = new Date().toFormat('YYYYMMDDHH24MISS');

        const docRef = await addDoc(collection(db, this.FIRE_STORE_KEYWORD.DATAS, loginId, this.FIRE_STORE_KEYWORD.PROJECTS, projectName, this.FIRE_STORE_KEYWORD.CODES), {
            TITLE: title,
            EXTENSION: extension,
            COMMENT: comment,
            CODE: code,
            CREATED: date,
            UPDATED: date,
        });

        return docRef.id;
    }
    // /DATAS/PROJECT/CODES/ITEMの更新
    async updateCodeDatas(loginId, projectName, title, extension, comment, code, key, createdDate) {

        await setDoc(doc(db, this.FIRE_STORE_KEYWORD.DATAS, loginId, this.FIRE_STORE_KEYWORD.PROJECTS, projectName, this.FIRE_STORE_KEYWORD.CODES, key), {
            TITLE: title,
            EXTENSION: extension,
            COMMENT: comment,
            CODE: code,
            CREATED: createdDate,
            UPDATED: new Date().toFormat('YYYYMMDDHH24MISS'),
        });
    }
    // プロジェクト一覧読み込み
    async readProjectList(keywords) {
        var results = [];
        var key = '';

        for (let cnt=0; cnt<keywords.length; cnt++){
            key += ('/' + keywords[cnt]);
        }

        const querySnapShot = await getDocs(query(collection(db, key), orderBy('CREATED', 'asc')));
        await querySnapShot.forEach((row) => {
            if (row.data().DELETE == false){
                results.push({
                    ID: row.id,
                    PROJECTNAME: row.data().PROJECTNAME,
                    CODELANGUAGE: row.data().CODELANGUAGE,
                });
            }
        });

        return results;
    }
    // プロジェクト読み込み
    async readProjectDetail(keywords) {
        var results = [];
        var key = '';

        for (let cnt=0; cnt<keywords.length; cnt++){
            key += ('/' + keywords[cnt]);
        }

        // console.log('readProjectDetail 01:' + key);

        const querySnapShot = await getDocs(query(collection(db, key), orderBy('CREATED', 'asc')));

        // console.log('readProjectDetail 02');

        await querySnapShot.forEach((row) => {

            // console.log('readProjectDetail 03');

            // console.log(row.data());
            results.push({
                KEY: row.id,
                CODE: row.data().CODE,
                COMMENT: row.data().COMMENT,
                CREATED: row.data().CREATED,
                EXTENSION: row.data().EXTENSION,
                TITLE: row.data().TITLE,
                UPDATED: row.data().UPDATED,
            });
        });

        return results;
    }
    // プロジェクト数を取得
    async getProjectCount(keywords) {
        var results = 0;
        var key = '';

        for (let cnt=0; cnt<keywords.length; cnt++){
            key += ('/' + keywords[cnt]);
        }

        const querySnapShot = await getDocs(query(collection(db, key)));
        await querySnapShot.forEach(() => {
            results++;
        });

        return results;
    }
    // お知らせ読み込み
    async readNoticeDataList() {
        var results = [];
        var key = 'NOTICE';

        const querySnapShot = await getDocs(query(collection(db, key), orderBy('DATE', 'desc')));
        await querySnapShot.forEach((row) => {
            results.push(row.data());
        });

        return results;
    }
    // ログイン情報を読み込み
    async readAccountInfo (loginId) {
        var loginType = 0;
        var docSnap;

        docSnap = await getDoc(doc(db, this.FIRE_STORE_KEYWORD.ACCOUNT, loginId));
        loginType = Number(docSnap.data().LOGIN_TYPE);

        // ログイン情報
        var accountInf = {
            blocks: {
                HTML: false,
                JS_CHART: false,
                JS_DEBUG: false,
                JS_DRAW: false,
                JS_IMAGE: false,
                JS_TIMER: false,
                JS_INPUT: false,
                JS_MAP: false,
                JS_TAKAMATSU_OPEN_DATA: false,
            },
            env: {
                MAX_SAVE_COUNTS: 0,
                LOGIN_TYPE: loginType,
                EXT_LINK: 0,
            }
        };

        // Cloud Storeオブジェクトを作成
        // 利用可能ブロック設定を取得
        docSnap = await getDoc(doc(db, this.FIRE_STORE_KEYWORD.CONFIG, 'LOGIN_TYPE', String(loginType), 'BLOCKS'));
        // HTML Block
        accountInf.blocks.HTML = Boolean(docSnap.data().HTML);
        // JS Block
        accountInf.blocks.JS_CHART = Boolean(docSnap.data().JS_CHART);
        accountInf.blocks.JS_DEBUG = Boolean(docSnap.data().JS_DEBUG);
        accountInf.blocks.JS_DRAW = Boolean(docSnap.data().JS_DRAW);
        accountInf.blocks.JS_IMAGE = Boolean(docSnap.data().JS_IMAGE);
        accountInf.blocks.JS_TIMER = Boolean(docSnap.data().JS_TIMER);
        accountInf.blocks.JS_INPUT = Boolean(docSnap.data().JS_INPUT);
        accountInf.blocks.JS_MAP = Boolean(docSnap.data().JS_MAP);
        accountInf.blocks.JS_THREE = Boolean(docSnap.data().JS_THREE);
        accountInf.blocks.JS_MEDIAPIPE = Boolean(docSnap.data().JS_MEDIAPIPE);
        accountInf.blocks.JS_INPUT = Boolean(docSnap.data().JS_INPUT);
        accountInf.blocks.JS_AR = Boolean(docSnap.data().JS_AR);
        accountInf.blocks.PY_NUMPY = Boolean(docSnap.data().PY_NUMPY);
        accountInf.blocks.PY_PANDAS = Boolean(docSnap.data().PY_PANDAS);
        accountInf.blocks.JS_TAKAMATSU_OPEN_DATA = Boolean(docSnap.data().JS_TAKAMATSU_OPEN_DATA);
        accountInf.blocks.CS_CLASS = Boolean(docSnap.data().CS_CLASS);
        
        // python Block
        // cs Block

        // 実行環境設定を取得
        docSnap = await getDoc(doc(db, this.FIRE_STORE_KEYWORD.CONFIG, 'LOGIN_TYPE', String(loginType), 'ENV'));
        accountInf.env.MAX_SAVE_COUNTS = Number(docSnap.data().MAX_SAVE_COUNTS);

        return accountInf;
    }
    // ログイン情報を読み込み
    async writeAccountInfo (loginId) {
        // Cloud Storeオブジェクトを作成
        await setDoc(doc(db, this.FIRE_STORE_KEYWORD.ACCOUNT, loginId), {
            LOGIN_TYPE: 1,
        }).then(() => {
            console.log('writeAccountInfo success');
        }).catch((error) => {
            console.log('writeAccountInfo: write error: ' + error);
        });

        return true;
    }
    // アプリケーション設定情報を読み込み
    async readApplicationConfig () {
        var results = [];

        // Cloud Storeオブジェクトを作成
        const querySnapShot = await getDocs(collection(db, '/CONFIG'));
        querySnapShot.forEach((row) => {
            results.push({
                KEY: row.id,
                BETA_SET: row.data().BETA_SET,
                VERSION_MAJOR: row.data().VERSION_MAJOR,
                VERSION_MINOR: row.data().VERSION_MINOR,
                VERSION_REVISION: row.data().VERSION_REVISION,
            });
        });

        return results;
    }
    // アプリケーション設定情報を読み込み
    async readExternalLink (code) {

        var docSnap = await getDoc(doc(db, this.FIRE_STORE_KEYWORD.CONFIG, 'EXTERNAL_LINK', code, 'INFO'));

        var results = {
            DOCID: docSnap.data().DOCID,
            TITLE: docSnap.data().TITLE,
            CODELANGUAGE: docSnap.data().CODELANGUAGE,
        };
        
        return results;
    }
    // storageから画像データを読み込み
    readStorageImage(url) {
        var xhr = new XMLHttpRequest();
        xhr.open('GET', url, false);
        xhr.responseType = 'blob';
        xhr.send();
        if(xhr.status == 200){
            // // var urlUtil = window.URL || window.webkitURL;
            // var imgUrl = urlUtil.createObjectURL(this.response);
            // var link = document.createElement('a');
            // link.href=imgUrl;
            // link.download = fileName;
            // link.innerHTML = 'ここからダウンロードできるよ';
            // document.body.appendChild(link);
        }

        // var storage = firebase.storage();
        // // var pathReference = storage.ref('images/test.png');
        // // var pathReference = storage.child('images/test.png');
        // var pathReference = await storage.refFromURL('gs://bucket/images/test.png')

        // await pathReference.getDownloadURL().then(async function(url) {
        //     console.log('check: ' + url);
        //     return url;
        // }).catch(function(error) {
        //     console.log(error);
        // });

        // return new Promise(function (resolve, reject) {
        //     var storage = firebase.storage();
        //     var storageRef = storage.ref();
        //     var tangRef = storageRef.child('test.png');

        //     tangRef.getDownloadURL().then(function(url)                             {
        //         // console.error(url);
        //         resolve(String(url));
        //     }).catch(function(error) {
        //         console.error(error);
        //         reject(error);
        //     });
        // });

        // // .then(function(value){
        // //     console.log('ret:' + value);
        // //     return value;
        // // }).then(function(error){
        // //     console.log('ret:' + error);
        // //     return '';
        // // });

        // var storage = firebase.storage();
        // var storageRef = storage.ref();
        // var tangRef = storageRef.child('test.png');

        // tangRef.getDownloadURL().then(function(url)                             {
        //     return (String(url));
        // }).catch(function(error) {
        //     console.error(error);
        // });

        console.log('readStorageImage: begin');

        // var storage = firebase.storage();
        // var storageRef = storage.ref();
        // var tangRef = storageRef.child('test.png');

        // return await tangRef.getDownloadURL();

        return new Promise((resolve, /*reject*/) => {

            new Promise((resolve_, reject_) => {
                var storage = app.storage();
                var storageRef = storage.ref();
                var tangRef = storageRef.child('test.png');
    
                tangRef.getDownloadURL().then((url) => {
                    // console.error(url);
                    resolve_(String(url));
                }).catch((error) => {
                    console.error(error);
                    reject_(error);
                });
    
                console.log('readStorageImage: resolve: ');
    
            }).then((value) => {
                console.log('ret_:' + value);
                resolve(value);
            });

        // }).then(function(value){
        // }).catch(function(error){

        }).then((value) => {
            console.log('ret:' + value);
            return value;
        });

        // console.log('readStorageImage: fin');

        // new Promise(function (resolve, reject) {
        //     var storage = firebase.storage();
        //     var storageRef = storage.ref();
        //     var tangRef = storageRef.child('test.png');

        //     tangRef.getDownloadURL().then(function(url)                             {
        //         // console.error(url);
        //         resolve(String(url));
        //     }).catch(function(error) {
        //         console.error(error);
        //         reject(error);
        //     });

        //     console.log('readStorageImage: resolve');

        //     // resolve('aaa');

        // // }).then(function(value){
        // //     console.log('ret:' + value);
        // // }).catch(function(error){
        // //     console.log('error:' + error);
        // });

        // // var retUrl = '';

        // // firebase.storage().ref().child('test.png').getDownloadURL().then(function(url)                             {
        // //     retUrl = String(url);
        // //     console.log('retUrl: ' + retUrl);
        // //     return retUrl;
        // // }).catch(function(error) {
        // //     console.error(error);
        // // });

        // var ret = async function () {
        //     console.log('readStorageImage: 1');
        //     const ref = firebase.storage().ref('images/test.png');
        //     console.log('readStorageImage: 2');
        //     const url = await ref.getDownloadURL();
        //     console.log('readStorageImage: 3');
        //     const response = await fetch(url);
        //     console.log(response);
        // }

        // console.log(ret);
        // console.log('readStorageImage: fin');
        
        // return 'https://firebasestorage.googleapis.com/v0/b/broccoly-97269.appspot.com/o/test.png?alt=media&token=73d9c1c5-857d-4273-98f7-1e873d24b6cc';


        // console.log(pathReference);

        // // // Initialize Firebase
        // // // const app = firebase.initializeApp(this.firebaseConfig);
        // // // const storage = app.storage();
        // const storageRef = fbStorage.ref();
        // // // const imagesRef = storageRef.child('images');
        // // const downRef = storageRef.child('images/' /*+ filename*/ + 'test.png');

        // console.log(filename);
        // // console.log(downRef);

        // // downRef.fullpath

        // // downRef.getDownloadURL().then(async function(url) {
        // //     console.log('check: ' + url);
        // //     return url;
        // // }).catch(function(error) {
        // //     console.log(error);
        // // });

        // return 'https://firebasestorage.googleapis.com/v0/b/broccoly-97269.appspot.com/o/test.png?alt=media&token=73d9c1c5-857d-4273-98f7-1e873d24b6cc';

        // /*var gsReference =*/ storageRef.refFromURL('gs://bucket/images/test.png')

        // return '';

        // document.getElementById('bucket').innerHTML = 'Bucket: ' + storageRef.bucket;
        // return downRef.bucket;
        // return downRef.fullPath;
    }

    getStorageImagePath(imangeName){
        // Points to the root reference
        var storageRef = getStorage();

        // Points to 'images'
        var imagesRef = storageRef.child('image');

        // Points to 'images/space.jpg'
        // Note that you can use variables to create child values
        var fileName = imangeName;
        var spaceRef = imagesRef.child(fileName);

        // File path is 'images/space.jpg'
        var path = spaceRef.fullPath;

        return path;

        // File name is 'space.jpg'
        //var name = spaceRef.name;

        // Points to 'images'
        //var imagesRef = spaceRef.parent;
    }

    // storageへ書き込み
    async writeStorageImage(folder, filename, file) {

        // Points to the root reference
        var storageRef = getStorage();

        // const metadata = {
        //     contentType: 'image/png',
        // };

        // UUIDをHASHキーに変換
        var hashkey = createHash('sha1').update(folder).digest('hex');

        // Points to 'images'
        const imagesRef = ref(storageRef, 'upload_image/' + hashkey + '/' + filename);

        // uploadBytes(imagesRef, file/*, metadata*/).then((snapshot) => {
        //     console.log('Uploaded a blob or file!');
        //     console.log(snapshot);
        // });
        await uploadBytes(imagesRef, file/*, metadata*/);

        // ダウンロードURLを取得
        return await getDownloadURL(imagesRef);
    }

    // storageへ書き込み
    async writeStorageImage3D(folder, filename, file) {

        // Points to the root reference
        var storageRef = getStorage();

        // const metadata = {
        //     contentType: 'image/png',
        // };

        // UUIDをHASHキーに変換
        var hashkey = createHash('sha1').update(folder).digest('hex');

        // Points to 'images'
        const imagesRef = ref(storageRef, 'upload_image3D/' + hashkey + '/' + filename);

        // uploadBytes(imagesRef, file/*, metadata*/).then((snapshot) => {
        //     console.log('Uploaded a blob or file!');
        //     console.log(snapshot);
        // });
        await uploadBytes(imagesRef, file/*, metadata*/);

        // ダウンロードURLを取得
        return await getDownloadURL(imagesRef);
    }

    // storageのファイルを削除
    async removeAllStorageImage(folder) {

        // UUIDをHASHキーに変換
        var hashkey = createHash('sha1').update(folder).digest('hex');

        // Points to the root reference
        const storageRef = getStorage();
        // Create a reference under which you want to list
        const listRef = ref(storageRef, 'upload_image/' + hashkey);

        // Find all the prefixes and items.
        listAll(listRef)
        .then((res) => {
            res.prefixes.forEach((folderRef) => {
                console.log('Folder: ' + folderRef.name);
            });
            res.items.forEach((itemRef) => {
                console.log('File: ' + itemRef.name);
                {
                    // Create a reference to the file to delete
                    const desertRef = ref(storageRef, 'upload_image/' + hashkey + '/' + itemRef.name);

                    // Delete the file
                    deleteObject(desertRef).then(() => {
                        // File deleted successfully
                        console.log('File deleted!');
                    }).catch((error) => {
                        // Uh-oh, an error occurred!
                        console.log(error);
                    });
                }
            });
        }).catch((error) => {
            console.log(error);
        });
                
    }

    // storageのファイルを削除
    async removeAllStorageImage3D(folder) {

        // UUIDをHASHキーに変換
        var hashkey = createHash('sha1').update(folder).digest('hex');

        // Points to the root reference
        const storageRef = getStorage();
        // Create a reference under which you want to list
        const listRef = ref(storageRef, 'upload_image3D/' + hashkey);

        // Find all the prefixes and items.
        listAll(listRef)
        .then((res) => {
            res.prefixes.forEach((folderRef) => {
                console.log('Folder: ' + folderRef.name);
            });
            res.items.forEach((itemRef) => {
                console.log('File: ' + itemRef.name);
                {
                    // Create a reference to the file to delete
                    const desertRef = ref(storageRef, 'upload_image3D/' + hashkey + '/' + itemRef.name);

                    // Delete the file
                    deleteObject(desertRef).then(() => {
                        // File deleted successfully
                        console.log('File deleted!');
                    }).catch((error) => {
                        // Uh-oh, an error occurred!
                        console.log(error);
                    });
                }
            });
        }).catch((error) => {
            console.log(error);
        });
                
    }

    // storageのダウンロードリンクを取得
    async getAllStorageImage(folder) {

        var datas = [];

        // UUIDをHASHキーに変換
        const hashkey = createHash('sha1').update(folder).digest('hex');

        // Points to the root reference
        const storageRef = getStorage();
        // Create a reference under which you want to list
        const listRef = await ref(storageRef, 'upload_image/' + hashkey);

        // Find all the prefixes and items.
        await listAll(listRef)
        .then(async (res) => {
            await res.items.forEach(async (itemRef) => {
                // console.log('File: ' + itemRef.name);

                // const fileRef = await ref(storageRef, 'upload_image/' + hashkey + '/' + itemRef.name);

                // console.log('listAll00:');

                // const url = await getDownloadURL(fileRef);

                // console.log('listAll01:');

                //datas.push(url);
                datas.push({
                    filename: itemRef.name,
                    url: '',
                });

                // console.log('listAll02:');
                // console.log('getDownloadURL:' + url);
            });

            // return datas;

        }).catch((error) => {
            console.log(error);
        });

        return datas;
    }

    // storageのダウンロードリンクを取得
    async getAllStorageImage3D(folder) {

        var datas = [];

        // UUIDをHASHキーに変換
        const hashkey = createHash('sha1').update(folder).digest('hex');

        // Points to the root reference
        const storageRef = getStorage();
        // Create a reference under which you want to list
        const listRef = await ref(storageRef, 'upload_image3D/' + hashkey);

        // Find all the prefixes and items.
        await listAll(listRef)
        .then(async (res) => {
            await res.items.forEach(async (itemRef) => {
                datas.push({
                    filename: itemRef.name,
                    url: '',
                });
            });
        }).catch((error) => {
            console.log(error);
        });

        return datas;
    }

    // storageのダウンロードリンク件数を取得
    async getAllStorageImageCount(folder) {

        var count = 0;

        console.log('getAllStorageImageCount 00');

        // UUIDをHASHキーに変換
        var hashkey = createHash('sha1').update(folder).digest('hex');

        console.log('getAllStorageImageCount 01');

        // Points to the root reference
        const storageRef = getStorage();
        // Create a reference under which you want to list
        const listRef = ref(storageRef, 'upload_image/' + hashkey);

        console.log('getAllStorageImageCount 02');

        // Find all the prefixes and items.
        await listAll(listRef)
        .then(async (res) => {
            // await res.items.forEach(async (/*itemRef*/) => {
            //     count++;
            // });

            console.log('getAllStorageImageCount 99 - ' + res.items.length);

            // return count;
            count = res.items.length;

        }).catch((error) => {
            console.log(error);
        });

        console.log('getAllStorageImageCount 10');

        return count;
    }

    // storageのダウンロードリンク件数を取得
    async getAllStorageImageCount3D(folder) {

        var count = 0;

        console.log('getAllStorageImageCount3D 00');

        // UUIDをHASHキーに変換
        var hashkey = createHash('sha1').update(folder).digest('hex');

        console.log('getAllStorageImageCount3D 01');

        // Points to the root reference
        const storageRef = getStorage();
        // Create a reference under which you want to list
        const listRef = ref(storageRef, 'upload_image3D/' + hashkey);

        console.log('getAllStorageImageCount3D 02');

        // Find all the prefixes and items.
        await listAll(listRef)
        .then(async (res) => {
            // await res.items.forEach(async (/*itemRef*/) => {
            //     count++;
            // });

            console.log('getAllStorageImageCount3D 99 - ' + res.items.length);

            // return count;
            count = res.items.length;

        }).catch((error) => {
            console.log(error);
        });

        console.log('getAllStorageImageCount3D 10');

        return count;
    }

    // storageのダウンロードリンクを取得
    async getDownloadImage(folder, filename) {

        // UUIDをHASHキーに変換
        const hashkey = createHash('sha1').update(folder).digest('hex');

        // Points to the root reference
        const storageRef = getStorage();
        // Create a reference under which you want to list
        // const listRef = await ref(storageRef, 'upload_image/' + hashkey);
        const fileRef = await ref(storageRef, 'upload_image/' + hashkey + '/' + filename);

        const url = await getDownloadURL(fileRef);

        // console.log('getDownloadImage:' + url);

        return url;
    }

    // storageのダウンロードリンクを取得
    async getDownloadImage3D(folder, filename) {

        // UUIDをHASHキーに変換
        const hashkey = createHash('sha1').update(folder).digest('hex');
        // Points to the root reference
        const storageRef = getStorage();
        // Create a reference under which you want to list
        // const listRef = await ref(storageRef, 'upload_image/' + hashkey);
        const fileRef = await ref(storageRef, 'upload_image3D/' + hashkey + '/' + filename);
        const url = await getDownloadURL(fileRef);

        return url;
    }

}

export default firebaseObject;


