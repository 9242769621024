<template>
    <v-container>

        <v-row align='center'>
            <v-subheader>新規アカウントを登録します。<br>次のメールアドレス、パスワードを入力してください。</v-subheader>
        </v-row>
        <v-row align='center'>
            <v-subheader></v-subheader>
        </v-row>
        <v-row align='center'>
            <v-subheader></v-subheader>
        </v-row>

        <v-row align='center'>
            <!-- 余白カラム -->
            <v-col
                cols='3'
                align='center'
            />
            <!-- フォームカラム -->
            <v-col
                cols='6'
                align='right'
            >
            <v-form
                ref='form'
                v-model="valid"
            >
            <v-text-field
                v-model="email"
                :rules="emailRules"
                label='メールアドレス'
                required
                ref='loginId'
                @keyup.enter="keyTabNext(0)"
            ></v-text-field>
            <v-text-field
                v-model="password"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="passwordRules"
                :type="show1 ? 'text' : 'password'"
                label='パスワード'
                required
                ref='password'
                @click:append="show1 = !show1"
                @keyup.enter="keyTabNext(1)"
            ></v-text-field>
            <v-text-field
                v-model="passwordVerification"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="passwordRules"
                :type="show1 ? 'text' : 'password'"
                label='確認用パスワード'
                required
                ref='passwordVerification'
                @click:append="show1 = !show1"
                @keyup.enter="keydown()"
            ></v-text-field>
            <v-btn
                :disabled="!valid"
                color='success'
                class='mr-4'
                @click="signon"
            >
                登録する
            </v-btn>
            <v-btn
                color='warning'
                class='mr-4'
                @click="reset"
            >
                リセット
            </v-btn>
            <v-btn
                text
                class='mr-4'
                @click="back"
            >
                もどる
            </v-btn>
            </v-form>
            </v-col>
            <!-- 余白カラム -->
            <v-col
                cols='3'
                align='center'
            />
        </v-row>
    </v-container>
</template>

<script>
import swal from 'sweetalert2'
import { swalIcon, swalTitle } from './../module/defines';

export default {

    name: 'Signon',

    // firebaseオブジェクト
    fbo : null,

    data() {
        return {
            valid: true,
            email: '',
            emailRules: [
                v => !!v || 'メールアドレスを入力してください',
                //v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ],
            password: '',
            passwordVerification: '',
            passwordRules: [
                v => !!v || 'パスワードを入力してください',
                // v => /.+@.+\..+/.test(v) || 'Password must be valid',
            ],

            // パスワードマスク
            show1: false,
        };
    },

    // computed: {
    // },

    created() {
        // firebaseオブジェクト
        this.fbo = this.$store.state.firebaseObject;

        if (this.fbo == null) {
            console.log('firebaseObject is null');
        }

        // 初期フォーカス設定
        setTimeout(() => {
            this.$refs.loginId.focus();
        }, 100);
    },

    methods: {

        // ログイン処理
        async signon() {
            
            // 入力不可に
            this.valid = false;

            this.$refs.form.validate()

            console.log('signon id:' + this.email);

            // オブジェクトの存在確認
            if (this.fbo == null){
                swal.fire({
                    title: swalTitle.INFO,
                    text: 'システムの異常が発生しました。',
                    icon: swalIcon.ERROR,
                });
                // 入力可に
                this.valid = true;
                // 処理を中断
                return;
            }

            // 入力パスワードの確認
            if (this.password != this.passwordVerification) {
                // エラーメッセージをを表示
                swal.fire({
                    title: swalTitle.INFO,
                    text: '入力されたパスワードと確認パスワードが一致しません。',
                    icon: swalIcon.WARNING,
                });
                // 入力可に
                this.valid = true;
                // 処理を中断
                return;
            }

            try {
                // 既にアカウントが登録されているかを調べる
                if (await this.fbo.isSignOn(this.email)){
                    swal.fire({
                        title: swalTitle.INFO,
                        text: 'このメールアドレスはすでに登録されています。',
                        icon: swalIcon.INFO,
                    });
                    // 入力可に
                    this.valid = true;
                    // 処理を中断
                    return;
                }

                let messageCode = {
                    code:'',
                    message:'',
                };

                // 登録処理
                if (await this.fbo.signon(this.email, this.password, messageCode) == true){
                    // アカウント情報を追加
                    await this.fbo.writeAccountInfo(this.email);

                    // 確認ダイアログ
                    swal.fire({
                        title: swalTitle.INFO,
                        text: '登録されたメールアドレスに確認メールを送信しました。',
                        icon: swalIcon.INFO,
                    });
                }else{
                    console.log('signon base:' + messageCode.code);

                    // エラーメッセージをを表示
                    var messageText = '';
                    if (messageCode.code == 'auth/weak-password'){
                        messageText = '6文字以上のパスワードを設定してください。';
                    }
                    swal.fire({
                        title: swalTitle.INFO,
                        text: messageText,
                        icon: swalIcon.WARNING,
                    });
                }
                // 入力可に
                this.valid = true;
            }catch(e){
                console.log(e);
            }

        },
        // リセット処理
        reset() {
            this.$refs.form.reset()
        },
        // もどる処理
        back() {
            // child -> parent
            this.$emit('signonProc', 'back');
        },
        // 警告リセット
        resetValidation() {
            this.$refs.form.resetValidation()
        },
        // 次のコントロールをフォーカス
        keyTabNext(event) {
            // フォーカス
            if (event == 0) {
                this.$refs.password.focus();
            }else{
                this.$refs.passwordVerification.focus();
            }
        },
        // キーダウンイベント
        keydown() {
            this.signon();
        },
    },
}
</script>
