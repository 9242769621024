import * as Blockly from 'blockly/core';

// ブロックの配色
var python_pandas_block_color = 210;

// Pandasブロック定義
const category_python_pandas = `
    <category name="pandas" colour="210">
        <block type="python_pandas_block_dataframe">
        </block>
        <block type="python_pandas_block_insert">
            <value name="column_index">
                <block type="math_number">
                    <field name="NUM">0</field>
                </block>
            </value>
            <value name="column_name">
                <block type="text">
                    <field name="TEXT">列名</field>
                </block>
            </value>
        </block>
        <block type="python_pandas_block_head">
        </block>
        <block type="python_pandas_block_tail">
        </block>
        <block type="python_pandas_block_columns">
        </block>

        <block type="python_pandas_block_get_row">
            <value name="row_index">
                <block type="math_number">
                    <field name="NUM">0</field>
                </block>
            </value>
        </block>
        
        <block type="python_pandas_block_isnull">
        </block>
        <block type="python_pandas_block_dropna">
        </block>
        <block type="python_pandas_block_drop">
            <value name="index">
                <block type="math_number">
                    <field name="NUM">0</field>
                </block>
            </value>
        </block>
        <block type="python_pandas_block_drop_range">
            <value name="index_first">
                <block type="math_number">
                    <field name="NUM">0</field>
                </block>
            </value>
            <value name="index_last">
                <block type="math_number">
                    <field name="NUM">0</field>
                </block>
            </value>
        </block>

        <block type="python_pandas_block_loc">
            <value name="row">
                <block type="math_number">
                    <field name="NUM">0</field>
                </block>
            </value>
            <value name="col">
                <block type="math_number">
                    <field name="NUM">0</field>
                </block>
            </value>
        </block>
        <block type="python_pandas_block_iloc">
            <value name="row">
                <block type="math_number">
                    <field name="NUM">0</field>
                </block>
            </value>
            <value name="col">
                <block type="math_number">
                    <field name="NUM">0</field>
                </block>
            </value>
        </block>
        <block type="python_pandas_block_sort">
            <value name="col">
                <block type="math_number">
                    <field name="NUM">0</field>
                </block>
            </value>
        </block>
        <block type="python_pandas_block_copy">
        </block>

    </category>`;

export {category_python_pandas};


///////////////////////////////////////////////////////////////////
// DataFrame
Blockly.Blocks['python_pandas_block_dataframe'] = {
    init: function() {
        this.appendDummyInput()
            .appendField('DataFrame')
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(python_pandas_block_color);
        this.setTooltip("DataFrame");
        this.setHelpUrl("");
    }
};

Blockly.Python['python_pandas_block_dataframe'] = function(/*block*/) {

    Blockly.Python.definitions_['import_pandas'] = 'import pandas as pd';

    var code = 'pd.DataFrame()';

    return [code, Blockly.Python.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// insert
Blockly.Blocks['python_pandas_block_insert'] = {
    init: function() {
        this.appendValueInput("objVar")
            .appendField("変数")
            .setCheck(null)
        this.appendDummyInput()
            .appendField('.insert(')
        this.appendValueInput("column_index")
            .appendField("列番号:")
            .setCheck("Number")
        this.appendValueInput("column_name")
            .appendField("列名:")
        this.appendValueInput("dataVar")
            .appendField("データ:")
            .setCheck(null)
        this.appendDummyInput()
            .appendField(')')
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(python_pandas_block_color);
        this.setTooltip("insert");
        this.setHelpUrl("");
    }
};

Blockly.Python['python_pandas_block_insert'] = function(block) {

    Blockly.Python.definitions_['import_pandas'] = 'import pandas as pd';

    var objVar = Blockly.Python.valueToCode(block, 'objVar', Blockly.Python.ORDER_ATOMIC);
    var columnIndex = Blockly.Python.valueToCode(block, 'column_index', Blockly.Python.ORDER_ATOMIC);
    var columnName = Blockly.Python.valueToCode(block, 'column_name', Blockly.Python.ORDER_ATOMIC);
    var dataVar = Blockly.Python.valueToCode(block, 'dataVar', Blockly.Python.ORDER_ATOMIC);

    var code = objVar + '.insert(' + columnIndex + ',' + columnName + ',' + dataVar + ')\n';

    return code;
};

///////////////////////////////////////////////////////////////////
// head
Blockly.Blocks['python_pandas_block_head'] = {
    init: function() {
        this.appendValueInput("objVar")
            .appendField("変数")
            .setCheck(null)
        this.appendDummyInput()
            .appendField('.head()')
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(python_pandas_block_color);
        this.setTooltip("head");
        this.setHelpUrl("");
    }
};

Blockly.Python['python_pandas_block_head'] = function(block) {

    Blockly.Python.definitions_['import_pandas'] = 'import pandas as pd';

    var objVar = Blockly.Python.valueToCode(block, 'objVar', Blockly.Python.ORDER_ATOMIC);

    var code = objVar + '.head()';

    return [code, Blockly.Python.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// tail
Blockly.Blocks['python_pandas_block_tail'] = {
    init: function() {
        this.appendValueInput("objVar")
            .appendField("変数")
            .setCheck(null)
        this.appendDummyInput()
            .appendField('.tail()')
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(python_pandas_block_color);
        this.setTooltip("tail");
        this.setHelpUrl("");
    }
};

Blockly.Python['python_pandas_block_tail'] = function(block) {

    Blockly.Python.definitions_['import_pandas'] = 'import pandas as pd';

    var objVar = Blockly.Python.valueToCode(block, 'objVar', Blockly.Python.ORDER_ATOMIC);

    var code = objVar + '.tail()';

    return [code, Blockly.Python.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// columns
Blockly.Blocks['python_pandas_block_columns'] = {
    init: function() {
        this.appendValueInput("objVar")
            .appendField("変数")
            .setCheck(null)
        this.appendDummyInput()
            .appendField('.columns')
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(python_pandas_block_color);
        this.setTooltip("columns");
        this.setHelpUrl("");
    }
};

Blockly.Python['python_pandas_block_columns'] = function(block) {

    Blockly.Python.definitions_['import_pandas'] = 'import pandas as pd';

    var objVar = Blockly.Python.valueToCode(block, 'objVar', Blockly.Python.ORDER_ATOMIC);

    var code = objVar + '.columns';

    return [code, Blockly.Python.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// get rows first
Blockly.Blocks['python_pandas_block_get_row'] = {
    init: function() {
        this.appendValueInput("objVar")
            .appendField("変数")
            .setCheck(null)
        this.appendDummyInput()
            .appendField('位置:')
            .appendField(new Blockly.FieldDropdown([
                ['先頭', 'True'],
                ['末尾', 'False'],
            ]), 'dataPos');
        this.appendValueInput("row_index")
            .appendField("行番号")
            .setCheck("Number")
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(python_pandas_block_color);
        this.setTooltip("get rows");
        this.setHelpUrl("");
    }
};

Blockly.Python['python_pandas_block_get_row'] = function(block) {

    Blockly.Python.definitions_['import_pandas'] = 'import pandas as pd';

    var objVar = Blockly.Python.valueToCode(block, 'objVar', Blockly.Python.ORDER_ATOMIC);
    var rowIndex = Blockly.Python.valueToCode(block, 'row_index', Blockly.Python.ORDER_ATOMIC);
    var dataPos = block.getFieldValue('dataPos');

    var code = '';
    if (dataPos == 'True'){
        code = objVar + '[:' + rowIndex + ']';
    }else{
        code = objVar + '[' + rowIndex + ':]';
    }

    return [code, Blockly.Python.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// isnull
Blockly.Blocks['python_pandas_block_isnull'] = {
    init: function() {
        this.appendValueInput("objVar")
            .appendField("変数")
            .setCheck(null)
        this.appendDummyInput()
            .appendField('.isnull()')
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(python_pandas_block_color);
        this.setTooltip("isnull");
        this.setHelpUrl("");
    }
};

Blockly.Python['python_pandas_block_isnull'] = function(block) {

    Blockly.Python.definitions_['import_pandas'] = 'import pandas as pd';

    var objVar = Blockly.Python.valueToCode(block, 'objVar', Blockly.Python.ORDER_ATOMIC);

    var code = objVar + '.isnull()';

    return [code, Blockly.Python.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// dropna
Blockly.Blocks['python_pandas_block_dropna'] = {
    init: function() {
        this.appendValueInput("objVar")
            .appendField("変数")
            .setCheck(null)
        this.appendDummyInput()
            .appendField('dropna()')
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(python_pandas_block_color);
        this.setTooltip("dropna");
        this.setHelpUrl("");
    }
};

Blockly.Python['python_pandas_block_dropna'] = function(block) {

    Blockly.Python.definitions_['import_pandas'] = 'import pandas as pd';

    var objVar = Blockly.Python.valueToCode(block, 'objVar', Blockly.Python.ORDER_ATOMIC);

    var code = objVar + '.dropna()';

    return [code, Blockly.Python.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// drop row
Blockly.Blocks['python_pandas_block_drop'] = {
    init: function() {
        this.appendValueInput("objVar")
            .appendField("変数")
            .setCheck(null)
        this.appendDummyInput()
            .appendField('.drop(')
        this.appendDummyInput()
            .appendField('並び順:')
            .appendField(new Blockly.FieldDropdown([
                ['行', 'True'],
                ['列', 'False'],
            ]), 'target');
        this.appendValueInput("index")
            .appendField("番号")
            .setCheck("Number")
        this.appendDummyInput()
            .appendField(')')
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(python_pandas_block_color);
        this.setTooltip("drop");
        this.setHelpUrl("");
    }
};

Blockly.Python['python_pandas_block_drop'] = function(block) {

    Blockly.Python.definitions_['import_pandas'] = 'import pandas as pd';

    var objVar = Blockly.Python.valueToCode(block, 'objVar', Blockly.Python.ORDER_ATOMIC);
    var index = Blockly.Python.valueToCode(block, 'index', Blockly.Python.ORDER_ATOMIC);
    var target = block.getFieldValue('target');

    var code = '';

    if (target == 'True'){
        code = objVar + '.drop(index=' + index + ',axis=0)';
    }else{
        code = objVar + '.drop(' + objVar + '.columns[' + index + '],axis=1)';
    }

    return [code, Blockly.Python.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// drop row range
Blockly.Blocks['python_pandas_block_drop_range'] = {
    init: function() {
        this.appendValueInput("objVar")
            .appendField("変数")
            .setCheck(null)
        this.appendDummyInput()
            .appendField('.drop(')
        this.appendDummyInput()
            .appendField('並び順:')
            .appendField(new Blockly.FieldDropdown([
                ['行', 'True'],
                ['列', 'False'],
            ]), 'target');
        this.appendValueInput("index_first")
            .appendField("番号")
            .setCheck("Number")
        this.appendValueInput("index_last")
            .appendField("から")
            .setCheck("Number")
        this.appendDummyInput()
            .appendField(')')
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(python_pandas_block_color);
        this.setTooltip("drop");
        this.setHelpUrl("");
    }
};

Blockly.Python['python_pandas_block_drop_range'] = function(block) {

    Blockly.Python.definitions_['import_pandas'] = 'import pandas as pd';

    var objVar = Blockly.Python.valueToCode(block, 'objVar', Blockly.Python.ORDER_ATOMIC);
    var index_first = Blockly.Python.valueToCode(block, 'index_first', Blockly.Python.ORDER_ATOMIC);
    var index_last = Blockly.Python.valueToCode(block, 'index_last', Blockly.Python.ORDER_ATOMIC);
    var target = block.getFieldValue('target');

    var code = '';

    if (target == 'True'){
        code = objVar + '.drop(index=range(' + index_first + ',' + index_last + '),axis=0)';
    }else{
        code = objVar + '.drop(' + objVar + '.columns[range(' + index_first + ',' + index_last + ')],axis=1)';
    }

    return [code, Blockly.Python.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// loc
Blockly.Blocks['python_pandas_block_loc'] = {
    init: function() {
        this.appendValueInput("objVar")
            .appendField("変数")
            .setCheck(null)
        this.appendDummyInput()
            .appendField('.loc(')
        this.appendValueInput("row")
            .appendField("行番号")
            .setCheck("Number")
        this.appendValueInput("col")
            .appendField("列番号")
        this.appendDummyInput()
            .appendField(')')
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(python_pandas_block_color);
        this.setTooltip("dropna");
        this.setHelpUrl("");
    }
};

Blockly.Python['python_pandas_block_loc'] = function(block) {

    Blockly.Python.definitions_['import_pandas'] = 'import pandas as pd';

    var objVar = Blockly.Python.valueToCode(block, 'objVar', Blockly.Python.ORDER_ATOMIC);
    var row = Blockly.Python.valueToCode(block, 'row', Blockly.Python.ORDER_ATOMIC);
    var col = Blockly.Python.valueToCode(block, 'col', Blockly.Python.ORDER_ATOMIC);

    var code = '';

    if (row == ':' && col != ':'){
        code = objVar + '.loc[' + row + ',' + col + ']';
    }else if (row != ':' && col == ':'){
        code = objVar + '.loc[' + row + ',' + col + ']';
    }else{
        code = objVar + '.loc[' + row + ':' + col + ']';
    }

    return [code, Blockly.Python.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// iloc
Blockly.Blocks['python_pandas_block_iloc'] = {
    init: function() {
        this.appendValueInput("objVar")
            .appendField("変数")
            .setCheck(null)
        this.appendDummyInput()
            .appendField('.iloc(')
        this.appendValueInput("row")
            .appendField("行番号")
            .setCheck("Number")
        this.appendValueInput("col")
            .appendField("列番号")
        this.appendDummyInput()
            .appendField(')')
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(python_pandas_block_color);
        this.setTooltip("dropna");
        this.setHelpUrl("");
    }
};

Blockly.Python['python_pandas_block_iloc'] = function(block) {

    Blockly.Python.definitions_['import_pandas'] = 'import pandas as pd';

    var objVar = Blockly.Python.valueToCode(block, 'objVar', Blockly.Python.ORDER_ATOMIC);
    var row = Blockly.Python.valueToCode(block, 'row', Blockly.Python.ORDER_ATOMIC);
    var col = Blockly.Python.valueToCode(block, 'col', Blockly.Python.ORDER_ATOMIC);

    var code = '';

    if (row == ':' && col != ':'){
        code = objVar + '.iloc[' + row + ',' + col + ']';
    }else if (row != ':' && col == ':'){
        code = objVar + '.iloc[' + row + ',' + col + ']';
    }else{
        code = objVar + '.iloc[' + row + ':' + col + ']';
    }

    return [code, Blockly.Python.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// sort
Blockly.Blocks['python_pandas_block_sort'] = {
    init: function() {
        this.appendDummyInput()
            .appendField('ソート')
        this.appendValueInput("objVar")
            .appendField("変数")
            .setCheck(null)
        this.appendValueInput("col")
            .appendField("列番号")
            .setCheck("Number")
        this.appendDummyInput()
            .appendField('並び順:')
            .appendField(new Blockly.FieldDropdown([
                ['昇順', 'True'],
                ['降順', 'False'],
            ]), 'sorter');
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(python_pandas_block_color);
        this.setTooltip("dropna");
        this.setHelpUrl("");
    }
};

Blockly.Python['python_pandas_block_sort'] = function(block) {

    Blockly.Python.definitions_['import_pandas'] = 'import pandas as pd';

    var objVar = Blockly.Python.valueToCode(block, 'objVar', Blockly.Python.ORDER_ATOMIC);
    var col = Blockly.Python.valueToCode(block, 'col', Blockly.Python.ORDER_ATOMIC);
    var sorter = block.getFieldValue('sorter');

    var code = objVar + '.sort_values(by=' + objVar + '.columns[' + col + '],ascending=' + sorter + ')';

    return [code, Blockly.Python.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// copy
Blockly.Blocks['python_pandas_block_copy'] = {
    init: function() {
        this.appendValueInput("objVar")
            .appendField("変数")
            .setCheck(null)
        this.appendDummyInput()
            .appendField('.copy()')
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(python_pandas_block_color);
        this.setTooltip("head");
        this.setHelpUrl("");
    }
};

Blockly.Python['python_pandas_block_copy'] = function(block) {

    Blockly.Python.definitions_['import_pandas'] = 'import pandas as pd';

    var objVar = Blockly.Python.valueToCode(block, 'objVar', Blockly.Python.ORDER_ATOMIC);

    var code = objVar + '.copy()';

    return [code, Blockly.Python.ORDER_ATOMIC];
};
