import * as Blockly from 'blockly/core';

// ブロックの配色
var js_draw_block_color = 200;

// Chartブロック定義
const category_Draw = `
    <category name="描画" colour="200">
        <block type="js_draw_canvas"></block>
        <block type="js_draw_clearrect">
            <value name="posX_1">
                <block type="math_number">
                    <field name="NUM">10</field>
                </block>
            </value>
            <value name="posY_1">
                <block type="math_number">
                    <field name="NUM">10</field>
                </block>
            </value>
            <value name="posX_2">
                <block type="math_number">
                    <field name="NUM">100</field>
                </block>
            </value>
            <value name="posY_2">
                <block type="math_number">
                    <field name="NUM">100</field>
                </block>
            </value>
        </block>
        <block type="js_draw_font"></block>
        <block type="js_draw_fillStyle"></block>
        <block type="js_draw_filltext">
            <value name="text">
                <block type="text">
                    <field name="TEXT">abc</field>
                </block>
            </value>
            <value name="posX">
                <block type="math_number">
                    <field name="NUM">10</field>
                </block>
            </value>
            <value name="posY">
                <block type="math_number">
                    <field name="NUM">10</field>
                </block>
            </value>
        </block>
        <block type="js_draw_fillrect">
            <value name="posX_1">
                <block type="math_number">
                    <field name="NUM">10</field>
                </block>
            </value>
            <value name="posY_1">
                <block type="math_number">
                    <field name="NUM">10</field>
                </block>
            </value>
            <value name="posX_2">
                <block type="math_number">
                    <field name="NUM">100</field>
                </block>
            </value>
            <value name="posY_2">
                <block type="math_number">
                    <field name="NUM">100</field>
                </block>
            </value>
        </block>
        <block type="js_draw_strokeStyle"></block>
        <block type="js_draw_stroketext">
            <value name="text">
                <block type="text">
                    <field name="TEXT">abc</field>
                </block>
            </value>
            <value name="posX">
                <block type="math_number">
                    <field name="NUM">10</field>
                </block>
            </value>
            <value name="posY">
                <block type="math_number">
                    <field name="NUM">10</field>
                </block>
            </value>
        </block>
        <block type="js_draw_strokerect">
            <value name="posX_1">
                <block type="math_number">
                    <field name="NUM">10</field>
                </block>
            </value>
            <value name="posY_1">
                <block type="math_number">
                    <field name="NUM">10</field>
                </block>
            </value>
            <value name="posX_2">
                <block type="math_number">
                    <field name="NUM">100</field>
                </block>
            </value>
            <value name="posY_2">
                <block type="math_number">
                    <field name="NUM">100</field>
                </block>
            </value>
        </block>
        <block type="js_draw_moveto">
            <value name="posX">
                <block type="math_number">
                    <field name="NUM">10</field>
                </block>
            </value>
            <value name="posY">
                <block type="math_number">
                    <field name="NUM">10</field>
                </block>
            </value>
        </block>
        <block type="js_draw_lineto">
            <value name="posX">
                <block type="math_number">
                    <field name="NUM">10</field>
                </block>
            </value>
            <value name="posY">
                <block type="math_number">
                    <field name="NUM">10</field>
                </block>
            </value>
        </block>
        <block type="js_draw_arc">
            <value name="posX">
                <block type="math_number">
                    <field name="NUM">10</field>
                </block>
            </value>
            <value name="posY">
                <block type="math_number">
                    <field name="NUM">10</field>
                </block>
            </value>
            <value name="radius">
                <block type="math_number">
                    <field name="NUM">10</field>
                </block>
            </value>
            <value name="startAngle">
                <block type="math_number">
                    <field name="NUM">10</field>
                </block>
            </value>
            <value name="endAngle">
                <block type="math_number">
                    <field name="NUM">10</field>
                </block>
            </value>
        </block>
    </category>`;

export {category_Draw};

///////////////////////////////////////////////////////////////////
// filltext()
Blockly.Blocks['js_draw_filltext'] = {
    init: function() {
        this.appendValueInput("text")
            .appendField("文字（塗りつぶし）")
            .appendField("Text")
            .setCheck("String")
        this.appendValueInput("posX")
            .appendField("X座標")
            .setCheck("Number")
        this.appendValueInput("posY")
            .appendField("Y座標")
            .setCheck("Number")
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(js_draw_block_color);
        this.setTooltip("FILL TEXT");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_draw_filltext'] = function(block) {
    // var text = Blockly.JavaScript.valueToCode(block, 'text', Blockly.JavaScript.ORDER_ATOMIC);
    var text = Blockly.JavaScript.valueToCode(block, 'text', Blockly.JavaScript.ORDER_NONE)||"''";
    var posX = Blockly.JavaScript.valueToCode(block, 'posX', Blockly.JavaScript.ORDER_ATOMIC);
    var posY = Blockly.JavaScript.valueToCode(block, 'posY', Blockly.JavaScript.ORDER_ATOMIC);

    // NULLを置換
    if (text == null || posX == null || posY == null) {
        text = '';
    }

    var code = 'context.fillText(' + text + ',' + posX + ',' + posY + ');\n';
    // console.log('js_draw_filltext: ' + code);

    return code;
};

///////////////////////////////////////////////////////////////////
// stroketext()
Blockly.Blocks['js_draw_stroketext'] = {
    init: function() {
        this.appendValueInput("text")
            .appendField("文字（枠のみ）")
            .setCheck("String")
        this.appendValueInput("posX")
            .appendField("X座標")
            .setCheck("Number")
        this.appendValueInput("posY")
            .appendField("Y座標")
            .setCheck("Number")
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(js_draw_block_color);
        this.setTooltip("STROKE TEXT");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_draw_stroketext'] = function(block) {
    var text = Blockly.JavaScript.valueToCode(block, 'text', Blockly.JavaScript.ORDER_ATOMIC);
    var posX = Blockly.JavaScript.valueToCode(block, 'posX', Blockly.JavaScript.ORDER_ATOMIC);
    var posY = Blockly.JavaScript.valueToCode(block, 'posY', Blockly.JavaScript.ORDER_ATOMIC);

    // NULLを置換
    if (text == null || posX == null || posY == null) {
        text = '';
    }

    var code = 'context.strokeText(' + text + ',' + posX + ',' + posY + ');\n';
    // console.log('js_draw_strokeText: ' + code);

    return code;
};

///////////////////////////////////////////////////////////////////
// strokeStyle()
Blockly.Blocks['js_draw_strokeStyle'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("枠の色")
            .appendField(new Blockly.FieldColour("#ff0000"), "Color")
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(js_draw_block_color);
        this.setTooltip("STROKE STYLE");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_draw_strokeStyle'] = function(block) {
    var colour_color = block.getFieldValue('Color');
    var code = 'context.strokeStyle=\'' + colour_color + '\';\n';
    // console.log('js_draw_strokeStyle: ' + code);

    return code;
};

///////////////////////////////////////////////////////////////////
// fillStyle()
Blockly.Blocks['js_draw_fillStyle'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("塗りつぶしの色")
            .appendField(new Blockly.FieldColour("#ff0000"), "Color")
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(js_draw_block_color);
        this.setTooltip("FILL STYLE");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_draw_fillStyle'] = function(block) {
    var colour_color = block.getFieldValue('Color');
    var code = 'context.fillStyle=\'' + colour_color + '\';\n';
    // console.log('js_draw_fillStyle: ' + code);

    return code;
};

///////////////////////////////////////////////////////////////////
// font()
Blockly.Blocks['js_draw_font'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("文字の大きさ")
            .appendField(new Blockly.FieldNumber("12"), "fontSize")
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(js_draw_block_color);
        this.setTooltip("FONT SIZE");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_draw_font'] = function(block) {
    var font_size = block.getFieldValue('fontSize');
    var code = 'context.font=\'' + font_size + 'px Arial\';\n';
    // console.log('js_draw_font: ' + code);

    return code;
};

///////////////////////////////////////////////////////////////////
// lineto()
Blockly.Blocks['js_draw_lineto'] = {
    init: function() {
        this.appendValueInput("posX")
            .appendField("直線（つづき）")
            .appendField("X座標")
            .setCheck("Number")
        this.appendValueInput("posY")
            .appendField("Y座標")
            .setCheck("Number")
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(js_draw_block_color);
        this.setTooltip("LINETO");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_draw_lineto'] = function(block) {
    var posX = Blockly.JavaScript.valueToCode(block, 'posX', Blockly.JavaScript.ORDER_ATOMIC);
    var posY = Blockly.JavaScript.valueToCode(block, 'posY', Blockly.JavaScript.ORDER_ATOMIC);

    // NULLを置換
    if (posX == null) {
        posX = 10;
    }
    if (posY == null) {
        posY = 10;
    }

    var code = 'context.lineTo(' + posX + ',' + posY + ');\n';
    // console.log('js_draw_lineTo: ' + code);

    return code;
};

///////////////////////////////////////////////////////////////////
// moveto()
Blockly.Blocks['js_draw_moveto'] = {
    init: function() {
        this.appendValueInput("posX")
            .appendField("直線（はじめ)")
            .appendField("X座標")
            .setCheck("Number")
        this.appendValueInput("posY")
            .appendField("Y座標")
            .setCheck("Number")
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(js_draw_block_color);
        this.setTooltip("MOVETO");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_draw_moveto'] = function(block) {
    var posX = Blockly.JavaScript.valueToCode(block, 'posX', Blockly.JavaScript.ORDER_ATOMIC);
    var posY = Blockly.JavaScript.valueToCode(block, 'posY', Blockly.JavaScript.ORDER_ATOMIC);

    // NULLを置換
    if (posX == null) {
        posX = 10;
    }
    if (posY == null) {
        posY = 10;
    }

    var code = 'context.moveTo(' + posX + ',' + posY + ');\n';
    // console.log('moveTo: ' + code);

    return code;
};

///////////////////////////////////////////////////////////////////
// arc()
Blockly.Blocks['js_draw_arc'] = {
    init: function() {
        this.appendValueInput("posX")
            .appendField("円弧")
            .appendField("中心X座標")
            .setCheck("Number")
        this.appendValueInput("posY")
            .appendField("中心Y座標")
            .setCheck("Number")
        this.appendValueInput("radius")
            .appendField("半径")
            .setCheck("Number")
        this.appendValueInput("startAngle")
            .appendField("開始角度")
            .setCheck("Number")
        this.appendValueInput("endAngle")
            .appendField("終了角度")
            .setCheck("Number")
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(js_draw_block_color);
        this.setTooltip("ARC");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_draw_arc'] = function(block) {
    var posX = Blockly.JavaScript.valueToCode(block, 'posX', Blockly.JavaScript.ORDER_ATOMIC);
    var posY = Blockly.JavaScript.valueToCode(block, 'posY', Blockly.JavaScript.ORDER_ATOMIC);
    var radius = Blockly.JavaScript.valueToCode(block, 'radius', Blockly.JavaScript.ORDER_ATOMIC);
    var startAngle = Blockly.JavaScript.valueToCode(block, 'startAngle', Blockly.JavaScript.ORDER_ATOMIC);
    var endAngle = Blockly.JavaScript.valueToCode(block, 'endAngle', Blockly.JavaScript.ORDER_ATOMIC);

    // NULLを置換
    if (posX == null) {
        posX = 10;
    }
    if (posY == null) {
        posY = 10;
    }

    var code = 'context.arc(' + posX + ',' + posY + ',' + radius + ',' + startAngle + ',' + endAngle + ');\n';
    // console.log('moveto: ' + code);

    return code;
};

///////////////////////////////////////////////////////////////////
// fillrect()
Blockly.Blocks['js_draw_fillrect'] = {
    init: function() {
        this.appendValueInput("posX_1")
            .appendField("四角形（塗りつぶし）")
            .appendField("X座標")
            .setCheck("Number")
        this.appendValueInput("posY_1")
            .appendField("Y座標")
            .setCheck("Number")
        this.appendValueInput("posX_2")
            .appendField("幅")
            .setCheck("Number")
        this.appendValueInput("posY_2")
            .appendField("高さ")
            .setCheck("Number")
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(js_draw_block_color);
        this.setTooltip("FILLRECT");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_draw_fillrect'] = function(block) {
    var posX1 = Blockly.JavaScript.valueToCode(block, 'posX_1', Blockly.JavaScript.ORDER_ATOMIC);
    var posY1 = Blockly.JavaScript.valueToCode(block, 'posY_1', Blockly.JavaScript.ORDER_ATOMIC);
    var posX2 = Blockly.JavaScript.valueToCode(block, 'posX_2', Blockly.JavaScript.ORDER_ATOMIC);
    var posY2 = Blockly.JavaScript.valueToCode(block, 'posY_2', Blockly.JavaScript.ORDER_ATOMIC);

    // NULLを置換
    if (posX1 == null) {
        posX1 = 10;
    }
    if (posY1 == null) {
        posY1 = 10;
    }
    if (posX2 == null) {
        posX2 = 100;
    }
    if (posY2 == null) {
        posY2 = 100;
    }

    var code = 'context.fillRect(' + posX1 + ',' + posY1 + ',' + posX2 + ',' + posY2 + ');\n';
    // console.log('fillRect: ' + code);

    return code;
};

///////////////////////////////////////////////////////////////////
// strokerect()
Blockly.Blocks['js_draw_strokerect'] = {
    init: function() {
        this.appendValueInput("posX_1")
            .appendField("四角形(枠のみ)")
            // .appendField("strokeRect")
            .appendField("X座標")
            .setCheck("Number")
        this.appendValueInput("posY_1")
            .appendField("Y座標")
            .setCheck("Number")
        this.appendValueInput("posX_2")
            .appendField("幅")
            // .appendField("Width")
            .setCheck("Number")
        this.appendValueInput("posY_2")
            .appendField("高さ")
            // .appendField("Height")
            .setCheck("Number")
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(js_draw_block_color);
        this.setTooltip("STROKERECT");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_draw_strokerect'] = function(block) {
    var posX1 = Blockly.JavaScript.valueToCode(block, 'posX_1', Blockly.JavaScript.ORDER_ATOMIC);
    var posY1 = Blockly.JavaScript.valueToCode(block, 'posY_1', Blockly.JavaScript.ORDER_ATOMIC);
    var posX2 = Blockly.JavaScript.valueToCode(block, 'posX_2', Blockly.JavaScript.ORDER_ATOMIC);
    var posY2 = Blockly.JavaScript.valueToCode(block, 'posY_2', Blockly.JavaScript.ORDER_ATOMIC);

    // NULLを置換
    if (posX1 == null) {
        posX1 = 10;
    }
    if (posY1 == null) {
        posY1 = 10;
    }
    if (posX2 == null) {
        posX2 = 100;
    }
    if (posY2 == null) {
        posY2 = 100;
    }

    var code = 'context.strokeRect(' + posX1 + ',' + posY1 + ',' + posX2 + ',' + posY2 + ');\n';
    // console.log('strokeRect: ' + code);

    return code;
};

///////////////////////////////////////////////////////////////////
// Canvas
Blockly.Blocks['js_draw_canvas'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("キャンバス 幅")
            .appendField(new Blockly.FieldNumber(500, 100, 500), "width")
            .appendField("高さ")
            .appendField(new Blockly.FieldNumber(300, 100, 300), "height")
        this.appendStatementInput("data")
            .setCheck(null);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(js_draw_block_color);
        this.setTooltip("CANVAS");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_draw_canvas'] = function(block) {
    var statements_data = Blockly.JavaScript.statementToCode(block, 'data');
    var width = block.getFieldValue('width');
    var height = block.getFieldValue('height');

    var code = 'var elm=document.createElement(\'canvas\');\n';
    code += 'elm.width=\'' + width + '\';\n';
    code += 'elm.height=\'' + height + '\';\n';
    code += 'var context=elm.getContext(\'2d\');\n';
    code += 'context.beginPath();\n';
    code += statements_data + '\n';
    code += 'context.closePath();\n';
    code += 'context.stroke();\n';
    code += 'document.getElementById(\'ExecutionPanel\').appendChild(elm);\n';

    return code;
};

///////////////////////////////////////////////////////////////////
// clearrect()
Blockly.Blocks['js_draw_clearrect'] = {
    init: function() {
        this.appendValueInput("posX_1")
            .appendField("クリア")
            .appendField("X座標")
            .setCheck("Number")
        this.appendValueInput("posY_1")
            .appendField("Y座標")
            .setCheck("Number")
        this.appendValueInput("posX_2")
            .appendField("幅")
            .setCheck("Number")
        this.appendValueInput("posY_2")
            .appendField("高さ")
            .setCheck("Number")
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(js_draw_block_color);
        this.setTooltip("FILLRECT");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_draw_clearrect'] = function(block) {
    var posX1 = Blockly.JavaScript.valueToCode(block, 'posX_1', Blockly.JavaScript.ORDER_ATOMIC);
    var posY1 = Blockly.JavaScript.valueToCode(block, 'posY_1', Blockly.JavaScript.ORDER_ATOMIC);
    var posX2 = Blockly.JavaScript.valueToCode(block, 'posX_2', Blockly.JavaScript.ORDER_ATOMIC);
    var posY2 = Blockly.JavaScript.valueToCode(block, 'posY_2', Blockly.JavaScript.ORDER_ATOMIC);

    // NULLを置換
    if (posX1 == null) {
        posX1 = 10;
    }
    if (posY1 == null) {
        posY1 = 10;
    }
    if (posX2 == null) {
        posX2 = 100;
    }
    if (posY2 == null) {
        posY2 = 100;
    }

    var code = 'context.clearRect(' + posX1 + ',' + posY1 + ',' + posX2 + ',' + posY2 + ');\n';
    // console.log('fillRect: ' + code);

    return code;
};
