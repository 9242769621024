import * as Blockly from 'blockly/core';

// ブロックの配色
var js_google_map_block_color = 320;

// Timerブロック定義
const category_googleMap = `
    <category name="マップ" colour="320">
        <block type="js_map_block_show"></block>
        <block type="js_map_block_get_map"></block>
        <block type="js_map_block_latlng">
            <value name="latitude">
                <block type="math_number">
                    <field name="NUM">35.658</field>
                </block>
            </value>
            <value name="longtitude">
                <block type="math_number">
                    <field name="NUM">139.745</field>
                </block>
            </value>
        </block>
        <block type="js_map_block_marker">
            <value name="latitude">
                <block type="math_number">
                    <field name="NUM">35.658</field>
                </block>
            </value>
            <value name="longtitude">
                <block type="math_number">
                    <field name="NUM">139.745</field>
                </block>
            </value>

            <value name="comment">
                <block type="text">
                    <field name="TEXT"></field>
                </block>
            </value>

        </block>
        <block type="js_map_block_circle">
            <value name="latitude">
                <block type="math_number">
                    <field name="NUM">35.658</field>
                </block>
            </value>
            <value name="longtitude">
                <block type="math_number">
                    <field name="NUM">139.745</field>
                </block>
            </value>
            <value name="radius">
                <block type="math_number">
                    <field name="NUM">1000</field>
                </block>
            </value>
        </block>
        <block type="js_map_block_rectangle">
            <value name="north">
                <block type="math_number">
                    <field name="NUM">35.458</field>
                </block>
            </value>
            <value name="south">
                <block type="math_number">
                    <field name="NUM">35.858</field>
                </block>
            </value>
            <value name="east">
                <block type="math_number">
                    <field name="NUM">139.545</field>
                </block>
            </value>
            <value name="west">
                <block type="math_number">
                    <field name="NUM">139.945</field>
                </block>
            </value>
        </block>
    </category>`;

// <block type="js_map_block_find_location">
//     <value name="ketword">
//         <block type="text">
//             <field name="TEXT"></field>
//         </block>
//     </value>
// </block>

export {category_googleMap};

///////////////////////////////////////////////////////////////////
// show()
Blockly.Blocks['js_map_block_show'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("マップ表示")
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(js_google_map_block_color);
        this.setTooltip("show map");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_map_block_show'] = function(/*block*/) {
    var code = 'this.showMap();\n';
    code += 'const obj = this;\n';

    return code;
};

///////////////////////////////////////////////////////////////////
// MAP
Blockly.Blocks['js_map_block_get_map'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("MAP")
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(js_google_map_block_color);
        this.setTooltip("MAP");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_map_block_get_map'] = function(/*block*/) {
    var code = 'obj.map';

    return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

///////////////////////////////////////////////////////////////////
// latlng
Blockly.Blocks['js_map_block_latlng'] = {
    init: function() {
        // this.appendValueInput("objVar")
        //     .setCheck(null)
        this.appendDummyInput()
            .appendField("map.setCenter(")
        this.appendValueInput("latitude")
            .appendField("lat")
            .setCheck("Number")
        this.appendValueInput("longtitude")
            .appendField("lng")
            .setCheck("Number")
        this.appendDummyInput()
            .appendField(")")
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(js_google_map_block_color);
        this.setTooltip("latlng");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_map_block_latlng'] = function(block) {

    var latitude = Blockly.JavaScript.valueToCode(block, 'latitude', Blockly.JavaScript.ORDER_ATOMIC);
    var longtitude = Blockly.JavaScript.valueToCode(block, 'longtitude', Blockly.JavaScript.ORDER_ATOMIC);
    // var objVar = Blockly.JavaScript.valueToCode(block, 'objVar', Blockly.JavaScript.ORDER_ATOMIC);

    // var code = 'var latlng = new window.google.maps.LatLng(' + latitude + ',' + longtitude + ');\n';
    
    // // var code = 'const obj = this;\n';
    // // code += 'var latlng = new window.google.maps.LatLng(' + latitude + ',' + longtitude + ');\n';

    // // code += 'this.map.setCenter(latlng);\n';
    // code += 'obj.map.setCenter(latlng);\n';
    // // code += 'this.map.setCenter({lat:' + latitude + ', lng:' + longtitude + '});\n';
    
    var code = 'obj.setCenterForMap(obj.map, ' + latitude + ',' + longtitude + ');\n';

    return code;
};

///////////////////////////////////////////////////////////////////
// marker
Blockly.Blocks['js_map_block_marker'] = {
    init: function() {
        // this.appendValueInput("objVar")
        //     .setCheck(null)
        this.appendDummyInput()
            .appendField("map.setMarker(")
        this.appendValueInput("latitude")
            .appendField("lat")
            .setCheck("Number")
        this.appendValueInput("longtitude")
            .appendField("lng")
            .setCheck("Number")
        this.appendValueInput("comment")
            .appendField("comment")
        this.appendDummyInput()
            .appendField(")")
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(js_google_map_block_color);
        this.setTooltip("marker");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_map_block_marker'] = function(block) {

    var latitude = Blockly.JavaScript.valueToCode(block, 'latitude', Blockly.JavaScript.ORDER_ATOMIC);
    var longtitude = Blockly.JavaScript.valueToCode(block, 'longtitude', Blockly.JavaScript.ORDER_ATOMIC);
    var comment = Blockly.JavaScript.valueToCode(block, 'comment', Blockly.JavaScript.ORDER_ATOMIC);

//     var code = 'var latlng = new window.google.maps.LatLng(' + latitude + ',' + longtitude + ');\n';
//     code += `var marker = new window.google.maps.Marker({
//         position: latlng,
//         title: ""
//     });
// marker.setMap(obj.map);
// `;

    // if (comment == '\'\''){
    //     comment = '';
    // }
    var code = 'obj.setMarkerForMap(obj.map, ' + latitude + ',' + longtitude + ',' + comment + ');\n';
    return code;
};

///////////////////////////////////////////////////////////////////
// rectangle
Blockly.Blocks['js_map_block_rectangle'] = {
    init: function() {
        // this.appendValueInput("objVar")
        //     .setCheck(null)
        this.appendDummyInput()
            .appendField("map.Rectangle(")
        this.appendValueInput("north")
            .appendField("north")
            .setCheck("Number")
        this.appendValueInput("south")
            .appendField("south")
            .setCheck("Number")
        this.appendValueInput("east")
            .appendField("east")
            .setCheck("Number")
        this.appendValueInput("west")
            .appendField("west")
            .setCheck("Number")
        this.appendDummyInput()
            .appendField(")")
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(js_google_map_block_color);
        this.setTooltip("rectangle");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_map_block_rectangle'] = function(block) {

    var north = Blockly.JavaScript.valueToCode(block, 'north', Blockly.JavaScript.ORDER_ATOMIC);
    var south = Blockly.JavaScript.valueToCode(block, 'south', Blockly.JavaScript.ORDER_ATOMIC);
    var east = Blockly.JavaScript.valueToCode(block, 'east', Blockly.JavaScript.ORDER_ATOMIC);
    var west = Blockly.JavaScript.valueToCode(block, 'west', Blockly.JavaScript.ORDER_ATOMIC);

    // var code = `const rectangle = new window.google.maps.Rectangle({
    //     strokeColor: "#FF0000",
    //     strokeOpacity: 0.8,
    //     strokeWeight: 2,
    //     fillColor: "#FF0000",
    //     fillOpacity: 0.35,
    //     map: obj.map,
    //     bounds: {
    //       north:` + north + `,
    //       south:` + south + `,
    //       east:` + east + `,
    //       west:` + west + `,
    //     },
    //   });
    //   `;

    var code = 'obj.setRectangleForMap(obj.map, ' + north + ',' + south + ',' + east + ',' + west + ');\n';

    return code;
};

///////////////////////////////////////////////////////////////////
// circle
Blockly.Blocks['js_map_block_circle'] = {
    init: function() {
        // this.appendValueInput("objVar")
        //     .setCheck(null)
        this.appendDummyInput()
            .appendField("map.Circle(")
        this.appendValueInput("latitude")
            .appendField("lat")
            .setCheck("Number")
        this.appendValueInput("longtitude")
            .appendField("lng")
            .setCheck("Number")
        this.appendValueInput("radius")
            .appendField("radius")
            .setCheck("Number")
        this.appendDummyInput()
            .appendField(")")
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(js_google_map_block_color);
        this.setTooltip("rectangle");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_map_block_circle'] = function(block) {

    var latitude = Blockly.JavaScript.valueToCode(block, 'latitude', Blockly.JavaScript.ORDER_ATOMIC);
    var longtitude = Blockly.JavaScript.valueToCode(block, 'longtitude', Blockly.JavaScript.ORDER_ATOMIC);
    var radius = Blockly.JavaScript.valueToCode(block, 'radius', Blockly.JavaScript.ORDER_ATOMIC);

    // var code = `const circle = new window.google.maps.Circle({
    //     strokeColor: "#FF0000",
    //     strokeOpacity: 0.8,
    //     strokeWeight: 2,
    //     fillColor: "#FF0000",
    //     fillOpacity: 0.35,
    //     map: obj.map,
    //     center: {
    //       lat:` + latitude + `,
    //       lng:` + longtitude + `,
    //     },
    //     radius: ` + radius + `
    //   });
    //   `;

    var code = 'obj.setCircleForMap(obj.map, ' + latitude + ',' + longtitude + ',' + radius + ');\n';

    return code;
};

///////////////////////////////////////////////////////////////////
// find location
Blockly.Blocks['js_map_block_find_location'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("find location")
        this.appendValueInput("text")
            .appendField("文字列")
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(js_google_map_block_color);
        this.setTooltip("MAP");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_map_block_find_location'] = function(block) {

    var text = Blockly.JavaScript.valueToCode(block, 'text', Blockly.JavaScript.ORDER_ATOMIC);

    // // var code = `https://maps.googleapis.com/maps/api/place/findplacefromtext/json?fields=formatted_address%2Cname%2Cgeometry&input=${text}&inputtype=textquery&key=AIzaSyCBmV_3TJB92d_BJi6osAUkSYBfhwuz-3M`;
    // // var code = `https://maps.googleapis.com/maps/api/place/findplacefromtext/json?fields=formatted_address&input=${text}&inputtype=textquery&key=AIzaSyCBmV_3TJB92d_BJi6osAUkSYBfhwuz-3M`;
    // // var code = `https://maps.googleapis.com/maps/api/place/findplacefromtext/json?fields=formatted_address&input=高松駅&inputtype=textquery&key=AIzaSyCBmV_3TJB92d_BJi6osAUkSYBfhwuz-3M`;
    // var code = `https://maps.googleapis.com/maps/api/place/findplacefromtext/json?input=高松駅&inputtype=textquery&key=AIzaSyCBmV_3TJB92d_BJi6osAUkSYBfhwuz-3M`;

    // var service = new window.google.maps.places.PlacesService(obj.map);
        
    // service.findPlaceFromQuery({
    //     query: ${text},
    //     fields: ['name', 'geometry'],
    // }, function(results, status) {
    //     if (status === window.google.maps.places.PlacesServiceStatus.OK) {
    //         return {
    //             lat: results[0].geometry.location.lat(),
    //             lng: results[0].geometry.location.lng(),
    //         }
    //     }
    // });

//     var code = `
//     await new window.google.maps.places.PlacesService(obj.map).findPlaceFromQuery({
//         query: ${text},
//         fields: ['name', 'geometry'],
//     }, function(results, status) {
//         if (status === window.google.maps.places.PlacesServiceStatus.OK) {
//             return {
//                 lat: results[0].geometry.location.lat(),
//                 lng: results[0].geometry.location.lng(),
//             }
//         }
//     });
// `;

    var code = 'obj.getLocationFromKeyword(obj.map, ' + text + ')';

    return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

