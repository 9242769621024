var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"font-family":"Noto Sans JP"}},[_c('v-app-bar',{attrs:{"app":"","color":"indigo","dark":""}},[_c('v-app-bar-nav-icon'),_c('div',{staticClass:"d-flex align-center",staticStyle:{"margin-right":"20px"}},[_c('v-img',{staticClass:"shrink mr-2",attrs:{"alt":"Vuetify Logo","contain":"","src":require("./../assets/broccoly.png"),"transition":"scale-transition","width":"40"}}),_c('div',{staticClass:"headline mb-0 ml-2",staticStyle:{"white-space":"nowrap"}},[_vm._v(" Broccoly Coder ")])],1),_c('v-menu',{attrs:{"close-on-click":_vm.closeOnClick,"close-on-content-click":_vm.closeOnContentClick,"offset-x":_vm.offsetX,"offset-y":_vm.offsetY,"open-on-hover":""}},[_c('v-list',[_vm._l((_vm.items),function(item){return [(item.div==true)?_c('v-divider',{key:item.id}):_c('v-list-item',{key:item.id,on:{"click":function($event){return _vm.menuClickEvent(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)]})],2)],1),_c('v-menu',{attrs:{"close-on-click":_vm.closeOnClick,"close-on-content-click":_vm.closeOnContentClick,"offset-x":_vm.offsetX,"offset-y":_vm.offsetY,"open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin-left":"8px"},attrs:{"slot":"activator","text":"","outlined":"","color":"normal","disabled":_vm.enabledFile},slot:"activator"},'v-btn',attrs,false),on),[_vm._v(" ファイル ")])]}}])},[_c('v-list',[_vm._l((_vm.mnFileItems),function(item){return [(item.div==true)?_c('v-divider',{key:item.id}):_c('v-list-item',{key:item.id,on:{"click":function($event){return _vm.menuClickEvent(item)}}},[_c('v-icon',{staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(item.icon))]),_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)]})],2)],1),_c('v-menu',{attrs:{"close-on-click":_vm.closeOnClick,"close-on-content-click":_vm.closeOnContentClick,"offset-x":_vm.offsetX,"offset-y":_vm.offsetY,"open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin-left":"8px"},attrs:{"slot":"activator","text":"","outlined":"","color":"normal","disabled":_vm.enabled},slot:"activator"},'v-btn',attrs,false),on),[_vm._v(" 編集 ")])]}}])},[_c('v-list',[_vm._l((_vm.mnEditItems),function(item){return [(item.div==true)?_c('v-divider',{key:item.id}):_c('v-list-item',{key:item.id,on:{"click":function($event){return _vm.menuClickEvent(item)}}},[_c('v-icon',{staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(item.icon))]),_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)]})],2)],1),_c('v-menu',{attrs:{"close-on-click":_vm.closeOnClick,"close-on-content-click":_vm.closeOnContentClick,"offset-x":_vm.offsetX,"offset-y":_vm.offsetY,"open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin-left":"8px"},attrs:{"slot":"activator","text":"","outlined":"","color":"normal","disabled":_vm.enabled},slot:"activator"},'v-btn',attrs,false),on),[_vm._v(" 実行 ")])]}}])},[_c('v-list',[_vm._l((_vm.mnRunItems),function(item){return [(item.div==true)?_c('v-divider',{key:item.id}):_c('v-list-item',{key:item.id,on:{"click":function($event){return _vm.menuClickEvent(item)}}},[_c('v-icon',{staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(item.icon))]),_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)]})],2)],1),_c('v-menu',{attrs:{"close-on-click":_vm.closeOnClick,"close-on-content-click":_vm.closeOnContentClick,"offset-x":_vm.offsetX,"offset-y":_vm.offsetY,"open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin-left":"8px"},attrs:{"slot":"activator","text":"","outlined":"","color":"normal","disabled":_vm.EnabledHelp},slot:"activator"},'v-btn',attrs,false),on),[_vm._v(" ヘルプ ")])]}}])},[_c('v-list',[_vm._l((_vm.mnHelpItems),function(item){return [(item.div==true)?_c('v-divider',{key:item.id}):_c('v-list-item',{key:item.id,on:{"click":function($event){return _vm.menuClickEvent(item)}}},[_c('v-icon',{staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(item.icon))]),_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)]})],2)],1),_c('v-text-field',{staticStyle:{"margin-left":"100px","width":"200px","height":"30px"},attrs:{"placeholder":"プロジェクト名","readonly":""},model:{value:(_vm.projectName),callback:function ($$v) {_vm.projectName=$$v},expression:"projectName"}}),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('v-subheader',[_vm._v(_vm._s(_vm.userName))]),_c('v-menu',{attrs:{"close-on-click":_vm.closeOnClick,"close-on-content-click":_vm.closeOnContentClick,"offset-x":_vm.offsetX,"offset-y":_vm.offsetY,"open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin-left":"8px","text-transform":"none","font-size":"12px"},attrs:{"slot":"activator","text":"","outlined":"","color":"normal","disabled":_vm.enabledLoginId},slot:"activator"},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.accountOperationTitle)+" ")])]}}])},[_c('v-list',[_vm._l((_vm.mnAccountItems),function(item){return [(item.div==true)?_c('v-divider',{key:item.id}):_c('v-list-item',{key:item.id,on:{"click":function($event){return _vm.menuClickEvent(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)]})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }