<template>
    <v-container style="font-family:Noto Sans JP;">
    <v-row align='center'>
        <!-- 余白カラム -->
        <v-col
            cols='1'
            align='center'
            />

        <!-- 余白カラム -->
        <v-col
            cols='10'
            align='left'
            >
        <strong>{{ this.$store.state.loginId==this.getTrialId() ? 'ゲスト': this.$store.state.loginId }}</strong> さん、こんにちは
        </v-col>
        <!-- 余白カラム -->
        <v-col
            cols='1'
            align='center'
            />
    </v-row>

    <v-row align='center'>
        <!-- 余白カラム -->
        <v-col
            cols='1'
            align='center'
            />

        <!-- 余白カラム -->
        <v-col
            cols='10'
            align='center'
            >

        <v-card
            width='1000'
            elevation='3'
            outlined
            >

        <template>
        <v-simple-table fixed-header height='230px'>
            <template v-slot:default>
            <thead>
                <tr>
                <th class='text-left headline mb-1' colspan='3' style='width:10%;'>
                    おしらせ
                </th>
                </tr>
            </thead>
            <tbody>
                <tr
                v-for="data in notices"
                :key="data.title"
                >
                <td style='width:10%;'>
                    <v-btn
                        dark
                        fab
                        top
                        left
                        :color="data.color"
                        small
                        style='margin-top:10px;margin-bottom:10px;margin-right:15px;'
                         >
                        <v-icon>{{ data.icon }}</v-icon>
                    </v-btn>
                </td>
                <td style='width:20%;'>
                    <div class='body-0'>{{ data.subtitle }}</div>
                </td>
                <td style='width::60%;'>
                    <div class='body-0'>{{ data.title }}</div>
                </td>
                </tr>
            </tbody>
            </template>
        </v-simple-table>
        </template>

        </v-card>
        </v-col>
        <!-- 余白カラム -->
        <v-col
            cols='1'
            align='center'
            />
    </v-row>

    <v-row justify='start' style='margin-bottom:0px;'>
        <!-- 余白カラム -->
        <v-col
            cols='1'
            align='center'
            />
        <!-- 余白カラム -->
        <v-col
            cols='10'
            align='center'
            >
        <v-card
            width='1000'
            elevation='0'
            align='left'
            >
        <v-simple-table>
        <template v-slot:default>
        <tbody>
        <tr>
            <td
                style="width:200px;"
                >
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        dark
                        top
                        left
                        color='success'
                        style='width:120px; height:50px; font-size: 18px;'
                        v-bind="attrs"
                        v-on="on"
                        @click="newProduct"
                        >
                        新規作成
                    </v-btn>
                    </template>
                <span>プロジェクトを新しく作成する</span>
                </v-tooltip>
            </td>
            <td
                style="width:800px;"
                >
                <v-radio-group v-model="selectionLang" v-if="enableLangSelection" row>
                    <v-radio
                        v-for="n in langName"
                        :key="n"
                        :label="n"
                        :value="n"
                        />
                </v-radio-group>
            </td>
        </tr>
        </tbody>
        </template>
        </v-simple-table>
        </v-card>
        </v-col>
        <!-- 余白カラム -->
        <v-col
            cols='1'
            align='center'
            />
    </v-row>

    <v-row align='center'>
        <!-- 余白カラム -->
        <v-col
            cols='1'
            align='center'
            />
        <!-- 余白カラム -->
        <v-col
            cols='10'
            align='center'
            >
        <v-card
            width='1000'
            elevation='3'
            outlined
            >
        <v-simple-table fixed-header height='355px'>
            <template v-slot:default>
            <thead>
                <tr>
                <th class='text-left headline mb-1' colspan='4' style='width:10%;'>
                    保存済みデータ
                </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="prj in projects"
                    :key="prj.id"
                    >
                <td style='width:5%;'>
                    <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            dark
                            fab
                            top
                            left
                            color='green lighten-1'
                            small
                            style='margin-top:10px;margin-bottom:10px;margin-right:15px;'
                            v-bind="attrs"
                            v-on="on"
                            @click="openItem(prj.id, prj.title, prj.lang)"
                            >
                            <v-icon>mdi-pencil-outline</v-icon>
                        </v-btn>
                        </template>
                    <span>[ {{prj.title}} ]を読み込む</span>
                    </v-tooltip>
                </td>
                <td style='width:5%;'>
                    <v-img
                        v-if="prj.lang == 'JavaScript'"
                        max-height="36"
                        max-width="36"
                        src="./../assets/logo_js.svg"
                    />
                    <v-img
                        v-if="prj.lang == 'python'"
                        max-height="36"
                        max-width="36"
                        src="./../assets/logo_python.svg"
                    />
                    <v-img
                        v-if="prj.lang == 'c#'"
                        max-height="36"
                        max-width="36"
                        src="./../assets/logo_csharp.svg"
                    />
                    <v-img
                        v-if="prj.lang == 'HTML'"
                        max-height="36"
                        max-width="36"
                        src="./../assets/logo_html.svg"
                    />
                </td>
                <td style='width:80%;'>
                    <div class='body-1'>{{ prj.title }}</div>
                </td>
                <td style='width:10%;' align='right'>
                    <v-tooltip right v-if="showSavedCard"> 
                        <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            dark
                            fab
                            top
                            right
                            color='grey lighten-1'
                            small
                            style='margin-top:10px;margin-bottom:10px;'
                            v-bind="attrs"
                            v-on="on"
                            @click="removeItem(prj.id, prj.title)"
                            >
                            <v-icon>mdi-trash-can-outline</v-icon>
                        </v-btn>
                        </template>
                    <span>[ {{prj.title}} ]を削除する</span>
                    </v-tooltip>
                </td>
                </tr>
            </tbody>
            </template>
        </v-simple-table>

        </v-card>
        <!-- 余白カラム -->
        </v-col>
        <!-- 余白カラム -->
        <v-col
            cols='1'
            align='center'
            />
    </v-row>
    </v-container>
</template>

<script>
import swal from 'sweetalert2'
import { swalIcon, swalTitle, codeLanguage } from './../module/defines';

export default {
    name: 'FileView',

    // firebaseオブジェクト
    fbo : null,

    data() {
        return {
            notices: [],
            projects: [],
            selectedItem: 0,
            showSavedCard: true,
            langName: [codeLanguage.JS, codeLanguage.Python, codeLanguage.CS, codeLanguage.HTML],
            selectionLang: codeLanguage.JS,
            enableLangSelection: false,
        };
    },
    async created() {
        // firebaseオブジェクト
        this.fbo = this.$store.state.firebaseObject;
        // Analytics開始
        this.fbo.startAnalytics_fileview();

        // オブジェクトの存在確認
        if (this.fbo == null){
            swal.fire({
                title: swalTitle.INFO,
                text: 'システムの異常が発生しました。',
                icon: swalIcon.ERROR,
            });
            // 処理を中断
            return;
        }

        // お知らせを読み込み
        await this.readNotice();
    },
    async mounted() {
        // 試用ユーザーの場合
        if (this.$store.state.loginId == process.env.VUE_APP_CONF_GUEST_ID) {
            // 表示切り替え
            this.showSavedCard = false;
        }
        // ユーザー権限
        // if (this.$store.state.accountInf.env.LOGIN_TYPE == 999) {
        //     this.enableLangSelection = true;
        // }
        this.enableLangSelection = true;

        // 保存済みプロジェクトを読み込み
        await this.readProject();
    },
    methods: {
        // 新規作成
        newProduct() {
            try {
                // プロジェクトの保存数を確認
                if (this.$store.state.loginId != this.getTrialId()
                    && this.projects.length >= this.$store.state.accountInf.env.MAX_SAVE_COUNTS) {
                    // 確認メッセージ
                    swal.fire({
                        title: swalTitle.INFO,
                        text: 'プロジェクトの保存できる上限数に達しています。これ以上プロジェクトを新規作成することはできませんが、よろしいですか？',
                        icon: swalIcon.INFO,
                        buttons: true,
                        // dangerMode: true,
                    }).then((answer) => {
                        if (!answer.isConfirmed) {
                            // 処理を中断
                            return;
                        }
                    });
                }

                // 言語選択
                this.$store.dispatch('commitSelectionLanguage', this.selectionLang);
                // child -> parent
                this.$emit('fileViewProc', {id: 'newProduct', text: ''});
            }catch(e){
                console.log(e);
            }
        },
        // お知らせを読み込み
        async readNotice() {
            // /NOTICEの取得
            var notices = await this.fbo.readNoticeDataList();
            var cnt = 0;

            var icon;
            var color;

            // お知らせを展開
            for (cnt=0; cnt<notices.length; cnt++) {
                // デフォルト設定
                icon = 'mdi-information-outline';
                color = 'blue';
                // 設定に応じて更新
                switch (notices[cnt].ICON) {
                    case 0:
                        icon = 'mdi-information-outline';
                        color = 'blue';
                        break;
                    case 1:
                        icon = 'mdi-information-outline';
                        color = 'orange';
                        break;
                    case 2:
                        icon = 'mdi-information-outline';
                        color = 'red';
                        break;
                    default:
                        break;
                }
                // 行データを出力
                var notice = {
                    color: color,
                    icon: icon,
                    subtitle: this.formatDate(notices[cnt].DATE.toDate(), 'yyyy/M/d'),
                    title: notices[cnt].CONTENTS,
                };
                // お知らせを追加
                this.notices.push(notice);
            }
        },
        // 保存済みプロジェクトを読み込み
        async readProject() {
            // リストを削除
            this.projects.length = 0;

            // データ読み込み
            var retDatas = await this.$store.state.firebaseObject.readProjectList([
                this.$store.state.firebaseObject.FIRE_STORE_KEYWORD.DATAS,
                this.$store.state.loginId,
                this.$store.state.firebaseObject.FIRE_STORE_KEYWORD.PROJECTS,
            ]);

            for (let cnt=0; cnt<retDatas.length; cnt++){
                var datas = {
                    id: retDatas[cnt].ID,
                    title: retDatas[cnt].PROJECTNAME,
                    lang: retDatas[cnt].CODELANGUAGE,
                    subtitle: '',
                };
                
                // リストにアイテムを追加
                this.projects.push(datas);
            }
        },
        // 保存データを展開
        openItem(key, title, lang) {
            try {
                // console.log('openItem:' + key);
                // console.log('openItem:' + title);
                // console.log('openItem:' + lang);

                // 言語選択
                this.$store.dispatch('commitSelectionLanguage', lang);
                // child -> parent
                // this.$emit('fileViewProc', {id: 'openProduct', text: key, title: title});

                this.$emit('fileViewProc', {
                    id: 'openProduct',
                    text: key,
                    title: title,
                    lang: lang
                });

            }catch(e){
                console.log(e);
            }
        },
        // 保存データを削除
        async removeItem(key, title) {
            try {
                // child -> parent
                this.$emit('fileViewProc', {id: 'removeProduct', key: key, title: title});

            }catch(e){
                console.log(e);
            }
        },
        // 日付フォーマット
        formatDate(date, format) {
            format = format.replace(/yyyy/g, date.getFullYear());
            format = format.replace(/M/g, ('00' + (date.getMonth() + 1)).slice(-2));
            format = format.replace(/d/g, ('00' + (date.getDate())).slice(-2));
            format = format.replace(/H/g, ('00' + (date.getHours())).slice(-2));
            format = format.replace(/m/g, ('00' + (date.getMinutes())).slice(-2));
            format = format.replace(/s/g, ('00' + (date.getSeconds())).slice(-2));
            format = format.replace(/S/g, ('000' + (date.getMilliseconds())).slice(-3));
            return format;
        },
        // 試用ログインIDを取得
        getTrialId() {
            return process.env.VUE_APP_CONF_GUEST_ID;
        },
    },
}
</script>
