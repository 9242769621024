import * as Blockly from 'blockly/core';

// ブロックの配色
var js_draw_block_image = 240;

const imageNames = [["かさ", "0"], ["くらげ", "1"], ["たこ", "2"], ["みかん", "3"], ["ヤシの木", "4"], 
                    ["ペリカン", "5"], ["植物", "6"], ["かもめ", "7"], ["ひとで", "8"], ["温度計", "9"], ];

// Imageブロック定義
const category_Image = `
    <category name="画像" colour="240">
        <block type="js_load_image" />
        <block type="js_draw_image">
            <value name="posX">
                <block type="math_number">
                    <field name="NUM">10</field>
                </block>
            </value>
            <value name="posY">
                <block type="math_number">
                    <field name="NUM">10</field>
                </block>
            </value>
        </block>
    </category>`;

export {category_Image};

///////////////////////////////////////////////////////////////////
// DrawImage()
Blockly.Blocks['js_load_image'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("画像")
            .appendField(new Blockly.FieldDropdown(imageNames), "imageNo");
        this.appendStatementInput("data")
            .setCheck(null);
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(js_draw_block_image);
        this.setTooltip("IMAGE");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_load_image'] = function(block) {
    var imageNo = block.getFieldValue('imageNo');
    var statements_data = Blockly.JavaScript.statementToCode(block, 'data');

    var imageNames = [
        'imgFbeachumbrella',
        'imgJellyfish',
        'imgOctopus',
        'imgOrange',
        'imgPalmtree',
        'imgPelican',
        'imgPlant',
        'imgSeagull',
        'imgStarfish',
        'imgThermometer',
    ];
    var storageFiles = [
        'beach-umbrella.png?alt=media&token=0acdeaf1-e267-4959-8015-51af6a96bbf0',
        'jellyfish.png?alt=media&token=06fb9ac5-eae9-4b06-8664-8d5979439aa7',
        'octopus.png?alt=media&token=cdc1b963-8c14-4c22-bd20-6a8808a1a1b1',
        'orange.png?alt=media&token=f24ce313-936c-46e9-bf4f-47c3d63464df',
        'palm-tree.png?alt=media&token=f83da49c-cd47-44f4-8ae5-b6eea5374378',
        'pelican.png?alt=media&token=b08869fd-8749-4d1e-b0c1-3c3e547bb2f5',
        'plant.png?alt=media&token=7b669ad2-6b89-45ba-8136-0d191529f93a',
        'seagull.png?alt=media&token=59b5b566-0b65-470f-918d-dc1f3af00937',
        'starfish.png?alt=media&token=37bcb6ea-503e-44c5-a1a1-5b5550340857',
        'thermometer.png?alt=media&token=6057ca82-c731-41da-b4c3-894af3c13dbe',
    ];

    var no = Number(imageNo);
    var image = 'https://firebasestorage.googleapis.com/v0/b/broccoly-97269.appspot.com/o/image%2F' + storageFiles[no];

    var code = `var ` + imageNames[no] + ` = new Image();\n`
        + imageNames[no] + `.src='` + image + `';\n`
        + imageNames[no] + `.onload = function() {\n` + statements_data + `}\n`;

    return code;
};


///////////////////////////////////////////////////////////////////
// DrawImage()
Blockly.Blocks['js_draw_image'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("画像")
            .appendField(new Blockly.FieldDropdown(imageNames), "imageNo");
        this.appendValueInput("posX")
            .appendField("X座標")
            .setCheck("Number")
        this.appendValueInput("posY")
            .appendField("Y座標")
            .setCheck("Number")
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(js_draw_block_image);
        this.setTooltip("IMAGE");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['js_draw_image'] = function(block) {
    var imageNo = block.getFieldValue('imageNo');
    var posX = Blockly.JavaScript.valueToCode(block, 'posX', Blockly.JavaScript.ORDER_ATOMIC);
    var posY = Blockly.JavaScript.valueToCode(block, 'posY', Blockly.JavaScript.ORDER_ATOMIC);

    // NULLを置換
    if (posX == null || posY == null) {
        return '';
    }

    // var no = Number(imageNo);
    // var storageFiles = [
    //     'beach-umbrella.png?alt=media&token=0acdeaf1-e267-4959-8015-51af6a96bbf0',
    //     'jellyfish.png?alt=media&token=06fb9ac5-eae9-4b06-8664-8d5979439aa7',
    //     'octopus.png?alt=media&token=cdc1b963-8c14-4c22-bd20-6a8808a1a1b1',
    //     'orange.png?alt=media&token=f24ce313-936c-46e9-bf4f-47c3d63464df',
    //     'palm-tree.png?alt=media&token=f83da49c-cd47-44f4-8ae5-b6eea5374378',
    //     'pelican.png?alt=media&token=b08869fd-8749-4d1e-b0c1-3c3e547bb2f5',
    //     'plant.png?alt=media&token=7b669ad2-6b89-45ba-8136-0d191529f93a',
    //     'seagull.png?alt=media&token=59b5b566-0b65-470f-918d-dc1f3af00937',
    //     'starfish.png?alt=media&token=37bcb6ea-503e-44c5-a1a1-5b5550340857',
    //     'thermometer.png?alt=media&token=6057ca82-c731-41da-b4c3-894af3c13dbe',
    // ];
    // var image = 'https://firebasestorage.googleapis.com/v0/b/broccoly-97269.appspot.com/o/image%2F' + storageFiles[no];
    // var code = `var img = new Image();
    //         img.src='` + image + `';
    //         img.onload=function() {
    //             context.drawImage(img,` + posX + `,` + posY + `);
    //         };`;

    var no = Number(imageNo);
    var storageFiles = [
        'imgFbeachumbrella',
        'imgJellyfish',
        'imgOctopus',
        'imgOrange',
        'imgPalmtree',
        'imgPelican',
        'imgPlant',
        'imgSeagull',
        'imgStarfish',
        'imgThermometer',
    ];

    var code = `context.drawImage(` + storageFiles[no] + `,` + posX + `,` + posY + `);\n`;
    return code;
};

