// メッセージテキストアイコン
const swalIcon = {
    INFO: 'info',
    WARNING: 'warning',
    ERROR: 'error',
};
const swalTitle = {
    INFO: '確認',
    WARNING: '警告',
};
const codeLanguage = {
    JS: 'JavaScript',
    Python: 'python',
    CS: 'c#',
    HTML: 'HTML',
};

export { swalIcon, swalTitle, codeLanguage, };
