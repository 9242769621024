<template>
    <!-- eslint-disable max-len -->
    <v-app>
        <!-- <p id="exscript" /> -->
        <MenuBarView
            ref='menuBarView'
            :menuEnabled="!menuEnabled"
            :menuLoginEnabled="!menuLoginEnabled"
            :menuFileEnabled="!menuFileEnabled"
            @menuProc="emitMenuEvent"
            @menuDialogProc="emitMenuDialogEvent"
            v-if="true"
            />

    <v-main class='pt-14'>
        <Login v-if="this.page == 0" @loginProc="emitLoginEvent" />
        <Signon v-if="this.page == 3" @signonProc="emitSigninEvent" />
        <TabView
            v-if="this.page == 2"
            ref='tabView'
            style='height: 98%'
            @tabProc="emitMenuDialogEvent"
            />
        <FileView
            v-if="this.page == 4"
            ref='fileView'
            @fileViewProc="emitFileViewEvent"
            />
    </v-main>
    </v-app>
</template>

<script>
import swal from 'sweetalert2';
import Login from './components/Login';
import Signon from './components/SignOn';
import TabView from './components/TabView';
import MenuBarView from './components/MenuBarView';
import FileView from './components/FileView';
import firebaseObject from './module/firebase';
import { swalIcon, swalTitle } from './module/defines';

export default {
    name: 'App',

    components: {
        Login,
        Signon,
        TabView,
        MenuBarView,
        FileView,
    },

    data() {
        return {
            // 表示ページ番号
            page: 0,

            // メニューの操作設定
            menuEnabled: false,
            // ログインメニューの操作設定
            menuLoginEnabled: false,
            // ファイルメニューの操作設定
            menuFileEnabled: false,

            // プロジェクト情報
            projectInf: {
                projectName: '',
                projectKey: '',
            },
        };
    },

    async created() {
        if (Boolean(this.$route.query.trial) == true
            && this.$route.query.sampleNo != undefined)
        {
            var sampleNo = Number(this.$route.query.sampleNo);
            if (sampleNo < 1001 && 1003 < sampleNo) {
                return;
            }
            // パラメーター入力対応
            await this.paramSupport(this.$route.query.sampleNo);
        }
    },

    // mounted() {
    //     let scriptEl = document.createElement('script')
    //     scriptEl.setAttribute('src', 'https://maps.googleapis.com/maps/api/js?key=' + process.env.VUE_APP_GOOGLE_MAP_KEY + '&callback=initMap')
    //     document.getElementById('exscript').appendChild(scriptEl)
    // },
    
    methods: {
        // ログイン処理
        emitLoginEvent(value) {
            // サインオン画面へ遷移
            if (value == 'move signon') {
                this.page = 3;
                return;
            }

            if (value != 'success login' && value != 'success login trial') {
                return;
            }

            if (value != 'success login trial') {
                // ログインユーザーを表示
                this.$refs.menuBarView.setAccountName(this.$store.state.loginId);
                this.menuLoginEnabled = true;
                this.menuFileEnabled = false;
            } else if (value == 'success login trial') {
                // ログインユーザーを表示
                this.$refs.menuBarView.setAccountName('おためし');
                this.menuLoginEnabled = false;
                this.menuFileEnabled = false;
            }

            // メニューの操作設定
            this.menuEnabled = false;
            // 画面を遷移
            this.page = 4;
        },
        // サインイン処理
        emitSigninEvent(value) {
            if (value == 'back') {
                // ログインユーザーを表示
                this.$refs.menuBarView.setAccountName('');
                // メニューの操作設定
                this.menuEnabled = false;
                // ログインメニューの操作設定
                this.menuLoginEnabled = false;
                // ファイルメニューの操作設定
                this.menuFileEnabled = false;
                // 画面を遷移
                this.page = 0;
                return;
            }

            if (value != 'success signon') {
                return;
            }

            // ログインユーザーを表示
            this.$refs.menuBarView.setAccountName(this.$store.state.loginId);
            // メニューの操作設定
            this.menuEnabled = false;
            // ログインメニューの操作設定
            this.menuLoginEnabled =
                this.$store.state.loginId == process.env.VUE_APP_CONF_GUEST_ID;
            // ファイルメニューの操作設定
            this.menuFileEnabled = false;
            // 画面を遷移
            this.page = 4;
        },
        // ファイルビューイベント
        async emitFileViewEvent(event) {

            // console.log('emitFileViewEvent:' + event.id);

            // お試しログイン
            if (event.id == 'trialLongin') {
                this.emitLoginEvent('success login trial');
                return;
            }
            // 新規プロジェクト作成
            else if (event.id == 'newProduct') {
                // メニューの操作設定
                this.menuEnabled = true;
                // ファイルメニューの操作設定
                this.menuFileEnabled = event.text == 'enable';
                // 画面を遷移
                this.page = 2;
            }
            // プロジェクトの削除
            else if (event.id == 'removeProduct') {
                await swal.fire({
                    title: swalTitle.INFO,
                    text: event.title + 'を削除してもよろしいですか？',
                    icon: swalIcon.INFO,
                    // buttons: true,
                    showCancelButton: true,
                    // dangerMode: true,
                }).then(async (answer) => {
                    if (answer.isConfirmed) {
                        // /DATAS/PROJECT/プロジェクトの削除
                        await this.porc_mnRemoveProject(event);
                        // 確認メッセージ
                        swal.fire({
                            title: swalTitle.INFO,
                            text: event.title + 'を削除しました。',
                            icon: swalIcon.INFO,
                        });
                        // 画面を更新
                        await this.$refs.fileView.readProject();
                    }
                });
                return;
            }
            // プロジェクトを開く
            else if (event.id == 'openProduct') {

                // メニューの操作設定
                this.menuEnabled = true;
                // 言語選択
                this.$store.dispatch('commitSelectionLanguage', event.lang);

                // 画面を遷移
                this.page = 2;
                // 保存データを展開
                await this.porc_mnLoadProject(event);
            }
        },
        // メニュー処理
        emitMenuEvent(event) {
            // メニューアイテム
            this.$refs.tabView.functionMethod(event);
        },
        // メニューダイアログ処理
        async emitMenuDialogEvent(event) {
            switch (event.id) {
                case 'mnHome':
                {
                    // ホーム画面へ遷移
                    swal.fire({
                        title: swalTitle.INFO,
                        text: 'ホーム画面に戻ってもよろしいですか？',
                        icon: swalIcon.INFO,
                        // buttons: true,
                        showCancelButton: true,
                        // dangerMode: true,
                    }).then((answer) => {
                        if (answer.isConfirmed) {
                            // ホーム画面へ戻る
                            this.porc_mnHome();
                        }
                    });
                }
                break;
                case 'mnSaveProject':
                {
                    // プロジェクト保存
                    // プロジェクト一覧読み込み
                    var count = await this.$store.state.firebaseObject.getProjectCount([
                    this.$store.state.firebaseObject.FIRE_STORE_KEYWORD.DATAS,
                    this.$store.state.loginId,
                    this.$store.state.firebaseObject.FIRE_STORE_KEYWORD.PROJECTS,
                    ]);

                    // 保存上限数の確認
                    if (count > this.$store.state.accountInf.env.MAX_SAVE_COUNTS) {
                        // 確認ダイアログ
                        await swal.fire({
                            title: swalTitle.INFO,
                            text: 'これ以上プロジェクトを新規作成することはできません。',
                            icon: swalIcon.INFO,
                        });
                        // 処理中断
                        return;
                    }

                    // 読み込みプロジェクトを確認
                    if (this.projectInf.projectKey != '') {
                        // 保存処理へ
                        await this.porc_mnSaveProject2(event);
                        // 確認メセージ
                        await swal.fire({
                            title: swalTitle.INFO,
                            text: 'プロジェクトを保存しました。',
                            icon: swalIcon.INFO,
                        });
                    } else {
                        swal.fire({
                            title: swalTitle.INFO,
                            text: '保存するプロジェクト名を入力してください。',
                            icon: swalIcon.INFO,
                            input: 'text',
                            // buttons: true,
                            showCancelButton: true,
                            // dangerMode: true,
                        }).then((answer) => {
                            if (answer.isConfirmed) {
                                // /DATAS/PROJECT/プロジェクト名の登録
                                this.porc_mnSaveProject2(answer.value);
                                // 確認メッセージ
                                swal.fire({
                                    title: swalTitle.INFO,
                                    text: 'プロジェクトの保存が正常に終了しました。',
                                    icon: swalIcon.INFO,
                                });
                            }
                        });
                    }
                }
                break;
                default:
                break;
            }
            // ヘルプ
            if (event.id == 'mnHelp') {
                return;
            }
            // バージョン
            if (event.id == 'mnVersion') {
                swal.fire({
                    title: swalTitle.INFO,
                    text: 'Broccoly Coder version 0.2.0',
                    icon: swalIcon.INFO,
                });
                return;
            }
            // ログアウト
            if (event.id == 'mnLogout') {
                swal.fire({
                    title: swalTitle.INFO,
                    text: 'ログアウトしてもよろしいですか？',
                    icon: swalIcon.INFO,
                    // buttons: true,
                    showCancelButton: true,
                    // dangerMode: true,
                }).then((answer) => {
                    if (answer.isConfirmed) {
                        // ログアウト
                        this.porc_mnLogout(event);
                    }
                });
                return;
            }
            // 実行
            if (event.id == 'mnRun') {
                this.proc_mnRun(event);
            }
            // ステップ実行
            if (event.id == 'mnStep') {
                this.proc_mnStep(event);
            }
            // 実行停止
            if (event.id == 'mnStop') {
                this.proc_mnStop(event);
            }
            // 画像アップロード
            if (event.id == 'mnUploadImage') {
                this.proc_mnUploadImage(event);
            }
            // パスワードリセット
            if (event.id == 'mnPswdReset') {
                swal.fire({
                    title: swalTitle.INFO,
                    text: '設定されたパスワードをリセットします。よろいいですか？',
                    icon: swalIcon.INFO,
                    // buttons: true,
                    showCancelButton: true,
                    // dangerMode: true,
                }).then((answer) => {
                    if (answer.isConfirmed) {
                        // パスワードリセット
                        this.porc_mnPswdReset(event);
                    }
                });
                return;
            }
            // 退会
            if (event.id == 'mnWithdrawal') {
                swal.fire({
                    title: '退会',
                    text: '退会すると保存された全てのデータも削除されます。退会してもよろしいですか？',
                    icon: 'warning',
                    // buttons: true,
                    showCancelButton: true,
                    // dangerMode: true,
                }).then((answer) => {
                    if (answer.isConfirmed) {
                        // 退会
                        this.porc_mnWithdrawal(event);
                    }
                });
                return;
            }
            // HTMLテンプレート出力
            if (event.id == 'mnOutputHtml') {
                return;
            }
        },
        // ホーム画面へ戻る
        porc_mnHome(/*event*/) {
            // 初期化
            this.$refs.menuBarView.projectName = '';
            this.projectInf.projectName = '';
            this.projectInf.projectKey = '';
            // メニューの操作設定
            this.menuEnabled = false;
            // 画面を遷移
            this.page = 4;
        },
        // 新規プロジェクト
        async porc_mnNewProject(mnObj) {
            // 全タブ削除
            this.$refs.tabView.functionMethod(mnObj);
        },
        // プロジェクトの保存
        async porc_mnSaveProject2(projectName) {
            var pkey = this.projectInf.projectKey;

            if (pkey == '') {
                // /DATAS/PROJECT/プロジェクト名の登録
                pkey = await this.$store.state.firebaseObject.writeProjectDatas(
                    this.$store.state.loginId,
                    projectName,
                    this.$store.state.selectionLanguage,
                );
                // プロジェクトキーを保存
                this.projectInf.projectKey = pkey;
                // プロジェクト名を出力
                this.$refs.menuBarView.projectName = projectName;
            }

            // 選択中タブのコードを更新
            await this.$refs.tabView.updateCurrentTabCode();

            // 展開中のファイルを保存
            // 展開中のタブ数を取得
            var tabCount = this.$refs.tabView.getTabCount();
            var cnt = 0;
            var tabObj = null;

            for (cnt = 0; cnt < tabCount; cnt++) {
                // タブ情報を取得
                tabObj = await this.$refs.tabView.findTabInformationByIndex(cnt);

                if (tabObj != null) {
                    if (tabObj.key == '') {
                        // 新規保存
                        // ファイルを保存
                        var key = await this.$store.state.firebaseObject.writeCodeDatas(
                            this.$store.state.loginId,
                            pkey,
                            tabObj.fileName,
                            tabObj.fileExtension,
                            '', // comment
                            tabObj.code
                        );

                        // 登録キー情報を設定
                        this.$refs.tabView.setTabKey(cnt, key);
                    } else {
                        // 更新
                        if ((tabObj = await this.$refs.tabView.findTabInformationByIndex(cnt)) == null) {
                            continue;
                        }

                        // ファイルを保存
                        await this.$store.state.firebaseObject.updateCodeDatas(
                            this.$store.state.loginId,
                            pkey,
                            tabObj.fileName,
                            tabObj.fileExtension,
                            '', // comment
                            tabObj.code,
                            tabObj.key,
                            tabObj.createdDate
                        );
                    }
                } else {
                    console.log('findTabInformationById is null');
                }
            }
        },
        // プロジェクトの削除
        async porc_mnRemoveProject(event) {

            // console.log('porc_mnRemoveProject: ' + this.$store.state.loginId);
            // console.log('porc_mnRemoveProject: ' + event.key);

            // /DATAS/PROJECT/プロジェクト名の登録
            await this.$store.state.firebaseObject.removeProjectDatas(
                this.$store.state.loginId,
                event.key
            );
        },
        // プロジェクトの読み込み
        async porc_mnLoadProject(event) {

            // console.log('porc_mnLoadProject:' + event.title);

            // プロジェクト情報を保存
            this.projectInf = {
                projectName: event.title,
                projectKey: event.text,
            };

            // データ読み込み
            var retCodes = await this.$store.state.firebaseObject.readProjectDetail([
                this.$store.state.firebaseObject.FIRE_STORE_KEYWORD.DATAS,
                this.$store.state.loginId,
                this.$store.state.firebaseObject.FIRE_STORE_KEYWORD.PROJECTS,
                event.text,
                this.$store.state.firebaseObject.FIRE_STORE_KEYWORD.CODES,
            ]);
            if (retCodes == null) {
                console.log('porc_mnLoadProject:code is not exist.');
                return;
            }

            // 言語選択
            this.$store.dispatch('commitSelectionLanguage', retCodes[0].EXTENSION);

            // プロジェクト名を展開
            this.$refs.menuBarView.projectName = this.projectInf.projectName;
            // すべてのタブを削除
            this.$refs.tabView.removeAllTab();
            // ファイル情報の展開
            var cnt = 0;
            for (cnt = 0; cnt < retCodes.length; cnt++) {

                // タブ情報の追加
                await this.$refs.tabView.addTab(
                    retCodes[cnt].TITLE,
                    retCodes[cnt].EXTENSION,
                    retCodes[cnt].CODE,
                    retCodes[cnt].KEY,
                    retCodes[cnt].CREATED,
                    retCodes[cnt].UPDATED,
                );
            }

            // console.log('porc_mnLoadProject:retCodes:' + retCodes[0].CODE);
            // console.log('porc_mnLoadProject:length:' + retCodes.length);

            // 展開タブを選択
            if (retCodes.length > 0) {
                await this.$refs.tabView.selectedTabByKey(retCodes[0].KEY);
            }
        },
        // ログアウト
        porc_mnLogout(/*event*/) {
            // ログインユーザーを削除
            // vuex データ更新
            this.$store.dispatch('commitLoginId', null);
            this.$store.dispatch('commitFirebaseObject', null);

            // 表示中ログインユーザーをクリア
            this.$refs.menuBarView.setAccountName('');
            // メニューの操作設定
            this.menuEnabled = false;
            // ログインメニューの操作設定
            this.menuLoginEnabled = false;
            // ファイルメニューの操作設定
            this.menuFileEnabled = false;
            // 画面を遷移
            this.page = 0;
        },
        // パスワードリセット
        async porc_mnPswdReset(/*event*/) {
            // firebase
            const fbo = this.$store.state.firebaseObject;
            // パスワードリセット処理
            await fbo.resetPassword(this.$store.state.loginId);
            swal.fire({
                title: swalTitle.INFO,
                text: 'パスワードリセット用メールを送信しました。',
                icon: swalIcon.INFO,
            });
        },
        // 退会
        async porc_mnWithdrawal(/*event*/) {
            // firebase
            var fbo = this.$store.state.firebaseObject;

            // 保存済み関連データを削除
            // /DATAS/の削除
            await fbo.removeDatas(this.$store.state.loginId);
            // /ACCOUNT/loginId/の削除
            await fbo.deleteAccountDatas(this.$store.state.loginId);
            // 退会処理
            await fbo.deleteAccount();

            // メッセージ
            swal.fire({
                title: swalTitle.INFO,
                text: 'アカウントを削除しました。Broccoly Coderのご利用ありがとうございました。',
                icon: swalIcon.INFO,
            });

            // ログアウト
            await this.porc_mnLogout(event);
        },
        // 実行
        async proc_mnRun(/*event*/) {
            // メニューアイテム
            await this.$refs.tabView.functionMethod({
                functionNo: 300,
            });
        },
        // ステップ実行
        async proc_mnStep(/*event*/) {
            // メニューアイテム
            this.$refs.tabView.functionMethod({
                functionNo: 303,
            });
        },
        // 停止
        async proc_mnStop(/*event*/) {
            // メニューアイテム
            this.$refs.tabView.functionMethod({
                functionNo: 301,
            });
        },
        // 画像アップロード
        async proc_mnUploadImage(/*event*/) {
            // メニューアイテム
            this.$refs.tabView.functionMethod({
                functionNo: 304,
            });
        },
        // 画像アップロード3D
        async proc_mnUploadImage3D(/*event*/) {
            // メニューアイテム
            this.$refs.tabView.functionMethod({
                functionNo: 305,
            });
        },
        // パラメーター入力対応
        async paramSupport(sampleNo) {
            // http://localhost:8080/?trial=true&sampleNo=1001

            this.fbo = new firebaseObject();
            // firebaseを初期化
            await this.fbo.initFirebase();
            await this.fbo.startFirebase();
            // Analytics開始
            this.fbo.startAnalytics_link();

            // トライアルログイン
            try {
                // ログイン処理
                if (
                    (await this.fbo.login(
                        process.env.VUE_APP_CONF_GUEST_ID,
                        'trial_user'
                    )) == true)
                {
                    // vuex データ更新
                    this.$store.dispatch(
                        'commitLoginId',
                        process.env.VUE_APP_CONF_GUEST_ID
                    );
                    this.$store.dispatch('commitFirebaseObject', this.fbo);

                    // child -> parent
                    this.$emit('loginProc', "success login trial");

                    // ログイン情報を読み込み
                    var inf = await this.fbo.readAccountInfo(
                        process.env.VUE_APP_CONF_GUEST_ID
                    );
                    // 外部リンク読み込み設定
                    inf.env.EXT_LINK = 1;

                    // vuex データ更新
                    this.$store.dispatch('commitAccountInf', inf);
                } else {
                    // エラーメッセージをを表示
                    this.error_msg_dialog = true;
                }
            } catch (e) {
                console.log(e);
            }

            // トライアルログイン
            if (sampleNo == 0){
                // データロード
                await this.emitFileViewEvent({
                    id: 'trialLongin',
                    text: '',
                    title: '',
                    lang: ''
                });
                //
                return;
            }

            // 読み込みコード生成
            var results = await this.fbo.readExternalLink(sampleNo);
            // データロード
            await this.emitFileViewEvent({
                id: 'openProduct',
                text: results.DOCID,
                title: results.TITLE,
                lang: results.CODELANGUAGE
            });
            // メッセージ
            swal.fire({
                title: '確認',
                text: '「' + results.TITLE + '」を開きます。',
                icon: "info",
            });
        },
    },
};
</script>

