<template>
    <v-card
        max-width='100%'
        height='100%'
        elevation='2'
        class='ml-1 pa-1'
        >
    <div
        style='width:100%; height:100%; overflow: scroll;'
        class='pa-1'
        >
        <pre v-if="texts != ''" style='text-align:left; font-size: 90%; font-family: math;'>{{ texts }}</pre>
        <div v-else style='margin-top: 0%; text-align: left; color: lightgray; font-family:Noto Sans JP; font-size: 150%;'>Code</div>
    </div>
    </v-card>
</template>

<script>
export default {
    name: 'SourceView',

    props: {
        height_b: Number,
        // 生成コード
        generateCode: String,
    },

    data() {
        return {
            // 生成コード行リスト
            items: [
            ],

            texts: '',
        };
    },

    mounted() {
        this.updateCodeList();
    },

    methods: {
        updateCodeList(code) {
            if (code == undefined){
                code = '';
            }
            this.texts = String(code).replace('/r', '&nbsp');
        },
    },
}
</script>
